import { Component, OnInit, NgZone, HostListener } from '@angular/core';
import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'app-default-container',
  templateUrl: './default-container.component.html',
  styleUrls: ['./default-container.component.css']
})
export class DefaultContainerComponent implements OnInit {
  sideBarOpened = true;
  modeSidebar = 'side';
  @HostListener('window:resize', ['$event'])
   onResize(event){
    
      if ( event.target.innerWidth < 890 ) {
        this.modeSidebar = 'over';
        this.sideBarOpened = false;
      } else {
        this.modeSidebar = 'side';
        this.sideBarOpened = true;
      }
     
}
  constructor( private auth: AuthService) {}
  
  ngOnInit(): void {
    this.auth.change.subscribe((resp: boolean) => { this.sideBarOpened =  !this.sideBarOpened; });
     this.modeSide();
  }

  modeSide() {
   if ( window.innerWidth < 890 ) {
     this.modeSidebar = 'over';
     this.sideBarOpened = false;
   }
  }
}

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PaymentsService } from "src/app/services/payments.service";
import { TranslateServices } from "../../../services/translate.service";
import Swal from "sweetalert2";
import { ChatSocketService } from '../../../services/chat-socket.service';

@Component({
  selector: "app-succespayment",
  templateUrl: "./succespayment.component.html",
  styleUrls: ["./succespayment.component.css"],
})
export class SuccespaymentComponent implements OnInit {
  public idDetails: number;
  public loading = false;

  constructor(
    private _paymentService: PaymentsService,
    private activatedRoute: ActivatedRoute,
    private chatSocketService: ChatSocketService,
    private navegation: Router,
    private translate: TranslateServices
  ) {
    this.translate.translateText();
    // Agregamos los lenguajes que seran usados
  }

  ngOnInit(): void {
    this.confirmPayment();
  }

  confirmPayment() {
    this.activatedRoute.params.subscribe((resp: any) => {
      let params = new URLSearchParams(location.search);
      var session_id = params.get("session_id");
      this.loading = true;

      this.idDetails = resp.id;
      this._paymentService
        .paymentResolution(this.idDetails, session_id)
        .subscribe((Response: any) => {
          if (Response.Ok === true) {
            setTimeout(() => {
              this.chatSocketService.paymentNotification(this.idDetails);
              this.chatSocketService.notificationManager();
              this.redirection();
            }, 3000);
          } else if (Response.OK === false) {
            Swal.fire({
              icon: "error",
              text: Response.Error,
            });
            this.navegation.navigateByUrl("payment");
          }
        });
    });
  }
  redirection() {
    this.navegation.navigateByUrl("payment/details-history/" + this.idDetails);
    this.loading = false;
  }
}

<div class="container-fluid" style="height: max-content">
  <div class="row justify-content-center">
    <mat-card id="card">
      <div id="mainContainer">
        <div id="left" *ngIf="showListChat === true">
          <div id="container-search">
            <form
              style="width: 100%"
              class="form-inline my-2 my-lg-0"
              autocomplete="off"
            >
              <input
                class="form-control col"
                type="search"
                placeholder="Search"
                aria-label="Search"
                name="person"
                [(ngModel)]="person"
                (keyup)="search()"
              />
              <button
                class="btn btn-outline ml-1"
                type="button"
                (click)="search()"
              >
                <i class="fa fa-search"></i> {{ "CHAT.search" | translate }}
              </button>
            </form>
          </div>
          <ul
            class="nav nav-pills mb-4 mt-5 justify-content-center"
            id="pills-tab"
            role="tablist"
          >
            <li class="nav-item" role="presentation">
              <a
                class="nav-link active"
                id="pills-chat-tab"
                data-toggle="pill"
                href="#pills-chat"
                role="tab"
                aria-controls="pills-chat"
                aria-selected="true"
              >
                {{ "CHAT.title" | translate }}</a
              >
            </li>
            <li class="nav-item" role="presentation">
              <a
                class="nav-link"
                id="pills-contact-tab"
                data-toggle="pill"
                href="#pills-contact"
                role="tab"
                aria-controls="pills-contact"
                aria-selected="false"
              >
                {{ "CHAT.newDoctor" | translate }}
              </a>
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-chat"
              role="tabpanel"
              aria-labelledby="pills-chat-tab"
            >
              <div class="list-group" id="list-tab" role="tablist">
                <a
                  class="list-group-item list-group-item-action"
                  *ngFor="let list of chats; let i = index"
                  id="list-home-list"
                  data-toggle="list"
                  href="#{{ list.first_name }}"
                  role="tab"
                  aria-controls="home"
                  (click)="
                    getMessage(
                      list.id,
                      list.user.first_name,
                      list.user.last_name,
                      true,
                      list.profile_picture,
                      list.user_id
                    )
                  "
                >
                  <div id="chat-list" class="row">
                    <div class="col-4">
                      <div class="container-img">
                        <div id="circle-img">
                          <img
                            id="imgCard"
                            [src]="list.profile_picture | imgFalse"
                            class=""
                            alt="Doctor image"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-6 text-left">
                      {{ list.user.first_name }} {{ list.user.last_name }}
                    </div>
                    <div class="col-2">
                      <span
                        *ngIf="
                          isRead === true &&
                          isReadIdUser === sendUserId &&
                          list.id === chatIdNotify
                        "
                      >
                        <i class="fa fa-circle text-success"></i>
                      </span>
                    </div>
                  </div>
                </a>
                <div
                  class="col-md-12 text-center overflow-h"
                  *ngIf="chats?.length === 0"
                >
                  <i
                    class="mt-5 fa fa-exclamation-triangle text-warning fa-3x"
                  ></i>
                  <h4 class="text-muted mt-3">
                    {{ "PAYMENT.no-result" | translate }}
                  </h4>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="pills-contact"
              role="tabpanel"
              aria-labelledby="pills-contact-tab"
            >
              <a
                class="list-group-item list-group-item-action"
                *ngFor="let list of chatsWithoutMessage; let i = index"
                id="list-home-list"
                data-toggle="list"
                href="#{{ list.first_name }}"
                role="tab"
                aria-controls="home"
                (click)="
                  getMessage(
                    list.id,
                    list.user.first_name,
                    list.user.last_name,
                    true,
                    list.profile_picture,
                    list.user_id
                  )
                "
              >
                <div id="chat-list">
                  <div class="container-img">
                    <div id="circle-img">
                      <img
                        id="imgCard"
                        [src]="list.profile_picture | imgFalse"
                        class=""
                        alt="Doctor image"
                      />
                    </div>
                  </div>
                  <div class="margin-name">
                    <label
                      >{{ list.user.first_name }}
                      {{ list.user.last_name }}</label
                    >
                  </div>
                  <div>
                    <span
                      *ngIf="
                        isRead === true &&
                        isReadIdUser === sendUserId &&
                        list.id === chatIdNotify
                      "
                    >
                      <i class="fa fa-circle text-success"></i>
                    </span>
                  </div>
                </div>
              </a>
              <div
                class="col-md-12 text-center overflow-h"
                *ngIf="chatsWithoutMessage?.length === 0"
              >
                <i
                  class="mt-5 fa fa-exclamation-triangle text-warning fa-3x"
                ></i>
                <h4 class="text-muted mt-3">
                  {{ "PAYMENT.no-result" | translate }}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div id="right" *ngIf="showListContainer === true">
          <ng-container *ngIf="showMesssage === false; else showChats">
            <div id="color-rules">
              <div class="text-center">
                <h1 class="text-muted mt-2">{{ "CHAT.rules" | translate }}</h1>
                <i class="fa fa-info-circle fa-3x text-warning mb-3 mt-3"></i>
              </div>
              <div class="" id="container">
                <ul id="lis-rules" class="container-rules">
                  <li class="mat-card">
                    <i class="fa fa-times fa-2x info-color"></i>
                    {{ "CHAT.rule1" | translate }}
                  </li>
                  <li class="mat-card">
                    <i class="fa fa-times-circle fa-2x info-color"></i>
                    {{ "CHAT.rule2" | translate }}
                  </li>
                  <li class="mat-card">
                    <i class="fa fa-comment-slash fa-2x info-color"></i>
                    {{ "CHAT.rule3" | translate }}
                  </li>
                  <!-- <li class="mat-card"><i class="fa fa-comment-dots fa-2x info-color"></i> {{ "CHAT.rule4" | translate  }}</li> -->
                </ul>
              </div>
            </div>
          </ng-container>
          <ng-template #showChats>
            <div id="" class="row">
              <div *ngIf="showListChat === false" class="col-2">
                <button
                  type="button"
                  class="btn"
                  (click)="showListChat = true; showListContainer = false"
                >
                  <i class="fa fa-arrow-left info-color"></i>
                </button>
              </div>
              <div
                class="col-4"
                [ngStyle]="{ 'width.%': 8, flex: 0, 'margin-left.px': 20 }"
              >
                <div class="image-chat">
                  <div id="circle-img">
                    <img
                      id="imgCard"
                      [src]="imgUser | imgFalse"
                      class=""
                      alt="Doctor image"
                    />
                  </div>
                </div>
              </div>
              <div class="col-2 ml-2" id="title-name">
                <!-- [ngStyle]="{'width.%': 40, 'flex': 'unset', 'max-width': 'unset', 'margin-left.px': 20}" -->
                <span class=" ">{{ chatTitle }}</span>
              </div>
              <div
                id="date-title"
                *ngIf="reservation_request_id !== null"
                class="col-md-4 ml-2"
              >
                <span class="">
                  {{ payAppointment.schedule_date | date }} |
                  {{ payAppointment.status_message }}
                </span>
              </div>
              <div
                id="pay-div"
                class="col-md-2 ml-2"
                *ngIf="disable == false"
              >
                <span class="">
                  <button
                    type="button"
                    [routerLink]="['/payment/details/', reservation_request_id]"
                    id="pay-btn"
                    class="btn btn-light"
                  >
                  {{ "PAYMENTDETAILS.btn-pay" | translate }}
                  </button>
                </span>
              </div>
            </div>
            <div id="chat-container">
              <div
                *ngFor="
                  let chat of messageSender;
                  let i = index;
                  let lastMessage = last
                "
              >
                <div
                  *ngIf="
                    chat.sending_user_id !== sendUserId;
                    then coming;
                    else sending
                  "
                ></div>
                <ng-template #sending class="text-right">
                  <div class="row justify-content-end">
                    <div class="size-message clearfix">
                      <div id="mat-color" class="mat-card mb-2">
                        <div class="display-info">
                          <div
                            *ngIf="
                              lastMessage === true && chat.isIncoming === false
                            "
                          >
                            <div class="">
                              <a
                                (click)="showEdit()"
                                class="float-right"
                                id="edit"
                              >
                                <i class="fas fa-edit"></i>
                              </a>
                              <div id="show-edit" class="show-edit">
                                <div>
                                  <textarea
                                    [value]="chat.message"
                                    #messageEdited
                                    class="form-control"
                                  ></textarea>
                                  <button
                                    (click)="
                                      sendEditMessage(
                                        messageEdited.value,
                                        chat.id,
                                        chat.chat_id
                                      )
                                    "
                                    class="btn btn-light btn-block mt-2"
                                  >
                                    <i class="fas fa-save">     {{ "settings.btn-save" | translate }}</i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p id="message">{{ chat.message }}</p>
                        </div>
                        <span class="text-muted"
                          >{{ chat.sent_at | date }}
                          {{ chat.sent_at | date: "shortTime" }}
                        </span>
                      </div>
                      <span
                        *ngIf="waitDoctor === true && lastMessage === true"
                        class="text-muted"
                      >
                        {{"CHAT.waitMessage" | translate }}
                      </span>
                    </div>
                  </div>
                </ng-template>
                <ng-template #coming>
                  <div class="row justify-content-start">
                    <div class="size-message clearfix">
                      <div id="mat-color-coming" class="mat-card mb-2">
                        <p id="message">{{ chat.message }}</p>
                        <span class="text-muted"
                          >{{ chat.sent_at | date }}
                          {{ chat.sent_at | date: "shortTime" }}</span
                        >
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
            <form class="form-inline my-2 my-lg-0" autocomplete="off">
              <textarea
                rows="1"
                (keyup.enter)="sendMessage()"
                class="form-control col"
                type="text"
                aria-label="Search"
                name="message"
                #message
                [(ngModel)]="messag"
                placeholder="Enter a new message"
              ></textarea>
              <button
                class="btn btn-outline-secondary"
                type="button"
                (click)="sendMessage()"
              >
                <i class="fab fa-telegram-plane"></i>
              </button>
            </form>
          </ng-template>
        </div>
      </div>
    </mat-card>
  </div>
</div>

<div class="row" id="overflow">
    <!-- <div class="col">
        <i class="fas fa-sync-alt fa-5x fa-spin"></i>
    </div> -->
    <!-- <div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
        <span class="sr-only">Loading...</span>
      </div> -->
      <div class="col">
      <div class="spinner-border  spinner-border-sm" style="width: 3.5rem; height: 3.5rem;" role="status">
        <span class="sr-only">Loading...</span>
      </div>
</div>

import { Component, OnInit } from "@angular/core";

//Nos permite recibir el parametro de la url
import { ActivatedRoute, Router } from "@angular/router";
import { GalleryService } from "../../services/gallery.service";
import { TranslateServices } from "../../services/translate.service";
import { SearchService } from "src/app/services/search.service";

@Component({
  selector: "app-gallery-next",
  templateUrl: "./gallery-next.component.html",
  styleUrls: ["./gallery-next.component.css"],
})
export class GalleryNextComponent implements OnInit {
  oneFromGallery: any[];
  doctor: any = [];
  access: boolean;
  dondeEstoy: String;

  constructor(
    private activatedRoute: ActivatedRoute,
    private galleryService: GalleryService,
    private translate: TranslateServices,
    private search: SearchService,
    private router: Router
  ) {
    this.translate.translateText();
  }
  backClient() {
    this.router.navigate(["gallery"]);
  }
  ngOnInit(): void {
    this.activatedRoute.params.subscribe((resp) => {
      const id = resp.id;
      this.search.getDoctorSelected(id).subscribe((response) => {
        this.doctor = response;
      });
      this.galleryService.getGallery(id).subscribe((resp) => {
        this.oneFromGallery = resp;
      });
    });
  }
}

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PaymentsService } from "src/app/services/payments.service";
import { loadStripe } from "@stripe/stripe-js";
import { async } from "rxjs/internal/scheduler/async";
import { TranslateServices } from "../../../services/translate.service";
//Ventana de alerta
import Swal from "sweetalert2";
import { MatDialog } from "@angular/material/dialog";
import { RefoundDialogComponent } from './refound-dialog/refound-dialog.component';
import { environment } from '../../../../environments/environment';

@Component({
  selector: "app-payment-details",
  templateUrl: "./payment-details.component.html",
  styleUrls: ["./payment-details.component.css"],
})
export class PaymentDetailsComponent implements OnInit {
  public detailsList: any[];
  idDetails: number;
  lang: string;
  is_praxis: boolean;
  private stripe = environment.stripeKey
  // "pk_test_XScUx5s318mc52gLgmHkcyR700Hmz0jhEc";

  constructor(
    private _paymentService: PaymentsService,
    private activatedRoute: ActivatedRoute,
    public translate: TranslateServices,
    public dialog: MatDialog
  ) {
    this.translate.translateText();
  }

  ngOnInit(): void {
    this.getDetails();
    this.lang = navigator.language;
  }

  getDetails() {
    this.activatedRoute.params.subscribe((resp: any) => {
      this.idDetails = resp.id;
      this._paymentService
        .getDetails(Number(resp.id))
        .subscribe((Response: any) => {
          this.detailsList = [Response.reservationRequest];
        });
    });
  }

 async openDialog() {
    const dialogRef = this.dialog.open(RefoundDialogComponent, {
      height: '400px',
      width: '600px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result ==  true) {
        this.createSession();
      }
    });
  }

  async createSession() {
  
    if (this.lang === "es-ES" || this.lang === "es") {
      Swal.fire({
        icon: "info",
        allowOutsideClick: false,
        text: "Espere por favor ...",
      });
    } else if (this.lang === "en-US" || this.lang === "en") {
      Swal.fire({
        icon: "info",
        allowOutsideClick: false,
        text: "Please wait ...",
      });
    } else {
      Swal.fire({
        icon: "info",
        allowOutsideClick: false,
        text: "Please wait ...",
      });
    }
    // Simulación de loading
    Swal.showLoading();
    const stripe = await loadStripe(this.stripe);
    this._paymentService
      .createSesion(this.idDetails)
      .subscribe(async (Response: any) => {
        const { error } = await stripe.redirectToCheckout({
          sessionId: Response.id,
        });

        if (error) {
        } else if (!error) {
          Swal.close();
        }
      });
  }
}

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NewsService } from "../../../services/news.service";

@Component({
  selector: "app-see-news",
  templateUrl: "./see-news.component.html",
  styleUrls: ["./see-news.component.css"],
})
export class SeeNewsComponent implements OnInit {
  publication: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private _newsService: NewsService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((resp) => {
      const id = resp.id;
      this._newsService.getOnePublication(id).subscribe((response: any) => {
        this.publication = response.publication;
      });
    });
  }
}

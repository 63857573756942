<div id="mainContainer1" *ngIf="mainContainer1">

    <h5 id="title">Formulario Dr. {{doctorName}}</h5>

    <div id="formContainer">
        <form [formGroup]="form" autocomplete="off">

            
            

            <div class="inputGroupContainer">

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.name' | translate}} <span class="requiredInput">*</span></mat-label>
                    <input matInput formControlName="first_name">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.lastname' | translate}} <span class="requiredInput">*</span></mat-label>
                    <input matInput formControlName="last_name">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.identification' | translate}}<span class="requiredInput">*</span></mat-label>
                    <input matInput formControlName="identification">
                </mat-form-field>

            </div>

            <div class="inputGroupContainer">

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.email' | translate}} <span class="requiredInput">*</span></mat-label>
                    <input matInput type="email" formControlName="email">
                    <mat-hint id="emailInfo" *ngIf="emailInfoContainer">{{emailInfoTextr}}</mat-hint>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.phone' | translate}} <span class="requiredInput">*</span></mat-label>
                    <input matInput formControlName="phone">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.dateBirth' | translate}} <span class="requiredInput">*</span></mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="dateBirth">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>


            <div class="inputGroupContainer">

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.gender' | translate}} <span class="requiredInput">*</span></mat-label>
                    <mat-select formControlName="gender">
                      <mat-option *ngFor="let item of genderGroup" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.languages' | translate}} <span class="requiredInput">*</span></mat-label>
                    <mat-select multiple formControlName="languages">
                      <mat-option *ngFor="let item of languages" [value]="item">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>


            </div>


            <div class="inputGroupContainer">

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.country' | translate}} <span class="requiredInput">*</span></mat-label>
                    <mat-select formControlName="country">
                      <mat-option *ngFor="let item of countryTotal" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.city' | translate}} <span class="requiredInput">*</span></mat-label>
                    <input matInput formControlName="city">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.address' | translate}} <span class="requiredInput">*</span></mat-label>
                    <input matInput formControlName="address">
                </mat-form-field>

            </div>


            <div class="inputGroupContainer">

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.status' | translate}}</mat-label>
                    <input matInput formControlName="status">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.religion' | translate}}</mat-label>
                    <input matInput formControlName="religion">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.occupation' | translate}}</mat-label>
                    <input matInput formControlName="occupation">
                </mat-form-field>

            </div>


            


            <div id="buttonsFeetAndMetersContainer">
                <mat-button-toggle-group>
                    <mat-button-toggle checked value="feet" (click)="feetAndMetersContainer = true">{{'FORMS-SURGERY.feetButton' | translate}}</mat-button-toggle>
                    <mat-button-toggle value="meters" (click)="feetAndMetersContainer = false">{{'FORMS-SURGERY.meterButton' | translate}}</mat-button-toggle>
                </mat-button-toggle-group>
            </div>

            <div class="feetAndMetersContainer" *ngIf="feetAndMetersContainer">
                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.pounds' | translate}}</mat-label>
                    <input type="number" matInput placeholder="155" formControlName="pounds">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.foot' | translate}}</mat-label>
                    <input type="number"  matInput placeholder="5,2"  formControlName="foot">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>IMC : <span>{{imc | number}}</span></mat-label>
                    <input matInput disabled="true">
                </mat-form-field>
            </div>

            <div class="feetAndMetersContainer" *ngIf="!feetAndMetersContainer">
                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.kg' | translate}}</mat-label>
                    <input type="number"  matInput placeholder="89" formControlName="kg">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.meter' | translate}}</mat-label>
                    <input type="number"  matInput placeholder="1,8" formControlName="meter">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>IMC : <span>{{imc | number}}</span></mat-label>
                    <input matInput disabled="true">
                </mat-form-field>
            </div>


            

            <div class="inputGroupContainer">

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.emergencyPerson' | translate}}</mat-label>
                    <input matInput formControlName="emergencyPerson">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.emergencyPersonPhone' | translate}}</mat-label>
                    <input matInput formControlName="emergencyPersonPhone">
                </mat-form-field>

            </div>


            


            <!------------------------------------------------------------------------------------------------------------------------------------ 
                Formulario dinamico
            ------------------------------------------------------------------------------------------------------------------------------------->
            <form autocomplete="off" [formGroup]="dynamicForm">


                <div class="moduleContainer" *ngFor="let module of moduleList">
                    <div class="title">
                        {{module.name}}
                    </div>

                    <div class="attributesContainer" >


                        <div style="display: flex; flex-wrap: wrap;" >

                            <div *ngFor="let value of module.list_total" [style]="moduleOptionAttribute( value.type )">

                                <div class="inputAttributesContainer" *ngIf="value.type === 'input'" >
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{value.name}}</mat-label>
                                        <input matInput [formControlName]="value.selector">
                                    </mat-form-field>
                                </div>
                               
                                <div class="selectAttributesContainer" *ngIf="value.type === 'select'">
                                    <mat-form-field appearance="outline" >
                                        <mat-label>{{value.name}}</mat-label>
                                        <mat-select [formControlName]="value.selector">
                                          <mat-option *ngFor="let total of value.listValue" [value]="total.id">
                                            {{total.name}}
                                          </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="checkboxAttributesContainer" *ngIf="value.type === 'checkbox'">
                                    <mat-checkbox [formControlName]="value.selector">{{value.name}}</mat-checkbox>
                                </div>
    
                                <div class="textareaAttributesContainer" *ngIf="value.type === 'textarea'">
                                    <mat-form-field  style="width: 100%;" appearance="outline">
                                        <mat-label>{{value.name}}</mat-label>
                                        <textarea #{{value.selector}} [formControlName]="value.selector" matInput></textarea>
                                    </mat-form-field>
                                </div>
                            </div>

                            
                        </div>
    
                    </div>

                </div>

            </form>

            



            
            


        


            <div class="imgGroupContainer">

                <div class="imgOneGroupContainer">
                    <p>{{'FORMS-SURGERY.profile' | translate}}</p>
                    <img [src]="img6"  alt="">
                    <mat-progress-bar *ngIf="imgProgress6" mode="indeterminate"></mat-progress-bar>
                    <button type="button" mat-stroked-button (click)="fileInput6.click()">
                        {{'FORMS-SURGERY.picture_of' | translate}} {{'FORMS-SURGERY.profile' | translate}}
                    </button>
                    <input hidden (change)="onFileSelected( $event, 6 )" #fileInput6 type="file" id="file6">
                </div>

            </div>


            <div class="inputGroupContainer">

                <mat-form-field  appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.reason_query' | translate}}</mat-label>
                    <textarea #{{value.selector}} required formControlName="reason" matInput style="height: 12vh;"></textarea>
                </mat-form-field>

            </div>

            <div class="inputGroupContainer">

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.location' | translate}} <span class="requiredInput">*</span></mat-label>
                    <mat-select formControlName="location">
                        <mat-option *ngFor="let item of locationGroup" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>

            <mat-checkbox style="white-space: pre-wrap;" formControlName="terms">
                {{'FORMS-SURGERY.I_accept_novusdoc_conditions' | translate}} <span class="requiredInput">*</span> <span class="goTo"  (click)="goTo()"> {{'FORMS-SURGERY.link_terms_Conditions' | translate}}</span>
            </mat-checkbox>


            <div id="sendFormProgressContainer" *ngIf="sendFormProgressContainer">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>


            <div id="sendButtonContainer">

                <button mat-flat-button color="warn" (click)="sendForm()">{{'FORMS-SURGERY.sendForm' | translate}}</button>

            </div>

            
            




        </form>
    </div>


    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>


</div>





<!---------------------------------------------------------------------------------------------------------------
    Formulario para dispositivos moviles
---------------------------------------------------------------------------------------------------------------->
<div id="mainContainer2" *ngIf="mainContainer2">

    <h5 id="title">Formulario Dr. {{doctorName}}</h5>
    <div id="formContainer">
        <form [formGroup]="form" autocomplete="off">


            <div class="inputGroupContainer">

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.name' | translate}} <span class="requiredInput">*</span></mat-label>
                    <input matInput formControlName="first_name">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.lastname' | translate}} <span class="requiredInput">*</span></mat-label>
                    <input matInput formControlName="last_name">
                </mat-form-field>

                

            </div>

            <div class="inputGroupContainer">
                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.identification' | translate}} <span class="requiredInput">*</span></mat-label>
                    <input matInput formControlName="identification">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.email' | translate}} <span class="requiredInput">*</span></mat-label>
                    <input matInput type="email" formControlName="email">
                    <mat-hint id="emailInfo" *ngIf="emailInfoContainer">{{emailInfoTextr}}</mat-hint>
                </mat-form-field>


            </div>

            <div class="inputGroupContainer">


                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.phone' | translate}} <span class="requiredInput">*</span></mat-label>
                    <input matInput formControlName="phone">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.dateBirth' | translate}} <span class="requiredInput">*</span></mat-label>
                    <input matInput [matDatepicker]="picker3" formControlName="dateBirth">
                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                    <mat-datepicker #picker3></mat-datepicker>
                </mat-form-field>
            </div>


            <div class="inputGroupContainer">


                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.gender' | translate}} <span class="requiredInput">*</span></mat-label>
                    <mat-select formControlName="gender">
                      <mat-option *ngFor="let item of genderGroup" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.languages' | translate}} <span class="requiredInput">*</span></mat-label>
                    <mat-select multiple formControlName="languages">
                      <mat-option *ngFor="let item of languages" [value]="item">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                

            </div>

            <div class="inputGroupContainer">

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.country' | translate}} <span class="requiredInput">*</span></mat-label>
                    <mat-select formControlName="country">
                      <mat-option *ngFor="let item of countryTotal" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>


            <div class="inputGroupContainer">

                

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.city' | translate}} <span class="requiredInput">*</span></mat-label>
                    <input matInput formControlName="city">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.address' | translate}} <span class="requiredInput">*</span></mat-label>
                    <input matInput formControlName="address">
                </mat-form-field>

            </div>


            <div class="inputGroupContainer">

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.status' | translate}}</mat-label>
                    <input matInput formControlName="status">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.religion' | translate}}</mat-label>
                    <input matInput formControlName="religion">
                </mat-form-field>

            </div>

            <div class="inputGroupContainer">

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.occupation' | translate}}</mat-label>
                    <input matInput formControlName="occupation">
                </mat-form-field>

            </div>


            <div id="buttonsFeetAndMetersContainer">
                <mat-button-toggle-group>
                    <mat-button-toggle checked value="feet" (click)="feetAndMetersContainer = true">{{'FORMS-SURGERY.feetButton' | translate}}</mat-button-toggle>
                    <mat-button-toggle value="meters" (click)="feetAndMetersContainer = false">{{'FORMS-SURGERY.meterButton' | translate}}</mat-button-toggle>
                </mat-button-toggle-group>
            </div>

            <div class="feetAndMetersContainer" *ngIf="feetAndMetersContainer">
                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.pounds' | translate}}</mat-label>
                    <input type="number" matInput placeholder="155" formControlName="pounds">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.foot' | translate}}</mat-label>
                    <input type="number"  matInput placeholder="5,2"  formControlName="foot">
                </mat-form-field>
                <mat-form-field class="imc" appearance="outline">
                    <mat-label>IMC : <span>{{imc | number}}</span></mat-label>
                    <input matInput disabled="true">
                </mat-form-field>
            </div>

            <div class="feetAndMetersContainer" *ngIf="!feetAndMetersContainer">
                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.kg' | translate}}</mat-label>
                    <input type="number"  matInput placeholder="89" formControlName="kg">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.meter' | translate}}</mat-label>
                    <input type="number"  matInput placeholder="1,8" formControlName="meter">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>IMC : <span>{{imc | number}}</span></mat-label>
                    <input matInput disabled="true">
                </mat-form-field>
            </div>



            <div class="inputGroupContainer">

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.emergencyPerson' | translate}}</mat-label>
                    <input matInput formControlName="emergencyPerson">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.emergencyPersonPhone' | translate}}</mat-label>
                    <input matInput formControlName="emergencyPersonPhone">
                </mat-form-field>

            </div>


            
            






            <!------------------------------------------------------------------------------------------------------------------------------------ 
                Formulario dinamico
            ------------------------------------------------------------------------------------------------------------------------------------->
            <form autocomplete="off" [formGroup]="dynamicForm">


                <div class="moduleContainer" *ngFor="let module of moduleList">
                    <div class="title">
                        {{module.name}}
                    </div>

                    <div class="attributesContainer" >


                        <div>

                            <div *ngFor="let value of module.list_total">

                                <div class="inputAttributesContainer" *ngIf="value.type === 'input'" >
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{value.name}}</mat-label>
                                        <input matInput [formControlName]="value.selector">
                                    </mat-form-field>
                                </div>
                               
                                <div class="selectAttributesContainer" *ngIf="value.type === 'select'">
                                    <mat-form-field appearance="outline" >
                                        <mat-label>{{value.name}} </mat-label>
                                        <mat-select [formControlName]="value.selector">
                                          <mat-option *ngFor="let total of value.listValue" [value]="total.id">
                                            {{total.name}}
                                          </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="checkboxAttributesContainer" *ngIf="value.type === 'checkbox'">
                                    <mat-checkbox [formControlName]="value.selector">{{value.name}}</mat-checkbox>
                                </div>
    
                                <div class="textareaAttributesContainer" *ngIf="value.type === 'textarea'">
                                    <mat-form-field  style="width: 100%;" appearance="outline">
                                        <mat-label>{{value.name}}</mat-label>
                                        <textarea #{{value.selector}} [formControlName]="value.selector" matInput></textarea>
                                    </mat-form-field>
                                </div>
                            </div>

                            
                        </div>
    
                    </div>

                </div>

            </form>










            





            <div class="imgGroupContainer">


                <div class="imgOneGroupContainer">
                    <p>{{'FORMS-SURGERY.profile' | translate}}</p>
                    <img [src]="img6"  alt="">
                    <mat-progress-bar *ngIf="imgProgress6" mode="indeterminate"></mat-progress-bar>
                    <button type="button" mat-stroked-button (click)="fileInput6.click()">
                        {{'FORMS-SURGERY.picture_of' | translate}} {{'FORMS-SURGERY.profile' | translate}}
                    </button>
                    <input hidden (change)="onFileSelected( $event, 6 )" #fileInput6 type="file" id="file6">
                </div>

            </div>



            <div class="inputGroupContainer">

                <mat-form-field  style="width: 100%;" appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.reason_query' | translate}}</mat-label>
                    <textarea #{{value.selector}} formControlName="reason" matInput style="height: 12vh;"></textarea>
                </mat-form-field>

            </div>

            <div class="inputGroupContainer">


                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.location' | translate}} <span class="requiredInput">*</span></mat-label>
                    <mat-select formControlName="location">
                        <mat-option *ngFor="let item of locationGroup" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>

            



            <mat-checkbox style="white-space: pre-wrap;" formControlName="terms">
                {{'FORMS-SURGERY.I_accept_novusdoc_conditions' | translate}} <span class="requiredInput">*</span> <span class="goTo" (click)="goTo()"> {{'FORMS-SURGERY.link_terms_Conditions' | translate}}</span>
            </mat-checkbox>


            <div id="sendFormProgressContainer" *ngIf="sendFormProgressContainer">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>

            <div id="sendButtonContainer">

                <button mat-flat-button color="warn" (click)="sendForm()">{{'FORMS-SURGERY.sendForm' | translate}}</button>

            </div>

            
            
            

        </form>
    </div>


    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>


</div>











<!-----------------------------------------------------------------------------------------
    Dialog envio final
------------------------------------------------------------------------------------------->
<div id="dialogConfirmContainer" *ngIf="dialogConfirmContainer">


    <mat-card>
        <form action="" [formGroup]="lastform">

            <h5 id="title">Formulario Dr. {{doctorName}}</h5>

            <div id="dialogCheckboxConfirmContainer">


                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.where_are_you' | translate}}</mat-label>
                    <mat-select formControlName="origin">
                      <mat-option value="1">{{'FORMS-SURGERY.in_consultation' | translate}}</mat-option>
                      <mat-option value="2">{{'FORMS-SURGERY.elsewhere' | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="textareaContainer">

                    <mat-form-field appearance="outline">
                        <mat-label>{{'FORMS-SURGERY.reason' | translate}} <span class="requiredInput">*</span> </mat-label>
                        <textarea matInput formControlName="reason"></textarea>
                    </mat-form-field>
    
                </div>


                
            </div>


        
            <div id="dialogButtonConfirmContainer">
            
                <button mat-flat-button color="warn" (click)="dialogConfirmContainer=false">{{'FORMS-SURGERY.cancel_button' | translate}}</button>
                <button mat-flat-button color="primary" (click)="sendFormDoctorFinal()">{{'FORMS-SURGERY.send_button' | translate}}</button>
            </div>

            <mat-progress-bar *ngIf="dialogConfirmProgressContainer" mode="indeterminate"></mat-progress-bar>


        </form>
        
    </mat-card>


</div>




<!-----------------------------------------------------------------------------------------
    Dialog Inicial
------------------------------------------------------------------------------------------->
<div id="startDialogContainer" *ngIf="startDialogContainer">


    <mat-card>
        <form autocomplete="off" [formGroup]="form">

            <mat-card-subtitle>{{'FORMS-SURGERY.info_text_dialog_direct_form_init' | translate}}</mat-card-subtitle>
            <div class="inputContainer">

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.email_or_identification' | translate}}</mat-label>
                    <input matInput type="email" formControlName="email">

                </mat-form-field>

            </div>


        
            
            <div id="dialogButtonConfirmContainer">
            
                <!-- 
                    <button mat-flat-button color="warn" (click)="closedStartDialog()">{{'FORMS-SURGERY.cancel_button' | translate}}</button>
                -->
                
                <button mat-flat-button color="primary" (click)="existingOrNotPatients()">{{'FORMS-SURGERY.send_button' | translate}}</button>
            </div>

            <mat-progress-bar *ngIf="startDialogContainerProgress" mode="indeterminate"></mat-progress-bar>


        </form>
        
    </mat-card>


</div>



<!-----------------------------------------------------------------------------------------
    Dialog Enviar razon
------------------------------------------------------------------------------------------->
<div id="reasonContainer" *ngIf="reasonContainer">


    
    <mat-card>
        <form autocomplete="off" [formGroup]="form">


            <mat-card-subtitle>{{'FORMS-SURGERY.reason_titel' | translate}}</mat-card-subtitle>
            <div class="inputGroupContainer">

                <mat-form-field  style="width: 100%;" appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.reason_query' | translate}}</mat-label>
                    <textarea #{{value.selector}} formControlName="reason" matInput style="height: 12vh;"></textarea>
                </mat-form-field>

            </div>
            <div class="inputGroupContainer">


                <mat-form-field style="width: 100%;" appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.location' | translate}} <span class="requiredInput">*</span></mat-label>
                    <mat-select formControlName="location">
                        <mat-option *ngFor="let item of locationGroup" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>


        
            
            <div id="dialogButtonConfirmContainer">
            
                <button mat-flat-button color="warn" (click)="closedStartDialog()">{{'FORMS-SURGERY.cancel_button' | translate}}</button>
                <button mat-flat-button color="primary" (click)="sendQueryReason()">{{'FORMS-SURGERY.send_button' | translate}}</button>
            </div>

            <mat-progress-bar *ngIf="reasonContainerProgress" mode="indeterminate"></mat-progress-bar>


        </form>
        
    </mat-card>


</div>









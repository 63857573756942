<h2 mat-dialog-title>Guía de uso</h2>
<div id="searchList" class="row justify-content-center" *ngIf="loading">
  <app-loading class=""></app-loading>
</div>

<mat-dialog-content class="mat-typography fade show" *ngIf="!loading">
  <div class="row" *ngIf="isFirstTime === 'true'">
    <div class="col-md-12">
      <h3 class="mt-3">
        <i class="fas fa-home"></i>
        <span class="ml-3">{{ "START.home" | translate }} </span>
      </h3>
      <video
        autoplay
        controls
        preload="auto"
        [src]="urlVideo.home[0].storage_id"
      ></video>
    </div>
  </div>

  <div class="row" *ngIf="isFirstTime === 'false'">
    <div class="col-md-4 color-background">
      <div class="content">
        <div class="list-group" id="list-tab" role="tablist">
          <a
            class="list-group-item list-group-item-action active"
            id="list-start-list"
            data-toggle="list"
            href="#list-start"
            role="tab"
            aria-controls="start"
          >
            <i class="fas fa-home"></i>
            <span class="ml-1"> {{ "START.home" | translate }} </span>
          </a>
          <a
            class="list-group-item list-group-item-action"
            id="list-form-list"
            data-toggle="list"
            href="#list-form"
            role="tab"
            aria-controls="form"
          >
            <i class="fas fa-file-medical"></i>
            <span class="ml-1"> {{ "START.form" | translate }} </span>
          </a>
          <a
            class="list-group-item list-group-item-action"
            id="list-chat-list"
            data-toggle="list"
            href="#list-chat"
            role="tab"
            aria-controls="chat"
          >
            <i class="fas fa-comments"></i>
            <span class="ml-1"> {{ "START.chat" | translate }} </span></a
          >
          <a
            class="list-group-item list-group-item-action"
            id="list-request-list"
            data-toggle="list"
            href="#list-request"
            role="tab"
            aria-controls="request"
            ><i class="fas fa-list-alt"></i>
            <span class="ml-1"> {{ "START.request" | translate }} </span></a
          >
          <a
            class="list-group-item list-group-item-action"
            id="list-citas-list"
            data-toggle="list"
            href="#list-citas"
            role="tab"
            aria-controls="citas"
          >
            <i class="fas fa-calendar-alt"></i>
            <span class="ml-1"> {{ "START.appointment" | translate }} </span></a
          >
          <a
            class="list-group-item list-group-item-action"
            id="list-gallery-list"
            data-toggle="list"
            href="#list-gallery"
            role="tab"
            aria-controls="gallery"
          >
            <i class="fas fa-images"></i>
            <span class="ml-1"> {{ "START.gallery" | translate }} </span></a
          >
          <a
            class="list-group-item list-group-item-action"
            id="list-config-list"
            data-toggle="list"
            href="#list-config"
            role="tab"
            aria-controls="config"
            ><i class="fas fa-user-cog"></i>
            <span class="ml-1"> {{ "NAVBAR.settings" | translate }} </span></a
          >

          <a
            class="list-group-item list-group-item-action"
            id="list-sugerencias-list"
            data-toggle="list"
            href="#list-sugerencias"
            role="tab"
            aria-controls="sugerencias"
            ><i class="fas fa-info-circle"></i>
            <span class="ml-1"> {{ "NAVBAR.sugerencias" | translate }} </span>
          </a>
        </div>
      </div>
    </div>

    <div class="col-md-8">
      <div class="tab-content" id="nav-tabContent">
        <div
          class="tab-pane fade show active"
          id="list-start"
          role="tabpanel"
          aria-labelledby="list-start-list"
        >
          <h3 class="mt-3 text-white text-center color-background">{{ "NAVBAR.module" | translate }} {{ "START.home" | translate }}</h3>
          <video
            class="fade show"
            controls
            preload="auto"
            [src]="urlVideo.home[0].storage_id"
          ></video>
        </div>
        <div
          class="tab-pane fade"
          id="list-form"
          role="tabpanel"
          aria-labelledby="list-form-list"
        >
          <div class="row">
            <div class="col-md-12">
              <h3 class="mt-3 text-white text-center color-background">{{ "NAVBAR.module" | translate }}  {{ "START.form" | translate }}</h3>
              <video controls [src]="urlVideo.form[0]?.storage_id"></video>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="list-chat"
          role="tabpanel"
          aria-labelledby="list-chat-list"
        >
          <div class="row">
            <div class="col-md-12">
              <h3 class="mt-3 text-white text-center color-background">{{ "NAVBAR.module" | translate }}  {{ "START.chat" | translate }}</h3>
              <video controls [src]="urlVideo.chat[0]?.storage_id"></video>
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          id="list-request"
          role="tabpanel"
          aria-labelledby="list-request-list"
        >
          <div class="row">
            <div class="col-md-12">
              <h3 class="mt-3 text-white text-center color-background">{{ "NAVBAR.module" | translate }}  {{ "START.request" | translate }} </h3>
              <video
                controls
                [src]="urlVideo.new_request[0]?.storage_id"
              ></video>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="list-citas"
          role="tabpanel"
          aria-labelledby="list-citas-list"
        >
          <div class="row">
            <div class="col-md-12">
              <h3 class="mt-3 text-white text-center color-background">{{ "NAVBAR.module" | translate }}  {{ "START.appointment" | translate }}</h3>
              <video
                controls
                [src]="urlVideo.appointment[0]?.storage_id"
              ></video>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="list-gallery"
          role="tabpanel"
          aria-labelledby="list-gallery-list"
        >
          <div class="row">
            <div class="col-md-12">
              <h3 class="mt-3 text-white text-center color-background">{{ "NAVBAR.module" | translate }}  {{ "START.gallery" | translate }}</h3>
              <video controls [src]="urlVideo.gallery[0]?.storage_id"></video>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="list-config"
          role="tabpanel"
          aria-labelledby="list-config-list"
        >
          <div class="row">
            <div class="col-md-12">
              <h3 class="mt-3 text-white text-center color-background">{{ "NAVBAR.module" | translate }}  {{ "NAVBAR.settings" | translate }}</h3>
              <video controls [src]="urlVideo.setting[0]?.storage_id"></video>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="list-sugerencias"
          role="tabpanel"
          aria-labelledby="list-sugerencias-list"
        >
          <div class="row">
            <div class="col-md-12">
              <h3 class="mt-3 text-white text-center color-background">{{ "NAVBAR.module" | translate }}  {{ "NAVBAR.sugerencias" | translate }}</h3>
              <video
                controls
                [src]="urlVideo.suggestion[0]?.storage_id"
              ></video>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{ "MODALCANCEL.close" | translate }}
  </button>
</mat-dialog-actions>

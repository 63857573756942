import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { AppointmentService } from "src/app/services/appointment.service";
import { TranslateServices } from "src/app/services/translate.service";
import Swal from "sweetalert2";
import { ModalResponseCanceledComponent } from "../modal-response-canceled/modal-response-canceled.component";

@Component({
  selector: "app-modal-cancel",
  templateUrl: "./modal-cancel.component.html",
  styleUrls: ["./modal-cancel.component.css"],
})
export class ModalCancelComponent implements OnInit {
  form: FormGroup;
  lang: string;
  showAmount: boolean;
  amount: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ModalCancelComponent>,
    private appointmentService: AppointmentService,
    private translate: TranslateServices,
    public dialog: MatDialog
  ) {
    this.translate.translateText();
  }

  get reasonNoValid() {
    return this.form.get("reason").invalid && this.form.get("reason").touched;
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      reason: ["", [Validators.required]],
    });
  }

  cancelarCita() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    if (this.form.valid) {
      const cancelInformation = {
        idProcedure: this.data.procedureId,
        idPatient: this.data.patiendId,
        reason: this.form.value.reason,
      };

      this.appointmentService
        .cancelAppointment(this.data.id, cancelInformation)
        .subscribe((resp: any) => {
          if (resp.OK === true) {
            this.showAmount = resp.is_refunded;
            this.amount = resp.amount;
            this.dialogRef.close();

            const dialogRef = this.dialog.open(ModalResponseCanceledComponent, {
              width: "40%",
              data: {
                showAmount: this.showAmount,
                amount: this.amount,
              },
            });

            dialogRef.afterClosed().subscribe((result) => {});
          } else if (resp.OK === false) {
            Swal.fire("Error", resp.Error, "error");
          }
        });
    }
  }
}

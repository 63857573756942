import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { FormModel } from "../models/form.model";
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  private url = environment.ApiUrl;
  // Usuario id
  private id: string;

  constructor(private http: HttpClient) {
    this.id = localStorage.getItem("id");
  }

  getLanguage() {
    return this.http.get(`${this.url}/languages`).pipe(
      map((Response) => {
        return Response;
      })
    );
  }

  putInformationSetting(formModel: FormModel) {
    const body = {
      email: formModel.email,
      phone: formModel.phone,
      occupation: formModel.occupation,
      address: formModel.address,
    };
    return this.http
      .put(`${this.url}/update-patient-settings/${this.id}`, body)
      .pipe(
        map((Response) => {
          return Response;
        })
      );
  }

  sendNewLang(newLanguages: any) {
    const lang = {
      languages: newLanguages,
    };
    return this.http
      .put(`${this.url}/update-patient-languages/${this.id}`, lang)
      .pipe(
        map((Response) => {
          return Response
        })
      );
  }
}

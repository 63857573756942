<form [formGroup]="form" (ngSubmit)="cancelarCita()">
  <mat-dialog-content class="mat-typography">
    <h2 class="text-center">{{ "MODALCANCEL.title" | translate }}</h2>
    <h3 class="">
      {{ "MODALCANCEL.subtitle" | translate }}
    </h3>
    <div class="row">
      <div class="col-md-12">
        <span *ngIf="reasonNoValid" class="text-danger">
          {{ "MODALCANCEL.reason" | translate }}
        </span>
        <textarea
          name=""
          class="form-control"
          id="reason"
          cols="5"
          rows="4"
          required
          formControlName="reason"
          [class.is-invalid]="reasonNoValid"
        ></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <ul class="list-group-horizontal mt-4">
          <li>{{ "MODALCANCEL.text-1" | translate }}</li>
          <li>{{ "MODALCANCEL.text-2" | translate }}</li>
          <li>{{ "MODALCANCEL.text-3" | translate }}</li>
        </ul>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>
      {{ "MODALCANCEL.close" | translate }}
    </button>
    <button mat-button type="submit" cdkFocusInitial>
      {{ "MODALCANCEL.send" | translate }}
    </button>
  </mat-dialog-actions>
</form>

import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormControl } from "@angular/forms";
import { SettingsService } from "../../../services/settings.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-modal-language",
  templateUrl: "./modal-language.component.html",
  styleUrls: ["./modal-language.component.css"],
})
export class ModalLanguageComponent implements OnInit {
  lang = new FormControl();
  langList: any[] = [];
  constructor(
    private _settingService: SettingsService,
    public dialogRef: MatDialogRef<ModalLanguageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.setValue();
    this.lenguage();
  }

  lenguage() {
    this._settingService.getLanguage().subscribe((Response: any) => {
      this.langList = Response.Languages;
    });
  }

  setValue() {
    let value = [];
    for (const iterator of this.data) {
      value.push(iterator.id);
      this.lang.setValue(value);
    }
  }

  setNewLanguage() {
    
    let idLang = [];
    for (const iterator of this.lang.value) {
      idLang.push({ id: iterator });
    }
    this._settingService.sendNewLang(idLang).subscribe((Response: any) => {
      if (Response.Ok == true) {
        this.dialogRef.close();
        Swal.fire({
          icon: "success",
          title: Response.Message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: Response.Message,
        });
      }
    });
  }
}

<mat-dialog-content>


  <form [formGroup]="form">
    <div class="row justify-content-center">
      <i class="fa fa-info-circle color-info fa-5x mb-4 mt-3"> </i>
    </div>
    <p class="text-center text-title">
      {{ "MODALAPPOINTMENT.request" | translate }}:
      <strong> {{ doctorInfo }} </strong>
    </p>
    <label style="text-align: left" class="form-text mt-4">
      {{ "MODALAPPOINTMENT.title-treatment" | translate }}
    </label>
    <div class="margin">
      <small *ngIf="procedureDoctor" class="text-danger">
        {{ "validators.complete" | translate }}
      </small>
      <select
        formControlName="procedure"
        [class.is-invalid]="procedureDoctor"
        #procedure
        class="form-control"
        id="selected"
      >
        <option selected>
          {{ "MODALAPPOINTMENT.option-title" | translate }}
        </option>
        <option
          [value]="item.identifier"
          *ngFor="let item of procedureByDoctor"
        >
          {{ item.name }}
        </option>
      </select>
    </div>
    <div class="mb-4" style="text-align: left ">
      <label style="text-align: left" class="mt-3 ">
        {{ "MODALAPPOINTMENT.reason" | translate }}
      </label>
      <br />
      <small *ngIf="reasonNoValid" class="text-danger">{{
        "validators.complete" | translate
      }}</small>
      <textarea
        [class.is-invalid]="reasonNoValid"
        placeholder="type..."
        formControlName="reason"
        style="resize: none"
        rows="6"
        #reason
        class="form-control"
      ></textarea>
    </div>
    <p class="text-info">
      <strong>
        {{ "MODALAPPOINTMENT.send-info" | translate }}
        {{ doctorInfo }}
      </strong>
    </p>

    <div class="form-group col-md-12" *ngIf="showOrigin">
      <label for=""
        >{{ "MODALAPPOINTMENT.clinic-title" | translate }}
        <span class="text-danger"> * </span></label
      >
      <select
       [class.is-invalid]="originNoValid"
       class="form-control custom-select"
       id="inputGroupSelect01"
       formControlName="origin"
      >
      <option value="" selected>
        {{ "MODALAPPOINTMENT.clinic-title" | translate }}
      </option>
        <option value="1">
       {{ "MODALAPPOINTMENT.clinic" | translate }}
        </option>
        <option value="2">
          {{ "MODALAPPOINTMENT.noclinic" | translate }}
        </option>
      </select>
    </div>

    <div class="form-group form-check">
      <input
        type="checkbox"
        class="form-check-input"
        formControlName="notFalseInformation"
        id="exampleCheck1"
        [class.is-invalid]="notFalseInformationNoValid"
      />
      <label class="form-check-label small"
        >
        {{ "FORMS-SURGERY.rule1" | translate }}
        
      </label>
      <span class="text-danger small" *ngIf="notFalseInformationNoValid"
        >{{ "ACCOUNT.required" | translate }} 
      </span>
    </div>

    <div class="form-group form-check">
      <input
        type="checkbox"
        class="form-check-input"
        formControlName="allowSavingInformation"
        id="exampleCheck1"
        [class.is-invalid]="allowSavingInformationNoValid"
      />
      <label class="form-check-label small"
        >
        {{ "FORMS-SURGERY.rule2" | translate }}
        
      </label>
      <span class="text-danger small" *ngIf="allowSavingInformationNoValid"
        >{{ "ACCOUNT.required" | translate }} 
      </span>
    </div>

    <div class="form-group form-check">
      <input
        type="checkbox"
        class="form-check-input"
        formControlName="changeFlight"
        id="exampleCheck1"
        [class.is-invalid]="changeFlightNoValid"
      />
      <label class="form-check-label small"
        >
        {{ "FORMS-SURGERY.rule3" | translate }}
      </label>
      <span class="text-danger small" *ngIf="changeFlightNoValid"
        >{{ "ACCOUNT.required" | translate }} 
      </span>
    </div>
  </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="btn btn-light">
    {{ "MODALAPPOINTMENT.close" | translate }}
  </button>
  <button
    class="btn btn-secondary"
    mat-button
    (click)="requestAppointment(procedure.value, reason.value)"
  >
    {{ "MODALAPPOINTMENT.request-btn" | translate }}
  </button>
</mat-dialog-actions>

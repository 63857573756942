import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FlagService {


  // Botones visibles del navbar
  navbarButtonsActive: boolean = false;

  // Botones visibles dentro del cliente dependiendo si entramos desde search o desde requestList
  clientButtonsActive: boolean = false;



  constructor() {
    // Almacenamos el valor devuelto de la posicion 
    this.navbarButtonsActive = JSON.parse(localStorage.getItem('position'));

    //Almacenammos el valor devuelto desde donde entramos
    this.clientButtonsActive = JSON.parse(localStorage.getItem('entryClient'));
  }

  //Enviamos a la bandera si estamos logueados o no diciendole en le contructor que estamos en home o start
  //pasamos los datos desde home.ts y start.ts
  getNavbarButtonsActive( position: string ) {
    
    if ( position === "home" ){
      this.navbarButtonsActive = false;
      localStorage.setItem('position', JSON.stringify(this.navbarButtonsActive));

    }
    else if ( position === "start" ) {

      this.navbarButtonsActive = true;
      localStorage.setItem('position', JSON.stringify(this.navbarButtonsActive));

    }
    
  }

  getClientButtonsActive( position: string ) {
    
    if ( position === "search" ){
      this.clientButtonsActive = false;
      localStorage.setItem('entryClient', JSON.stringify(this.navbarButtonsActive));

    }
    else if ( position === "request" ) {

      this.clientButtonsActive = true;
      localStorage.setItem('entryClient', JSON.stringify(this.navbarButtonsActive));

    }
    
  }
}

<div id="main-Container">
  <div id="firstDiv">
    <div class="container">
      <div class="row">
        <div class="col">
          <h1>{{ "HOME.h1" | translate }}</h1>
          <p>{{ "HOME.h1-p" | translate }}</p>
        </div>
      </div>
    </div>
  </div>

  <div id="secondDiv" class="container">
    <div class="row">
      <div class="col-12 col-sm-6 col-md-3">
        <div id="img">
          <i class="fas fa-comments"></i>
        </div>
        <h3>{{ "HOME.communication" | translate }}</h3>
        <p>{{ "HOME.communication-text" | translate }}</p>
      </div>
      <div class="col-12 col-sm-6 col-md-3">
        <div id="img">
          <i class="fas fa-map-marked-alt"></i>
        </div>
        <h3>{{ "HOME.locator" | translate }}</h3>
        <p>{{ "HOME.locator-text" | translate }}</p>
      </div>
      <div class="col-12 col-sm-6 col-md-3">
        <div id="img">
          <i class="far fa-clock"></i>
        </div>
        <h3>{{ "HOME.speed" | translate }}</h3>
        <p>{{ "HOME.speed-text" | translate }}</p>
      </div>
      <div class="col-12 col-sm-6 col-md-3">
        <div id="img">
          <i class="fas fa-info-circle"></i>
        </div>
        <h3>{{ "HOME.information" | translate }}</h3>
        <p>{{ "HOME.information-text" | translate }}</p>
      </div>
    </div>
  </div>

  <div id="thirdDiv">
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-6">
          <ul>
            <li>{{ "HOME.clinic" | translate }}</li>
            <li>{{ "HOME.surgeons" | translate }}</li>
            <li>{{ "HOME.cardiologists" | translate }}</li>
            <li>{{ "HOME.Endocrinologists" | translate }}</li>
            <li>{{ "HOME.aesthetic" | translate }}</li>
            <li>{{ "HOME.houses" | translate }}</li>
          </ul>
        </div>
        <div class="col-12 col-sm-6 col-md-6">
          <h2>{{ "HOME.collaborate" | translate }}</h2>
          <button type="button" class="btn btn-outline-success" (click)="go()">
            {{ "HOME.button-collaborate" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imgUpload'
})
export class ImgUploadPipe implements PipeTransform {

  transform(imgUpload: boolean ): boolean {
 


    if ( imgUpload === false ) {
      return   true;
    // } else {
    //   return  false;
    // }
    }
    // if ( url === false ) {
    //   return "assets/img/drop-images.png"
    //  }
  }

}

import { Component, OnInit } from "@angular/core";
import { TranslateServices } from "../../services/translate.service";

@Component({
  selector: "app-privacy-policy",
  templateUrl: "./privacy-policy.component.html",
  styleUrls: ["./privacy-policy.component.css"],
})
export class PrivacyPolicyComponent implements OnInit {
  constructor(private translate: TranslateServices) {
    this.translate.translateText();
  }

  ngOnInit(): void {}
}

import { Component, OnInit, HostListener } from "@angular/core";
import { AuthService } from "../../../services/auth.service";
import { ChatSocketService } from "src/app/services/chat-socket.service";
import { TranslateServices } from "../../../services/translate.service";
import Swal from "sweetalert2";
import { UploadImageService } from "../../../services/upload-image.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  dropdown: any = document.getElementsByClassName("dropdown-btn");
  i: any;
  moveText = false;
  nameUser: string;
  //profile_picture: string;
  checkNotification: any;
  showBagdet: any;

  constructor(
    private auth: AuthService,
    public _socketService: ChatSocketService,
    public translate: TranslateServices,
    public imageService: UploadImageService
  ) {
    this.translate.translateText();
  }

  ngOnInit(): void {
  
    this.dropMenu();
    this.getImages();
    this.moveInfo();
    this.nameUser = localStorage.getItem("name");

    this._socketService.checkNotification().subscribe( (Response: any) => {
      this.checkNotification = Response;

      this._socketService.paymenttNotificationCount =  Response.paymentCount;
      this._socketService.paymentmentBadget = Response.payment;

    });
    // this detect the internet connection
    this.auth.detectInternerConection().subscribe((res) => {
      let Toast;
      if (res === false) {
        Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showCloseButton: true,
          width: "50vw",
          background: "#f8d7da",
        });
        Toast.fire({
          icon: "error",
          title:
            " <span style='color:#721c24'>No hay conexión a internet</span>",
          showConfirmButton: false,
          iconColor: "#721c24",
        });
      } else if (res === true) {
        Swal.close();
      }
    });

    this._socketService.Notifications().subscribe((Response: any) => {
      localStorage.setItem("userComing", "139");
      localStorage.setItem("newMessages", "true" );
      localStorage.setItem("chatid", "25" );
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showCloseButton: true,
        timer: 8000,
        timerProgressBar: true,
      });
      Toast.fire({
        icon: "info",
        title: Response.user_full_name + " ha enviado un nuevo mensaje",
        showConfirmButton: false,
      });
    });
  }
  getImages() {
    this.imageService.getImages().subscribe((resp: any) => {
      if (resp.patient_image) {
        this.imageService.profile_picture = resp.patient_image[0].url;
      }
    });
  }

  // metodo para abrir o cerrar el drop-down
  dropMenu() {
    for (this.i = 0; this.i < this.dropdown.length; this.i++) {
      this.dropdown[this.i].addEventListener("click", function () {
        this.classList.toggle("active");
        var dropdownContent = this.nextElementSibling;
        if (dropdownContent.style.display === "block") {
          dropdownContent.style.display = "none";
        } else {
          dropdownContent.style.display = "block";
        }
      });
    }
  }

  showBadget() {
    this._socketService.paymentmentBadget = false;
  } 
  // permite cerrar el sidebar cuando la pantalla es menor a 890px
  toggleSideBar() {
    if (window.innerWidth < 890) {
      this.auth.toggle();
    }
  }

 
  // cierra el sidebar cuando se le da click al chat

  // Listener para detectar lod cambios del tamaño en la ventana
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (event.target.innerWidth < 890) {
      this.moveText = true;
    } else {
      this.moveText = false;
    }
  }

  // mueve la informacion del navbar al sidebar cuando la pantalla es menor a 890px
  moveInfo() {
    if (window.innerWidth < 890) {
      this.moveText = true;
    } else {
      this.moveText = false;
    }
  }
}

<div class="container-fluid mat-card">
  <div class="row text-center">
    <div class="col-md-12 mt-4">
      <i class="fas fa-user-cog fa-4x mb-2 color-icon"></i>
      <h1 class="text-center">{{ "NAVBAR.settings" | translate }}</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <form [formGroup]="form" (ngSubmit)="update()" class="form-padding">
        <div class="form-row">
          <div class="form-group col-md-4 padding-input">
            <label> {{ "FORMS-SURGERY.name" | translate }} </label>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text color-icon fa fa-user" id="basic-addon1">
                </span>
              </div>
              <input
                type="text"
                formControlName="first_name"
                [class.is-invalid]="nameNoValid"
                readonly
                class="form-control"
                placeholder="Name"
                aria-label="Username"
              />
            </div>

            <label>{{ "FORMS-SURGERY.email" | translate }}</label>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text fa fa-envelope color-icon" id="basic-addon1">
                </span>
              </div>
              <input
                type="email"
                formControlName="email"
                [class.is-invalid]="emailNoValid"
                class="form-control"
                placeholder="Email"
                aria-label="Eamil"
              />
            </div>

            <label>{{ "FORMS-SURGERY.address" | translate }}</label>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  <i class="fas fa-map-marker-alt color-icon"></i>
                </span>
              </div>
              <input
                type="text"
                formControlName="address"
                [class.is-invalid]="addressNoValid"
                class="form-control"
                placeholder="Address"
                aria-label="Address"
              />
            </div>

            <div class="form-group">
              <label>{{ "ACCOUNT.lenguage" | translate }}</label>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" for="inputGroupSelect01">
                    <i class="fas fa-language color-icon"></i>
                  </span>
                </div>
                <select
                  formControlName="lenguage"
                  class="form-control"
                  class="custom-select"
                  id="inputGroupSelect01"
                >
                  <option *ngFor="let item of languague">
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!--  column 2 -->

          <div class="form-group col-md-4 padding-input">
            <label>{{ "FORMS-SURGERY.lastname" | translate }}</label>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text fa fa-user color-icon" id="basic-addon1">
                </span>
              </div>
              <input
                type="text"
                formControlName="last_name"
                [class.is-invalid]="lastnameNoValid"
                readonly
                class="form-control"
                placeholder="Last name"
                aria-label="Last name"
              />
            </div>

            <label>{{ "FORMS-SURGERY.phone" | translate }}</label>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  <i class="fas fa-phone color-icon"></i>
                </span>
              </div>
              <input
                type="text"
                formControlName="phone"
                [class.is-invalid]="phoneNoValid"
                class="form-control"
                placeholder="phone"
                aria-label="phone"
              />
            </div>
            <label>{{ "FORMS-SURGERY.occupation" | translate }}</label>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  <i class="fas fa-laptop-house color-icon"></i>
                </span>
              </div>
              <input
                type="text"
                formControlName="occupation"
                class="form-control"
                placeholder="occupation"
                aria-label="occupation"
              />
            </div>

            <label>{{ "FORMS-SURGERY.n-lang" | translate }}</label>
            <div>
              <button
                type="button"
                class="btn btn-outline-secondary form-control-range"
                (click)="openDialog()"
              >
                <i class="fas fa-globe color-icon"></i>
                {{ "settings.title-modal" | translate }}
              </button>
            </div>
          </div>
          <div class="form-group padding-input col-md-4">
            <!--Targetas para la captura de imagenes-->
            <label> {{ "settings.profile" | translate }}</label>
            <div class="card card-image">
              <mat-card>
                <div class="container-img">
                  <div id="circle-img">
                      <img id="imgCard" [src]="imageService.profile_picture || imgGlobal" class="" alt="Doctor image">
                  </div>
                </div>
                <!-- <img [src]="url2 | imgFalse" class="card-img-top" /> -->
                <div class="card-body">
                  
                  <div class="custom-file bottonImgContainer">

                    <button type="button" mat-stroked-button  (click)="fileInput6.click()">
                      {{ "settings.file" | translate }}
                    </button>
                    <input hidden (change)="onFileSelected( $event )" #fileInput6 type="file" id="file6">


                    <!--   

                    <input
                      type="file"
                      name="file2"
                      aria-describedby="inputGroupFileAddon04"
                      id="file2"
                      class="inputfile custom-file-input"
                      (click)="imgContainer = 2"
                      (change)="onselectFile($event)"
                    />
                    <label id="label-browse" class="custom-file-label" for="inputGroupFile04">
                      <p id="p-browse">
                        {{ "settings.file" | translate }} hola
                      </p>
                    </label>

                    -->
                    
                  </div>
                </div>
              </mat-card>
            </div>
            <label>{{ "FORMS-SURGERY.c-password" | translate }}</label>
            <button
              class="btn btn-outline-secondary form-control-range"
              [routerLink]="['/forgot-password-private']"
              routerLinkActive="router-link-active"
            >
              <i class="fas fa-unlock-alt color-icon"> </i>
              {{ "settings.btn-password" | translate }}
            </button>
          </div>
        </div>
        <div class="form-row justify-content-end mt-4">
          <div class="form-group padding-input col-md-4">
            <div id="sendButton">
              <button type="submit" class="btn btn-send btn-block">
                <i class="fas fa-save"></i>
                {{ "FORMS-SURGERY.save" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

import { Component, OnInit } from "@angular/core";
import { TranslateServices } from "../../services/translate.service";
import { FormGroup, NgForm } from "@angular/forms";

//Ventana de alerta
import Swal from "sweetalert2";
import { ForgotPasswordService } from "../../services/forgot-password.service";
import { FlagService } from "../../services/flag.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"],
})
export class ForgotPasswordComponent implements OnInit {
  lang: string;

  constructor(
    private translate: TranslateServices,
    private forgot: ForgotPasswordService,
    public flag: FlagService
  ) {
    this.translate.translateText();
  }

  ngOnInit(): void {
    this.lang = navigator.language;
  }

  sendEmail(form: NgForm) {
    Swal.fire({
      icon: "info",
      allowOutsideClick: false,
      text: "Espere por favor ...",
    });

    // Simulation de loading
    Swal.showLoading();

    // Envia al servicio toda la informacion necesaria y devuelve una respuesta
    this.forgot.sendEmailService(form.value).subscribe((resp: any) => {
      Swal.close();
      if (resp.OK === true) {
        if (this.lang === "es-ES" || this.lang === "es") {
          Swal.fire({
            icon: "success",
            allowOutsideClick: false,
            title: resp.message,
            text:
              "Email enviado correctamente, por favor vaya a su email para restaurar su contraseña.",
          });
        } else if (this.lang === "en-US" || this.lang === "en") {
          Swal.fire({
            icon: "success",
            allowOutsideClick: false,
            title: resp.message,
            text:
              "Email sent successfully, please go to your email to reset your password.",
          });
        } else {
          Swal.fire({
            icon: "success",
            allowOutsideClick: false,
            title: resp.message,
            text:
              "Email sent successfully, please go to your email to reset your password.",
          });
        }

        form.reset();
      } else if (resp.OK === false) {
        if (this.lang === "es-ES" || this.lang === "es") {
          Swal.fire({
            icon: "error",
            title: "Error al enviar email",
            text: resp.Error,
          });
        } else if (this.lang === "en-US" || this.lang === "en") {
          Swal.fire({
            icon: "error",
            title: "Error sending email",
            text: resp.Error,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error sending email",
            text: resp.Error,
          });
        }
      }
    });

    // validamos si los campos son validos en caso contrario salimos
    if (form.invalid) {
      return;
    }
  }
}

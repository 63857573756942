import { Injectable } from "@angular/core";
import { DoctorModel } from "../models/doctor.model";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import * as io from 'socket.io-client';
import { TranslateService } from "@ngx-translate/core";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class SearchService {
  private url = environment.ApiUrl;

  // Recibimos la bbdd de nuestros cards
  private doctorList: DoctorModel[];
  idUser: string;
  languague: string;
  private socket;

  constructor(private http: HttpClient,
    private translate: TranslateService) {
      this.languague = this.translate.getBrowserLang();
      this.socket = io(environment.SOCKET_ENDPOINT)
    }

  // get Contries
  getContries() {
    return this.http
      .get(`${this.url}/countries`)
      .pipe(map((resp: any) => resp.Country));
  }

  getProcedureType() {
    return this.http
      .get(`${this.url}/procedureType`)
      .pipe(map((resp: any) => resp.ProcedureType));
  }

  // Busqueda de doctor
  getDoctorList() {
    return this.http.get(`${this.url}/search-doctors`).pipe(
      map((resp: any) => { 
       return resp.Doctor;
      })
    );
  }

   // Obtener paises de doctores
   getCountryList() {
    return this.http.get(`${this.url}/countries-actived`).pipe(
      map((resp: any) => { 
       return resp;
      })
    );
  }

  // Obtener doctor buscado
  getSearchDoctor(items: any) {
    return this.http.post(`${this.url}/search-doctors`, items).pipe(
      map((resp: any) => {
        return resp.Doctor;
      })
    );
  }

  // Pages Doctor vista de un solo doctor
  getDoctorSelected(id: string) {
    return this.http
      .get(`${this.url}/search-doctor-public/` + id)
      .pipe(map((resp: any) => resp.Doctor));
  }
  // validar si el formulario esta completo
  validateFormComplete() {
    this.idUser = localStorage.getItem("id");
    return this.http
      .get(`${this.url}/validate-form/` + this.idUser)
      .pipe(map((resp) => resp));
  }

  putFavorite(id: string) {
    this.idUser = localStorage.getItem("id");
    const body = {
      doctor_id: id,
    };
    return this.http.post(`${this.url}/new-favorite/` + this.idUser, body).pipe(
      map((resp) => {
        return resp;
      })
    );
  }
  deleteFavorite(id: string) {
    this.idUser = localStorage.getItem("id");
    const body = {
      doctor_id: id,
    };
    return this.http
      .post(`${this.url}/delete-favorite/` + this.idUser, body)
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }
  getFavorite() {
    this.idUser = localStorage.getItem("id");
    return this.http
      .get(`${this.url}/get-favorite/` + this.idUser)
      .pipe(map((resp: any) => resp.Favorites));
  }

  sendRequest(info: any) {
    this.idUser = localStorage.getItem("id");
    const body = {
      reason: info.reason,
      doctor_id: info.doctorId,
      origin: info.origin,
      procedure_type_identifier: info.selectedInformacion,
      assistants_agreement: info.assistants_agreement,
      flight_agreement : info.flight_agreement,
      information_agreement : info.information_agreement
    };

    return this.http.post(`${this.url}/new-request/` + this.idUser, body).pipe(
      map((resp) => {
        this.sendNotificationDoctor(info.doctorId, info.origin);
        return resp;
      })
    );
  }

  // put doctor favorite

  putNotificationDoctor(doctorId) {
    this.idUser = localStorage.getItem("id");
    const body = {
      doctor_id: doctorId,
    };
    return this.http
      .put(`${this.url}/update-favorite/` + this.idUser, body)
      .pipe(map((resp: any) => resp));
  }

  getNotification() {
    this.idUser = localStorage.getItem("id");
    return this.http.get(`${this.url}/get-notification/` + this.idUser).pipe(
      map((item: any) => {
        return item.notifications;
      })
    );
  }
  
  sendNotificationDoctor( doctorId: string, origin: number) {
    this.idUser = localStorage.getItem('idUser');
    const data = {
     doctor_id: doctorId,
     origin: origin,
     user_id: this.idUser
    }
    this.socket.emit('NotificationNewRequest', data);
  }
 
}

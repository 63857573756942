import { BrowserModule } from "@angular/platform-browser";
import { NgModule} from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

// Para evitar que haga la pagina un refres al pulsar button de submit
// Angular tomara control de los formularios
// Importación de formulario reactivo
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// Permite la internacionalizacion de la app
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
// Funcion encargada de conectar con la carpeta de traduccion
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

// Pages
import { NavbarComponent } from "./pages/shared/navbar/navbar.component";
import { FooterComponent } from "./pages/shared/footer/footer.component";
import { HomeComponent } from "./pages/home/home.component";
import { StartComponent } from "./pages/start/start.component";
import { AboutComponent } from "./pages/about/about.component";
import { LoginComponent } from "./pages/login/login.component";
import { CreateAccountComponent } from "./pages/create-account/create-account.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { FormsComponent } from "./pages/forms/forms.component";
import { FormSurgeryComponent } from "./pages/form-surgery/form-surgery.component";
import { FormGeneralComponent } from "./pages/form-general/form-general.component";
import { FormEstheticComponent } from "./pages/form-esthetic/form-esthetic.component";
import { ChatComponent } from "./pages/chat/chat.component";
import { GalleryComponent } from "./pages/gallery/gallery.component";
import { SearchComponent } from "./pages/search/search.component";
import { AppointmentComponent } from "./pages/appointment/appointment.component";
import { NewsComponent } from "./pages/news/news.component";
import { GalleryNextComponent } from "./pages/gallery-next/gallery-next.component";
import { FavoriteComponent } from "./pages/favorite/favorite.component";
import { RequestListComponent } from "./pages/request-list/request-list.component";
import { ClientComponent } from "./pages/client/client.component";
import { LoadingComponent } from "./pages/shared/loading/loading.component";
import { PrivacyPolicyComponent } from "./pages/privacy-policy/privacy-policy.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SidebarComponent } from "./pages/shared/sidebar/sidebar.component";
import { DefaultContainerComponent } from "./layouts/default-container/default-container.component";

// Angular Material
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSelectModule } from "@angular/material/select";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from "@angular/material/list";
import { MatCardModule } from "@angular/material/card";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatBadgeModule } from '@angular/material/badge';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


import { ChangePasswordComponent } from "./pages/change-password/change-password.component";
import { ImgFalsePipe } from "./pipes/img-false.pipe";
import { ImgUploadPipe } from "./pipes/img-upload.pipe";
import { ChatSocketService } from "./services/chat-socket.service";
import { PaymentComponent } from "./pages/payment/payment.component";
import { PaymentDetailsComponent } from "./pages/payment/payment-details/payment-details.component";
import { MatDialogModule } from "@angular/material/dialog";
import { PaymentLayoutComponent } from "./pages/payment/payment-layout/payment-layout.component";
import { PaymentDetailsHistoryComponent } from "./pages/payment/payment-details-history/payment-details-history.component";
import { SuccespaymentComponent } from "./pages/payment/succespayment/succespayment.component";
import { QRFormComponent } from "./pages/qr-form/qr-form.component";
import { TermsConditionsComponent } from "./pages/terms-conditions/terms-conditions.component";
import { ModalAppointmentComponent } from "./pages/client/modal-appointment/modal-appointment.component";
import { SettingsComponent } from "./pages/settings/settings.component";
import { ModalLanguageComponent } from "./pages/settings/modal-language/modal-language.component";
import { SeeNewsComponent } from "./pages/news/see-news/see-news.component";
import { RefoundDialogComponent } from "./pages/payment/payment-details/refound-dialog/refound-dialog.component";
import { ModalCancelComponent } from "./pages/appointment/modal-cancel/modal-cancel.component";
import { ModalResponseCanceledComponent } from "./pages/appointment/modal-response-canceled/modal-response-canceled.component";
import { ModalUserFirstTimeComponent } from "./pages/shared/navbar/modal-user-first-time/modal-user-first-time.component";
import { TranslateServices } from "./services/translate.service";
import { DirectFormComponent } from './pages/direct-form/direct-form.component';
import { DirectFormAestheticComponent } from './pages/direct-form/direct-form-aesthetic/direct-form-aesthetic.component';
import { DirectFormSurgeryComponent } from './pages/direct-form/direct-form-surgery/direct-form-surgery.component';
import { DirectFormGeneralComponent } from './pages/direct-form/direct-form-general/direct-form-general.component';

import { FormEsthetic2Component } from './pages/form-esthetic2/form-esthetic2.component';
import { FormSurgery2Component } from './pages/form-surgery2/form-surgery2.component';
import { FormGeneral2Component } from './pages/form-general2/form-general2.component';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    StartComponent,
    AboutComponent,
    LoginComponent,
    CreateAccountComponent,
    ContactComponent,
    ForgotPasswordComponent,
    FormsComponent,
    FormSurgeryComponent,
    FormGeneralComponent,
    FormEstheticComponent,
    ChatComponent,
    GalleryComponent,
    SearchComponent,
    AppointmentComponent,
    NewsComponent,
    GalleryNextComponent,
    FavoriteComponent,
    RequestListComponent,
    ClientComponent,
    LoadingComponent,
    PrivacyPolicyComponent,
    SidebarComponent,
    DefaultContainerComponent,
    ChangePasswordComponent,
    ImgFalsePipe,
    ImgUploadPipe,
    PaymentComponent,
    PaymentDetailsComponent,
    PaymentLayoutComponent,
    PaymentDetailsHistoryComponent,
    SuccespaymentComponent,
    QRFormComponent,
    TermsConditionsComponent,
    ModalAppointmentComponent,
    SettingsComponent,
    ModalLanguageComponent,
    SeeNewsComponent,
    RefoundDialogComponent,
    ModalCancelComponent,
    ModalResponseCanceledComponent,
    ModalUserFirstTimeComponent,
    DirectFormComponent,
    DirectFormAestheticComponent,
    DirectFormSurgeryComponent,
    DirectFormGeneralComponent,
    FormEsthetic2Component,
    FormSurgery2Component,
    FormGeneral2Component,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatSelectModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatBadgeModule,
    MatButtonModule,
    MatDividerModule,
    MatMenuModule,
    MatExpansionModule,
    MatCardModule,
    MatListModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [TranslateModule],
  providers: [ChatSocketService],
  bootstrap: [AppComponent],
})
export class AppModule {

}

import { Component, OnInit } from '@angular/core';
import { TranslateServices } from "../../services/translate.service";
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  constructor( public translate: TranslateServices ) { 
    this.translate.translateText();
  }


  ngOnInit(): void { }

}

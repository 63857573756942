import { Component, OnInit } from "@angular/core";
import { DoctorModel } from "../../models/doctor.model";
import { SearchService } from "../../services/search.service";
import { Router } from "@angular/router";
import { RequestsListService } from "../../services/requests-list.service";
import { FlagService } from "../../services/flag.service";
import { TranslateServices } from "../../services/translate.service";
import { JsonPipe } from "@angular/common";
import { GalleryService } from "../../services/gallery.service";
import { CountrysService } from "../../services/countrys.service";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.css"],
})
export class SearchComponent implements OnInit {
  // Lista de doctores
  searchDoctorList: DoctorModel[];
  fullName: string;
  parseFistLastName: string;
  parseLastName: string;
  firstN: string;
  listContries: any[] = [];
  listProcedureType: any[] = [];
  noFound = false;
  totalCountries: any[];
  text: any;
  // Apagar y encender botones
  turnOn: boolean = true;

  // Loading Que se mostrara mientras se carga la informacion
  loading: boolean;

  // Almacenamos desde donde entramos a client
  access: boolean;

  constructor(
    private listSearch: SearchService,
    private router: Router,
    private accesoClient: RequestsListService,
    private flat: FlagService,
    private translate: TranslateServices,
    private CountrysService: CountrysService
  ) {
    this.translate.translateText();

    this.loading = true;
    this.listDoctors();
    // Simulamos la carga de datos
    // setTimeout(() => {
    // this.loading = false;
    // this.searchDoctorList = listSearch.getTopDoctorList();
    // }, 1000);
    // this.loading = false;

    flat.getClientButtonsActive("search");
  }

  ngOnInit(): void {
    this.getProcedureType();
    this.getCountries();
  }

  // getContries() {
  //   this.listSearch
  //     .getContries()
  //     .subscribe((resp) => (this.listContries = resp));
  // }

  getProcedureType() {
    this.listSearch
      .getProcedureType()
      .subscribe((resp) => (this.listProcedureType = resp));
  }
  search(searchText: string, country: string, treatment: string) {
    // Antiva y apaga el Encontrados
    this.turnOn = false;

    // Activacion del loading
    this.loading = true;
    const firt_name = searchText.split(" ", 1);
    const lastName = searchText.split(" ", 3);
    let firstLastName = lastName[1];
    let lastname = lastName[2];
    let firstName = firt_name[0];

    this.parseFistLastName = String(firstLastName);
    this.parseLastName = String(lastname);
    this.firstN = String(firt_name);
    if (this.parseFistLastName === "undefined") {
      firstLastName = "";
    }
    if (this.parseLastName === "undefined") {
      lastname = "";
    }
    let fullName = firstLastName + " " + lastname;
    const body = {
      type: treatment,
      name: firstName,
      countries: country,
      last_name: fullName.trim(),
    };

    // Simulamos la carga de datos
    setTimeout(() => {
      this.loading = false;
      this.listSearch.getSearchDoctor(body).subscribe((resp: DoctorModel[]) => {
        this.searchDoctorList = resp;
        if (resp.length === 0) {
          this.noFound = true;
        } else {
          this.noFound = false;
        }
      });
    }, 1000);
  }

  // Nos manda a la pagina del cliente
  client(i: string) {
    localStorage.setItem("idDoctor", i);

    this.access = this.accesoClient.accesoClient = false;
    this.router.navigate(["/client", i]);
  }

  listDoctors() {
    setTimeout(() => {
      this.loading = false;
      this.listSearch.getDoctorList().subscribe((resp: DoctorModel[]) => {
        this.searchDoctorList = resp;

      });
    }, 1000);
  }

  getCountries() {
  this.listSearch.getCountryList().subscribe( (Response: any) => {
    this.listContries = Response.Country;
  });
  }

  
}

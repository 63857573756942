<h2 mat-dialog-title class="text-center"> {{ "PAYMENTDETAILS.refund-policy" | translate }}</h2>
<mat-dialog-content class="mat-typography">

  <ul>
     <li> 
        <p> 
          {{ "PAYMENTDETAILS.refund-1" | translate }}
        </p>
     </li>
     <li> 
        <p> 
            {{ "PAYMENTDETAILS.refund-2" | translate }}
        </p>
     </li>
     <li> 
        <p> 
            {{ "PAYMENTDETAILS.refund-3" | translate }}
        </p>
     </li>
  </ul>
  
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button cdkFocusInitial [mat-dialog-close]="true" >Aceptar</button>
</mat-dialog-actions>
<div class="container-fluid">
  <div class="row">
    <div class="mat-card" id="mat-card">
      <div class="h-vertical">
        <button
          type="button"
          routerLink="/payment"
          class="btn float-left clearfix"
        >
          <i class="fa fa-arrow-left fa-2x color-nav"></i>
        </button>
        <h2 class="text-center color-nav">
          {{ "PAYMENTDETAILS.title" | translate }}
        </h2>
      </div>
    </div>
  </div>
  <div class="mt-5">
    <div class="text-center">
      <img src="/assets/img/pay.png" />
    </div>
  </div>
  <div id="container-overflow">
    <div
      class="row mt-3 justify-content-center"
      *ngFor="let item of detailsList"
    >
      <mat-card id="mat-details" class="mt-3">
        <ul id="ul-d" class="list-unstyled">
          <li>
            <i class="fa fa-user"> </i>
            <span class="font-weight-bold">
              {{ "PAYMENTDETAILS.name" | translate }}:
            </span>
            <span class="ml-4">
              {{ item.doctor.first_name }} {{ item.doctor.last_name }}
            </span>
          </li>
          <li>
            <i class="fa fa-calendar-alt"> </i>
            <span class="font-weight-bold">
              {{ "PAYMENTDETAILS.date" | translate }}:
            </span>
            <span class="ml-4"> {{ item.created_at | date: "short" }} </span>
          </li>
          <li>
            <i class="fa fa-procedures"> </i>
            <span class="font-weight-bold">
              {{ "PAYMENTDETAILS.process" | translate }}:
            </span>
            <span class="ml-4"> {{ item.procedureType.name }} </span>
          </li>
          <li>
            <i class="fa fa-clock"> </i>
            <span class="font-weight-bold">
              {{ "PAYMENTDETAILS.status" | translate }}:
            </span>
            <span class="ml-4">
              <p
                *ngIf="item.status === false"
                class="fa fa-clock text-primary"
              ></p>
              <span> {{ "PAYMENTDETAILS.status-pending" | translate }}</span>
            </span>
          </li>
          <li>
            <i class="fa fa-book-medical"> </i>
            <span class="font-weight-bold">
              {{ "PAYMENTDETAILS.reason" | translate }}:
            </span>
            <span class="ml-4">
              <span *ngIf="item.is_praxis"> 
                {{ "PAYMENTDETAILS.surgery" | translate }}
              </span>
              <span *ngIf="item.is_praxis === false"> 
                {{ "PAYMENTDETAILS.consultation" | translate }}
              </span>
            </span>
          </li>
         
          <li>
            <i class="fa fa-money-bill-wave"> </i>
            <span class="font-weight-bold">
              {{ "PAYMENTDETAILS.amount" | translate }}:
            </span>
            <span class="ml-4">
              <span class="ml-4"> {{ item.amount }} </span>
            </span>
          </li>
          <hr />
        </ul>
        <button
          class="btn btn-outline-success float-right"
          (click)="openDialog()"
        >
          <i class="fa fa-credit-card"></i>
          {{ "PAYMENTDETAILS.btn-pay" | translate }}
        </button>
        <button
          class="btn btn-secondary float-right mr-2"
          routerLink="/payment"
        >
          <i class="fa fa-angle-left"></i>
          {{ "PAYMENTDETAILS.btn-back" | translate }}
        </button>
      </mat-card>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { TranslateServices } from "../../services/translate.service";

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css']
})
export class TermsConditionsComponent implements OnInit {

  constructor( public translate: TranslateServices) { 
    this.translate.translateText();
  }

  ngOnInit(): void {
  }

}

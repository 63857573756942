<div class="container" id="mainContainer">
  <mat-card>
    <h2 class=" text-center text-muted">Formularios</h2>
    <div class="row justify-content-center">
      <div id="divCol" class="col-md-4">
        <button type="button" class="btn btn-color" (click)="goTo(1)">
          <div>
            <i class="fas fa-file-medical"></i>
            <p> {{ "FORMS.surgery" | translate }} </p>
          </div>
        </button>
      </div>
      
      <div id="divCol" class="col-md-4">
        <button type="button" class="btn btn-color" (click)="goTo(2)">
          <div>
            <i class="fas fa-file"></i>
            <p>{{ "FORMS.esthetic" | translate }}</p>
          </div>
        </button>
        
      </div> 

      <div id="divCol" class="col-md-4">
        <button type="button" class="btn btn-color" (click)="goTo(3)">
          <div>
            <i class="fas fa-file"></i>
            <p>{{ "FORMS.general_form" | translate }}</p>
          </div>
        </button>
        
      </div> 

      <!-- 
      <div id="divCol" class="col-md-6">
        <button type="button" class="btn btn-color" (click)="generalForm()">
          <div>
            <i class="fas fa-file"></i>
            <p>{{ "FORMS.general_form" | translate }}</p>
          </div>
        </button>
        
      </div> -->
    </div>    
  </mat-card>
</div>
import { Component, OnInit } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { UploadImageService } from "../../services/upload-image.service";
import { FormModel } from "../../models/form.model";
import Swal from "sweetalert2";
import { TranslateServices } from "../../services/translate.service";
import { FormService } from "../../services/form.service";
import { ModalLanguageComponent } from "./modal-language/modal-language.component";
import { MatDialog } from "@angular/material/dialog";
import { SettingsService } from "../../services/settings.service";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.css"],
})
export class SettingsComponent implements OnInit {
  form: FormGroup;
  selectedFile: File = null;
  imgName: string;
  //url2: string;
  lang: string;
  dataModel: FormModel;
  languague: any[];
  public imageName: string;
  public imgGlobal: string = "assets/img/user2x.png";

  // Permite determinar a cual url sera enviada la imagen
  imgContainer: number;
  constructor(
    private fb: FormBuilder,
    public imageService: UploadImageService,
    private formService: FormService,
    public translate: TranslateServices,
    private _settingService: SettingsService,
    public dialog: MatDialog
  ) {
    this.translate.translateText();
  }

  ngOnInit(): void {
    this.getImages();
    this.createForm();
    this.getDataForm();
    this.lang = navigator.language;
  }

  get nameNoValid() {
    return (
      this.form.get("first_name").invalid && this.form.get("first_name").touched
    );
  }
  get lastnameNoValid() {
    return (
      this.form.get("last_name").invalid && this.form.get("last_name").touched
    );
  }

  get phoneNoValid() {
    return this.form.get("phone").invalid && this.form.get("phone").touched;
  }
  get emailNoValid() {
    return this.form.get("email").invalid && this.form.get("email").touched;
  }
  get addressNoValid() {
    return this.form.get("address").invalid && this.form.get("address").touched;
  }

  createForm() {
    this.form = this.fb.group({
      first_name: ["", [Validators.required]],
      last_name: ["", [Validators.required]],
      phone: ["", [Validators.required]],
      email: ["", [Validators.required]],
      address: ["", [Validators.required]],
      lenguage: [""],
      occupation: [""],
    });
  }

  update() {
    if (this.form.valid) {
      this.dataModel = new FormModel();

      this.dataModel.first_name = this.form.value.name;
      this.dataModel.last_name = this.form.value.lastname;
      this.dataModel.phone = this.form.value.phone;
      this.dataModel.email = this.form.value.email;
      this.dataModel.primary_language_id = this.form.value.lenguage;
      this.dataModel.occupation = this.form.value.occupation;
      this.dataModel.address = this.form.value.address;
    }
    this._settingService
      .putInformationSetting(this.dataModel)
      .subscribe((Response: any) => {
        if (Response.Ok == true) {
          if (this.lang === "es-ES" || this.lang === "es") {
            Swal.fire({
              icon: "success",
              title: "Información actualizada correctamente",
              showConfirmButton: false,
              timer: 1500,
            });
          } else if (this.lang === "en-US" || this.lang === "en") {
            Swal.fire({
              icon: "success",
              title: "Information updated correctly",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            Swal.fire({
              icon: "success",
              title: "Information updated correctly",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            title: Response.Message,
          });
        }
      });
  }

  openDialog() {
    const dialogRef = this.dialog.open(ModalLanguageComponent, {
      minWidth: "30%",
      data: this.languague,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getDataForm();
    });
  }

  getDataForm() {
    this.formService.getData().subscribe((resp: any) => {
      this.languague = resp.patient.language;
      this.imgName = resp.patientHistory.front_picture;
      localStorage.setItem("nameImg", this.imgName);

      this.form.patchValue({
        last_name: resp.patient.last_name,
        phone: resp.patient.phone,
        email: resp.patient.email,
        first_name: resp.patient.first_name,
        address: resp.patient.address,
        occupation: resp.patient.occupation,
      });
    });
  }
  // metodo para obtener las imagenes
  getImages() {
    this.imageService.getImages().subscribe((resp: any) => {
      if (resp.patient_image) {
        //this.url2 = resp.patient_image[0].url;
        this.imageService.profile_picture = resp.patient_image[0].url;
      }
    });
  }




  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  //Seleccionar imagen y enviarla al servicio
  //Seleeciona las imagenes
  public onFileSelected( event:any ) {

 

    let patient_id: any;
    patient_id = localStorage.getItem("id");

    //Se encarga de detectar si se a pulsado el boton cancelar, para que no se rompa la app
    if ( event.target.value.length === 0) {
      //console.log("Suspect Cancel was hit, no files selected.");
      return;
    
    }
  
    //Desactivamos el boton
    //this.disabledButton = true;

    //En la consola siguiente target file, encontraremos la url de la imagen nombre y demas
    if(event.target.files){


      let reader = new FileReader();
      //Archivo seleecionado para el envio por el servicio
      this.selectedFile = <File>event.target.files[0];

      //Almacenamos la url de la imagen
      reader.readAsDataURL( event.target.files[0] );
      //console.log(event.target.files[0]);
      //console.log(event.target.files);
      //console.log(event.target.files[0].name);
      //console.log(event.target.files[0].type);
      let fileType = event.target.files[0].type;
      let nameFile = event.target.files[0].name;


      reader.onload = ( eventDoc:any ) => {

        //Muestra el string de datos de la imagen
        //console.log(eventDoc.target.result);
        let object = {
          name: nameFile,
          type: fileType,
          file: this.selectedFile
        }

        //Texto de carga de imagen
        if (this.lang === "es-ES" || this.lang === "es") {
          Swal.fire({
            icon: "info",
            allowOutsideClick: false,
            text: "Actualizando imagen ...",
          });
        } else if (this.lang === "en-US" || this.lang === "en") {
          Swal.fire({
            icon: "info",
            allowOutsideClick: false,
            text: "Updating image ...",
          });
        } else {
          Swal.fire({
            icon: "info",
            allowOutsideClick: false,
            text: "Updating image ...",
          });
        }

        // Simulación de loading
        Swal.showLoading();
        //this.imgProgress = true;
        this.imageService.sendImagest( object, "profile", patient_id  ).subscribe((resp:any) => {
          //this.imgProgress = false;
          console.log(resp);
          Swal.close();
          if( !resp.OK ) {

            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Compruebe su conexion a Internet y vuelva a intentar en unos minutos !!!',
            })
          }
          else {

            if (this.lang === "es-ES" || this.lang === "es") {
              Swal.fire({
                icon: "success",
                allowOutsideClick: false,
                title: "Imagen actualizada correctamente",
              })
            } else if (this.lang === "en-US" || this.lang === "en") {
              Swal.fire({
                icon: "success",
                title: "Image updated successfully",
                allowOutsideClick: false,
              })
            } else {
              Swal.fire({
                icon: "success",
                title: "Image updated successfully",
                allowOutsideClick: false,
              })
            }
            this.imageService.profile_picture = eventDoc.target.result;
          }
          //this.imgProgress6 = false;
          //this.disabledButton = false;
        });

      }

    }
  }

}

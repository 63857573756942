import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { TranslateServices } from "../../services/translate.service";
import { FileItem } from "../../models/file-item.model";
import { UploadImageService } from "../../services/upload-image.service";
import { HttpClient } from "@angular/common/http";
import Swal from "sweetalert2";
import { FormService } from "../../services/form.service";
import { FormModel } from "../../models/form.model";
import { SearchService } from "../../services/search.service";
import { FlagService } from "../../services/flag.service";
import { ActivatedRoute, Router, NavigationStart } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { TermsConditionsComponent } from "../terms-conditions/terms-conditions.component";
import { ModalAppointmentComponent } from "../client/modal-appointment/modal-appointment.component";
import { CountrysService } from '../../services/countrys.service';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-form-surgery",
  templateUrl: "./form-surgery.component.html",
  styleUrls: ["./form-surgery.component.css"],
})
export class FormSurgeryComponent implements OnInit {
  nameLength = true;
  lang: string;
  fullName: string;
  url: string;
  currentUrl: string;
  parseFistLastName: string;
  parseLastName: string;
  firstN: string;
  nameUser: string;
  procedureByDoctor: Array<any> = [];
  lenguage: any[] = [];
  doctorSearch: any[] = [];
  front: string = "Actualizar";
  back: string = "Actualizar";
  left: string = "Actualizar";
  rigth: string = "Actualizar";
  passport: string = "Actualizar";
  validataeEmailExist = false;
  profile: string = "Actualizar";
  // Referencia al formulario
  form: FormGroup;
  countryTotal: any[] = [];
  kg: number;
  meters: number;
  pounds: number;
  foot1: number;
  foot2: number;
  showDoctorName = false;
  gender: string;
  female = true;
  imgName: string;
  id: string;
  noFound = false;
  doctorName: string = "";

  // Mandamos la referencia a la pagina a moverno si han sido completados los valores basicos.
  public pageReference1: string = "#first";
  public pageReference2: string = "#second";
  public pageReference3: string = "#third";
  public pageReference4: string = "#fourth";

  // Desactiva las siguientes paginas hasta que los campos principales estan activos
  public tagDisabled: string = "disabled";
  // Activa metro o pies
  meterFeet: boolean = true;
  // Almacena el IMC para enviarlo al html
  imcTotal: number = 0;
  // Se encarga de tomar una imagen o archivo para la subida al servidor
  selectedFile: File = null;
  // variable donde almacenamos el url de la imagen
  url1 = "assets/img/front.png";
  url2 = "assets/img/back.png";
  url3 = "assets/img/left-profile.png";
  url4 = "assets/img/right-profile.png";
  url5 = "assets/img/document.png";
  url6 = "assets/img/profile.png";

  // Permite determinar a cual url sera enviada la imagen
  imgContainer: number;

  // Nombre de la imagen subida
  public imageName: string;
  dataModel: FormModel;

  // Idioma
  public language: string;

  constructor(
    private translate: TranslateServices,
    private fb: FormBuilder,
    private translat: TranslateService,
    private imageService: UploadImageService,
    private activatedRoute: ActivatedRoute,
    private _countryServices: CountrysService,
    private http: HttpClient,
    private formService: FormService,
    public flag: FlagService,
    private _doctorServices: SearchService,
    public dialog: MatDialog,
    private roter: Router
  ) {
    this.createForm();
    this.translate.translateText();
    // Listener Form, para detertar los cambios en el formulario
    this.listenerForm();

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (p) {
          const latitude = p.coords.latitude;
          const longitude = p.coords.longitude;
        },
        function (e) {
          // ipLookup();
        }
      );
    }
  }

  ngOnInit(): void {

    this.lang = navigator.language;
    let url = window.location.href;
    let split = url.split("/", 4);
    this.currentUrl = split[3];

    if (this.currentUrl === "form-surgery") {
     this.showDoctorName = false;
      this.form.patchValue({
        terms: true,
      });
      this.getDataForm();
      this.getImages();
    } else if (this.currentUrl === "form-direct") {
      localStorage.removeItem("id");
      localStorage.removeItem("idUser");

      this.updateText();
      this.showDoctorName = true
      this.activatedRoute.queryParams

      .subscribe( params => {
        if ( params.name == undefined) {
          this.doctorName = "";
        } else  {
          this.doctorName = `Doctor: ${params.name}`;
        }
       }
      );
  
    }
    this.countryTotal = this._countryServices.sendCoubntries()
    this.changeViewGender();
    this.getLenguage();
    this.getIdDoctor();
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.form.reset();
  }
  openDialog() {
    const dialogRef = this.dialog.open(TermsConditionsComponent);
    dialogRef.afterClosed().subscribe((result) => {});
  }

  // Permite que nos movamos entre las paginas del formulario
  pages() {
    // Le pondremos a todos los campos que han sido tocados
    Object.values(this.form.controls).forEach((control) => {
      // Me devuelve todos los valores
      // Todos los valores han sido tocados
      if (this.form.invalid) {
        // Mostrando mensaje de error
        if (this.lang === "es-ES" || this.lang === "es") {
          Swal.fire({
            icon: "error",
            title: "Formulario Incompleto",
            text: "Por favor complete los datos basicos minimos",
          });
        } else if (this.lang === "en-US" || this.lang === "en") {
          Swal.fire({
            icon: "error",
            title: "Incomplete Form",
            text: "Please complete the minimum basic data",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Incomplete Form",
            text: "Please complete the minimum basic data",
          });
        }
      }
      control.markAllAsTouched();
    });

    // Si todos los datos estan llenos llamamos las funcion de crear formulario
    if (this.form.valid) {
      this.dataModel = new FormModel();

      if (this.currentUrl === "form-direct") {
      var idLang = [];
      for (const iterator of this.form.value.lenguage) {
        idLang.push({ id: iterator });
      }
 
      this.dataModel.language = idLang;
      this.dataModel.primary_language_id = this.translat.getBrowserLang();
    }
      this.dataModel.first_name = this.form.value.name;
      this.dataModel.last_name = this.form.value.lastname;
      this.dataModel.passport = this.form.value.identification;
      this.dataModel.dateBirth = this.form.value.dateBirth;
      this.dataModel.country = this.form.value.country;
      this.dataModel.city = this.form.value.city;
      this.dataModel.civil_status = this.form.value.status;
      this.dataModel.religion = this.form.value.religion;
      this.dataModel.phone = this.form.value.phone;
      this.dataModel.email = this.form.value.email;
      this.dataModel.occupation = this.form.value.occupation;
      this.dataModel.address = this.form.value.address;
      this.dataModel.emergency_person = this.form.value.emergencyPerson;
      this.dataModel.last_pregnancy = this.form.value.lastpregnancy;
      this.dataModel.emergency_contact = this.form.value.emergencyPersonPhone;
      this.dataModel.previous_surgeries = this.form.value.previousSurgeries;
      this.dataModel.weight = this.form.value.kg;
      this.dataModel.terms = this.form.value.terms;
      this.dataModel.heigth = this.form.value.meter;
      this.dataModel.gender = this.form.value.gender;
     
      this.dataModel.number_of_pregnancies = this.form.value.pregnancy;
      this.dataModel.number_of_abortions = this.form.value.abortions;
      this.dataModel.number_of_child_births = this.form.value.childbirth;
      this.dataModel.number_of_caesareans = this.form.value.caesarean;
      this.dataModel.other_diseases = this.form.value.diseases;
      this.dataModel.medicines_in_use = this.form.value.medicines;
      this.dataModel.extra_information = this.form.value.extraInformation;
      this.dataModel.asthma = this.form.value.asthma;
      this.dataModel.allergies = this.form.value.allergies;
      this.dataModel.falcemia = this.form.value.falcemia;
      this.dataModel.diabetes = this.form.value.diabetes;
      this.dataModel.smoker = this.form.value.smoker;
      this.dataModel.alcohol = this.form.value.alcohol;
      this.dataModel.oral_contraceptive = this.form.value.contraceptive;
      this.dataModel.hiv = this.form.value.hiv;
      this.dataModel.cancer = this.form.value.cancer;
      this.dataModel.thalassemia = this.form.value.thalassemia;
      this.dataModel.willebrand = this.form.value.willebrand;
      this.dataModel.biopolymers = this.form.value.biopolymers;
      this.dataModel.hypertension = this.form.value.hypertension;
      this.dataModel.falcemia_carrier = this.form.value.pFalcemia;
      this.dataModel.depression = this.form.value.depression;
      this.dataModel.hookah = this.form.value.hookah;
      this.dataModel.drugs = this.form.value.drugs;
      this.dataModel.lactating = this.form.value.lactating;
      this.dataModel.hepatitis_b_or_c = this.form.value.hepatitis;
      this.dataModel.leukemia = this.form.value.leukemia;
      this.dataModel.hemophilia = this.form.value.hemophilia;
      this.dataModel.penicillin_allergy = this.form.value.penicillinAllergy;
      this.dataModel.accepts_blood_transfusion = this.form.value.transfusion;
      this.dataModel.imc = this.form.value.imc;

      //
      this.dataModel.currently_pregnant = this.form.value.currently_pregnant;
      this.dataModel.previous_transfusion = this.form.value.previous_transfusion;

      this.dataModel.last_transfusion_date = this.form.value.last_transfusion_date;
      this.dataModel.blood_type = this.form.value.blood_type;
      this.dataModel.aspirin = this.form.value.currently_pregnant;
      this.dataModel.sedatives = this.form.value.sedatives;
      this.dataModel.anti_hypertensive = this.form.value.anti_coagulants;
      this.dataModel.anti_depressants = this.form.value.anti_depressants;
      this.dataModel.medicines_to_lose_weight = this.form.value.medicines_to_lose_weight;
      this.dataModel.anti_coagulants = this.form.value.anti_coagulants;
      this.dataModel.oxycodone = this.form.value.oxycodone;

      this.dataModel.natural_medicines = this.form.value.natural_medicines;
      this.dataModel.had_covid = this.form.value.had_covid;
      this.dataModel.vaccinated_covid = this.form.value.vaccinated_covid;

      this.dataModel.calcium = this.form.value.calcium;
      this.dataModel.vitamins_e_d_c = this.form.value.vitamins_e_d_c;
      this.dataModel.omega_3 = this.form.value.omega_3;
      this.dataModel.leucine = this.form.value.leucine;
      this.dataModel.sinusitis = this.form.value.sinusitis;
      this.dataModel.hypercholesterolemia = this.form.value.hypercholesterolemia;
      this.dataModel.tachycardia = this.form.value.tachycardia;
      this.dataModel.neurological = this.form.value.neurological;

      this.dataModel.circulatory = this.form.value.circulatory;
      this.dataModel.skin = this.form.value.skin;
      this.dataModel.gastrointestinal = this.form.value.gastrointestinal;
      this.dataModel.breast_pathologies = this.form.value.breast_pathologies;
      this.dataModel.hypothyroidism = this.form.value.hypothyroidism;
      this.dataModel.hereditary_history = this.form.value.hereditary_history;

      // Creamos el formulario y recibimos la respuesta del mismo

      /*
      this.formService
        .createBasicForm("surgery", this.dataModel)
        .subscribe((Response) => {
          if (this.currentUrl === "form-direct") {
             this.formService.initConectionSocket();
             setTimeout(() => {
              Swal.close();
            }, 1200);
       
          }
        
          localStorage.setItem("id", Response.patient_id);
          localStorage.setItem("idUser", Response.user_patient_id)
          
        });*/
      // Damos acceso a los botones si todo el formulario esta lleno eliminando el disabled
      this.tagDisabled = "";
    } else {
      this.tagDisabled = "disabled";
    }
  }

  showLoading() {
   
    if (this.currentUrl === "form-direct") {
      Swal.fire({
        onBeforeOpen () {
          Swal.showLoading ()
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      })
  
    }
  }
  // Se encarga de activar y desactivar la ventana de metros o pies
  offOn(v: string) {
    if (v === "m") {
      this.meterFeet = true;
      this.converToMeters();
    } 
    if(v === "f") {
      this.meterFeet = false;
      this.convertToFoot();
    }
  }

  // Validaciones graficas
  get nameNoValid() {
    return this.form.get("name").invalid && this.form.get("name").touched;
  }
  get cityNoValid() {
    return this.form.get("city").invalid && this.form.get("city").touched;
  }

  get countryNoValid() {
    return this.form.get("country").invalid && this.form.get("country").touched;
  }

  get lastnameNoValid() {
    return (
      this.form.get("lastname").invalid && this.form.get("lastname").touched
    );
  }
  get identificationNoValid() {
    return (
      this.form.get("identification").invalid &&
      this.form.get("identification").touched
    );
  }
  get dateBirth() {
    return this.form.get("dateBirth").invalid && this.form.get("dateBirth").touched;
  }
  get statusNoValid() {
    return this.form.get("status").invalid && this.form.get("status").touched;
  }
  get religionNoValid() {
    return (
      this.form.get("religion").invalid && this.form.get("religion").touched
    );
  }
  get genderNoValid() {
    return this.form.get("gender").invalid && this.form.get("gender").touched;
  }
  get lenguague() {
    return (
      this.form.get("lenguage").invalid && this.form.get("lenguage").invalid
    );
  }
  get procedureDoctor() {
    return (
      this.form.get("procedure_doctor").invalid &&
      this.form.get("procedure_doctor").invalid
    );
  }

  get lastpregnancyNoValid() {
    return (
      this.form.get("lastpregnancy").invalid &&
      this.form.get("lastpregnancy").touched
    );
  }
  get termsNoValid() {
    return this.form.get("terms").invalid && this.form.get("terms").touched;
  }

  get phoneNoValid() {
    return this.form.get("phone").invalid && this.form.get("phone").touched;
  }
  get emailNoValid() {
    return this.form.get("email").invalid && this.form.get("email").touched;
  }

  get kgNoValid() {
    return this.form.get("kg").invalid && this.form.get("kg").touched;
  }

  get meterNoValid() {
    return this.form.get("meter").invalid && this.form.get("meter").touched;
  }
  get poundsNoValid() {
    return this.form.get("pounds").invalid && this.form.get("pounds").touched;
  }

  get foot1NoValid() {
    return this.form.get("foot1").invalid && this.form.get("foot1").touched;
  }
 
  get imcNoValid() {
    return this.form.get("imc").invalid && this.form.get("imc").touched;
  }

  get allergiesNoValid() {
    return (
      this.form.get("allergies").invalid && this.form.get("allergies").touched
    );
  }

  get allowSavingInformationNoValid() {
    return (
      this.form.get("allowSavingInformation").invalid &&
      this.form.get("allowSavingInformation").touched
    );
  }

  // Inicializacion del formulario y sus validaciones
  createForm() {
    this.form = this.fb.group({
      name: ["", [Validators.required]],
      city: ["", [Validators.required]],
      country: ["", [Validators.required]],
      lastname: ["", [Validators.required]],
      identification: ["", [Validators.required]],
      dateBirth: ["", [Validators.required]],
      status: ["", [Validators.required]],
      religion: ["", [Validators.required]],
      lastpregnancy: [""],
      phone: ["", [Validators.required]],
      email: ["", [Validators.required]],
      occupation: [""],
      address: [""],
      kg: ["", [Validators.required]],
      meter: ["", [Validators.required]],
      pounds: [""],
      foot1: [""],
      imc: ["", [Validators.required]],
      residence: [""],
      residencePhone: [""],
      emergencyPerson: [""],
      emergencyPersonPhone: [""],
      terms: [false, [Validators.required]],
      previousSurgeries: [""],
      pregnancy: [""],
      abortions: [""],
      childbirth: [""],
      caesarean: [""],
      diseases: [""],
      medicines: [""],
      gender: [""],
      lenguage: ["", [Validators.required]],
      procedure_doctor: [""],
      allergies: [""],
      extraInformation: [""],
      asthma: [false],
      falcemia: [false],
      diabetes: [false],
      smoker: [false],
      alcohol: [false],
      contraceptive: [false],
      hiv: [false],
      cancer: [false],
      thalassemia: [false],
      willebrand: [false],
      biopolymers: [false],
      hypertension: [false],
      pFalcemia: [false],
      depression: [false],
      hookah: [false],
      drugs: [false],
      lactating: [false],
      hepatitis: [false],
      leukemia: [false],
      hemophilia: [false],
      penicillinAllergy: [false],
      transfusion: [false],
      changeFlight: [false, [Validators.required]],
      notFalseInformation: [false, [Validators.required]],
      allowSavingInformation: [false, [Validators.required]],
      currently_pregnant: [false],
      previous_transfusion: [false],
      last_transfusion_date: [Date],
      blood_type: [""],
      aspirin: [false],
      sedatives: [false],
      anti_hypertensive: [false],
      anti_coagulants: [false],
      anti_depressants: [false],
      medicines_to_lose_weight: [false],
      oxycodone: [false],
      natural_medicines: [false],
      calcium: [false],
      vitamins_e_d_c: [false],
      had_covid: [false],
      vaccinated_covid: [false],
      omega_3: [false],
      leucine: [false],
      sinusitis: [false],
      hypercholesterolemia: [false],
      tachycardia: [""],
      neurological: [""],
      circulatory: [""],
      skin: [""],
      gastrointestinal: [""],
      breast_pathologies: [""],
      hypothyroidism: [""],
      hereditary_history: [""]
    });
  }

  // Permite recibir cualquier cambio en el formulario
  listenerForm() {
    // Permite determinar los cambios em el formulario
    this.form.valueChanges.subscribe((resp) => {
   
       // // Calculo IMC pies
      if ( resp.pounds || resp.foot1 ) {

        this.pounds =  resp.pounds;
        this.foot1 =  resp.foot1;
       

        let unityFoot = String(this.foot1);
        let kg = this.pounds / 2.2046;
        let m = parseFloat(unityFoot) / 3.2808;
        var nume = (Number(kg / (m * m)).toFixed(3));
        this.imcTotal = (Number(nume))
    
      }
      

      // Calculo IMC metros
      if (resp["kg"] || resp["meter"]) {
        this.kg = resp["kg"];
        this.meters = resp["meter"];
        var num = (this.kg / (this.meters * this.meters)).toFixed(3);
        this.imcTotal = (Number(num))
      
      }

    });

    this.form.get('foot1').valueChanges
      .subscribe(value => { 

        if (value) {

          const kgControl = this.form.get('kg');
          const meterControl = this.form.get('meter');
          kgControl.setValidators(null);
          meterControl.setValidators(null);
          
          kgControl.updateValueAndValidity();
          meterControl.updateValueAndValidity();
        }

     })

 
    
    // Activamos los botones si el formulario basico esta completo
    // Si todos los valores han sido completados correctamente
    this.form.valueChanges.subscribe((resp) => {
      if (this.validataeEmailExist === false && this.form.valid) {
        // Damos acceso a los botones si todo el formulario esta lleno eliminando el disabled
        this.tagDisabled = "";
      }
    });

    let url = window.location.href;
    let split = url.split("/", 4);
    this.currentUrl = split[3];
    this.form.get("email").valueChanges.subscribe((value) => {
      if (this.currentUrl === "form-direct") {
        this.validateExistEmail(value);
      }
    });
  }

  validateExistEmail(email: string) {
    this.formService.validateEmailExist(email).subscribe((Response: any) => {
      this.validataeEmailExist = Response.exist;
      if (this.validataeEmailExist !== false) {

        if (this.lang === "es-ES" || this.lang === "es") {
          Swal.fire({
            icon: "info",
            text: "Este correo electrónico, ya pertenece a un usuario registrado, inicie sesión y haga clic en recuperar contraseña.",
          });
        } else if (this.lang === "en-US" || this.lang === "en") {
          Swal.fire({
            icon: "info",
            text: "This email, already belongs to a registered user, go to login and click on recover password",
          });
        } else {
          Swal.fire({
            icon: "info",
            text: "This email, already belongs to a registered user, go to login and click on recover password",
          });
        }
        this.tagDisabled = "disabled";
      }
    });
  }
  // metodo para ocultar los campos que pertecen a un genero en especifico
  changeViewGender() {
    const gender = localStorage.getItem("gender");

    if (gender === "female" || gender === "other") {
      this.female = true;
    }
    if (gender === "male") {
      this.female = false;
    }
  }
  disable(input: string) {

    if (input.length > 0) {
      this.nameLength = false;
    } else {
      this.nameLength = true;
      this.noFound = false;
      this.doctorSearch = [];
    }
  }
  
  convertToFoot() {
    
    let foot: number;
    let convertString: string;
    let libra: string;
      
    this.form.get("kg").valueChanges.subscribe((value) => {
      this.kg = value;
    });
    this.form.get("meter").valueChanges.subscribe((value) => {
      this.meters = value;
    });

    // convertir metros a pies
    foot = this.meters * 3.281;
    // redondear el resultado
    const round = foot.toFixed(1);
    convertString = round.toString();

    // convertir de kg a libra
    libra = (this.kg * 2.205).toFixed(2);
    this.form.patchValue({
      pounds: parseFloat(libra),
      foot1: convertString
    });
  }

  converToMeters() {
    let meters: number;
    let convertString: string;
    let kilog: number;
    
    this.form.get("pounds").valueChanges.subscribe((value) => {
      this.pounds = value;
    });
    this.form.get("foot1").valueChanges.subscribe((value) => {
      this.foot1 = value;
    });
    
    const unityFoot = parseFloat(String(this.foot1));
   

    // convertir  pies a metro
    meters = unityFoot / 3.281;

    // redondear el resultado
    const round = meters.toFixed(1);
    convertString = round.toString();

    // convertir de kg a libra
    kilog = this.pounds / 2.205;
    this.form.patchValue({
      kg: kilog.toFixed(2),
      meter: meters.toFixed(2),
    });
  
  }

  // evita enviar el formulario al hacer enter
  handleKeyupEnter(event) {
    if (event.code == "Enter") event.preventDefault();
  }
  // Enviamos los datos al cambiar de vista
  save() {
    // Permite validar despues de ser pulsado el boton de envia todos los campos y si no es asi disparar los mensajes
    if (this.form.invalid || this.form.value.terms == false ) {
      Object.values(this.form.controls).forEach((control) => {
        // Me devuelve todos los valores
        control.markAllAsTouched(); // Todos los valores han sido tocados
      });

      if (this.form.value.terms == false ) {
        //Mostrando mensaje de error
        if (this.lang === "es-ES" || this.lang === "es") {
          Swal.fire({
            //icono que mostrara
            icon: "error",
            //Titulo
            title: "Condiciones",
            //Texto que se mostrara
            text: "Por favor acepte las condiciones para enviar la información",
          });
        } else if (this.lang === "en-US" || this.lang === "en") {
          Swal.fire({
            icon: "error",
            title: "Terms",
            text: "Please accept the conditions to send the information",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Terms",
            text: "Please accept the conditions to send the information",
          });
        }
      }
      return;
    }
    const id = localStorage.getItem("idDoctor");

    if (this.form.valid && id === null) {
      if (this.language === "es-ES" || this.lang === "es") {
        Swal.fire({
          icon: "success",
          title: "Felicitaciones",
          text: "Su formulario a sido enviado satisfactoriamente",
        });
      } else if (this.language === "en-US" || this.lang === "en") {
        Swal.fire({
          icon: "success",
          title: "Congratulations",
          text: "Your form has been sent successfully",
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Congratulations",
          text: "Your form has been sent successfully",
        });
      }
    }
    if (id && this.form.valid) {
      const dialogRef = this.dialog.open(ModalAppointmentComponent, {
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((result) => {
        localStorage.removeItem("idDoctor");
        
      });
    }
  }

  // Configuracion botones de imagen para mostrar la imagen en el navegador y almacenarlas
  onselectFile(event) {
    // La informacion que necesitamos estara en la consola en :
    // Event > target > files > 0 : Que es lo que tendremos que extraer dentro del event devuelto
    // Dentro de 0 tendremos : name, size, type.
    // Almacenamos el archivo contenido en el event
    this.selectedFile = <File>event.target.files[0];
    // En la consola siguiente target file, encontraremos la url de la imagen nombre y demas
    if (event.target.files) {
      let reader = new FileReader();
      // Almacenamos la url de la imagen
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event: any) => {
        // Pasamos la imagen a la url para que pueda ser visible en el navegador
        switch (this.imgContainer) {
          case 1: {

            this.url1 = event.target.result;
            this.imageName = "front";
            break;
          }
          case 2: {
            this.url2 = event.target.result;
            this.imageName = "back";
            break;
          }
          case 3: {
            this.url3 = event.target.result;
            this.imageName = "left";
            break;
          }
          case 4: {
            this.url4 = event.target.result;
            this.imageName = "right";
            break;
          }
          case 5: {
            this.url5 = event.target.result;
            this.imageName = "passport";
            break;
          }
          case 6: {
            this.url6 = event.target.result;
            this.imageName = "profile";
            break;
          }
          default: {
            this.url1 = "assets/img/front.png";
            this.url2 = "assets/img/back.png";
            this.url3 = "assets/img/left-profile.png";
            this.url4 = "assets/img/right-profile.png";
            this.url5 = "assets/img/document.png";
            this.url6 = "assets/img/profile.png";
            break;
          }
        }
        // Le pasamos el archivo al servicio encargado de subir la imagen y el nombre de la imagen
        this.imageService
          .uploadImagedb("surgery", this.imageName, this.selectedFile)
          .subscribe();
      };
    }
  }

  // metodo para traer los datos del formulario si el usuario anteriormente ha completado el formulario
  getDataForm() {
    this.formService.getData().subscribe((resp: any) => {
  
      this.imgName = resp.patientHistory.front_picture;
      localStorage.setItem("nameImg", this.imgName);
      this.nameUser = resp.patient.first_name + " " + resp.patient.last_name;
      localStorage.setItem("name", this.nameUser);
     
      this.form.patchValue({
        lastname: resp.patient.last_name,
        identification: resp.patient.passport,
        dateBirth: resp.patient.birthday,
        status: resp.patient.civil_status,
        had_covid: resp.patientHistory.had_covid,
        vaccinated_covid: resp.patientHistory.vaccinated_covid,
        religion: resp.patient.religion,
        lastpregnancy: resp.patientHistory.last_pregnancy_date,
        phone: resp.patient.phone,
        email: resp.patient.email,
        occupation: resp.patient.occupation,
        address: resp.patient.address,
        kg: resp.patientHistory.weight,
        meter: resp.patientHistory.height,
        emergencyPerson: resp.patient.emergency_person,
        emergencyPersonPhone: resp.patient.emergency_contact,
        previousSurgeries: resp.patientHistory.previous_surgeries,
        name: resp.patient.first_name,
        residence: resp.patient.address,
        residencePhone: resp.patient.phone,
        pregnancy: resp.patientHistory.number_of_pregnancies,
        abortions: resp.patientHistory.number_of_abortions,
        childbirth: resp.patientHistory.number_of_child_births,
        caesarean: resp.patientHistory.number_of_cesareans,
        diseases: resp.patientHistory.other_diseases,
        medicines: resp.patientHistory.medicines_in_use,
        extraInformation: resp.patientHistory.extra_information,
        asthma: resp.patientHistory.asthma,
        falcemia: resp.patientHistory.falcemia,
        diabetes: resp.patientHistory.diabetes,
        smoker: resp.patientHistory.smoker,
        alcohol: resp.patientHistory.alcohol,
        contraceptive: resp.patientHistory.oral_contraceptive,
        hiv: resp.patientHistory.hiv,
        cancer: resp.patientHistory.cancer,
        thalassemia: resp.patientHistory.thalassemia,
        willebrand: resp.patientHistory.willebrand,
        biopolymers: resp.patientHistory.biopolymers,
        hypertension: resp.patientHistory.hypertension,
        pFalcemia: resp.patientHistory.falcemia_carrier,
        depression: resp.patientHistory.depression,
        hookah: resp.patientHistory.hookah,
        drugs: resp.patientHistory.drugs,
        lactating: resp.patientHistory.lactating,
        hepatitis: resp.patientHistory.hepatitis_b_or_c,
        leukemia: resp.patientHistory.leukemia,
        hemophilia: resp.patientHistory.hemophilia,
        country: resp.patient.country,
        city: resp.patient.city,
        penicillinAllergy: resp.patientHistory.penicillin_allergy,
        transfusion: resp.patientHistory.accepts_blood_transfusion,
        lenguage: resp.patient.primarylanguage.name,
        currently_pregnant: resp.patientHistory.currently_pregnant,
        previous_transfusion: resp.patientHistory.previous_transfusion,
        last_transfusion_date: resp.patientHistory.last_transfusion_date,
        blood_type: resp.patientHistory.blood_type,
        aspirin: resp.patientHistory.aspirin,
        sedatives: resp.patientHistory.sedatives,
        anti_hypertensive: resp.patientHistory.anti_hypertensive,
        anti_depressants: resp.patientHistory.anti_depressants,
        medicines_to_lose_weight: resp.patientHistory.medicines_to_lose_weight,
        natural_medicines: resp.patientHistory.natural_medicines,
        calcium: resp.patientHistory.calcium,
        vitamins_e_d_c: resp.patientHistory.vitamins_e_d_c,
        omega_3: resp.patientHistory.omega_3,
        leucine: resp.patientHistory.leucine,
        sinusitis: resp.patientHistory.sinusitis,
        hypercholesterolemia: resp.patientHistory.hypercholesterolemia,
        tachycardia: resp.patientHistory.tachycardia,
        neurological: resp.patientHistory.neurological,
        circulatory: resp.patientHistory.circulatory,
        skin: resp.patientHistory.skin,
        gastrointestinal: resp.patientHistory.gastrointestinal,
        breast_pathologies: resp.patientHistory.breast_pathologies,
        hypothyroidism: resp.patientHistory.hypothyroidism,
        hereditary_history: resp.patientHistory.hereditary_history,
        anti_coagulants: resp.patientHistory.anti_coagulants,
        oxycodone: resp.patientHistory.oxycodone,
      });
    });
  }

  updateText(){
    this.front = "Subir";
    this.back = "Subir";
    this.back = "Subir";
    this.left = "Subir";
    this.rigth = "Subir";
    this.passport = "Subir";
    this.profile = "Subir";
  }

  // metodo para obtener las imagenes
  getImages() {
    this.imageService.getImages().subscribe((resp: any) => {
      if (resp.patient_image) {
        if (resp.patient_image[3].url === false) {
          this.front = "Subir";
        }
        if (resp.patient_image[6].url === false) {
          this.back = "Subir";
        }
        if (resp.patient_image[4].url === false) {
          this.left = "Subir";
        }
        if (resp.patient_image[5].url === false) {
          this.rigth = "Subir";
        }
        if (resp.patient_image[1].url === false) {
          this.passport = "Subir";
        }
        if (resp.patient_image[0].url === false) {
          this.profile = "Subir";
        }
        this.url1 = resp.patient_image[3].url;
        this.url2 = resp.patient_image[6].url;
        this.url3 = resp.patient_image[4].url;
        this.url4 = resp.patient_image[5].url;
        this.url5 = resp.patient_image[1].url;
        this.url6 = resp.patient_image[0].url;
      }
    });
  }

  searchDoctor(text: string) {
    const firt_name = text.split(" ", 1);
    const lastName = text.split(" ", 3);
    let firstLastName = lastName[1];
    let lastname = lastName[2];
    let firstName = firt_name[0];

    this.parseFistLastName = String(firstLastName);
    this.parseLastName = String(lastname);
    this.firstN = String(firt_name);
    if (this.parseFistLastName === "undefined") {
      firstLastName = "";
    }
    if (this.parseLastName === "undefined") {
      lastname = "";
    }
    let fullName = firstLastName + " " + lastname;
    const body = {
      name: firstName,
      last_name: fullName.trim(),
    };
    this.formService.searchDoctor(body).subscribe((resp: any) => {
      this.doctorSearch = resp.Doctor;
      if (resp.Doctor.length === 0) {
        this.noFound = true;
      } else {
        this.noFound = false;
      }
    });
  }
  changeLabel() {
  
  }

  seeDoctor(id: number) {
    this.formService.confirmDoctor(id).subscribe((resp: any) => {
      this.doctorSearch = [];
      let name = document.getElementById("name") as HTMLInputElement;
      name.value = "";
      Swal.fire({
        title: "Confirmacion del doctor",
        html:
          '<div class="row">' +
          '<div class="col-md-6">' +
          '<img src="' +
          resp.Doctor.profile_picture +
          '" class="img-fluid" style="width:260; heigth:210" alt="doctor"' +
          ">" +
          "</div>" +
          '<div class="col-md-6 text-left">' +
          "<label><strong> Nombre:</strong> " +
          resp.Doctor.first_name +
          " " +
          resp.Doctor.last_name +
          "</label>" +
          "<label><strong>  Telefono: </strong> " +
          resp.Doctor.phone +
          "</label>" +
          "<label> <strong> Pais: </strong> " +
          resp.Doctor.country.name +
          "</label>" +
          "<label><strong>  Tipo de Procedimientos:</strong>  " +
          resp.Doctor.procedureTypes[0].name +
          "</label>" +
          "</div>" +
          "</div>",
        showCancelButton: true,
        confirmButtonText: "Realizar cita con este doctor",
      }).then((result) => {
        if (result.value) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showCloseButton: true,
          });
          Toast.fire({
            icon: "success",
            title:
              resp.Doctor.first_name +
              " " +
              resp.Doctor.last_name +
              ", seleccionado correctamente",
            showConfirmButton: false,
          });
          const info = {
            name: resp.Doctor.first_name + " " + resp.Doctor.last_name,
          };
          localStorage.setItem("idDoctor", String(id));
          localStorage.setItem("doctorSelectedInfo", String(info.name));
        }
      });
    });
  }

  getLenguage() {
    this.formService.getLenguaje().subscribe((Response: any) => {
      this.lenguage = Response.Languages;
    });
  }

  getIdDoctor() {
    this.activatedRoute.params.subscribe((resp) => {
      const id = resp.id;
      const name = resp.name;
      if (id) {
        localStorage.setItem("idDoctor", String(id));
        // localStorage.setItem("doctorSelectedInfo", String(info.name));
        this.getProcedureByDoctor(id);
      }
    });
  }

  getProcedureByDoctor(idDoctor: any) {
    this.formService
      .getProcedureByDoctor(idDoctor)
      .subscribe((Response: any) => {
        if (this.lang === "es-ES" || this.lang === "es") {
          var procedure = [];
          for (const iterator of Response.procedureTypes) {
            procedure.push({ name: iterator.name_es, identifier: iterator.identifier });
          }
          this.procedureByDoctor = procedure;
        } else {
          var procedure = [];
          for (const iterator of Response.procedureTypes) {
            procedure.push({ name: iterator.name });
          }
          this.procedureByDoctor = procedure;
        }
      });
  }
}

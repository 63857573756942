import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { NewsModel } from "../models/news.model";
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: "root",
})
export class NewsService {
  idUser: string;
  //Todo lo que sea comun de las ur lo guardamos en una variable
  private url = environment.ApiUrl;

  constructor(private http: HttpClient) {
    this.idUser = localStorage.getItem("id");
  }

  getPublication() {
    return this.http.get(`${this.url}/publication-patient/` + this.idUser).pipe(
      map((Response: any) => {
        return Response.publications;
      })
    );
  }

  getOnePublication(id: string) {
    return this.http.get(`${this.url}/get-publication/` + id).pipe(
      map((Response: any[]) => {

        return Response;
      })
    );
  }
}

import { Component, OnInit } from "@angular/core";
import { TranslateServices } from "../../../services/translate.service";
import { Router, NavigationStart } from "@angular/router";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit {
  formdirect = false;
  url: string;
  currentUrl: string;

  constructor(private translate: TranslateServices, private roter: Router) {
    this.translate.translateText();
    //Agregamos los lenguajes que seran usados
  }

  ngOnInit(): void {
    this.roter.events.subscribe((url: any) => {
      if (url instanceof NavigationStart) {
        this.url = url.url;
        const split = this.url.split("/", 2);
        this.currentUrl = split[1];

        if (this.currentUrl === "form-direct") {
          this.formdirect = true;
        } else {
          this.formdirect = false;
        }
      }
    });
  }
}

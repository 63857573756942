import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { FormValidatorsService } from "../../services/form-validators.service";
import { ForgotPasswordService } from "../../services/forgot-password.service";
import { TranslateServices } from "src/app/services/translate.service";

// Ventana de alerta
import Swal from "sweetalert2";
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.css"],
})
export class ChangePasswordComponent implements OnInit {
  form: FormGroup;
  lenguage: string;
  lang: string;

  constructor(
    private fb: FormBuilder,
    private FormValidators: FormValidatorsService,
    private _changePassword: ForgotPasswordService,
    public translate: TranslateServices,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.translate.translateText();
  }

  ngOnInit(): void {
    this.createForm();
    this.lang = navigator.language;
  }

  get password1NoValid() {
    return (
      this.form.get("password1").invalid && this.form.get("password1").touched
    );
  }

  get password2NoValid() {
    const pass1 = this.form.get("password1").value;
    const pass2 = this.form.get("password2").value;
    return pass1 === pass2 ? false : true;
    // return this.form.get('password2').invalid && this.form.get('password2').touched;
  }

  createForm() {
    this.form = this.fb.group(
      {
        password1: ["", [Validators.required, Validators.minLength(6)]],
        password2: ["", [Validators.required, Validators.minLength(6)]],
      },
      {
        validators: this.FormValidators.equalPasswords(
          "password1",
          "password2"
        ),
      }
    );
  }

  changePassword() {
    if (this.form.invalid) {
      return;
    }

    if (this.lang === "es-ES" || this.lang === "es") {
      Swal.fire({
        icon: "info",
        allowOutsideClick: false,
        text: "Espere por favor ...",
      });
    } else if (this.lang === "en-US" || this.lang === "en") {
      Swal.fire({
        icon: "info",
        allowOutsideClick: false,
        text: "Please wait ...",
      });
    } else {
      Swal.fire({
        icon: "info",
        allowOutsideClick: false,
        text: "Please wait ...",
      });
    }

    // Simulation de loading
    Swal.showLoading();
    this.activatedRoute.params.subscribe( resp => {
    const body = {
      password: this.form.value.password1,
      email: resp.email
    };
  
    this._changePassword.changePassword(body).subscribe((resp: any) => {
      Swal.close();

      if (resp.OK === true) {

        if (this.lang === "es-ES" || this.lang === "es") {
          Swal.fire({
            icon: "success",
            allowOutsideClick: false,
            title: resp.message,
            text: "Contraseña cambiada correctamente",
          }).then((result) => {
               this.router.navigateByUrl("/login");
          });
        } else if (this.lang === "en-US" || this.lang === "en") {
          Swal.fire({
            icon: "success",
            title: resp.message,
            text: "Password changed successfully"
          });
        } else {
          Swal.fire({
            icon: "success",
            title: resp.message,
            text: "Password changed successfully"
          });
        }

       
      } else if (resp.OK === false) {
        if (this.lang === "es-ES" || this.lang === "es") {
          Swal.fire({
            icon: "error",
            title: "Error intentando cambiar la contraseña",
            text: resp.Error,
          });
        } else if (this.lang === "en-US" || this.lang === "en") {
          Swal.fire({
            icon: "error",
            title: "Error trying to change password",
            text: resp.Error,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error trying to change password",
            text: resp.Error,
          });
        }
      }
      this.form.reset();
    });
  });
  }
}

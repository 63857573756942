import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-response-canceled',
  templateUrl: './modal-response-canceled.component.html',
  styleUrls: ['./modal-response-canceled.component.css']
})
export class ModalResponseCanceledComponent implements OnInit {

  showAmount: boolean;
  amount: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.showAmount = this.data.showAmount;
    this.amount = this.data.amount;
  }

}

import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";
import { FlagService } from "../../services/flag.service";
import { TranslateServices } from "../../services/translate.service";
import Swal from "sweetalert2";
import { ChatSocketService } from "src/app/services/chat-socket.service";
import { AppointmentService } from '../../services/appointment.service';
import { RequestsListService } from '../../services/requests-list.service';
import { MatDialog } from "@angular/material/dialog";
import { ModalUserFirstTimeComponent } from "../shared/navbar/modal-user-first-time/modal-user-first-time.component";

@Component({
  selector: "app-start",
  templateUrl: "./start.component.html",
  styleUrls: ["./start.component.css"],
})
export class StartComponent implements OnInit {
  public haveNotification = false;
  haveAppointment = false;
  lang: string;
  checkNotification: any;
  isFirstTime: string;

  constructor(
    private auth: AuthService,
    private router: Router,
    private position: FlagService,
    private translate: TranslateServices,
    private accesoClient: RequestsListService,
    public _socketService: ChatSocketService,
    private _appointmentService: AppointmentService,
    public dialog: MatDialog
  ) {
    this.translate.translateText();
    position.getNavbarButtonsActive("start");
  }

  ngOnInit() {
    this._socketService.initConectionSocket();
    
    this.IsUserFisrtTime();
    this._socketService.checkNotification().subscribe( (Response: any) => {
    
      this.checkNotification = Response;

      this._socketService.chatNotificationCount = Response.chatCount;
      this._socketService.chatBadget = Response.chat;

      this._socketService.requestNotificationCount = Response.totalRequestCount;
      this._socketService.requestBadget = Response.totalRequest;

      this._socketService.appointmentNotificationCount = Response.appointmentCount;
      this._socketService.appointmentBadget = Response.appointment;


    });
    
    this.lang = navigator.language;
    this._socketService.haveMessage().subscribe((Response: any) => {
      this.haveNotification = Response.newMessages;
    });
    
   this._socketService.NotifyAppointment().subscribe((Response: any) => {
    if (Response.OK === true )  {
      if (this.lang === "es-ES" || this.lang === "es") {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showCloseButton: true,
          timer: 8000,
          timerProgressBar: true,
        });
        Toast.fire({
          icon: "info",
          title: "Nueva cita agendada",
          showConfirmButton: false
        });

      } else if (this.lang === "en-US" || this.lang === "en") {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showCloseButton: true,
          timer: 8000,
          timerProgressBar: true,
        });
        Toast.fire({
          icon: "info",
          title: "New Appointment schedule",
          showConfirmButton: false,
        });

      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showCloseButton: true,
          timer: 8000,
          timerProgressBar: true,
        });
        Toast.fire({
          icon: "info",
          title: "New Appointment schedule",
          showConfirmButton: false,
        });
    }
    } else {
      this.haveAppointment = false;
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showCloseButton: true,
        timer: 8000,
        timerProgressBar: true,
      });
      Toast.fire({
        icon: "info",
        title: "New Appointment schedule",
        showConfirmButton: false,
      });
    }
   });


   this._socketService.emitNotification().subscribe( (Response: any) => {

    if ( Response.status === true ) {
      if (this.lang === "es-ES" || this.lang === "es") {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showCloseButton: true,
          timer: 8000,
          timerProgressBar: true,
        });
        Toast.fire({
          icon: "info",
          title: "Nueva solicitud aceptada",
          showConfirmButton: false,
        });

      } else if (this.lang === "en-US" || this.lang === "en") {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showCloseButton: true,
          timer: 8000,
          timerProgressBar: true,
        });
        Toast.fire({
          icon: "info",
          title: "New request accepted",
          showConfirmButton: false,
        });

      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showCloseButton: true,
          timer: 8000,
          timerProgressBar: true,
        });
        Toast.fire({
          icon: "info",
          title: "New request accepted",
          showConfirmButton: false,
        });
    }
    }
    if ( Response.status === false ) {
      if (this.lang === "es-ES" || this.lang === "es") {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showCloseButton: true,
          timer: 8000,
          timerProgressBar: true,
        });
        Toast.fire({
          icon: "info",
          title: "Nueva solicitud rechazada",
          showConfirmButton: false,
        });

      } else if (this.lang === "en-US" || this.lang === "en") {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showCloseButton: true,
          timer: 8000,
          timerProgressBar: true,
        });
        Toast.fire({
          icon: "info",
          title: "New request rejected",
          showConfirmButton: false,
        });

      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showCloseButton: true,
          timer: 8000,
          timerProgressBar: true,
        });
        Toast.fire({
          icon: "info",
          title: "New request rejected",
          showConfirmButton: false,
        });
      }
    }
  });
  
  }
   

  IsUserFisrtTime() {
    this.isFirstTime = localStorage.getItem("isFirstTime");
    if (this.isFirstTime === "true") {
      this.openDialog();
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(ModalUserFirstTimeComponent, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.isFirstTime = localStorage.getItem("isFirstTime");
      if (this.isFirstTime === "true") {
        localStorage.setItem('isFirstTime', 'false');
      }
    });
  }

  //Permite que salgamos
  exit() {
    //llamamos la función que destruye el token
    this.auth.logout();
    //Movemos al usuario al login
    this.router.navigateByUrl("home");
  }

  forms() {
    //Nos movemos a forms
    this.router.navigateByUrl("forms");
  }

  search() {
    this.router.navigateByUrl("search");
  }

  chat() {
      this.router.navigateByUrl("chat");
    
  }

  requestList() {
    this.router.navigateByUrl("request-list");
  }

  gallery() {
    this.router.navigateByUrl("gallery");
  }

  appointment() {
    this.router.navigateByUrl("appointment");
  }
}

<div class="row justify-content-center">
  <h3 class="text-muted text-center mt-3" *ngIf="!showDoctorName">
    {{ "FORMS-SURGERY.title" | translate }}
  </h3>
  <h2 class="text-muted text-center mt-3">{{ doctorName }}</h2>
</div>
<div id="mainContainer" class="container-fluid">
  <mat-card>
    <div id="nav-form">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <a
            class="nav-link active"
            id="page1-tab"
            data-toggle="tab"
            href="{{ pageReference1 }}"
            (click)="pages()"
            role="tab"
            aria-controls="page1"
            aria-selected="true"
            >{{ "FORMS-ESTHETIC.page" | translate }} 1</a
          >
        </li>
        <li class="nav-item" role="presentation">
          <!--{{tagDisabled}} : Permite tener activos o desactivadoslos tags -->
          <a
            class="nav-link {{ tagDisabled }}"
            id="page2-tab"
            data-toggle="tab"
            href="{{ pageReference2 }}"
            (click)="pages(); showLoading()"
            role="tab"
            aria-controls="page2"
            aria-selected="false"
            >{{ "FORMS-ESTHETIC.page" | translate }} 2</a
          >
        </li>
        <li class="nav-item" role="presentation">
          <a
            class="nav-link {{ tagDisabled }}"
            id="page3-tab"
            data-toggle="tab"
            href="{{ pageReference3 }}"
            (click)="pages()"
            role="tab"
            aria-controls="page3"
            aria-selected="false"
            >{{ "FORMS-ESTHETIC.page" | translate }} 3</a
          >
        </li>
        <li class="nav-item" role="presentation">
          <a
            class="nav-link {{ tagDisabled }}"
            id="page4-tab"
            data-toggle="tab"
            href="{{ pageReference4 }}"
            (click)="pages()"
            role="tab"
            aria-controls="page4"
            aria-selected="false"
            >{{ "FORMS-ESTHETIC.page" | translate }} 4</a
          >
        </li>
      </ul>
      <form
        [formGroup]="form"
        (ngSubmit)="save()"
        (keydown)="handleKeyupEnter($event)"
      >
        <div class="tab-content" id="myTabContent">
          <!-------------------------------------------------------------------------------------
                    Tab 1
                    --------------------------------------------------------------------------------------->
          <div
            class="tab-pane fade show active"
            id="first"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <!---------------------------------------------------------------
                            Grupo 1
                            ----------------------------------------------------------------->

            <div class="row">
              <div class="col-md-12 mb-4" *ngIf="flag.navbarButtonsActive">
                <div>
                  <h4 class="text-muted">
                    {{ "FORMS-ESTHETIC.title-search" | translate }}
                  </h4>
                </div>
                <!-- <h3>¿Conoces el doctor?  Busca con su nombre o codigo para tu cita</h3> -->
                <div class="form-inline my-0 my-lg-0">
                  <input
                    class="form-control col"
                    name="search"
                    id="name"
                    #NameDoctor
                    (keyup)="disable(NameDoctor.value)"
                    type="text"
                    placeholder="* {{ 'GALLERY.optional' | translate }}"
                    aria-label="Search"
                  />
                  <button
                    class="btn btn-outline-danger"
                    [disabled]="nameLength"
                    type="button"
                    (click)="searchDoctor(NameDoctor.value)"
                  >
                    {{ "GALLERY.search" | translate }}
                  </button>
                  <button  [disabled]="nameLength" 
                           class="btn btn-secondary" type="button"
                         (click)="disable(''); NameDoctor.value = '' ">
                     {{ "settings.btn-close" | translate }}
                    </button>
                </div>
                <div *ngIf="noFound === true" class="row">
                  <div class="col-md-12 mt-5 text-center">
                    <i
                      class="mt-5 fa fa-exclamation-triangle text-warning fa-3x"
                    ></i>
                    <h4 class="text-muted mt-3">
                      {{ "PAYMENT.no-result" | translate }}
                    </h4>
                  </div>
                </div>
                <div
                  class="row mt-3 justify-content-center"
                  *ngIf="doctorSearch.length > 0"
                >
                  <div
                    class="col-md-6 mb-3 mt-2"
                    *ngFor="let item of doctorSearch"
                  >
                    <mat-card class="shadow-lg">
                      <div class="card-body align-self-center text-center">
                        <div class="container-img mb-3">
                          <h4 id="title-d">
                            <strong
                              >{{ "FORMS-SURGERY.name" | translate }}
                            </strong>
                            {{ item.first_name }}
                            {{ item.last_name }}
                          </h4>
                          <div id="doctor-img">
                            <img
                              id="imgCard"
                              [src]="item.profile_picture | imgFalse"
                              alt=""
                            />
                          </div>
                        </div>
                        <button
                          id="btn-doctor"
                          type="button"
                          (click)="seeDoctor(item.id)"
                          class="btn btn-outline-danger"
                        >
                          {{ "NAVBAR.confirm-d" | translate }}
                        </button>
                      </div>
                    </mat-card>
                  </div>
                </div>
              </div>
              <div class="col-md-12 scroll-container">
                <div class="form-row">
                  <div class="col-12">
                    <h5>{{ "FORMS-SURGERY.h5-page1" | translate }}</h5>
                  </div>
                  <div class="col-12">
                    <p class="text-danger">
                      {{ "FORMS-SURGERY.obligatory" | translate }}
                    </p>
                  </div>

                  <div class="form-group col-md-3">
                    <label
                      >{{ "FORMS-SURGERY.name" | translate
                      }}<span class="text-danger"> * </span>
                    </label>
                    <input
                      type="text"
                      formControlName="name"
                      [class.is-invalid]="nameNoValid"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label
                      >{{ "FORMS-SURGERY.lastname" | translate }}
                      <span class="text-danger"> * </span></label
                    >
                    <input
                      type="text"
                      formControlName="lastname"
                      [class.is-invalid]="lastnameNoValid"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label
                      >{{ "FORMS-SURGERY.identification" | translate }}
                      <span class="text-danger"> * </span></label
                    >
                    <input
                      type="text"
                      formControlName="identification"
                      [class.is-invalid]="identificationNoValid"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label
                      >{{ "FORMS-SURGERY.email" | translate }}
                      <span class="text-danger"> * </span></label
                    >
                    <input
                      type="email"
                      formControlName="email"
                      [class.is-invalid]="emailNoValid"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="form-row" *ngIf="!flag.navbarButtonsActive">
                  <!-- <button (click)="openDialog()">Terminos </button> -->
                  <div class="form-group col-md-4">
                    <div class="form-group">
                      <div class="input-text">
                        <label
                          >{{ "ACCOUNT.gender" | translate }}
                          <span class="text-danger"> * </span></label
                        >
                        <small *ngIf="genderNoValid" class="text-danger">{{
                          "ACCOUNT.required" | translate
                        }}</small>
                      </div>
                      <select
                        [class.is-invalid]="genderNoValid"
                        formControlName="gender"
                        class="form-control"
                      >
                        <option [class.is-invalid]="genderNoValid">male</option>
                        <option [class.is-invalid]="genderNoValid">
                          female
                        </option>
                        <option [class.is-invalid]="genderNoValid">
                          other
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group col-md-4">
                    <div class="form-group">
                      <div class="input-text">
                        <label
                          >{{ "ACCOUNT.lenguage" | translate }}
                          <span class="text-danger"> * </span></label
                        >
                        <!-- <small *ngIf="lenguague" class="text-danger ml-1 mr-3">{{
                          "ACCOUNT.required" | translate
                        }}</small> -->
                      </div>

                      <mat-form-field appearance="outline">
                        <mat-select
                          formControlName="lenguage"
                          [class.is-invalid]="lenguague"
                          multiple
                        >
                          <mat-option
                            *ngFor="let langs of lenguage"
                            [value]="langs.id"
                          >
                            {{ langs.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <div class="form-group">
                      <div class="input-text">
                        <label
                          >{{ "FORMS-SURGERY.procedure" | translate }}
                          <span class="text-danger"> * </span></label
                        >
                        <small *ngIf="procedureDoctor" class="text-danger">
                          {{ "ACCOUNT.required" | translate }}</small
                        >
                      </div>
                      <select
                        [class.is-invalid]="procedureDoctor"
                        formControlName="procedure_doctor"
                        class="form-control"
                      >
                        <option
                          [class.is-invalid]="procedureDoctor"
                          *ngFor="let item of procedureByDoctor"
                          [value]="item.identifier"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <!---------------------------------------------------------------
                    Grupo 2
                    ----------------------------------------------------------------->
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label
                      >{{ "FORMS-SURGERY.dateBirth" | translate }}
                      <span class="text-danger"> * </span></label
                    >
                    <input
                      type="date"
                      formControlName="dateBirth"
                      [class.is-invalid]="dateBirth"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label
                      >{{ "FORMS-SURGERY.status" | translate }}
                      <span class="text-danger"> * </span></label
                    >
                    <input
                      type="text"
                      formControlName="status"
                      [class.is-invalid]="statusNoValid"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label
                      >{{ "FORMS-SURGERY.religion" | translate }}
                      <span class="text-danger"> * </span></label
                    >
                    <input
                      type="text"
                      formControlName="religion"
                      [class.is-invalid]="religionNoValid"
                      class="form-control"
                    />
                  </div>
                </div>
                <!---------------------------------------------------------------
                        Grupo 3
                        ----------------------------------------------------------------->
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label
                      >{{ "FORMS-SURGERY.phone" | translate }}
                      <span class="text-danger"> * </span></label
                    >
                    <input
                      type="text"
                      formControlName="phone"
                      [class.is-invalid]="phoneNoValid"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label>{{ "FORMS-SURGERY.occupation" | translate }}</label>
                    <input
                      type="text"
                      formControlName="occupation"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for=""
                      >{{ "FORMS-SURGERY.country" | translate }}
                      <span class="text-danger"> * </span></label
                    >
                    <select
                      [class.is-invalid]="countryNoValid"
                      class="form-control custom-select"
                      id="inputGroupSelect01"
                      formControlName="country"
                    >
                      <option value="" selected>
                        {{ "GALLERY.country" | translate }}
                      </option>
                      <option
                        *ngFor="let country of countryTotal"
                        [value]="country.id"
                      >
                        {{ country.name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-4">
                    <label for=""
                      >{{ "FORMS-SURGERY.city" | translate }}
                      <span class="text-danger"> * </span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      [class.is-invalid]="cityNoValid"
                      formControlName="city"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label>{{ "FORMS-SURGERY.address" | translate }}</label>
                    <input
                      type="text"
                      formControlName="address"
                      class="form-control"
                    />
                  </div>
                </div>
                <!---------------------------------------------------------------
                        Grupo dedicado a metros y pies
                        ----------------------------------------------------------------->
                <div
                  class="form-row"
                  style="
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    margin-bottom: 20px;
                  "
                >
                  <div
                    class="form-group col-md-12"
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      position: static;
                    "
                  >
                    <div
                      class="btn-group btn-group-toggle"
                      data-toggle="buttons"
                      role="group"
                      style="width: 50%"
                    >
                      <label
                        class="btn btn-outline-secondary active"
                        (click)="offOn('m')"
                      >
                        <input
                          type="radio"
                          name="options"
                          id="option1"
                          checked
                        />{{ "FORMS-SURGERY.meterButton" | translate }}
                      </label>
                      <label
                        class="btn btn-outline-secondary"
                        (click)="offOn('f')"
                      >
                        <input type="radio" name="options" id="option2" />
                        {{ "FORMS-SURGERY.feetButton" | translate }}
                      </label>
                    </div>
                  </div>
                  <div class="form-row col-md-12" *ngIf="meterFeet">
                    <div class="form-group col-md-4">
                      <label for="inputPassword4"
                        >{{ "FORMS-SURGERY.kg" | translate }}
                        <span class="text-danger"> * </span></label
                      >
                      <input
                        type="number"
                        formControlName="kg"
                        [class.is-invalid]="kgNoValid"
                        class="form-control"
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label for="inputPassword4"
                        >{{ "FORMS-SURGERY.meter" | translate }}
                        <span class="text-danger"> * </span></label
                      >
                      <input
                        type="number"
                        formControlName="meter"
                        [class.is-invalid]="meterNoValid"
                        placeholder="1.67"
                        class="form-control"
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label for="inputPassword4">{{
                        "FORMS-SURGERY.imc" | translate
                      }}</label>
                      <input
                        type="number"
                        class="form-control"
                        formControlName="imc"
                        [ngModel]="imcTotal | number: '.2-2'"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="form-row col-md-12" *ngIf="!meterFeet">
                    <div class="form-group col-md-4">
                      <label for="inputPassword4"
                        >{{ "FORMS-SURGERY.pounds" | translate }}
                        <span class="text-danger"> * </span></label
                      >
                      <input
                        type="number"
                        formControlName="pounds"
                        class="form-control"
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label for="inputPassword4"
                        >{{ "FORMS-SURGERY.foot" | translate }}
                        <span class="text-danger"> * </span></label
                      >

                      <input
                        type="number"
                        formControlName="foot1"
                        placeholder="5.6'"
                        class="form-control"
                      />
                    </div>
                    <div class="form-group col-md-4">
                      <label for="inputPassword4"
                        >{{ "FORMS-SURGERY.imc" | translate }}
                      </label>
                      <input
                        type="number"
                        formControlName="imc"
                        [ngModel]="imcTotal | number: '.2-2'"
                        disabled
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>

                <!---------------------------------------------------------------
                        Grupo 4
                        ----------------------------------------------------------------->
                <div class="form-row">
                  <div class="form-group col-md-3">
                    <label for="inputEmail4">{{
                      "FORMS-SURGERY.residence" | translate
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="residence"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label for="inputPassword4">{{
                      "FORMS-SURGERY.residencePhone" | translate
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="residencePhone"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label for="inputPassword4">{{
                      "FORMS-SURGERY.emergencyPerson" | translate
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="emergencyPerson"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label for="">{{
                      "FORMS-SURGERY.emergencyPersonPhone" | translate
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="emergencyPersonPhone"
                    />
                  </div>
                </div>

                <!---------------------------------------------------------------
                        Grupo 5
                        ----------------------------------------------------------------->
                <div class="form-row">
                  <div
                    class="form-group col-md-12"
                    *ngIf="flag.navbarButtonsActive"
                  >
                    <label
                      >{{ "ACCOUNT.lenguage" | translate }}
                      <span class="text-danger"> * </span></label
                    >
                    <input
                      type="text"
                      formControlName="lenguage"
                      [class.is-invalid]="lenguague"
                      class="form-control"
                      readonly
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label>{{
                      "FORMS-SURGERY.previousSurgeries" | translate
                    }}</label>
                    <textarea
                      class="form-control"
                      formControlName="previousSurgeries"
                      id="Textarea1"
                      rows="3"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-------------------------------------------------------------------------------------
                Tab 2
                --------------------------------------------------------------------------------------->
          <div
            class="tab-pane fade container-overflow"
            id="second"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <!---------------------------------------------------------------
                        Grupo 1
                        ----------------------------------------------------------------->
            <div class="row">
              <div class="col-12">
                <h5>{{ "FORMS-SURGERY.page2" | translate }}</h5>
              </div>
            </div>

            <div class="form-row" *ngIf="female">
              <div class="col-12">
                <h5>{{ "FORMS-SURGERY.h5-page2" | translate }}</h5>
              </div>
              <div class="form-group col-md-4">
                <label>{{ "FORMS-SURGERY.pregnancy" | translate }}</label>
                <input
                  type="number"
                  formControlName="pregnancy"
                  class="form-control"
                />
              </div>
              <div class="form-group col-md-4">
                <label>{{ "FORMS-SURGERY.abortions" | translate }}</label>
                <input
                  type="number"
                  formControlName="abortions"
                  class="form-control"
                />
              </div>
              <div class="form-group col-md-4">
                <label>{{ "FORMS-SURGERY.childbirth" | translate }}</label>
                <input
                  type="number"
                  formControlName="childbirth"
                  class="form-control"
                />
              </div>
              <div class="form-group col-md-6">
                <label>{{ "FORMS-SURGERY.lastpregnancy" | translate }}</label>
                <input
                  type="date"
                  formControlName="lastpregnancy"
                  [class.is-invalid]="lastpregnancyNoValid"
                  class="form-control"
                />
              </div>
              <div class="form-group col-md-6">
                <label>{{ "FORMS-SURGERY.caesarean" | translate }}</label>
                <input
                  type="number"
                  formControlName="caesarean"
                  class="form-control"
                />
              </div>
            </div>

            <!-- new values -->
            <div class="form-row border-check">
              <div class="form-group col-md-4">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="transfusion"
                    id="transfusion"
                  />
                  <label class="custom-control-label" for="transfusion">
                    {{ "FORMS-SURGERY.transfusion" | translate }}
                  </label>
                </div>
              </div>
              <div class="form-group  col-md-4">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="had_covid"
                    id="had_covid"
                  />
                  <label class="custom-control-label" for="had_covid">
                    {{ "FORMS-SURGERY.had_covid" | translate }}
                  </label>
                </div>
              </div>
              <div class="form-group col-md-4">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="vaccinated_covid"
                    id="vaccinated_covid"
                  />
                  <label class="custom-control-label" for="vaccinated_covid">
                    {{ "FORMS-SURGERY.vaccinated_covid" | translate }}
                  </label>
                </div>
              </div>
            </div>
            
            <div class="form-row">
              <div class="form-group col-md-4">
                <label>{{
                  "FORMS-SURGERY.last_transfusion_date" | translate
                }}</label>
                <input
                  type="date"
                  formControlName="last_transfusion_date"
                  class="form-control"
                />
              </div>

              <div class="form-group col-md-4">
                <label>{{ "FORMS-SURGERY.blood_type" | translate }}</label>
                <input
                  type="text"
                  formControlName="blood_type"
                  class="form-control"
                />
              </div>

              <div class="form-group col-md-4">
                <label>{{ "FORMS-SURGERY.tachycardia" | translate }}</label>
                <input
                  type="text"
                  formControlName="tachycardia"
                  class="form-control"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label>{{ "FORMS-SURGERY.neurological" | translate }}</label>
                <input
                  type="text"
                  formControlName="neurological"
                  class="form-control"
                />
              </div>
              <div class="form-group col-md-6">
                <label>{{ "FORMS-SURGERY.circulatory" | translate }}</label>
                <input
                  type="text"
                  formControlName="circulatory"
                  class="form-control"
                />
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-3">
                <label>{{ "FORMS-SURGERY.skin" | translate }}</label>
                <input
                  type="text"
                  formControlName="skin"
                  class="form-control"
                />
              </div>
              <div class="form-group col-md-3">
                <label>{{
                  "FORMS-SURGERY.gastrointestinal" | translate
                }}</label>
                <input
                  type="text"
                  formControlName="gastrointestinal"
                  class="form-control"
                />
              </div>
              <div class="form-group col-md-3">
                <label>{{
                  "FORMS-SURGERY.breast_pathologies" | translate
                }}</label>
                <input
                  type="text"
                  formControlName="breast_pathologies"
                  class="form-control"
                />
              </div>
              <div class="form-group col-md-3">
                <label>{{ "FORMS-SURGERY.hypothyroidism" | translate }}</label>
                <input
                  type="text"
                  formControlName="hypothyroidism"
                  class="form-control"
                />
              </div>
            </div>

            <!---------------------------------------------------------------
                        Grupo 2
                     ----------------------------------------------------------------->
            <div class="form-row">
              <div class="form-group col-md-6">
                <label>{{ "FORMS-SURGERY.diseases" | translate }}</label>
                <textarea
                  class="form-control"
                  formControlName="diseases"
                  id="Textarea2"
                  rows="3"
                ></textarea>
              </div>
              <div class="form-group col-md-6">
                <label>{{ "FORMS-SURGERY.medicines" | translate }}</label>
                <textarea
                  class="form-control"
                  formControlName="medicines"
                  id="Textarea3"
                  rows="3"
                ></textarea>
              </div>
            </div>
            <!---------------------------------------------------------------
                        Grupo 3
                    ----------------------------------------------------------------->
            <div class="form-row">
              <div class="form-group col-md-12">
                <label>{{ "FORMS-SURGERY.allergies" | translate }} </label>
                <textarea
                  type="text"
                  formControlName="allergies"
                  class="form-control"
                  rows="3"
                >
                </textarea>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12">
                <label>{{
                  "FORMS-SURGERY.hereditary_history" | translate
                }}</label>
                <textarea
                  class="form-control"
                  formControlName="hereditary_history"
                  id="Textarea4"
                  rows="3"
                ></textarea>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12">
                <label>{{
                  "FORMS-SURGERY.extraInformation" | translate
                }}</label>
                <textarea
                  class="form-control"
                  formControlName="extraInformation"
                  id="Textarea4"
                  rows="3"
                ></textarea>
              </div>
            </div>
            <!---------------------------------------------------------------
                        Grupo 4
                    ----------------------------------------------------------------->
            <div class="form-row mt-3">
              <div class="form-group col-sm-4 col-md-4">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="asthma"
                    id="asthma"
                  />
                  <label class="custom-control-label" for="asthma">{{
                    "FORMS-SURGERY.asthma" | translate
                  }}</label>
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="falcemia"
                    id="falcemia"
                  />
                  <label class="custom-control-label" for="falcemia">{{
                    "FORMS-SURGERY.falcemia" | translate
                  }}</label>
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="diabetes"
                    id="diabetes"
                  />
                  <label class="custom-control-label" for="diabetes">{{
                    "FORMS-SURGERY.diabetes" | translate
                  }}</label>
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="smoker"
                    id="smoker"
                  />
                  <label class="custom-control-label" for="smoker">{{
                    "FORMS-SURGERY.smoker" | translate
                  }}</label>
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="alcohol"
                    id="alcohol"
                  />
                  <label class="custom-control-label" for="alcohol">{{
                    "FORMS-SURGERY.alcohol" | translate
                  }}</label>
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="contraceptive"
                    id="contraceptive"
                  />
                  <label class="custom-control-label" for="contraceptive">{{
                    "FORMS-SURGERY.contraceptive" | translate
                  }}</label>
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="hiv"
                    id="hiv"
                  />
                  <label class="custom-control-label" for="hiv">{{
                    "FORMS-SURGERY.hiv" | translate
                  }}</label>
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="cancer"
                    id="cancer"
                  />
                  <label class="custom-control-label" for="cancer">{{
                    "FORMS-SURGERY.cancer" | translate
                  }}</label>
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="thalassemia"
                    id="thalassemia"
                  />
                  <label class="custom-control-label" for="thalassemia">{{
                    "FORMS-SURGERY.thalassemia" | translate
                  }}</label>
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="willebrand"
                    id="willebrand"
                  />
                  <label class="custom-control-label" for="willebrand">{{
                    "FORMS-SURGERY.willebrand" | translate
                  }}</label>
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="biopolymers"
                    id="biopolymers"
                  />
                  <label class="custom-control-label" for="biopolymers">{{
                    "FORMS-SURGERY.biopolymers" | translate
                  }}</label>
                </div>

                <!-- new values  -->
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="oxycodone"
                    id="oxycodone"
                  />
                  <label class="custom-control-label" for="oxycodone">{{
                    "FORMS-SURGERY.oxycodone" | translate
                  }}</label>
                </div>
              </div>

              <!-- new colunm -->
              <div class="form-group col-sm-4 col-md-4">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="natural_medicines"
                    id="natural_medicines"
                  />
                  <label class="custom-control-label" for="natural_medicines">{{
                    "FORMS-SURGERY.natural_medicines" | translate
                  }}</label>
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="calcium"
                    id="calcium"
                  />
                  <label class="custom-control-label" for="calcium">{{
                    "FORMS-SURGERY.calcium" | translate
                  }}</label>
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="vitamins_e_d_c"
                    id="vitamins_e_d_c"
                  />
                  <label class="custom-control-label" for="vitamins_e_d_c">{{
                    "FORMS-SURGERY.vitamins_e_d_c" | translate
                  }}</label>
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="omega_3"
                    id="omega_3"
                  />
                  <label class="custom-control-label" for="omega_3">{{
                    "FORMS-SURGERY.omega_3" | translate
                  }}</label>
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="leucine"
                    id="leucine"
                  />
                  <label class="custom-control-label" for="leucine">{{
                    "FORMS-SURGERY.leucine" | translate
                  }}</label>
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="sinusitis"
                    id="sinusitis"
                  />
                  <label class="custom-control-label" for="sinusitis">{{
                    "FORMS-SURGERY.sinusitis" | translate
                  }}</label>
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="hypercholesterolemia"
                    id="hypercholesterolemia"
                  />
                  <label
                    class="custom-control-label"
                    for="hypercholesterolemia"
                    >{{
                      "FORMS-SURGERY.hypercholesterolemia" | translate
                    }}</label
                  >
                </div>

                <!-- old values -->

                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="hypertension"
                    id="hypertension"
                  />
                  <label class="custom-control-label" for="hypertension">{{
                    "FORMS-SURGERY.hypertension" | translate
                  }}</label>
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="pFalcemia"
                    id="pFalcemia"
                  />
                  <label class="custom-control-label" for="pFalcemia">{{
                    "FORMS-SURGERY.pFalcemia" | translate
                  }}</label>
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="depression"
                    id="depression"
                  />
                  <label class="custom-control-label" for="depression">{{
                    "FORMS-SURGERY.depression" | translate
                  }}</label>
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="hookah"
                    id="hookah"
                  />
                  <label class="custom-control-label" for="hookah">{{
                    "FORMS-SURGERY.hookah" | translate
                  }}</label>
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="drugs"
                    id="drugs"
                  />
                  <label class="custom-control-label" for="drugs">{{
                    "FORMS-SURGERY.drugs" | translate
                  }}</label>
                </div>
              </div>

              <div class="form-group col-sm-4 col-md-4">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="lactating"
                    id="lactating"
                  />
                  <label class="custom-control-label" for="lactating">{{
                    "FORMS-SURGERY.lactating" | translate
                  }}</label>
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="hepatitis"
                    id="hepatitis"
                  />
                  <label class="custom-control-label" for="hepatitis">{{
                    "FORMS-SURGERY.hepatitis" | translate
                  }}</label>
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="leukemia"
                    id="leukemia"
                  />
                  <label class="custom-control-label" for="leukemia">{{
                    "FORMS-SURGERY.leukemia" | translate
                  }}</label>
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="hemophilia"
                    id="hemophilia"
                  />
                  <label class="custom-control-label" for="hemophilia">{{
                    "FORMS-SURGERY.hemophilia" | translate
                  }}</label>
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="penicillinAllergy"
                    id="penicillinAllergy"
                  />
                  <label class="custom-control-label" for="penicillinAllergy">{{
                    "FORMS-SURGERY.penicillinAllergy" | translate
                  }}</label>
                </div>

                <!-- new values  -->
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="currently_pregnant"
                    id="currently_pregnant"
                  />
                  <label
                    class="custom-control-label"
                    for="currently_pregnant"
                    >{{ "FORMS-SURGERY.currently_pregnant" | translate }}</label
                  >
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="previous_transfusion"
                    id="previous_transfusion"
                  />
                  <label
                    class="custom-control-label"
                    for="previous_transfusion"
                    >{{
                      "FORMS-SURGERY.previous_transfusion" | translate
                    }}</label
                  >
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="aspirin"
                    id="aspirin"
                  />
                  <label class="custom-control-label" for="aspirin">{{
                    "FORMS-SURGERY.aspirin" | translate
                  }}</label>
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="sedatives"
                    id="sedatives"
                  />
                  <label class="custom-control-label" for="sedatives">{{
                    "FORMS-SURGERY.sedatives" | translate
                  }}</label>
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="anti_hypertensive"
                    id="anti_hypertensive"
                  />
                  <label class="custom-control-label" for="anti_hypertensive">{{
                    "FORMS-SURGERY.anti_hypertensive" | translate
                  }}</label>
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="anti_coagulants"
                    id="anti_coagulants"
                  />
                  <label class="custom-control-label" for="anti_coagulants">{{
                    "FORMS-SURGERY.anti_coagulants" | translate
                  }}</label>
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="anti_depressants"
                    id="anti_depressants"
                  />
                  <label class="custom-control-label" for="anti_depressants">{{
                    "FORMS-SURGERY.anti_depressants" | translate
                  }}</label>
                </div>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    formControlName="medicines_to_lose_weight"
                    id="medicines_to_lose_weight"
                  />
                  <label
                    class="custom-control-label"
                    for="medicines_to_lose_weight"
                    >{{
                      "FORMS-SURGERY.medicines_to_lose_weight" | translate
                    }}</label
                  >
                </div>
              </div>
            </div>
          </div>
          <!-------------------------------------------------------------------------------------
                Tab 3
                --------------------------------------------------------------------------------------->
          <div
            class="tab-pane fade container-overflow"
            id="third"
            role="tabpanel"
            aria-labelledby="contact-tab"
          >
            <div class="form-row card-image-container">
              <div class="col-12">
                <h5>{{ "FORMS-SURGERY.h5-page3" | translate }}</h5>
              </div>
              <div class="form-group col-md-6 card-image">
                <!--Targetas para la captura de imagenes-->
                <div class="card">
                  <div class="container-img">
                    <p>{{ "FORMS-SURGERY.front" | translate }}</p>
                    <div id="circle-img">
                      <img id="imgCard" [src]="url1 | imgFalse" alt="" />
                    </div>
                  </div>
                  <div class="card-body" id="card-action">
                    <!-- El label sustituira el input para poder darle estilo al input type="file"-->
                    <div>
                      <input
                        type="file"
                        name="file1"
                        id="file1"
                        class="inputfile"
                        (click)="imgContainer = 1"
                        (change)="onselectFile($event)"
                      />
                      <label for="file1" class="labelButtonFile">{{
                        front
                      }}</label>
                    </div>
                    <!-- <div>
                            <input type="file" name="button1" id="button1" class="inputDelete" (click)="imgContainer=1" (change)="onselectFile( $event )" />
                             <label for="button1"  class="labelButtonDelete">{{ "FORMS-SURGERY.modifyButton" | translate }}</label>
                          </div> -->
                  </div>
                </div>
              </div>
              <div class="form-group col-md-6 card-image">
                <!--Targetas para la captura de imagenes-->
                <div class="card">
                  <div class="container-img">
                    <p>{{ "FORMS-SURGERY.back" | translate }}</p>
                    <div id="circle-img">
                      <img id="imgCard" [src]="url2 | imgFalse" alt="" />
                    </div>
                  </div>
                  <div class="card-body" id="card-action">
                    <!--El label sustituira el input para poder darle estilo al input type="file"-->
                    <div>
                      <input
                        type="file"
                        name="file2"
                        id="file2"
                        class="inputfile"
                        (click)="imgContainer = 2"
                        (change)="onselectFile($event)"
                      />
                      <label for="file2" class="labelButtonFile">
                        {{ back }}</label
                      >
                    </div>
                    <!-- <div>
                                        <input type="file" name="button2" id="button2"  class="inputDelete" />
                                        <label for="button2" class="labelButtonDelete">{{ "FORMS-SURGERY.modifyButton" | translate }}</label>
                                    </div> -->
                  </div>
                </div>
              </div>
              <div class="form-group col-md-6 card-image">
                <!--Targetas para la captura de imagenes-->
                <div class="card">
                  <div class="container-img">
                    <p>{{ "FORMS-SURGERY.left" | translate }}</p>
                    <div id="circle-img">
                      <img id="imgCard" [src]="url3 | imgFalse" alt="" />
                    </div>
                  </div>
                  <div class="card-body" id="card-action">
                    <!--El label sustituira el input para poder darle estilo al input type="file"-->
                    <div>
                      <input
                        type="file"
                        name="file3"
                        id="file3"
                        class="inputfile"
                        (click)="imgContainer = 3"
                        (change)="onselectFile($event)"
                      />
                      <label for="file3" class="labelButtonFile">
                        {{ left }}
                      </label>
                    </div>

                    <!-- <div>
                                        <input type="file" name="button3" id="button3" class="inputDelete"  (click)="imgContainer=3" (change)="onselectFile( $event )" />
                                        <label for="button3" class="labelButtonDelete">{{ "FORMS-SURGERY.modifyButton" | translate }}</label>
                                    </div> -->
                  </div>
                </div>
              </div>
              <div class="form-group col-md-6 card-image">
                <!--Targetas para la captura de imagenes-->
                <div class="card">
                  <div class="container-img">
                    <p>{{ "FORMS-SURGERY.right" | translate }}</p>
                    <div id="circle-img">
                      <img id="imgCard" [src]="url4 | imgFalse" alt="" />
                    </div>
                  </div>
                  <div class="card-body" id="card-action">
                    <!--El label sustituira el input para poder darle estilo al input type="file"-->
                    <div>
                      <input
                        type="file"
                        name="file4"
                        id="file4"
                        class="inputfile"
                        (click)="imgContainer = 4"
                        (change)="onselectFile($event)"
                      />
                      <label for="file4" class="labelButtonFile">{{
                        rigth
                      }}</label>
                    </div>
                    <!-- <div>
                                        <input type="file" name="button4" id="button4" class="inputDelete"   (click)="imgContainer=3" (change)="onselectFile( $event )"/>
                                        <label for="button4" class="labelButtonDelete">{{"FORMS-SURGERY.modifyButton" | translate }}</label>
                                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-------------------------------------------------------------------------------------
                Tab 4
                --------------------------------------------------------------------------------------->
          <div
            class="tab-pane fade container-overflow"
            id="fourth"
            role="tabpanel"
            aria-labelledby="contact-tab"
          >
            <div class="form-row">
              <div class="col-12">
                <h5>{{ "FORMS-SURGERY.h5-page4" | translate }}</h5>
              </div>
              <div class="form-group col-md-6 card-image">
                <!--Targetas para la captura de imagenes-->
                <div class="card">
                  <div class="container-img">
                    <p>{{ "FORMS-SURGERY.passport" | translate }}</p>
                    <div id="circle-img">
                      <img id="imgCard" [src]="url5 | imgFalse" alt="" />
                    </div>
                  </div>
                  <div class="card-body" id="card-action">
                    <!--El label sustituira el input para poder darle estilo al input type="file"-->
                    <div>
                      <input
                        type="file"
                        name="file5"
                        id="file5"
                        class="inputfile"
                        (click)="imgContainer = 5"
                        (change)="onselectFile($event)"
                      />
                      <label for="file5" class="labelButtonFile">{{
                        passport
                      }}</label>
                    </div>
                    <!-- <div>
                                        <input type="file" name="button5" id="button5" class="inputDelete"  (click)="imgContainer=5" (change)="onselectFile( $event )"/>
                                        <label for="button5" class="labelButtonDelete">{{ "FORMS-SURGERY.modifyButton" | translate }}</label>
                                    </div> -->
                  </div>
                </div>
              </div>
              <div class="form-group col-md-6 card-image">
                <!-- Targetas para la captura de imagenes -->
                <div class="card">
                  <div class="container-img">
                    <p>{{ "FORMS-SURGERY.profile" | translate }}</p>
                    <div id="circle-img">
                      <img id="imgCard" [src]="url6 | imgFalse" alt="" />
                    </div>
                  </div>
                  <!-- <div>
                          <p>{{ "FORMS-SURGERY.profile" | translate }}</p>
                           <img [src]="url6 | imgFalse" alt="">
                       </div> -->
                  <div class="card-body" id="card-action">
                    <!--El label sustituira el input para poder darle estilo al input type="file"-->
                    <div>
                      <input
                        type="file"
                        name="file6"
                        id="file6"
                        class="inputfile"
                        (click)="imgContainer = 6"
                        (change)="onselectFile($event)"
                      />
                      <label for="file6" class="labelButtonFile">{{
                        profile
                      }}</label>
                    </div>
                    <!-- <div>
                                            <input type="file" name="button6" id="button6" class="inputDelete"  [disabled]="imgNull" (click)="imgContainer=6" (change)="onselectFile( $event )" />
                                            <label for="button6" class="labelButtonDelete">{{ "FORMS-SURGERY.modifyButton" | translate }}</label>
                                        </div> -->
                  </div>
                </div>
              </div>
            </div>

            <div
              class="form-group form-check"
              *ngIf="!flag.navbarButtonsActive"
            >
              <input
                type="checkbox"
                class="form-check-input"
                formControlName="terms"
                id="exampleCheck1"
                [class.is-invalid]="termsNoValid"
              />
              <label class="form-check-label small">
                {{ "terms.terms-condition" | translate }}
                <span class="text-danger"> * </span>
                <a id="terms" class="navbar-brand" (click)="openDialog()">
                  {{ "terms.terms-link" | translate }}
                </a>
              </label>
              <span class="text-danger small" *ngIf="termsNoValid">
                {{ "ACCOUNT.required" | translate }}
                {{ "terms.terms-link" | translate }}
              </span>
            </div>

            <div id="sendButton">
              <button type="submit" class="btn btn-danger">
                {{ "FORMS-SURGERY.sendForm" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-card>
</div>

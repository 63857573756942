import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthService,
              private router: Router) {}

  canActivate(): boolean {

    // Si no esta logueado que mande al usuario a login
    if ( this.auth.isAuthenticated() ) {
      return true;
    } else {
      // Si el usuario no esta loqueado sera enviado al login
      this.router.navigateByUrl('/login')
      return false;
    }
  }
}


import { Component, OnInit } from "@angular/core";
import { DoctorModel } from "../../models/doctor.model";
import { RequestsListService } from "../../services/requests-list.service";
import { Router } from "@angular/router";
import { FlagService } from "../../services/flag.service";
import { TranslateServices } from "../../services/translate.service";
import Swal from "sweetalert2";
import { ChatSocketService } from "../../services/chat-socket.service";

@Component({
  selector: "app-request-list",
  templateUrl: "./request-list.component.html",
  styleUrls: ["./request-list.component.css"],
})
export class RequestListComponent implements OnInit {
  // Lista de doctores
  requestsWait = [];
  requestAccepted = [];
  lang: string;
  showEs: boolean;
  acceptedNotification: boolean;
  rejectedNotification: boolean;
  requestsRejected = [];
  checkNotification: any;
  d: Date = new Date();
  // Almacenamos desde donde entramos a client
  access: boolean;

  constructor(
    private list: RequestsListService,
    private router: Router,
    private accesoClient: RequestsListService,
    private flag: FlagService,
    public chatSocketService: ChatSocketService,
    private translate: TranslateServices
  ) {
    this.translate.translateText();
    // this.requestsList = list.getRequestsList();
    flag.getClientButtonsActive("request");
  }

  ngOnInit(): void {
    this.getRequest();
    this.lang = navigator.language;
    this.chatSocketService.checkNotification().subscribe((Response: any) => {
      this.checkNotification = Response;

      this.chatSocketService.requestCountAccepted = Response.requestCountAccepted;
      this.chatSocketService.requestAccepted = Response.requestAccepted;

      this.chatSocketService.requestCountRejected = Response.requestCountRejected;
      this.chatSocketService.requestRejected = Response.requestRejected;
    });
  

    this.chatSocketService.emitNotification().subscribe((Response: any) => {
      if (Response.status === true) {
        this.acceptedNotification = true;
        this.rejectedNotification = false;
      }
      if (Response.status === false) {
        this.acceptedNotification = false;
        this.rejectedNotification = true;
      }
    });
  }
  // Nos manda a la pagina del cliente
  client(i: string) {
    this.access = this.accesoClient.accesoClient = true;
    this.router.navigate(["/client", i]);
  }

  getRequest() {
    this.accesoClient.getRequest().subscribe((resp: any) => {
     
      if (resp.OK === true) {
     
        if (this.lang === "es-ES" || this.lang === "es") {
          this.showEs = true;
        } else if (this.lang === "en-US" || this.lang === "en") {
          this.showEs = false;
        } else {
          this.showEs = false;
        }
       
        this.requestsWait = resp.procedureRequest.filter(
          (request) => request.status === "request_created"
          );
          this.requestAccepted = resp.procedureRequest.filter(
            (request) => request.status === "request_accepted"
            );
            this.requestsRejected = resp.procedureRequest.filter(
              (request) => request.status === "request_rejected"
              );
             
      } else if (resp.Ok === false) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: resp.Message,
        });
      }
    });
  }

  seeReason(reason: string) {
    Swal.fire({
      text: reason,
      icon: "info",
    });
  }
  deleteAppointment(id: string) {
    if (this.lang === "es-ES" || this.lang === "es") {
      Swal.fire({
        text: "No podrá revertir esto",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminarlo",
      }).then((result) => {
        if (result.value) {
          this.list.deleteRequest(id).subscribe((resp: any) => {
            if (resp.OK === true) {
              Swal.fire({
                title: "Cancelado",
                text: "Cita cancelada correctamente",
                icon: "success",
              });
              this.getRequest();
            } else {
              Swal.fire({
                title: "Error",
                text: resp.message,
                icon: "error",
              });
            }
          });
        }
      });
    } else if (this.lang === "en-US" || this.lang === "en") {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.list.deleteRequest(id).subscribe((resp: any) => {
            if (resp.OK === true) {
              Swal.fire({
                title: "Cancelled",
                text: "Appointment canceled successfully",
                icon: "success",
              });
              this.getRequest();
            } else {
              Swal.fire({
                title: "Error",
                text: resp.message,
                icon: "error",
              });
            }
          });
        }
      });
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.list.deleteRequest(id).subscribe((resp: any) => {
            if (resp.OK === true) {
              Swal.fire({
                title: "Cancelled",
                text: "Appointment canceled successfully",
                icon: "success",
              });
              this.getRequest();
            } else {
              Swal.fire({
                title: "Error",
                text: resp.message,
                icon: "error",
              });
            }
          });
        }
      });
    }
  }
}

<div id="mainContainer" class="container">
  <mat-card class="mb-4 mt-5 mat-heigh">
    <div id="vertical">
      <i
        id="back"
        (click)="backClient()"
        class="fa fa-arrow-left fa-2x text-danger float-left"
      ></i>
      <h2 id="text" class="text-danger float-left">
        {{ "SEARCH.back" | translate }}
      </h2>
      <h3 class="text-muted text-center">
        {{ "SEARCH.titlePage" | translate }}
      </h3>
    </div>
  </mat-card>
  <!--HeadClient-->
  <mat-card class="mb-4">
    <div class="row" id="headerInformation">
      <div class="col-12 col-md-4">
        <div class="container-image">
          <div id="circle-image">
            <img
              id="img-"
              class="img"
              [src]="doctor.profile_picture | imgFalse"
              alt="doctor picture"
            />
          </div>
        </div>
      </div>
      <div id="info" class="col-12 col-md-4">
        <p><b>{{ "SEARCH.docTitle" | translate }} </b> {{ doctor.title }}</p>
        <p><b>{{ "SEARCH.docName" | translate }} </b> {{ doctor.first_name }}</p>
        <p><b>{{ "SEARCH.docCountry" | translate }}  </b> {{ doctor.country.name }}</p>
        <p><b>{{ "SEARCH.docLanguage" | translate }}  </b> {{ doctor.primarylanguage }}</p>
      </div>
      <div id="info-2" class="col-12 col-md-4">
        <p><b>{{ "SEARCH.description" | translate }} </b></p>
        <p id="description">{{ doctor.description }}</p>
        <p><b> {{ "SEARCH.treatments" | translate }}  </b> {{ doctor.procedureTypes[0].name }}</p>
      </div>
    </div>
  </mat-card>
  <!-- <div class="actionButtons">
        <button class="btn btn-outline-danger" (click)="putFavorite(true)">{{ textFavorite }}</button>
    </div> -->
  <div id="bodyGallery" style="width: 100%" class="mt-5">
    <div class="card-columns">
      <div class="card" *ngFor="let picture of oneFromGallery">
        <mat-card>
          <div class="container-img">
            <div id="circle-img">
              <img
                id="imgCard"
                [src]="picture.url | imgFalse"
                class=""
                alt="Doctor image"
              />
            </div>
          </div>
          <!-- <img [src]="picture.url" class="card-img-top" alt="..."> -->
          <div class="card-body">
            <!-- <h5 class="card-title">{{ picture.date | date }}</h5> -->
            <p class="card-text">{{ picture.description }}</p>
            <p class="card-text">
              <small style="color: #009688">{{ picture.date | date }}</small>
            </p>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>

<div id="main-Container">
    <div id="firstDiv">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h2>
                        NovusDoc
                    </h2>
                    <h1><small>{{ "ABOUT.h1" | translate }}</small></h1>
                    <p>{{ "ABOUT.h1-text" | translate }}</p>
                </div>
            </div>
        </div>

    </div>

    <div id="secondDiv">
        <div class="container">
            <div class="row">

                <div class="col-12 col-sm-6 col-md-3">
                    <div id="img">
                        <i class="fas fa-user-md"></i>
                    </div>
                    <h3><b>{{ "ABOUT.doctors" | translate }}</b></h3>
                    <p>{{ "ABOUT.doctors-text" | translate }}</p>
                </div>


                <div class="col-12 col-sm-6 col-md-3">
                    <div id="img">
                        <i class="fas fa-file-medical"></i>
                    </div>
                    <h3><b>{{ "ABOUT.documentation" | translate }}</b></h3>
                    <p>{{ "ABOUT.documentation-text" | translate }}</p>
                </div>

                <div class="col-12 col-sm-6 col-md-3">
                    <div id="img">
                        <i class="fas fa-cloud-upload-alt"></i>
                    </div>
                    <h3><b>{{ "ABOUT.cloud" | translate }}</b></h3>
                    <p>{{ "ABOUT.cloud-text" | translate }}</p>
                </div>

                <div class="col-12 col-sm-6 col-md-3">
                    <div id="img">
                        <i class="fas fa-house-user"></i>
                    </div>
                    <h3><b>{{ "ABOUT.recovery" | translate }}</b></h3>
                    <p>{{ "ABOUT.recovery-text" | translate }}</p>
                </div>

            </div>
        </div>

    </div>


</div>
import { Component, OnInit } from "@angular/core";
import { TranslateServices } from "../../services/translate.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UploadImageService } from "../../services/upload-image.service";
import { FormService } from "../../services/form.service";
import Swal from "sweetalert2";
import { FormModel } from "../../models/form.model";
import { SearchService } from "../../services/search.service";
import { ModalAppointmentComponent } from "../client/modal-appointment/modal-appointment.component";
import { MatDialog } from "@angular/material/dialog";
import { CountrysService } from '../../services/countrys.service';

@Component({
  selector: "app-form-esthetic",
  templateUrl: "./form-esthetic.component.html",
  styleUrls: ["./form-esthetic.component.css"],
})
export class FormEstheticComponent implements OnInit {
  nameLength = true;
  doctorSearch: any[] = [];
  // Referencia al formulario
  form: FormGroup;
  front: string = "Actualizar";
  back: string = "Actualizar";
  picture: string = "Actualizar";
  nameUser: string;
  fullName: string;
  countryTotal: any[] = [];
  parseFistLastName: string;
  parseLastName: string;
  firstN: string;
  lang: string;
  dataModel: FormModel;
  kg: number;
  meters: number;
  pounds: number;
  foot1: number;
  foot2: number;
  gender: string;
  noFound = false;

  // Mandamos la referencia a la pagina a moverno si han sido completados los valores basicos.
  public pageReference1 = "#first";
  public pageReference2 = "#second";
  public pageReference3 = "#thrid";

  // Desactiva las siguientes paginas hasta que los campos principales estan activos
  public tagDisabled = "disabled";

  // Activa metro o pies
  meterFeet = true;

  // Almacena el IMC para enviarlo al html
  imcTotal = 0;

  // Se encarga de tomar una imagen o archivo para la subida al servidor
  selectedFile: File = null;

  // variable donde almacenamos el url de la imagen
  url1 = "assets/img/drop-images.png";
  url2 = "assets/img/drop-images.png";
  url3 = "assets/img/drop-images.png";

  // Permite determinar a cual url sera enviada la imagen
  imgContainer: number;
  female = true;
  // Nombre de la imagen subida
  public imageName: string;
  // Idioma
  public language: string;

  constructor(
    private translate: TranslateServices,
    private fb: FormBuilder,
    private imageService: UploadImageService,
    public dialog: MatDialog,
    private _countryServices: CountrysService,
    private formService: FormService,
    private _doctorServices: SearchService
  ) {
    this.getDataForm();
    this.translate.translateText();

    this.createForm();

    // Listener Form, para detertar los cambios en el formulario
    this.listenerForm();

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (p) {
          const latitude = p.coords.latitude;
          const longitude = p.coords.longitude;
        },
        function (e) {
          // ipLookup();
        }
      );
    }
  }

  ngOnInit(): void {
    this.changeViewGender();
    this.getDataForm();
    this.getImages();
    this.lang = navigator.language;
    this.countryTotal = this._countryServices.sendCoubntries()
 
    // this._doctorServices.getContries().subscribe((resp) => {
    //   this.countryTotal = resp;
    // });
  }

  // Permite que nos movamos entre las paginas del formulario
  pages() {
    // Le pondremos a todos los campos que han sido tocados
    Object.values(this.form.controls).forEach((control) => {
      // Me devuelve todos los valores
      // Todos los valores han sido tocados
      // Ventana emergente informativa
      if (this.form.invalid) {
        // Mostrando mensaje de error
        if (this.lang === "es-ES" || this.lang === "es") {
          Swal.fire({
            icon: "error",
            title: "Formulario Incompleto",
            text: "Por favor complete los datos basicos minimos",
          });
        } else if (this.lang === "en-US" || this.lang === "en") {
          Swal.fire({
            icon: "error",
            title: "Incomplete Form",
            text: "Please complete the minimum basic data",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Incomplete Form",
            text: "Please complete the minimum basic data",
          });
        }
      }
      control.markAllAsTouched();
    });

    // Si todos los datos estan llenos llamamos las funcion de crear formulario
    if (this.form.valid) {
      this.dataModel = new FormModel();

      this.dataModel.first_name = this.form.value.first_name;
      this.dataModel.last_name = this.form.value.last_name;
      this.dataModel.passport = this.form.value.passport;
      this.dataModel.dateBirth = this.form.value.dateBirth;
      this.dataModel.civil_status = this.form.value.status;
      this.dataModel.religion = this.form.value.religion;
      this.dataModel.country = this.form.value.country;
     
      this.dataModel.city = this.form.value.city;
      this.dataModel.accepts_blood_transfusion = this.form.value.transfusion;
      this.dataModel.phone = this.form.value.phone;
      this.dataModel.email = this.form.value.email;
      this.dataModel.occupation = this.form.value.occupation;
      this.dataModel.address = this.form.value.address;
      this.dataModel.emergency_person = this.form.value.emergencyPerson;
      this.dataModel.last_pregnancy = this.form.value.lastpregnancy;
      this.dataModel.emergency_contact = this.form.value.emergencyPersonPhone;
      this.dataModel.previous_surgeries = this.form.value.previousSurgeries;
      this.dataModel.weight = this.form.value.kg;
      this.dataModel.heigth = this.form.value.meter;
      this.dataModel.imc = this.form.value.imc;
      this.dataModel.vaccinated_covid = this.form.value.vaccinated_covid;
      this.dataModel.had_covid = this.form.value.had_covid;
      // 
      this.dataModel.currently_pregnant = this.form.value.currently_pregnant;
      this.dataModel.previous_transfusion = this.form.value.previous_transfusion;

      this.dataModel.last_transfusion_date = this.form.value.last_transfusion_date;
      this.dataModel.blood_type = this.form.value.blood_type;
      this.dataModel.aspirin = this.form.value.aspirin;
      this.dataModel.sedatives = this.form.value.sedatives;
      this.dataModel.anti_hypertensive = this.form.value.anti_hypertensive;
      this.dataModel.anti_depressants = this.form.value.anti_depressants;
      this.dataModel.medicines_to_lose_weight = this.form.value.medicines_to_lose_weight;
      this.dataModel.anti_coagulants = this.form.value.anti_coagulants;
      this.dataModel.oxycodone = this.form.value.oxycodone;


      this.dataModel.natural_medicines = this.form.value.natural_medicines;
      this.dataModel.calcium = this.form.value.calcium;
      this.dataModel.vitamins_e_d_c = this.form.value.vitamins_e_d_c;
      this.dataModel.omega_3 = this.form.value.omega_3;
      this.dataModel.leucine = this.form.value.leucine;
      this.dataModel.sinusitis = this.form.value.sinusitis;
      this.dataModel.hypercholesterolemia = this.form.value.hypercholesterolemia;
      this.dataModel.tachycardia = this.form.value.tachycardia;
      this.dataModel.neurological = this.form.value.neurological;

      this.dataModel.circulatory = this.form.value.circulatory;
      this.dataModel.skin = this.form.value.skin;
      this.dataModel.gastrointestinal = this.form.value.gastrointestinal;
      this.dataModel.breast_pathologies = this.form.value.breast_pathologies;
      this.dataModel.hypothyroidism = this.form.value.hypothyroidism;
      this.dataModel.hereditary_history = this.form.value.hereditary_history;

      // Creamos el formulario y recibimos la respuesta del mismo

      /*
      this.formService
        .createBasicForm("esthetic", this.dataModel)
        .subscribe((resp) => {});*/

      // Damos acceso a los botones si todo el formulario esta lleno eliminando el disabled
      this.tagDisabled = "";
    }
  }

  // Se encarga de activar y desactivar la ventana de metros o pies
  offOn(sizeWindow: string) {
    if (sizeWindow === "meters") {
      this.meterFeet = true;
      this.converToMeters();
    } else {
      this.meterFeet = false;
      this.convertToFoot();
    }
  }
  // Validaciones graficas
  get nameNoValid() {
    return (
      this.form.get("first_name").invalid && this.form.get("first_name").touched
    );
  }
  get cityNoValid() {
    return this.form.get("city").invalid && this.form.get("city").touched;
  }
  get countryNoValid() {
    return this.form.get("country").invalid && this.form.get("country").touched;
  }
  get lastnameNoValid() {
    return (
      this.form.get("last_name").invalid && this.form.get("last_name").touched
    );
  }
  get identificationNoValid() {
    return (
      this.form.get("passport").invalid && this.form.get("passport").touched
    );
  }
  get dateBirth() {
    return this.form.get("dateBirth").invalid && this.form.get("dateBirth").touched;
  }
  get statusNoValid() {
    return this.form.get("status").invalid && this.form.get("status").touched;
  }
  get religionNoValid() {
    return (
      this.form.get("religion").invalid && this.form.get("religion").touched
    );
  }
  // get lastpregnancyNoValid() {
  //   return this.form.get('lastpregnancy').invalid && this.form.get('lastpregnancy').touched;
  // }
  get phoneNoValid() {
    return this.form.get("phone").invalid && this.form.get("phone").touched;
  }
  get emailNoValid() {
    return this.form.get("email").invalid && this.form.get("email").touched;
  }

  get imcNoValid() {
    return this.form.get("imc").invalid && this.form.get("imc").touched;
  }

  get changeFlightNoValid() {
    return (
      this.form.get("changeFlight").invalid &&
      this.form.get("changeFlight").touched
    );
  }

  get notFalseInformationNoValid() {
    return (
      this.form.get("notFalseInformation").invalid &&
      this.form.get("notFalseInformation").touched
    );
  }

  get allowSavingInformationNoValid() {
    return (
      this.form.get("allowSavingInformation").invalid &&
      this.form.get("allowSavingInformation").touched
    );
  }

  // Inicializacion del formulario y sus validaciones
  createForm() {
    this.form = this.fb.group({
      first_name: ["", [Validators.required]],
      last_name: ["", [Validators.required]],
      city: ["", [Validators.required]],
      country: ["", [Validators.required]],
      passport: ["", [Validators.required]],
      dateBirth: ["", [Validators.required]],
      status: ["", [Validators.required]],
      religion: ["", [Validators.required]],
      lastpregnancy: [""],
      phone: ["", [Validators.required]],
      email: ["", [Validators.required]],
      occupation: [""],
      address: [""],
      kg: [""],
      had_covid: [false],
      vaccinated_covid: [false],
      meter: [""],
      pounds: [""],
      transfusion: [false],
      foot1: [""],
      imc: ["", [Validators.required]],
      emergencyPerson: [""],
      emergencyPersonPhone: [""],
      previousSurgeries: [""],
      notFalseInformation: [false, [Validators.required]],
      allowSavingInformation: [false, [Validators.required]],
       
      // new values
      currently_pregnant: [false],
      previous_transfusion: [false],
      last_transfusion_date: [Date],
      blood_type: [""],
      aspirin: [false],
      sedatives: [false],
      anti_hypertensive: [false],
      anti_coagulants: [false],
      anti_depressants: [false],
      medicines_to_lose_weight: [false],
      oxycodone: [false],
      natural_medicines : [false],
      calcium: [false],
      vitamins_e_d_c: [false],
      omega_3: [false],
      leucine: [false],
      sinusitis: [false],
      hypercholesterolemia: [false],
      tachycardia :[""],
      neurological :[""],
      circulatory:[""],
      skin:[""],
      gastrointestinal:[""],
      breast_pathologies:[""],
      hypothyroidism:[""],
      hereditary_history:[""]
    });
  }

  // metodo para ocultar los campos que pertecen a un genero en especifico
  changeViewGender() {
    const gender = localStorage.getItem("gender");

    if (gender === "female" || gender === "other") {
      this.female = true;
    }
    if (gender === "male") {
      this.female = false;
    }
  }

  // Permite recibir cualquier cambio en el formulario
  listenerForm() {
    //  Permite determinar los cambios em el formulario
    this.form.valueChanges.subscribe((resp) => {
      // Calculo IMC metros
      if (resp["kg"] || resp["meter"]) {
        this.kg = resp["kg"];
        this.meters = resp["meter"];
        this.imcTotal = this.kg / (this.meters * this.meters);
      }

      // Calculo IMC pies
      if (resp["pounds"] || resp["foot1"] ) {
        this.pounds = resp["pounds"];
        this.foot1 = resp["foot1"];

        let unityFoot = String(this.foot1);

        let kg = this.pounds / 2.2046;
        let m = parseFloat(unityFoot) / 3.2808;
        var nume = (Number(kg / (m * m)).toFixed(3));
        this.imcTotal = (Number(nume))
      }
    });

    // Activamos los botones si el formulario basico esta completo
    // Si todos los valores han sido completados correctamente
    this.form.valueChanges.subscribe((resp) => {
      if (this.form.valid) {
        // Damos acceso a los botones si todo el formulario esta lleno eliminando el disabled
        this.tagDisabled = "";
      }
    });
  }

  convertToFoot() {
    let foot: number;
    let convertString: string;
    let splitNumber: string[];
    let libra: string;

    this.form.get("kg").valueChanges.subscribe((value) => {
      this.kg = value;
    });
    this.form.get("meter").valueChanges.subscribe((value) => {
      this.meters = value;
    });

    // convertir metros a pies
    foot = this.meters * 3.281;

    // redondear el resultado
    const round = foot.toFixed(1);
    convertString = round.toString();

 
    // convertir de kg a libra
    libra = (this.kg * 2.205).toFixed(2);
    this.form.patchValue({
      pounds: parseFloat(libra),
      foot1: convertString
    });
  }

  disable(input: string) {
    if (input.length > 0) {
      this.nameLength = false;
    } else {
      this.nameLength = true;
      this.noFound = false;
      this.doctorSearch = [];
    }
  }

  converToMeters() {
    let meters: number;
    let convertString: string;
    let kilog: number;

    this.form.get("pounds").valueChanges.subscribe((value) => {
      this.pounds = value;
    });
    this.form.get("foot1").valueChanges.subscribe((value) => {
      this.foot1 = value;
    });
   
    const unityFoot = parseFloat(String(this.foot1));
    // convertir  pies a metro
    meters = unityFoot / 3.281;

    // redondear el resultado
    const round = meters.toFixed(1);
    convertString = round.toString();

    // convertir de kg a libra
    kilog = this.pounds / 2.205;

    this.form.patchValue({
      kg: kilog.toFixed(2),
      meter: meters.toFixed(2),
    });
  }

  // evita enviar el formulario al hacer enter
  handleKeyupEnter(event) {
    if (event.code == "Enter") event.preventDefault();
  }

  // Enviamos los datos al cambiar de vista
  save() {
    // Permite validar despues de ser pulsado el boton de envia todos los campos y si no es asi disparar los mensajes
    if (this.form.invalid) {
      // Le pondremos a todos los campos que han sido tocados
      Object.values(this.form.controls).forEach((control) => {
        // Me devuelve todos los valores
        // Todos los valores han sido tocados
        control.markAllAsTouched();
      });
      // Este return evita que mande los valores del formulario si no son corrector los valores
      return;
    }
    const id = localStorage.getItem("idDoctor");
    if (this.form.valid && id === null) {
      if (this.lang === "es-ES" || this.lang === "es") {
        Swal.fire({
          icon: "success",
          title: "Felicitaciones",
          text: "Su formulario a sido enviado satisfactoriamente",
        });
      } else if (this.lang === "en-US" || this.lang === "en") {
        Swal.fire({
          icon: "success",
          title: "Congratulations",
          text: "Your form has been sent successfully",
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Congratulations",
          text: "Your form has been sent successfully",
        });
      }
    }
    if (id) {
      const dialogRef = this.dialog.open(ModalAppointmentComponent, {
        minWidth: "35%",
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((result) => {

        localStorage.removeItem("idDoctor");
      });
    }
  }

  // Configuracion botones de imagen para mostrar la imagen en el navegador y almacenarlas
  onselectFile(event) {
    // La informacion que necesitamos estara en la consola en :
    // Event > target > files > 0 : Que es lo que tendremos que extraer dentro del event devuelto
    // Dentro de 0 tendremos : name, size, type.
    // Almacenamos el archivo contenido en el event
    this.selectedFile = <File>event.target.files[0];
    // En la consola siguiente target file, encontraremos la url de la imagen nombre y demas
    if (event.target.files) {
      const reader = new FileReader();

      // Almacenamos la url de la imagen
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event: any) => {
        // Pasamos la imagen a la url para que pueda ser visible en el navegador
        switch (this.imgContainer) {
          case 1: {
            this.url1 = event.target.result;
            this.imageName = "esthetic";
            break;
          }
          case 2: {
            this.url2 = event.target.result;
            this.imageName = "profile";
            break;
          }
          case 3: {
            this.url3 = event.target.result;
            this.imageName = "esthetic2";
            break;
          }

          default: {
            this.url1 = "assets/img/drop-images.png";
            this.url2 = "assets/img/drop-images.png";
            this.url3 = "assets/img/drop-images.png";
            break;
          }
        }
        // Le pasamos el archivo al servicio encargado de subir la imagen y el nombre de la imagen
        this.imageService
          .uploadImagedb("esthetic", this.imageName, this.selectedFile)
          .subscribe((resp) => {});
      };
    }
  }

  // metodo para traer los datos del formulario si el usuario anteriormente ha completado el formulario
  getDataForm() {
    this.formService.getData().subscribe((resp: any) => {
      this.nameUser = resp.patient.first_name + " " + resp.patient.last_name;
      localStorage.setItem("name", this.nameUser);
      this.gender = resp.patientHistory.gender;
      localStorage.setItem("gender", this.gender);
      this.form.patchValue({
        first_name: resp.patient.first_name,
        last_name: resp.patient.last_name,
        passport: resp.patient.passport,
        had_covid: resp.patientHistory.had_covid,
        vaccinated_covid: resp.patientHistory.vaccinated_covid,
        dateBirth: resp.patient.birthday,
        status: resp.patient.civil_status,
        religion: resp.patient.religion,
        lastpregnancy: resp.patientHistory.last_pregnancy_date,
        phone: resp.patient.phone,
        email: resp.patient.email,
        occupation: resp.patient.occupation,
        address: resp.patient.address,
        kg: resp.patientHistory.weight,
        meter: resp.patientHistory.height,
        emergencyPerson: resp.patient.emergency_person,
        emergencyPersonPhone: resp.patient.emergency_contact,
        previousSurgeries: resp.patientHistory.previous_surgeries,
        notFalseInformation: false,
        allowSavingInformation: false,
        country: resp.patient.country,
        transfusion: resp.patientHistory.accepts_blood_transfusion,
        city: resp.patient.city,
        currently_pregnant: resp.patientHistory.currently_pregnant,
        previous_transfusion : resp.patientHistory.previous_transfusion,
        last_transfusion_date : resp.patientHistory.last_transfusion_date,
        blood_type : resp.patientHistory.blood_type,
        aspirin : resp.patientHistory.aspirin,
        sedatives : resp.patientHistory.sedatives,
        anti_hypertensive : resp.patientHistory.anti_hypertensive,
        anti_depressants : resp.patientHistory.anti_depressants,
        medicines_to_lose_weight : resp.patientHistory.medicines_to_lose_weight,
        natural_medicines : resp.patientHistory.natural_medicines,
        calcium : resp.patientHistory.calcium,
        vitamins_e_d_c : resp.patientHistory.vitamins_e_d_c,
        omega_3 : resp.patientHistory.omega_3,
        leucine : resp.patientHistory.leucine,
        sinusitis : resp.patientHistory.sinusitis,
        hypercholesterolemia : resp.patientHistory.hypercholesterolemia,
        tachycardia : resp.patientHistory.tachycardia,
        neurological : resp.patientHistory.neurological,
        circulatory : resp.patientHistory.circulatory,
        skin : resp.patientHistory.skin,
        gastrointestinal : resp.patientHistory.gastrointestinal,
        breast_pathologies : resp.patientHistory.breast_pathologies,
        hypothyroidism : resp.patientHistory.hypothyroidism,
        hereditary_history : resp.patientHistory.hereditary_history,
        anti_coagulants : resp.patientHistory.anti_coagulants,
        oxycodone : resp.patientHistory.oxycodone
      });
    });
  }

  // metodo para obtener las imagenes
  // metodo para obtener las imagenes
  getImages() {
    this.imageService.getImages().subscribe((resp: any) => {
      if (resp.patient_image) {
        if (resp.patient_image[7].url === false) {
          this.front = "Subir";
        }
        if (resp.patient_image[0].url === false) {
          this.back = "Subir";
        }
        if (resp.patient_image[8].url === false) {
          this.picture = "Subir";
        }
    
        this.url1 = resp.patient_image[7].url;
        this.url2 = resp.patient_image[0].url;
        this.url3 = resp.patient_image[8].url;
      }
    });
  }
  searchDoctor(text: string) {
    const firt_name = text.split(" ", 1);
    const lastName = text.split(" ", 3);
    let firstLastName = lastName[1];
    let lastname = lastName[2];
    let firstName = firt_name[0];

    this.parseFistLastName = String(firstLastName);
    this.parseLastName = String(lastname);
    this.firstN = String(firt_name);
    if (this.parseFistLastName === "undefined") {
      firstLastName = "";
    }
    if (this.parseLastName === "undefined") {
      lastname = "";
    }
    let fullName = firstLastName + " " + lastname;
    const body = {
      name: firstName,
      last_name: fullName.trim(),
    };
    this.formService.searchDoctor(body).subscribe((resp: any) => {
      this.doctorSearch = resp.Doctor;
      if (resp.Doctor.length === 0) {
        this.noFound = true;
      } else {
        this.noFound = false;
      }
    });
  }

  seeDoctor(id: number) {
    this.formService.confirmDoctor(id).subscribe((resp: any) => {
      this.doctorSearch = [];
      let name = document.getElementById("name") as HTMLInputElement;
      name.value = "";
      Swal.fire({
        title: "Confirmacion del doctor",
        html:
          '<div class="row">' +
          '<div class="col-md-6">' +
          '<img src="' +
          resp.Doctor.profile_picture +
          '" class="img-fluid" style="width:260; heigth:200" alt="doctor"' +
          ">" +
          "</div>" +
          '<div class="col-md-6 text-left">' +
          "<label><strong> Nombre:</strong> " +
          resp.Doctor.first_name +
          " " +
          resp.Doctor.last_name +
          "</label>" +
          "<label> Telefono: " +
          resp.Doctor.phone +
          "</label>" +
          "<label> Pais: " +
          resp.Doctor.country.name +
          "</label>" +
          "<label> Tipo de Procedimientos: " +
          resp.Doctor.procedureTypes[0].name +
          "</label>" +
          "</div>" +
          "</div>",
        showCancelButton: true,
        confirmButtonText: "Realizar cita con este doctor",
      }).then((result) => {
        if (result.value) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top",
            showCloseButton: true,
          });
          Toast.fire({
            icon: "success",
            title:
              resp.Doctor.first_name +
              " " +
              resp.Doctor.last_name +
              ", seleccionado correctamente",
            showConfirmButton: false,
          });
          const info = {
            name: resp.Doctor.first_name + " " + resp.Doctor.last_name,
          };
          localStorage.setItem("idDoctor", String(id));
          localStorage.setItem("doctorSelectedInfo", String(info.name));
        }
      });
    });
  }
}

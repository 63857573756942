<mat-dialog-content class="mat-typography">
  <p class="text-center mb-4">
    <i class="fa fa-check-circle text-success fa-4x"> </i>
  </p>

  <h1 mat-dialog-title class="text-center">
    {{ "MODALCANCEL.title-canceled" | translate }}
  </h1>
  <div class="row" *ngIf="showAmount">
    <div class="col-md-12">
      <h2 class="text-center"> 
          {{ "MODALCANCEL.showAmount" | translate }} {{ amount }}
     </h2>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{ "MODALCANCEL.close" | translate }}
  </button>
</mat-dialog-actions>

import { Component, OnInit } from "@angular/core";
import { FlagService } from "../../services/flag.service";
import { TranslateServices } from "../../services/translate.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  constructor(
    private position: FlagService,
    public translate: TranslateServices,
    private router: Router
  ) {
    this.translate.translateText();

    //Permite determinar si se esta logueado o no para activar los botones del navbar
    position.getNavbarButtonsActive("home");
  }

  ngOnInit(): void {}

  go() {
    this.router.navigateByUrl("/contact");
  }
}

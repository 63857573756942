import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { CountrysService } from '../../services/countrys.service';
import { FormGeneralService } from '../../services/form-general.service';
import { ok } from 'assert';
import { UploadImageService } from '../../services/upload-image.service';
import myLocaleEs from '@angular/common/locales/es';
import myLocaleFr from '@angular/common/locales/fr';
import {registerLocaleData} from '@angular/common/';
import { DynamicFormModel } from '../../classContainer/dynamic-form';
import { DirectFormService } from '../../services/direct-form.service';
import { FormService } from '../../services/form.service';

@Component({
  selector: 'app-form-general',
  templateUrl: './form-general.component.html',
  styleUrls: ['./form-general.component.css']
})
export class FormGeneralComponent implements OnInit {

  public codeDoctorForm: FormGroup;
  public searchCodeProgress: boolean = false;
  public form: FormGroup;
  public lastform: FormGroup;
  public doctorName: string;
  public code_doctor: number;
  public countryTotal:any[] = [];
  public languages:any[] = [];
  public procedureList:any[] = [];
  public language: string;
  public loadingContainer: boolean = false;
  public disabledSEndButton: boolean = false;
  public feetAndMetersContainer: boolean = true;
  public imc:number = 0;
  public firstSave: boolean = false;

  //public emailInfoContainer: boolean = true;
  //public emailInfoTextr: string = "";
  //public valid_email: boolean = false;
  //public emailChange: number = 0;

  //Acivar el progress envio de imagen
  public sendFormProgressContainer: boolean = false;

  public genderGroup:any[] = [];
  //Permite almacenar altura peso y IMC para enviarlo
  public heightWeightIMC: any = {
    height: null,
    weight: null,
    imc: null,
  };

  //Habrir dialogo envio final al doctor
  public dialogConfirmContainer: boolean = false;
  public dialogConfirmProgressContainer: boolean = false;
  public firtSaveLoadingContainer: boolean = false;


  //Todo lo necesario para la seleecion de imagenes
  //public disabledButton: boolean = true;
  public modeProgress:any;
  public nameProgressID: string = "nameProgress";
  public numberProgressID: number = 0;
  public selectedFile:any;
  public nameFile:any;
  public fileType:any;

  //public img_profile: string = "assets/img/profile.png";
  public imgProgress: boolean = false;


  public panelOpenState = false;

  public patientInfo:any = null;

  public imgGlobal: string = "assets/img/user2x.png";


  public first_name:any = null;
  public last_name:any = null;
  public identification:any = null;

  public email:any = null;
  public phone:any = null;
  public dateBirth:any = null;

  public gender:any = null;
  public languagess:any[] = [];

  public country:any = null;
  public city:any = null;
  public address:any = null;

  public civil_status:any = null;
  public religion:any = null;
  public occupation:any = null;

  public weight:any = null;
  public height:any = null;
  public pounds:any = null;
  public foot:any = null;

  public emergency_person:any = null;
  public emergency_contact:any = null;

  public had_covid:any = false;
  public vaccinated_covid:any = false;

  public obesity:any = false;
  public smoker:any = false;
  public alcohol:any = false;
  public valvulopathy:any = false;

  public asthma:any = false;
  public syncope:any = false;
  public oral_contraceptive:any = false;
  public hepatitis_b_or_c:any = false;

  public diabetes:any = false;
  public myocardial_infarction:any = false;
  public cardiovascular_accident:any = false;
  public thyroid_disease:any = false;


  public heart_failure:any = false;
  public high_blood_pressure_1:any = false;
  public high_blood_pressure_2:any = false;

  public allergies:any = false;
  public previous_surgeries:any = false;
  public other_diseases:any = false;
  public medicines_in_use:any = false;
  public hereditary_history:any = false;
  public extra_information:any = false;

  public revascularization:any = false;
  public revascularization_date:any = null;


  public finalDialogContainer: boolean = false;
  public form_ok:string;


  //DynamicFormModel
  public dynamicFormClass:any = new DynamicFormModel;
  public dynamicForm: FormGroup;
  public moduleList:any[] = [];
  public moduleListArray: any[] = [];
  public totalModule:any[] = [];

  public img_global:string = "assets/img/user2x.png";

  public doctorListWithForm:any[] = [];
  
  constructor(private fb: FormBuilder,
              private countryServices: CountrysService,
              private translate: TranslateService,
              private formGeneralService: FormGeneralService,
              private router: Router,
              public uploadImageService: UploadImageService,
              public directFormService: DirectFormService,
              public formService: FormService) 
  { 
    
    this.language = this.translate.getBrowserLang();
    registerLocaleData(myLocaleEs);
    //console.log( this.language );
  }




  ngOnInit(): void {

    //Tomamos los datos almecenado del doctor
    if( localStorage.getItem('doctorInfo')) {
      //Para pasar de string a array, proceso inverso
      let doctorInfo ​= JSON.parse(localStorage.getItem('doctorInfo'));

      //console.log(doctorInfo);
      this.doctorName = doctorInfo?.full_name;
      this.procedureList = doctorInfo?.procedureTypes;
      this.code_doctor = doctorInfo?.code_doctor;

    }

    this.countryTotal = this.countryServices.sendCoubntries();
    //console.log(this.countryTotal);
    this.createForm();
    this.listTotal();

  }

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  public searchListFound: any[] = [];
  public searchListFoundSee: boolean = false;
  //Search doctor
  public searchDoctor( codeOrName: string ) {

    console.log(codeOrName);

    if( codeOrName === '' ) {
      this.searchListFoundSee = false;
      return;
    }


    let search:string = codeOrName;
    let count: number = 0;
    let value1: string = "";
    let value2: string = "";
    let value3: string = "";


    for( let item of search ) {
      console.log(item);
      if( item === " " ) {
        console.log("Corte");
        count ++;
      }
      if( count === 0 ) {
        value1 += item;
      }
      if( count === 1 ) {
        value2 += item;
      }
      if( count === 2 ) {
        value3 += item;
      }
    }

    console.log(value1.trim());
    console.log(value2.trim());
    console.log(value3.trim());

    console.log(value1.length);

    this.searchCodeProgress = true;
    if( value1.length > 2 ) {
      console.log("Menor que 2");
    }

    const body = {
      name: value1.trim(),
      last_name: value2.trim(),
    };
    
    this.searchListFoundSee = true;
    this.formService.searchDoctor( body ).subscribe((resp: any) => {

      console.log(resp);
      this.searchCodeProgress = false;
      this.searchListFound = resp.Doctor;
      /*
      this.doctorSearch = resp.Doctor;
      if (resp.Doctor.length === 0) {
        this.noFound = true;
      } else {
        this.noFound = false;
      }*/
    });
  }


  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  public selectedDoctorHTML:string = "Formulario General";
  //Doctor seleccionado
  public selectedDoctor( item ) {
    
    console.log(item);
    this.selectedDoctorHTML = "Formulario General del Dr. " + item.first_name + " " + item.last_name;

    this.searchListFoundSee = false;

    //Lista de tratamientos que hace el doctor
    this.directFormService.getDynamicForm( item.code  ).subscribe( (resp:any) => {

      console.log(resp);
      this.moduleList = resp.moduleList;
      this.moduleListArray = resp.moduleListArray;

      this.fillDynamicForm( resp.moduleList );

    });
  }


  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  //Doctor seleccionado de la lista de formularios
  public selectedDoctorList( item ) {
    
    console.log(item);
    this.selectedDoctorHTML = "Formulario General del Dr. " + item.first_name + " " + item.last_name;

    this.searchListFoundSee = false;

    //Lista de tratamientos que hace el doctor
    this.directFormService.getDynamicForm( item.code ).subscribe( (resp:any) => {

      console.log(resp);
      this.moduleList = resp.moduleList;
      this.moduleListArray = resp.moduleListArray;

      this.fillDynamicForm( resp.moduleList );

    });
  }



  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  //Listas
  public listTotal() {

    this.formGeneralService.getLanguage().subscribe((resp:any) => {
      //console.log(resp);
      this.languages = resp.Languages;
    });

    if (this.language === 'es' || this.language === 'es-ES' ) {

      this.genderGroup = [
        {
          id: "female",
          name: "Femenino"
        },
        {
          id: "male",
          name: "Masculino"
        }
      ];

    } 
    else if (this.language === 'en' || this.language === 'en-US') {

      this.genderGroup = [
        {
          id: "female",
          name: "Female"
        },
        {
          id: "male",
          name: "Male"
        }
      ];

    }






    this.countryTotal = this.countryServices.sendCoubntries();

    let patient_id: any;
    patient_id = localStorage.getItem("id");

    this.formGeneralService.getImages( patient_id ).subscribe((resp:any) => {

      console.log(resp);
      let array:any[] = [];
      array = resp.patient_image;
      this.uploadImageService.profile_picture = array.find(x => x.name == "profile_picture").url;

      //console.log(this.uploadImageService.profile_picture);
    });

    this.formGeneralService.getData( patient_id ).subscribe((resp:any) => {
      console.log(resp);
      //this.patientInfo = resp;
      //console.log(this.patientInfo);
      this.first_name = resp.patient?.first_name;
      this.last_name = resp.patient?.last_name;
      this.identification = resp.patient?.passport;

      this.email = resp.patient?.email;
      this.phone = resp.patient?.phone;
      this.dateBirth = resp.patient?.birthday;

      this.gender = resp.patientHistory?.gender;
      //this.languagess = resp.patient?.language;
      let arrayLanguage:any[] = [];
      arrayLanguage = resp.patient?.language;
      for( let item of arrayLanguage ) {
        this.languagess.push(item.id);
      }

      this.country = resp.patient?.country;
      this.city = resp.patient?.city;
      this.address = resp.patient?.address;

      this.civil_status = resp.patient?.civil_status;
      this.religion = resp.patient?.religion;
      this.occupation = resp.patient?.occupation;

      this.weight = resp.patientHistory?.weight;
      this.height = resp.patientHistory?.height;

      this.pounds = (resp.patientHistory?.weight * 2.2046).toFixed(2);
      this.foot = (resp.patientHistory?.height * 3.28).toFixed(1);
      this.imc = resp.patientHistory?.imc;
      //console.log(this.form.value);

      this.heightWeightIMC.weight = this.weight;
      this.heightWeightIMC.height = this.height;
      this.heightWeightIMC.imc = this.imc;

      //console.log(this.heightWeightIMC);

      this.emergency_person = resp.patient?.emergency_person;
      this.emergency_contact = resp.patient?.emergency_contact;

      this.had_covid = resp.patientHistory?.had_covid;
      this.vaccinated_covid = resp.patientHistory?.vaccinated_covid;

      this.obesity = resp.patientHistory?.obesity;
      this.smoker = resp.patientHistory?.smoker;
      this.alcohol = resp.patientHistory?.alcohol;
      this.valvulopathy = resp.patientHistory?.valvulopathy;

      this.asthma = resp.patientHistory?.asthma;
      this.syncope = resp.patientHistory?.syncope;
      this.oral_contraceptive = resp.patientHistory?.oral_contraceptive;
      this.hepatitis_b_or_c = resp.patientHistory?.hepatitis_b_or_c;

      this.diabetes = resp.patientHistory?.diabetes;
      this.myocardial_infarction = resp.patientHistory?.myocardial_infarction;
      this.cardiovascular_accident = resp.patientHistory?.cardiovascular_accident;
      this.thyroid_disease = resp.patientHistory?.thyroid_disease;

      this.heart_failure = resp.patientHistory?.heart_failure;
      this.high_blood_pressure_1 = resp.patientHistory?.high_blood_pressure_1;
      this.high_blood_pressure_2 = resp.patientHistory?.high_blood_pressure_2;

      this.allergies = resp.patientHistory?.allergies;
      this.previous_surgeries = resp.patientHistory?.previous_surgeries;
      this.other_diseases = resp.patientHistory?.other_diseases;
      this.medicines_in_use = resp.patientHistory?.medicines_in_use;
      this.hereditary_history = resp.patientHistory?.hereditary_history;
      this.extra_information = resp.patientHistory?.extra_information;

      this.revascularization = resp.patientHistory?.revascularization;
      this.revascularization_date = resp.patientHistory?.revascularization_date;

      //this.valid_email = true;

      this.createForm()
    });


    //Lista de tratamientos que hace el doctor
    /*
    this.directFormService.getDynamicForm( this.code_doctor  ).subscribe( (resp:any) => {

      console.log(resp);
      this.moduleList = resp.moduleList;
      this.moduleListArray = resp.moduleListArray;

      this.fillDynamicForm( resp.moduleList );

    });*/

    this.formService.getDoctorsLisDynamicForm().subscribe((resp:any) => {
      console.log(resp);
      this.doctorListWithForm = resp.list;
    })

  }

  


  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //Funcion encargada de rellenar los datos recibidos del formulario dinamico
  public fillDynamicForm( data ) {

    console.log("+++++++++++++++");
    console.log("+++++++++++++++");
    console.log(data);
    let array:any[] = [];
    array = data;
    //console.log(data.moduleList);
    for( let item1 of array ) {

      //console.log(item1);
      let arrayList:any[] = [];
      arrayList = item1.list_total;

      for( let item2 of arrayList ) {
        //console.log(item2);
        //console.log(item2.selector);
        //let selector = item2.selector;

        let value:any; 
        if( item2.type === "select" ) {
          value = parseInt(item2.value);
        }
        else {
          value = item2.value;
        }
        this.dynamicFormClass.formModule1[item2.selector] = value;
        //console.log(this.dynamicFormClass.formModule1['module3_t1']);
        //console.log(this.dynamicFormClass.formModule1.module3_t1 = "Holas");
        //let found = this.dynamicFormClass.formModule1.find(x => x.selector == item2.selector);
        //console.log(found);

      }

    }
    //this.dynamicFormClass.formModule1['module1_t1'] = 11;
    //console.log(this.dynamicFormClass.formModule1.module1_t1 );

    this.createForm();

  }

  
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  //Creacion del formulario
  public createForm() {


    this.codeDoctorForm = this.fb.group({
      codeOrName: [null]
    });

    this.codeDoctorForm.valueChanges.subscribe((resp:any) => {
      console.log(resp);
      this.searchDoctor( resp.codeOrName );
    });



    this.form = this.fb.group({
      first_name: [this.first_name, [Validators.required]],
      last_name: [this.last_name, [Validators.required]],
      identification: [this.identification, [Validators.required]],

      email: [this.email, [Validators.required]],
      phone: [this.phone, [Validators.required]],
      dateBirth: [this.dateBirth, [Validators.required]],

      gender: [this.gender, [Validators.required]],
      languages: [this.languagess, [Validators.required]],


      country: [this.country, [Validators.required]],
      city: [this.city, [Validators.required]],
      address: [this.address, [Validators.required]],


      status: [this.civil_status],
      religion: [this.religion],
      occupation: [this.occupation],

      
      kg: [this.weight], 
      meter: [this.height],
      pounds: [this.pounds],
      foot: [this.foot],
      imc: [this.imc],
      

      emergencyPerson: [this.emergency_person],
      emergencyPersonPhone: [this.emergency_contact],

      reason: [null],

      had_covid: [this.had_covid],
      vaccinated_covid: [this.vaccinated_covid],


      obesity: [this.obesity],
      smoker: [this.smoker],
      alcohol: [this.alcohol],
      valvulopathy: [this.valvulopathy],


      bronchialAsthma: [this.asthma],
      syncope: [this.syncope],
      oral_contraceptive: [this.oral_contraceptive],
      hepatitis_b_or_c: [this.hepatitis_b_or_c],


      diabetes: [this.diabetes],
      myocardial_infarction: [this.myocardial_infarction],
      cardiovascular_accident: [this.cardiovascular_accident],
      thyroid_disease: [this.thyroid_disease],

      heart_failure: [this.heart_failure],
      high_blood_pressure_1: [this.high_blood_pressure_1],
      high_blood_pressure_2: [this.high_blood_pressure_2],


      allergies: [this.allergies],
      previous_surgeries: [this.previous_surgeries],
      diseases: [this.other_diseases],
      medicines: [this.medicines_in_use],
      hereditary_history: [this.hereditary_history],
      extraInformation: [this.extra_information],
      

      revascularization: [this.revascularization],
      revascularization_date: [this.revascularization_date],      
   
    });

    

    this.form.valueChanges.subscribe((resp:any) => {

      //console.log(resp);

      let email: string = "";
      email = resp.email;
      let cont: number = 0;
      let emailInfo = $('#emailInfo');
      
      
      //console.log(email);

      //console.log(email?.length);
      //console.log(this.emailChange);
      
      //Se encarga de determina si el formato del email es correcto y si no existe en NovusDoc
      /*
      if( email?.length > 4 && email?.length !== this.emailChange ) {

        for( let item of email ) {

          //console.log(item);
          if( item === '.' || item === '@' ) {
            cont ++;
          }

        }

        if( cont == 2 ) {

          //Esperamos 3 segundos antes de realizar la busqueda para no saturar la bbdd
          setTimeout(() => {
            //console.log("Buscar el email");
            this.formGeneralService.emailVerifier( email ).subscribe((resp:any) => {

              //console.log(resp);
              if( resp.exist ) {

                this.valid_email = false;
                this.emailInfoTextr = "Este Email ya esta siendo utilizado !!!";
                emailInfo.css('color', 'red');
              }
              else {

                this.valid_email = true;
                this.emailInfoTextr = "Email Valido";
                emailInfo.css('color', 'green');
              }
            });
          }, 4000);
          
        }

        this.emailChange = email?.length;

      }*/




      //this.heightWeightIMC
      //Calcula imc en pies
      if( this.feetAndMetersContainer ) {

        //console.log(resp.pounds);
        //console.log(resp.foot);
        if( resp.pounds !== null && resp.foot !== null ) {
          let pounds:number = 0;
          let foot:number = 0;
          pounds = resp.pounds / 2.2046;
          foot = resp.foot * 0.3048;
          //console.log(pounds);
          //console.log(foot);
          
          this.imc = (pounds / ( foot * foot ));

          this.heightWeightIMC.weight = pounds;
          this.heightWeightIMC.height = foot;
          this.heightWeightIMC.imc = this.imc;
          //console.log(this.heightWeightIMC);
        }
      }

      //Calcula imc en metros
      if( !this.feetAndMetersContainer ) {

        //console.log(resp.kg);
        //console.log(resp.meter);
        if( resp.kg !== null && resp.meter !== null ) {

          let kg:number = 0;
          let meter:number = 0;
          kg = resp.kg;
          meter = resp.meter;

          this.imc = (kg / ( meter * meter ));

          this.heightWeightIMC.height = meter;
          this.heightWeightIMC.weight = kg;
          this.heightWeightIMC.imc = this.imc;
          //console.log(this.heightWeightIMC);
        }

      }



      //Verifica si los datos basicos estas rellenos y guardar la informacion del paciente si es la primera vez que se completan los datos basicos
      if( !this.firstSave ) { 
        //console.log("##########################");
        //console.log("Entre primer Guardao 1");
        //console.log("##########################");
        
        if( this.form.value.first_name !== null && this.form.value.last_name !== null && this.form.value.identification !== null && this.form.value.email !== null &&
          this.form.value.dateBirth !== null && this.form.value.country !== null && this.form.value.city !== null && this.form.value.address !== null) {
  
            this.firstSave = true;
            //console.log("##########################");
            //console.log("Entre primer Guardao 2");
            //console.log("##########################");
          //No pasaremos este valor a true hasta que no sea guardado el paciente
          setTimeout(() => {

            //console.log("##########################");
            //console.log("Entre primer Guardao 3");
            //console.log("##########################");
            this.initialSaveForm();
          }, 10000);
    
        }

      }


    });


    this.lastform = this.fb.group({

      reason: [null, [Validators.required]],
      origin: [null, [Validators.required]],
      changeFlight: [false, [Validators.required]],
      notFalseInformation: [false, [Validators.required]],
      allowSavingInformation: [false, [Validators.required]],


    });


    this.lastform.valueChanges.subscribe((resp:any) => {
      //console.log(resp);
    });




    this.dynamicForm = this.fb.group(
      this.dynamicFormClass.formModule1
    );


    this.dynamicForm.valueChanges.subscribe((resp:any) => {
      //console.log(resp);
    });

  }



  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  //Seleccionar imagen y enviarla al servicio
  //Seleeciona las imagenes
  public onFileSelected( event:any, destination ) {

 

    let patient_id: any;
    patient_id = localStorage.getItem("id");



    this.nameProgressID = "nameProgress" + this.numberProgressID;

    //console.log(this.nameProgressID);
    this.modeProgress = "indeterminate";

    //console.log(event.target.value);
    ////console.log(this.listOfArchive);

    //Se encarga de detectar si se a pulsado el boton cancelar, para que no se rompa la app
    if ( event.target.value.length === 0) {
      //console.log("Suspect Cancel was hit, no files selected.");
      return;
    
    }
  
    //Desactivamos el boton
    //this.disabledButton = true;

    //En la consola siguiente target file, encontraremos la url de la imagen nombre y demas
    if(event.target.files){


      let reader = new FileReader();
      //Archivo seleecionado para el envio por el servicio
      this.selectedFile = <File>event.target.files[0];

      //Almacenamos la url de la imagen
      reader.readAsDataURL( event.target.files[0] );
      //console.log(event.target.files[0]);
      //console.log(event.target.files);
      //console.log(event.target.files[0].name);
      //console.log(event.target.files[0].type);
      this.fileType = event.target.files[0].type;
      this.nameFile = event.target.files[0].name;


      reader.onload = ( eventDoc:any ) => {

        //Muestra el string de datos de la imagen
        //console.log(eventDoc.target.result);
        let object = {
          name: this.nameFile,
          type: this.fileType,
          file: this.selectedFile
        }


        this.imgProgress = true;
        this.formGeneralService.sendImagest( object, "profile", patient_id  ).subscribe((resp:any) => {
          this.imgProgress = false;
          console.log(resp);
          if( !resp.OK ) {

            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Compruebe su conexion a Internet y vuelva a intentar en unos minutos !!!',
            })
          }
          else {
            //this.img_profile = eventDoc.target.result;
            this.uploadImageService.profile_picture = eventDoc.target.result;
          }
          //this.imgProgress6 = false;
          //this.disabledButton = false;
        });

      }

    }
  }



  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  //Funcion encargada de hacer el guardado inicial del formulario para optener el paciente_id
  public initialSaveForm() {
    //console.log("##########################");
    //console.log("Entre primer Guardao 4");
    //console.log("##########################");
    let patient_id: number;
    //Tomamos los datos almecenado del doctor
    if( localStorage.getItem('patientInfo')) {
      //Para pasar de string a array, proceso inverso
      let patientInfo ​= JSON.parse(localStorage.getItem('patientInfo'));

      //console.log(patientInfo); 
      patient_id = patientInfo.patient_id;

    }

    //console.log(patient_id);

    this.formGeneralService.postFormGeneral( this.form.value, this.heightWeightIMC, patient_id ).subscribe((resp:any) => {
      //console.log(resp);
      if( !resp.Ok ) {
        this.firstSave = false;
      }
      else {
        let patientInfo = {
          patient_id: resp?.patient_id,
          user_patient_id: resp?.user_patient_id,
        };
  
        //console.log(patientInfo);
        localStorage.setItem('patientInfo',JSON.stringify(patientInfo));
        this.firstSave = true;
        //this.disabledButton = false;
          
        //return patientInfo;
      }
        
    });

  }

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  //Envio del formulario
  public sendForm() {

    let incompleteForm: string;
    let answerForm: string;
    let emailInfo: string;
    let incomplete_form:string = "";
    //let form_ok:string = "";
    let form_error:string = "";

    //console.log(this.language );
    if (this.language === 'es' || this.language === 'es-ES' ) {
      incompleteForm = "Por favor rellene todos los campos con asterisco ( * ) !!!";
      answerForm = "Error al enviar el formulario, vuelva a probar en unos minutos !!!";
      emailInfo = "Introduzca un email valido !!!";
      incomplete_form = "Para poder continuar rellene acepte las condiciones de NovusDoc !!!";
      form_error = "Su formulario no pudo ser guardado, vuelva a probar en unos minutos";
      this.form_ok = "Su formulario a sido guardado correctamente, ahora puede solicitar citas a los especialistas médicos registrados en NovusDoc";
    } 
    else if (this.language === 'en' || this.language === 'en-US') {
      incompleteForm = "Please fill in all fields with an asterisk ( * ) !!!";
      emailInfo = "Error submitting the form, please try again in a few minutes !!!";
      emailInfo = "Enter a valid email !!!";
      incomplete_form = "To be able to continue fill in accept the conditions of NovusDoc.com !!!";
      form_error = "Your form could not be saved, please try again in a few minutes";
      this.form_ok = "Your form has been saved correctly, now you can request appointments to medical specialists registered in NovusDoc";
    }

    if( this.form.value.first_name == null || this.form.value.last_name === null || this.form.value.passport === null || this.form.value.phone === null || 
        this.form.value.email === null || this.form.value.country === null || this.form.value.city === null || this.form.value.address === null || 
        this.form.value.gender === null || this.form.value.languages === null || this.form.value.procedure_doctor === null  ) {
      
      
      Swal.fire({
        icon: 'info',
        title: 'Oops...',
        text: incomplete_form,
      })
      this.firstSave = true;
      return;
    }



    //console.log("Detectar Email Existente");
    //console.log(this.valid_email);
    /*
    if( !this.valid_email ) {

      Swal.fire({
        icon: 'info',
        title: 'Oops...',
        text: emailInfo,
      })

      return;
    }*/


    //yearDateofbirth = age;

    //console.log(age);
    ////console.log(yearDateofbirth);
    /*
    kg: [this.weight], 
      meter: [this.height],
      pounds: [this.pounds],
      foot: [this.foot],
      imc: [this.imc],*/
      
    

    //console.log(answerForm);
    this.loadingContainer = true;
    this.disabledSEndButton = true;


    //console.log(this.form.value);
    //console.log(this.heightWeightIMC);

    let patient_id: any;
    patient_id = localStorage.getItem("id");

    //console.log(patient_id);
    this.sendFormProgressContainer = true;
    //Envia el formulario y abre el dialog
    this.formGeneralService.postFormGeneral( this.form.value, this.heightWeightIMC, patient_id ).subscribe((resp:any) => {

      console.log(resp);
      this.sendFormProgressContainer = false;
      //this.dialogConfirmContainer = true;

      if( !resp.Ok ) {

        Swal.fire(form_error)
      }
      else {

        
        this.finalDialogContainer = true;
        //Borramos el pacientInfo

        //this.dialogConfirmContainer = true;
        
      }
      //this.dialogConfirmProgressContainer = false;



    });


  }


  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  //Nos permite ir al buscador de doctores
  public goToSearch() {

    this.router.navigate(['search']);
  }



  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  //Enviar formulario al doctor
  public sendFormDoctorFinal() {

    //console.log("Enviar al doctor Final ");

    //Actualizamos los datos del formulario
    let patient_id: any;
    patient_id = localStorage.getItem("id");

    let incomplete_form:string = "";
    let form_ok:string = "";
    let form_error:string = "";

    if (this.language === 'es' || this.language === 'es-ES' ) {
      incomplete_form = "Para poder continuar rellene los campos y acepte las condiciones de NovusDoc !!!";
      form_ok = "Su formulario fue creado correctamente y se le creo una cuenta en NovusDoc.com  \n " + "Usuario : " + this.form.value.email + "\n" + "Contraseña : " +  this.form.value.identification + "\n Vaya a su email y verifique su correo electronico y Cambie su contraseña despues de iniciar sesión";
      form_error = "Error al enviar el formulario, compruebe su conexión a internet !!!";
    } 
    else if (this.language === 'en' || this.language === 'en-US') {
      incomplete_form = "In order to continue, fill in the fields and accept the conditions of NovusDoc !!!";
      form_ok = "Your form was created successfully and a NovusDoc.com account was created for you \n " + "User : " + this.form.value.email  + "\n" + "Password : " + this.form.value.identification + "\n Go to your email and verify your email and Change your password after login";
      form_error = "Error submitting the form, please check your internet connection !!!";
    }

    //this.finishWait = true;
    //console.log(this.lastform.value);

    //Verificamos que todos los checkbox esten seleccionados
    if( this.lastform.value.origin === null || this.lastform.value.reason === false ) {


        Swal.fire(incomplete_form)
        ////console.log(object);
        return;
    }


    //console.log(this.form.value);
    //console.log(this.form.value.procedure_doctor);
    //console.log(this.form.value.procedure_doctor?.identifier);

    const data = {
      reason: this.lastform.value.reason,
      doctor_id: this.code_doctor,
      origin: this.lastform.value.origin,

    };

    //console.log(data);
    this.dialogConfirmProgressContainer = true;
    //this.dialogConfirmContainer = true;
    this.formGeneralService.sendRequest( data, patient_id  ).subscribe((resp:any) => {

      //console.log(resp);
      if( !resp.Ok ) {

        Swal.fire(form_error)
      }
      else {

        Swal.fire({
          icon: 'success',
          title: form_ok,
        })
        //Borramos el pacientInfo
        localStorage.removeItem("patientInfo");

        this.dialogConfirmContainer = false;
        this.router.navigate(['login']);
        
      }
      this.dialogConfirmProgressContainer = false;

    });
    
  }


  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  public infoFormContainer: boolean = false;
  public infoTextFormContainer: string = "";
  public infoTitleFormContainer: string = "";
  //dialog Informacion paciente
  public infoGeneralForm() {

    console.log("Info");
    if( this.infoFormContainer ) {
      this.infoFormContainer = false;
    }
    else {
      this.infoFormContainer = true;
    }


    if (this.language === 'es' || this.language === 'es-ES' ) {

      this.infoTextFormContainer = "Formulario general es un recopilatorio de la información básica solicitada por cada una de las especialidades médicas existentes en NovusDoc. Usted podrá rellenar la parte del formulario que sea de su interes ó rellenar por completo, lo que le permitirá enviar el formulario médico, a todos los expecialistas médicos que forman parte de NovusDoc ";
      this.infoTitleFormContainer = "Formulario";

    } 
    else if (this.language === 'en' || this.language === 'en-US') {

      this.infoTextFormContainer = "General form is a compilation of the basic information requested by each of the medical specialties existing in NovusDoc. You can fill out the part of the form that is of interest to you or fill it out completely, which will allow you to send the medical form to all the medical specialists that are part of NovusDoc";
      this.infoTitleFormContainer = "Form";

    }

  }

}

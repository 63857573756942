import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateServices } from "../../services/translate.service";
import { ContactService } from '../../services/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {


  //Variable para referencia con el formulario y tomar los datos contenido
  form: FormGroup;

  constructor(private fb: FormBuilder,
              public translate: TranslateServices,
              private contact: ContactService) { 
     
                this.translate.translateText();
    
            this.createForm();
   }

  ngOnInit(): void {
  }


  get nameNoValid(){
    return this.form.get('name').invalid && this.form.get('name').touched;
  }

  get lastnameNoValid() {
    return this.form.get('lastname').invalid && this.form.get('lastname').touched;
  }
  
  get emailNoValid() {
    return this.form.get('email').invalid && this.form.get('email').touched;
  }

  get optionNoValid() {
    return this.form.get('option').invalid && this.form.get('option').touched;
  }

  get textareaNoValid() {
    return this.form.get('textarea').invalid && this.form.get('textarea').touched;
  }

  //Creamos una funcion con todos los parametros y validaciones que deberan tener el formulario antes de que se muestre el html 
  //por lo que inicializaremos esta funcion en el constructor
  createForm() {
    
    //Le agregamos a la referencia del formulario el FormBuild para crear las validaciones y valores por defecto
    this.form = this.fb.group({
      name: ['', [Validators.required] ],
      lastname: ['', [Validators.required] ],
      email: ['', [Validators.required, Validators.email] ],
      option: ['', [Validators.required] ],
      textarea: ['', [Validators.required] ]

    });
  }


  sendForm(){

    this.contact.getContact(this.form.value);

  }

}

import { Component, OnInit } from "@angular/core";
import { TranslateServices } from "../../services/translate.service";
import { FormGroup, FormBuilder, Validators, NgForm } from "@angular/forms";
import { UserModel } from "../../models/user.model";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";
//Ventana de alerta
import Swal from "sweetalert2";
import { ChatSocketService } from "src/app/services/chat-socket.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  user: UserModel = new UserModel();
  lang: string;
  remember: boolean = false;

  constructor(
    private translate: TranslateServices,
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private _socketService: ChatSocketService
  ) {
    this.translate.translateText();

    this.createForm();
  }

  ngOnInit(): void {
    this.lang = navigator.language;
  }

  // Validaciones de los campos
  get emailNoValid() {
    return this.form.get("email").invalid && this.form.get("email").touched;
  }

  get passwordNoValid() {
    return (
      this.form.get("password").invalid && this.form.get("password").touched
    );
  }

  // Inicializamos el formulario
  createForm() {
    // Permite identificar si podemos o no guardar el email
    if (localStorage.getItem("email")) {
      this.user = new UserModel();
      this.user.email = localStorage.getItem("email");
      this.remember = true;
    }
    this.form = this.fb.group({
      email: [this.user.email, [Validators.required, Validators.email]],
      password: ["", [Validators.required]],
      remember: [this.remember],
    });
  }

  sendLogin() {
    // validamos si los campos son validos en caso contrario salimos
    if (this.form.invalid) {
      return;
    }
    this.user.email = this.form.value.email;
    this.user.password = this.form.value.password;
    this.remember = this.form.value.remember;

    if (this.lang === "es-ES" || this.lang === "es") {
      Swal.fire({
        icon: "info",
        allowOutsideClick: false,
        text: "Espere por favor ...",
      });
    } else if (this.lang === "en-US" || this.lang === "en") {
      Swal.fire({
        icon: "info",
        allowOutsideClick: false,
        text: "Please wait ...",
      });
    } else {
      Swal.fire({
        icon: "info",
        allowOutsideClick: false,
        text: "Please wait ...",
      });
    }

    // Simulación de loading
    Swal.showLoading();

    // Permiete loguearnos y devolver una respuesta con el token o el error
    this.auth.login(this.user).subscribe(
      (resp: any) => {
        // Almacenamos el Id del usuario
        localStorage.setItem("id", resp.id);
        localStorage.setItem("idUser", resp.idUser);
        const nameUser = resp.fullName;
        localStorage.setItem("name", nameUser);
        localStorage.setItem("isFirstTime", resp.isFirstTime);
        // cancelamos el alert después de recibir los datos

        Swal.close();
        if (resp.OK === false) {
          // Mostrando mensaje de error
          if (this.lang === "es-ES" || this.lang === "es") {
            Swal.fire({
              icon: "error",
              title: "Error al iniciar sesión",
              text: resp.Error,
            });
          } else if (this.lang === "en-US" || this.lang === "en") {
            Swal.fire({
              icon: "error",
              title: "Failed to login",
              text: resp.Error,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Failed to login",
              text: resp.Error,
            });
          }
        }
        // Nos permite guardar el email en caso de que el checkbox sea true
        if (this.remember) {
          localStorage.setItem("email", this.user.email);
        }
        if (resp.OK == true && resp.idToken.length > 0) {
          this.router.navigateByUrl("/");
        }
        // Nos movemos a la ventana start
      },
      (err) => {
        if (this.lang === "es-ES" || this.lang === "es") {
          Swal.fire({
            icon: "error",
            title: "Error al iniciar sesión",
            text: err.error.message,
          });
        } else if (this.lang === "en-US" || this.lang === "en") {
          Swal.fire({
            icon: "error",
            title: "Failed to authenticate",
            text: err.error.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed to authenticate",
            text: err.error.message,
          });
        }
      }
    );
  }

  create() {
    this.router.navigateByUrl("create-account");
  }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CountrysService } from '../../../services/countrys.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { DirectFormService } from '../../../services/direct-form.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { DynamicFormModel } from 'src/app/classContainer/dynamic-form';

@Component({
  selector: 'app-direct-form-general',
  templateUrl: './direct-form-general.component.html',
  styleUrls: ['./direct-form-general.component.css']
})
export class DirectFormGeneralComponent implements OnInit {

  public form: FormGroup;
  public lastform: FormGroup;

  public dynamicFormClass:any = new DynamicFormModel;
  public dynamicForm: FormGroup;
  public moduleList:any[] = [];
  public moduleListArray: any[] = [];
  public totalModule:any[] = [];

  public doctorName: string;
  public code_doctor: number;
  public countryTotal:any[] = [];
  public languages:any[] = [];
  public procedureList:any[] = [];
  public language: string;
  public loadingContainer: boolean = false;
  public disabledSEndButton: boolean = false;
  public feetAndMetersContainer: boolean = true;
  public imc:number = 0;
  public firstSave: boolean = false;

  public emailInfoContainer: boolean = true;
  public emailInfoTextr: string = "";
  public valid_email: boolean = false;
  public emailChange: number = 0;

  //Acivar el progress envio de imagen
  public sendFormProgressContainer: boolean = false;

  public genderGroup:any[] = [];
  public locationGroup:any[] = [];
  //Permite almacenar altura peso y IMC para enviarlo
  public heightWeightIMC: any = {
    height: null,
    weight: null,
    imc: null,
  };

  //Habrir dialogo envio final al doctor
  public dialogConfirmContainer: boolean = false;
  public dialogConfirmProgressContainer: boolean = false;
  public firtSaveLoadingContainer: boolean = false;


  //Todo lo necesario para la seleecion de imagenes
  public disabledButton: boolean = true;
  public modeProgress:any;
  public nameProgressID: string = "nameProgress";
  public numberProgressID: number = 0;
  public selectedFile:any;
  public nameFile:any;
  public fileType:any;

  public img1: string = "assets/img/imagen_esthetic.png";
  public img2: string = "assets/img/imagen_esthetic.png";
  public img3: string = "assets/img/left-profile.png";
  public img4: string = "assets/img/right-profile.png";
  public img5: string = "assets/img/document.png";
  public img6: string = "assets/img/profile.png";

  public imgProgress1: boolean = false;
  public imgProgress2: boolean = false;
  public imgProgress3: boolean = false;
  public imgProgress4: boolean = false;
  public imgProgress5: boolean = false;
  public imgProgress6: boolean = false;


  public panelOpenState = false;


  constructor(private fb: FormBuilder,
              private countryServices: CountrysService,
              private translate: TranslateService,
              private directFormService: DirectFormService,
              private router: Router) 
  { 
    
    this.language = this.translate.getBrowserLang();
    ////console.log( this.language );
  }




  ngOnInit(): void {

    //Tomamos los datos almecenado del doctor
    if( localStorage.getItem('doctorInfo')) {
      //Para pasar de string a array, proceso inverso
      let doctorInfo ​= JSON.parse(localStorage.getItem('doctorInfo'));

      //console.log(doctorInfo);
      this.doctorName = doctorInfo?.full_name;
      this.procedureList = doctorInfo?.procedureTypes;
      this.code_doctor = doctorInfo?.code_doctor;

    }

    this.countryTotal = this.countryServices.sendCoubntries();
    ////console.log(this.countryTotal);
    this.createForm();
    this.listTotal();

  }



  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  //Listas
  public listTotal() {

    this.directFormService.getLanguage().subscribe((resp:any) => {
      ////console.log(resp);
      this.languages = resp.Languages;
    });

    if (this.language === 'es' || this.language === 'es-ES' ) {

      this.genderGroup = [
        {
          id: "female",
          name: "Femenino"
        },
        {
          id: "male",
          name: "Masculino"
        }
      ];

      this.locationGroup = [
        {
          id: 1,
          name: "Estoy en la consulta médica"
        },
        {
          id: 2,
          name: "No estoy en la consulta médica"
        }
      ];

    } 
    else if (this.language === 'en' || this.language === 'en-US') {

      this.genderGroup = [
        {
          id: "female",
          name: "Female"
        },
        {
          id: "male",
          name: "Male"
        }
      ];

      this.locationGroup = [
        {
          id: 1,
          name: "I'm at the doctor's office"
        },
        {
          id: 2,
          name: "I'm not at the doctor's office"
        }
      ];

    }


    this.countryTotal = this.countryServices.sendCoubntries();

    //Lista de tratamientos que hace el doctor
    this.directFormService.getDynamicForm( this.code_doctor  ).subscribe( (resp:any) => {

      //console.log(resp);
      this.moduleList = resp.moduleList;
      this.moduleListArray = resp.moduleListArray;

    });

  }

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  //Creacion del formulario
  public createForm() {


    this.form = this.fb.group({
      first_name: [null, [Validators.required]],
      last_name: [null, [Validators.required]],
      identification: [null, [Validators.required]],

      email: [null, [Validators.required]],
      phone: [null, [Validators.required]],
      dateBirth: [null, [Validators.required]],

      gender: [null, [Validators.required]],
      languages: [null, [Validators.required]],
      procedure_doctor: [null, [Validators.required]],

      country: [null, [Validators.required]],
      city: [null, [Validators.required]],
      address: [null, [Validators.required]],


      status: [null],
      religion: [null],
      occupation: [null],

      
      kg: [null], 
      meter: [null],
      pounds: [null],
      foot: [null],
      imc: [null],
      

      emergencyPerson: [null],
      emergencyPersonPhone: [null],

      reason: [null],
      location: [null],




      terms: [false, [Validators.required]],

      
   
    });

    

    this.form.valueChanges.subscribe((resp:any) => {

      //console.log(resp);

      let email: string = "";
      email = resp.email;
      let cont: number = 0;
      let emailInfo = $('#emailInfo');
      
      
      ////console.log(email);

      ////console.log(email?.length);
      ////console.log(this.emailChange);
      
      //Se encarga de determina si el formato del email es correcto y si no existe en NovusDoc
      if( email?.length > 4 && email?.length !== this.emailChange ) {

        for( let item of email ) {

          ////console.log(item);
          if( item === '.' || item === '@' ) {
            cont ++;
          }

        }

        if( cont == 2 ) {

          //Esperamos 3 segundos antes de realizar la busqueda para no saturar la bbdd
          setTimeout(() => {
            ////console.log("Buscar el email");
            this.directFormService.emailVerifier( email ).subscribe((resp:any) => {

              ////console.log(resp);
              if( resp.exist ) {

                this.valid_email = false;
                this.emailInfoTextr = "Este Email ya esta siendo utilizado !!!";
                emailInfo.css('color', 'red');
              }
              else {

                this.valid_email = true;
                this.emailInfoTextr = "Email Valido";
                emailInfo.css('color', 'green');
              }
            });
          }, 4000);
          
        }

        this.emailChange = email?.length;

      }




      //this.heightWeightIMC
      //Calcula imc en pies
      if( this.feetAndMetersContainer ) {

        ////console.log(resp.pounds);
        ////console.log(resp.foot);
        if( resp.pounds !== null && resp.foot !== null ) {
          let pounds:number = 0;
          let foot:number = 0;
          pounds = resp.pounds / 2.2046;
          foot = resp.foot * 0.3048;
          ////console.log(pounds);
          ////console.log(foot);
          
          this.imc = (pounds / ( foot * foot ));

          this.heightWeightIMC.weight = pounds;
          this.heightWeightIMC.height = foot;
          this.heightWeightIMC.imc = this.imc;
          ////console.log(this.heightWeightIMC);
        }
      }

      //Calcula imc en metros
      if( !this.feetAndMetersContainer ) {

        ////console.log(resp.kg);
        ////console.log(resp.meter);
        if( resp.kg !== null && resp.meter !== null ) {

          let kg:number = 0;
          let meter:number = 0;
          kg = resp.kg;
          meter = resp.meter;

          this.imc = (kg / ( meter * meter ));

          this.heightWeightIMC.height = meter;
          this.heightWeightIMC.weight = kg;
          this.heightWeightIMC.imc = this.imc;
          ////console.log(this.heightWeightIMC);
        }

      }



      //Verifica si los datos basicos estas rellenos y guardar la informacion del paciente si es la primera vez que se completan los datos basicos
      if( !this.firstSave ) { 
        ////console.log("##########################");
        ////console.log("Entre primer Guardao 1");
        ////console.log("##########################");
        
        if( this.form.value.first_name !== null && this.form.value.last_name !== null && this.form.value.identification !== null && this.form.value.email !== null &&
          this.form.value.dateBirth !== null && this.form.value.country !== null && this.form.value.city !== null && this.form.value.address !== null) {
  
            this.firstSave = true;
            ////console.log("##########################");
            ////console.log("Entre primer Guardao 2");
            ////console.log("##########################");
          //No pasaremos este valor a true hasta que no sea guardado el paciente
          setTimeout(() => {

            ////console.log("##########################");
            ////console.log("Entre primer Guardao 3");
            ////console.log("##########################");
            this.initialSaveForm();
          }, 10000);
    
        }

      }


    });


    this.lastform = this.fb.group({

      reason: [null, [Validators.required]],
      origin: [null, [Validators.required]],
      changeFlight: [false, [Validators.required]],
      notFalseInformation: [false, [Validators.required]],
      allowSavingInformation: [false, [Validators.required]],


    });


    this.lastform.valueChanges.subscribe((resp:any) => {
      ////console.log(resp);
    });






    //console.log(this.dynamicFormClass);
    //console.log(this.dynamicFormClass.formModule1);

    this.dynamicForm = this.fb.group(
      this.dynamicFormClass.formModule1
    );


    this.dynamicForm.valueChanges.subscribe((resp:any) => {
      ////console.log(resp);
    });


  }

  

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  //Ir a terminos y condiciones
  public goTo() {

    ////console.log("Ir a terminos y condiciones");

    this.router.navigate(['terms-conditions']);
  }




  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  //Seleccionar imagen y enviarla al servicio
  //Seleeciona las imagenes
  public onFileSelected( event:any, destination ) {

 



    this.nameProgressID = "nameProgress" + this.numberProgressID;

    ////console.log(this.nameProgressID);
    this.modeProgress = "indeterminate";

    ////console.log(event.target.value);
    //////console.log(this.listOfArchive);

    //Se encarga de detectar si se a pulsado el boton cancelar, para que no se rompa la app
    if ( event.target.value.length === 0) {
      ////console.log("Suspect Cancel was hit, no files selected.");
      return;
    
    }
  
    //Desactivamos el boton
    this.disabledButton = true;

    //En la consola siguiente target file, encontraremos la url de la imagen nombre y demas
    if(event.target.files){


      let reader = new FileReader();
      //Archivo seleecionado para el envio por el servicio
      this.selectedFile = <File>event.target.files[0];

      //Almacenamos la url de la imagen
      reader.readAsDataURL( event.target.files[0] );
      ////console.log(event.target.files[0]);
      ////console.log(event.target.files);
      ////console.log(event.target.files[0].name);
      ////console.log(event.target.files[0].type);
      this.fileType = event.target.files[0].type;
      this.nameFile = event.target.files[0].name;


      reader.onload = ( eventDoc:any ) => {

        //Muestra el string de datos de la imagen
        ////console.log(eventDoc.target.result);
        let object = {
          name: this.nameFile,
          type: this.fileType,
          file: this.selectedFile
        }


        this.urlImage = eventDoc.target.result;
        this.img1 = eventDoc.target.result;
        this.img2 = eventDoc.target.result;
        this.img6 = eventDoc.target.result;


      }

    }

    
  }

  public urlImage: any;



  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  //Funcion encargada de hacer el guardado inicial del formulario para optener el paciente_id
  public initialSaveForm() {

    ////console.log(this.form.value);
    //this.wait(0); 
    

    ////console.log("##########################");
    ////console.log("Entre primer Guardao 4");
    ////console.log("##########################");
    let patient_id: number;
    //Tomamos los datos almecenado del doctor
    if( localStorage.getItem('patientInfo')) {
      //Para pasar de string a array, proceso inverso
      let patientInfo ​= JSON.parse(localStorage.getItem('patientInfo'));

      ////console.log(patientInfo); 
      patient_id = patientInfo.patient_id;

    }

    ////console.log(patient_id);

    /*
    this.directFormService.postFormGeneral( this.form.value, this.heightWeightIMC, patient_id ).subscribe((resp:any) => {
      ////console.log(resp);
      if( !resp.Ok ) {
        this.firstSave = false;
      }
      else {
        let patientInfo = {
          patient_id: resp?.patient_id,
          user_patient_id: resp?.user_patient_id,
        };
  
        ////console.log(patientInfo);
        localStorage.setItem('patientInfo',JSON.stringify(patientInfo));
        this.firstSave = true;
        this.disabledButton = false;
          
        //return patientInfo;
      }
        
    });*/

  }





  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  //Envio del formulario
  public sendForm() {

    let incompleteForm: string;
    let answerForm: string;
    let emailInfo: string;
    let incomplete_form:string = "";
    let form_ok:string = "";
    let form_error:string = "";

    ////console.log(this.language );
    if (this.language === 'es' || this.language === 'es-ES' ) {
      incompleteForm = "Por favor rellene todos los campos con asterisco ( * ) !!!";
      answerForm = "Error al enviar el formulario, vuelva a probar en unos minutos !!!";
      emailInfo = "Introduzca un email valido !!!";
      incomplete_form = "Para poder continuar rellene acepte las condiciones de NovusDoc !!!";
      form_ok = "Formulario enviado correctamente !!!";
      form_error = "Error al guardar el formulario, vuelva a probar en unos minutos !!!";
    } 
    else if (this.language === 'en' || this.language === 'en-US') {
      incompleteForm = "Please fill in all fields with an asterisk ( * ) !!!";
      answerForm = "Error submitting the form, please try again in a few minutes !!!";
      emailInfo = "Enter a valid email !!!";
      incomplete_form = "To be able to continue fill in accept the conditions of NovusDoc.com !!!";
      form_ok = "Form sent successfully !!! !!!";
      form_error = "Error saving the form, please try again in a few minutes!!!";
    }

    //console.log(this.form.value);
    //console.log(this.dynamicForm.value);

    if( this.form.value.first_name === null || this.form.value.last_name === null || this.form.value.passport === null || this.form.value.phone === null || 
        this.form.value.email === null || this.form.value.country === null || this.form.value.city === null || this.form.value.address === null || 
        this.form.value.gender === null || this.form.value.languages === null || this.form.value.reason === null || this.form.value.location === null || 
        this.form.value.terms === null ) {
      
      
      Swal.fire({
        icon: 'info',
        title: 'Oops...',
        text: incomplete_form,
      })
      this.firstSave = true;
      return;
    }


    let email:any[] = [];
    email = this.form.value.email;
    let cont:number = 0;

    for( let item of email ) {

      ////console.log(item);
      if( item === '.' || item === '@' ) {
        cont ++;
      }

    }

    //Determina si se a introducido un email
    if( cont < 2 ) {

      Swal.fire({
        icon: 'info',
        title: 'Oops...',
        text: emailInfo,
      })
      return;
    }




    ////console.log(answerForm);
    this.loadingContainer = true;
    this.disabledSEndButton = true;


    ////console.log(this.form.value);
    ////console.log(this.heightWeightIMC);

    let patient_id: number;
    //Tomamos los datos almecenado del doctor
    if( localStorage.getItem('patientInfo')) {
      //Para pasar de string a array, proceso inverso
      let patientInfo ​= JSON.parse(localStorage.getItem('patientInfo'));

      ////console.log(patientInfo); 
      patient_id = patientInfo.patient_id;

    }

    //console.log(this.form.value);
    ////console.log(patient_id);
    this.sendFormProgressContainer = true;
    //Envia el formulario y abre el dialog
    this.directFormService.postFormGeneral( this.form.value,this.dynamicForm.value, this.moduleList, this.urlImage, this.heightWeightIMC, patient_id, this.code_doctor ).subscribe((resp:any) => {

      //console.log(resp);
      this.sendFormProgressContainer = false;
      //this.dialogConfirmContainer = true;

      if( !resp.Ok ) {

        Swal.fire(form_error)
      }
      else {

        
        Swal.fire({
          icon: 'success',
          title: form_ok,
        })
        //Borramos el pacientInfo

        this.directFormService.sendNotificationDoctor( this.code_doctor, this.form.value.location );
        //this.dialogConfirmContainer = true;
        //this.router.navigate(['home']);

        
      }
      this.dialogConfirmProgressContainer = false;



    });


  }



  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  //Enviar formulario al doctor
  public sendFormDoctorFinal() {

    ////console.log("Enviar al doctor Final ");

    //Actualizamos los datos del formulario
    let patient_id: number;
    //Tomamos los datos almecenado del doctor
    if( localStorage.getItem('patientInfo')) {
      //Para pasar de string a array, proceso inverso
      let patientInfo ​= JSON.parse(localStorage.getItem('patientInfo'));

      ////console.log(patientInfo); 
      patient_id = patientInfo.patient_id;

    }

    let incomplete_form:string = "";
    let form_ok:string = "";
    let form_error:string = "";

    //console.log(this.form.value);

    if (this.language === 'es' || this.language === 'es-ES' ) {
      incomplete_form = "Para poder continuar rellene los campos y acepte las condiciones de NovusDoc !!!";
      form_ok = "Su formulario fue creado correctamente y se le creo una cuenta en NovusDoc.com  \n " + "Usuario : " + this.form.value.email + "\n" + "Contraseña : " +  this.form.value.identification + "\n Vaya a su email y verifique su correo electronico y Cambie su contraseña despues de iniciar sesión";
      form_error = "Error al enviar el formulario, compruebe su conexión a internet !!!";
    } 
    else if (this.language === 'en' || this.language === 'en-US') {
      incomplete_form = "In order to continue, fill in the fields and accept the conditions of NovusDoc !!!";
      form_ok = "Your form was created successfully and a NovusDoc.com account was created for you \n " + "User : " + this.form.value.email  + "\n" + "Password : " + this.form.value.identification + "\n Go to your email and verify your email and Change your password after login";
      form_error = "Error submitting the form, please check your internet connection !!!";
    }

    //this.finishWait = true;
    ////console.log(this.lastform.value);

    //Verificamos que todos los checkbox esten seleccionados
    if( this.lastform.value.origin === null || this.lastform.value.reason === false ) {


        Swal.fire(incomplete_form)
        //////console.log(object);
        return;
    }


    ////console.log(this.form.value);
    ////console.log(this.form.value.procedure_doctor);
    ////console.log(this.form.value.procedure_doctor?.identifier);

    const data = {
      reason: this.lastform.value.reason,
      doctor_id: this.code_doctor,
      origin: this.lastform.value.origin,

    };

    //console.log(this.form.value);

    ////console.log(data);
    this.dialogConfirmProgressContainer = true;
    //this.dialogConfirmContainer = true;
    this.directFormService.sendRequest( data, patient_id  ).subscribe((resp:any) => {

      ////console.log(resp);
      if( !resp.Ok ) {

        Swal.fire(form_error)
      }
      else {

        Swal.fire({
          icon: 'success',
          title: form_ok,
        })
        //Borramos el pacientInfo
        localStorage.removeItem("patientInfo");

        this.dialogConfirmContainer = false;
        this.router.navigate(['login']);
        
      }
      this.dialogConfirmProgressContainer = false;

    });
    
  }


  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //Determina el espacio que ocupara el elemento en funcion de si es imput, select o textarea
  public moduleOptionAttribute( type ) {

    ////console.log(type);
    let width: string;
    if( type === 'input' || type === 'checkbox' || type === 'select') {
      width = 'width: 25%;';
    }

    if( type === 'textarea' ) {
      width = 'width: 100%;';
    }
    //width: 100%;
    return width;
  }




  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  public mainContainer1: boolean = false;
  public mainContainer2: boolean = false;
  public startDialogContainer: boolean = true;
  public startDialogContainerProgress: boolean = false;
  public reasonContainerProgress: boolean = false;
  //Verifica si el paciente tiene cuenta o a sido registrado mediante email o documento de identidad
  public existingOrNotPatients() {

    //console.log("Buscar");

    let message1: string;
    let message2: string;
    let message3: string;


    if (this.language === 'es' || this.language === 'es-ES' ) {
      message1 = "Error al buscar su email, vuelva a pruebar en unos minutos !!!!";
      message2 = "No pudimos encontrar un formulario rellenado para el Dr. " + this.doctorName + ", por favor rellene el formulario médico a continuación";
      message3 = "Por favor agregue un Email ó su documento de identidad !!!";


    } 
    else if (this.language === 'en' || this.language === 'en-US') {
      message1 = "Error searching your email, try again in a few minutes !!!!";
      message2 = "We were unable to find a completed form for Dr. " + this.doctorName + ", please fill out the medical form below";
      message3 = "Please add an Email or your identity document !!! ";

    }

    if( this.form.value.email === null ) {
      Swal.fire({
        icon: 'info',
        title: 'Oops...',
        text: message3,

      })
      return;
    }

    
    //Si no es encontrado el paciente activamos el formulario
    this.startDialogContainerProgress = true;

    this.directFormService.getPatientInfo( this.form.value.email, this.code_doctor ).subscribe((resp:any) => {

      //console.log(resp);
      

      if( !resp.OK ) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: message1,
  
        })
      }
      else {


        if( !resp.exist ) {

          
          Swal.fire({
            icon: 'info',
            title: 'Oops...',
            text: message2,
    
          })
          this.mainContainer1 = true;
          this.mainContainer2 = true;
        }
        else {
          this.reasonContainer = true;
          this.patient_id = resp.patient_id;
        }

        this.form.reset();
      }

      this.startDialogContainerProgress = false;
      this.startDialogContainer = false;

    });

  }


  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //Cerrar dialog
  public closedStartDialog() {

    this.startDialogContainer = false;
    this.reasonContainer = false;
    this.mainContainer1 = true;
    this.mainContainer2 = true;

  }


  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  public reasonContainer: boolean = false;
  public patient_id:any;
  public sendQueryReason() {

    //console.log("Buscar");

    //console.log(this.code_doctor);
    //console.log(this.patient_id);
    //console.log(this.form.value.reason);

    let error_text: string;
    let message: string;

    if (this.language === 'es' || this.language === 'es-ES' ) {
      error_text = "Error al enviar al guardar su consulta, vuelva a provar en unos minutos !!!";
      message = "Su consulta a sido guardado correctamente !!!";
    } 
    else if (this.language === 'en' || this.language === 'en-US') {
      error_text = "Error sending when saving your query, try again in a few minutes !!!";
      message = "Your query has been saved successfully !!!";
    }


    this.reasonContainerProgress = true;

    this.directFormService.postReasonQuery( this.form.value.reason, this.form.value.location,  this.patient_id, this.code_doctor ).subscribe((resp:any) => {
      this.reasonContainerProgress = false;
      //console.log(resp);

      if( !resp.Ok ) {

        Swal.fire({
          icon: 'info',
          title: 'Oops...',
          text: error_text,
  
        })
      }
      else {

        Swal.fire({
          icon: 'success',
          title: message,
          showConfirmButton: false,
          timer: 2500
  
        })

        this.directFormService.sendNotificationDoctor2( this.code_doctor, this.form.value.location, this.patient_id );

        this.router.navigate(['home']);
      }


      
    });

  }






}

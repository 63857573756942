import { Component, OnInit } from "@angular/core";
import { element } from "protractor";
import { PaymentsService } from "src/app/services/payments.service";
import { TranslateServices } from "../../../services/translate.service";

@Component({
  selector: "app-payment-layout",
  templateUrl: "./payment-layout.component.html",
  styleUrls: ["./payment-layout.component.css"],
})
export class PaymentLayoutComponent implements OnInit {
  public historyList: any[];
  public requestList: any[];
  public getAll = false;
  from: Date;
  to: Date;

  constructor(
    private _paymentService: PaymentsService,
    public translate: TranslateServices
  ) {
    this.translate.translateText();
  }

  ngOnInit(): void {
    this.getHistoryPayment();
    this.getRequestPayment();
  }

  getRequestPayment() {
    this._paymentService.getRequestPayment().subscribe(
      (Response: any) => {
        this.requestList = Response.reservationRequest;
      },
      (error) => {
      }
    );
  }

  getHistoryPayment() {
    this._paymentService.getHistory().subscribe(
      (Response: any) => {
        this.historyList = Response.reservationPayment;
        let from = document.getElementById("from") as HTMLInputElement;
        let to = document.getElementById("to") as HTMLInputElement;
        this.getAll = false;
        from.value = "";
        to.value = "";
      },
      (error) => {
      }
    );
  }

  filterHistory(from: Date, to: Date) {
    this._paymentService.filterHistory(from, to).subscribe(
      (Response: any) => {
        this.historyList = Response.reservationPayment;
        this.getAll = true;
      },
      (error) => {
      }
    );
  }
}

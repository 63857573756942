import { Injectable, OnInit } from '@angular/core';
import { GalleryModel } from '../models/gallery.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GalleryService  {

  private url = environment.ApiUrl;

  constructor( private http: HttpClient ) { }

  // Obtencion de los datos
  getGallery( id: string ) {
    return this.http.get( `${ this.url }/get-gallery/` + id )
                     .pipe( map (  (resp: any) => {
                      return resp.gallery;
                     }));
  }
 getAllGallery() {
   return this.http.get(`${ this.url }/get-gallery`)
                    .pipe( map ( (resp: any ) => {
                        return resp;
              }));
 }
 getTypeGallery( identify: string) {
   const body = {
     identifier: identify
   }
   return this.http.get(`${ this.url }/get-gallery-type/` + identify)
                   .pipe( map ( resp => {

                    return resp;
                   }));
 }
  // Obtencion de un solo objeto de la galeria
  getGalleryOne( id: string ) {
    // console.log(id);
    // console.log(this.gallery[id]);
    // return this.gallery[id];
  }
  searchGallery( search: any ){
    const namelower = search.name.toString();
    const idCountry  = search.countries;
    const body = {
      name: namelower.toLocaleLowerCase(),
      countries: idCountry,
      last_name: search.last_name
    };
 
    return this.http.post(`${ this.url }/get-gallery`, body )
                    .pipe( map ( resp => {
                      return resp;
                    }));
  }
}
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { CreateAccountComponent } from './pages/create-account/create-account.component';
import { LoginComponent } from './pages/login/login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { AuthGuard } from './guards/auth.guard';
import { StartComponent } from './pages/start/start.component';
import { FormsComponent } from './pages/forms/forms.component';
import { FormSurgeryComponent } from './pages/form-surgery/form-surgery.component';
import { FormEstheticComponent } from './pages/form-esthetic/form-esthetic.component';
import { ChatComponent } from './pages/chat/chat.component';
import { GalleryComponent } from './pages/gallery/gallery.component';
import { SearchComponent } from './pages/search/search.component';
import { AppointmentComponent } from './pages/appointment/appointment.component';
import { NewsComponent } from './pages/news/news.component';
import { GalleryNextComponent } from './pages/gallery-next/gallery-next.component';
import { FavoriteComponent } from './pages/favorite/favorite.component';
import { RequestListComponent } from './pages/request-list/request-list.component';
import { ClientComponent } from './pages/client/client.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { DefaultContainerComponent } from './layouts/default-container/default-container.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { PaymentDetailsComponent } from './pages/payment/payment-details/payment-details.component';
import { PaymentLayoutComponent } from './pages/payment/payment-layout/payment-layout.component';
import { PaymentDetailsHistoryComponent } from './pages/payment/payment-details-history/payment-details-history.component';
import { SuccespaymentComponent } from './pages/payment/succespayment/succespayment.component';
import { QRFormComponent } from './pages/qr-form/qr-form.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { FormOnlineGuard } from './guards/form-online.guard';
import { SettingsComponent } from './pages/settings/settings.component';
import { SeeNewsComponent } from './pages/news/see-news/see-news.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { DirectFormComponent } from './pages/direct-form/direct-form.component';
import { DirectFormAestheticComponent } from './pages/direct-form/direct-form-aesthetic/direct-form-aesthetic.component';
import { DirectFormSurgeryComponent } from './pages/direct-form/direct-form-surgery/direct-form-surgery.component';
import { DirectFormGeneralComponent } from './pages/direct-form/direct-form-general/direct-form-general.component';
import { FormGeneralComponent } from './pages/form-general/form-general.component';
import { FormSurgery2Component } from './pages/form-surgery2/form-surgery2.component';
import { FormGeneral2Component } from './pages/form-general2/form-general2.component';
import { FormEsthetic2Component } from './pages/form-esthetic2/form-esthetic2.component';


const routes: Routes = [
  
  { path:'home', component: HomeComponent },
  { path:'about', component: AboutComponent },
  { path:'change-password/:email', component: ChangePasswordComponent },
  { path:'contact', component: ContactComponent },
  { path:'form-direct/:id', component: FormSurgeryComponent, canActivate: [FormOnlineGuard] },
  { path:'create-account', component: CreateAccountComponent },
  { path:'login', component: LoginComponent },
  { path:'forgot-password', component: ForgotPasswordComponent },
  { path: 'code-qr-form/:id', component: QRFormComponent},
  { path:'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'terms-conditions',component: TermsConditionsComponent },
  { path:'success-payment/:id', component: SuccespaymentComponent, canActivate:[AuthGuard] },
  { path:'direct-forms/:id', component: DirectFormComponent },
  { path:'direct-forms-surgery', component: DirectFormSurgeryComponent },
  { path:'direct-forms-aesthetic', component: DirectFormAestheticComponent },
  { path:'direct-forms-general', component: DirectFormGeneralComponent },

  // { path:'**', pathMatch: 'full', redirectTo: 'home' },
  //canActivate:[AuthGuard] : Evita que podamos navegar a todas las rutas que lo tengan implementado
  { path: '',
   component: DefaultContainerComponent,
   canActivate: [AuthGuard],
   children: [
    { path:'start', component: StartComponent, canActivate:[AuthGuard] },
    { path:'settings', component: SettingsComponent, canActivate:[AuthGuard] },
    { path:'forgot-password-private', component: ForgotPasswordComponent, canActivate: [AuthGuard] },
    { path:'forms', component: FormsComponent, canActivate:[AuthGuard] },
    { path:'form-surgery', component: FormSurgeryComponent, canActivate:[AuthGuard] },
    { path:'form-general', component: FormGeneralComponent, canActivate:[AuthGuard] },
    { path:'form-esthetic', component: FormEstheticComponent, canActivate:[AuthGuard] },
    { path:'form-surgery2', component: FormSurgery2Component, canActivate:[AuthGuard] },
    { path:'form-general2', component: FormGeneral2Component, canActivate:[AuthGuard] },
    { path:'form-esthetic2', component: FormEsthetic2Component, canActivate:[AuthGuard] },
    { path:'chat', component: ChatComponent, canActivate:[AuthGuard] },
    { path:'gallery', component: GalleryComponent, canActivate:[AuthGuard] },
    { path:'gallery-next/:id', component: GalleryNextComponent, canActivate:[AuthGuard] },
    { path:'search', component: SearchComponent, canActivate:[AuthGuard] },
    { path:'payment', component: PaymentComponent, canActivate:[AuthGuard],
        children: [ 
          { path:'', component: PaymentLayoutComponent, canActivate:[AuthGuard] }, 
          { path:'details/:id', component: PaymentDetailsComponent, canActivate:[AuthGuard] }, 
          { path:'details-history/:id', component: PaymentDetailsHistoryComponent, canActivate:[AuthGuard] }, 
          // { path:'success-payment/:id', component: SuccespaymentComponent, canActivate:[AuthGuard] }
          // { path:'', pathMatch: 'full', redirectTo: 'payment', canActivate:[AuthGuard] }
        ] 
     },
    { path:'appointment', component: AppointmentComponent, canActivate:[AuthGuard] },
    { path:'request-list', component: RequestListComponent, canActivate:[AuthGuard] },
    { path:'news', component: NewsComponent, canActivate:[AuthGuard] },
    { path:'favorite', component: FavoriteComponent, canActivate:[AuthGuard] },
    { path:'client/:id', component: ClientComponent, canActivate:[AuthGuard] },
    { path:'publication/:id', component: SeeNewsComponent, canActivate:[AuthGuard] },
    { path:'contact/log', component: ContactComponent, canActivate:[AuthGuard] },
    { path:'**', pathMatch: 'full', redirectTo: 'start', canActivate:[AuthGuard] }
  ]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

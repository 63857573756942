export class FormModel {
  id: number;
  first_name: string;
  last_name: string;
  dateBirth?: Date;
  city: string;
  origin: string;
  passport: string;
  country: string;
  age: number;
  civil_status: string;
  religion: string;
  phone: string;
  email: string;
  occupation: string;
  address: string;
  emergency_person: string;
  emergency_contact: string;
  allergies: string;
  last_pregnancy?: Date;
  weight: number;
  terms: boolean;
  language: any[];
  heigth: number;
  previous_surgeries?: string;
  number_of_pregnancies?: number;
  number_of_abortions?: number;
  number_of_child_births?: number;
  number_of_caesareans?: number;
  other_diseases?: string;
  medicines_in_use?: string;
  extra_information?: string;
  gender: string;
  asthma?: boolean;
  falcemia?: boolean;
  diabetes?: boolean;
  smoker?: boolean;
  alcohol?: boolean;
  oral_contraceptive?: boolean;
  hiv?: boolean;
  cancer?: boolean;
  thalassemia?: boolean;
  willebrand?: boolean;
  biopolymers?: boolean;
  hypertension?: boolean;
  falcemia_carrier?: boolean;
  depression?: boolean;
  hookah?: boolean;
  drugs?: boolean;
  lactating?: boolean;
  hepatitis_b_or_c?: boolean;
  leukemia?: boolean;
  hemophilia?: boolean;
  imc: number;
  penicillin_allergy?: boolean;
  primary_language_id: string;
  accepts_blood_transfusion?: boolean;
  // 
  currently_pregnant: boolean;
  previous_transfusion: boolean;
  last_transfusion_date: Date;
  blood_type: string;
  aspirin: boolean;
  sedatives:  boolean;
  anti_hypertensive:  boolean;
  anti_coagulants:  boolean;
  anti_depressants:  boolean;
  medicines_to_lose_weight:  boolean;
  oxycodone:  boolean;
  natural_medicines : boolean;
  calcium:  boolean;
  vitamins_e_d_c:  boolean;
  omega_3:  boolean;
  leucine:  boolean;
  sinusitis:  boolean;
  hypercholesterolemia:  boolean;
  tachycardia : string;
  neurological : string;
  circulatory: string;
  skin: string;
  gastrointestinal: string;
  breast_pathologies: string;
  hypothyroidism: string;
  hereditary_history: string;
  had_covid: boolean;
  vaccinated_covid: boolean;
}

// id?: string;
// name: string;
// lastname: string;
// identification: string;
// age: number;
// status: string;
// religion: string;
// lastpregnancy: Date;
// phone: string;
// email: string;
// occupation: string;
// address: string;
// imc: number;
// residence?: string;
// residencePhone?: string;
// emergencyPerson?: string;
// emergencyPersonPhone?: string;
// previousSurgeries?: string;
// pregnancy?: string;
// abortions?: string;
// childbirth?: string;
// caesarean?: string;
// diseases?: string;
// medicines?: string;
// extraInformation?: string;
// asthma?: boolean;
// falcemia?: boolean;
// diabetes?: boolean;
// smoker?: boolean;
// alcohol?: boolean;
// contraceptive?: boolean;
// hiv?: boolean;
// cancer?: boolean;
// thalassemia?: boolean;
// willebrand?: boolean;
// biopolymers?: boolean;
// hypertension?: boolean;
// pFalcemia?: boolean;
// depression?: boolean;
// hookah?: boolean;
// drugs?: boolean;
// lactating?: boolean;
// hepatitis?: boolean;
// leukemia?: boolean;
// hemophilia?: boolean;
// penicillinAllergy?: boolean;
// transfusion?: boolean;
// changeFlight?: boolean;
// notFalseInformation?: boolean;
// allowSavingInformation?: boolean;

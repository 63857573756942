import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {


  // Todo lo que sea comun de las ur lo guardamos en una variable
  private url = environment.ApiUrl;
  email: string;
  lenguage: string;


  constructor(private http: HttpClient, private _ts: TranslateService) {
     this.lenguage = this._ts.getBrowserLang();
   }

  // Envio del email
  sendEmailService( Email: any ) {

    return this.http.post(this.url + '/forget', Email)
                    .pipe( map( (resp: any) => {
                    this.email = Email.email;
                    return resp;
    }));
  }


   // Cambio de password
   changePassword( form: any ) {
   const body = {
      email: form.email,
      password: form.password,
      lenguage: this.lenguage
   };

   return this.http.post( this.url + '/newPass', body )
                    .pipe( map ( resp =>  {
                      return resp;
                    }));
  }
}


import { Component, OnInit } from "@angular/core";
import { GalleryService } from "../../services/gallery.service";
import { GalleryModel } from "../../models/gallery.model";
import { Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { CountrysService } from "../../services/countrys.service";
import { TranslateServices } from "../../services/translate.service";
import { SearchService } from "../../services/search.service";
import { DoctorModel } from "../../models/doctor.model";

// Me permite tomar parametros de la url

@Component({
  selector: "app-gallery",
  templateUrl: "./gallery.component.html",
  styleUrls: ["./gallery.component.css"],
})
export class GalleryComponent implements OnInit {
  fullName: string;
  noFound = false;
  showFullImage = false;
  imgUrl: string = "";
  loading: boolean;
  parseFistLastName: string;
  parseLastName: string;
  gallery: GalleryModel[] = [];
  doctorGallery: DoctorModel[] = [];
  // Atrapamos la opcion elegina
  // optionGalleryButton: number = 1;
  startGallery: boolean = true;
  // relacion con el formulario
  galleryForm = {
    search: "",
    country: "",
  };

  countryTotal: any[] = [];

  constructor(
    private galleryService: GalleryService,
    private router: Router,
    private _doctorServices: SearchService,
    private translate: TranslateServices
  ) {
    this.loading = true;
    this.translate.translateText();
  }
  ngOnInit(): void {
    this.getAllGallery();
    // Tomamos las ciudades desde el servicio
    this._doctorServices.getContries().subscribe((resp) => {
      this.countryTotal = resp;
    });
  }
  // Tomamos la opcion de galeria elegida
  optionGallery(identity: string) {
    this.galleryService.getTypeGallery(identity).subscribe((resp: any) => {
      this.gallery = resp.gallery;
    });
  }

  fullImage(url: string) {
    this.showFullImage = true;
    this.imgUrl = url;
  }

  closeFullImage() {
    this.showFullImage = false;
  }
  // Permite movernos a la pagina de la imagen
  nextView(id: string) {
    // Nos movemos a la siguiente vista tomando el id de la imagen
    this.router.navigate(["gallery-next", id]);
  }

  // Nos permite hacer una busqueda en funcion
  search(form: NgForm) {
    this.loading = true;
    this.startGallery = false;
    const firt_name = form.value.search.split(" ", 1);
    const lastName = form.value.search.split(" ", 3);
    let firstLastName = lastName[1];
    let lastname = lastName[2];
    let firstName = firt_name[0];

    this.parseFistLastName = String(firstLastName);
    this.parseLastName = String(lastname);
    if (this.parseFistLastName === "undefined") {
      firstLastName = "";
    }
    if (this.parseLastName === "undefined") {
      lastname = "";
    }
    let fullName = firstLastName + " " + lastname;
    const body = {
      name: firstName,
      countries: form.value.country,
      last_name: fullName.trim(),
    };
    setTimeout(() => {
      this.galleryService.searchGallery(body).subscribe((resp: any) => {
        this.loading = false;
        this.gallery = resp.gallery;
        if (resp.gallery.length === 0) {
          this.noFound = true;
        } else {
          this.noFound = false;
        }
      });
    }, 1000);
  }

  getAllGallery() {
    setTimeout(() => {
      this.galleryService.getAllGallery().subscribe((resp: any) => {
        this.loading = false;
        this.gallery = resp.gallery;
        this.doctorGallery = resp.doctor;
      });
    }, 0);
  }
}

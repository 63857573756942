<div id="mainContainer" class="container">
    <div class="row">
        <div class="col">
            <!--Titulo del login-->
            <div id="title">
                <h2> {{ "FORGOTPASSWORD.title" | translate }} </h2>
            </div>
            <mat-card class="mb-4 mt-5 mat-heigh" *ngIf="flag.navbarButtonsActive">
                <div id="vertical">
                <i id="back" [routerLink]="['/settings']" routerLinkActive="router-link-active"  class="fa fa-arrow-left fa-2x text-danger float-left"></i> 
                <h2 id="text" class=" text-danger float-left"> {{ "SEARCH.back" | translate }}</h2>
                <h3 class="text-muted text-center">  {{ "NAVBAR.settings" | translate }} </h3>
                </div>
        </mat-card>
            <!--Card-->
            <div class="card">
                <div class="card-body">
                    <form (ngSubmit)="sendEmail( form )" #form="ngForm">
                        <div class="form-group">
                            <label for="exampleInputEmail1"> {{ "FORGOTPASSWORD.email" | translate }} </label>
                            <input type="email" class="form-control" name="email" email required placeholder="Bob@gmail.com" ngModel [class.is-invalid]="email.invalid && email.touched" #email="ngModel">
                        </div>
                        <button type="submit" class="btn btn-success btn-block"> {{ "FORGOTPASSWORD.send" | translate }} </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
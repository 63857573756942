import { Injectable, EventEmitter, Output } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { UserModel } from '../models/user.model';

import { map } from 'rxjs/operators';
import { ChatSocketService } from './chat-socket.service';
import { observable, Observable } from 'rxjs';
import { environment } from '../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class AuthService {

// Sirve para emitir este evento en default-container y nav para controlar el open or close de sidebar
  isOpen = false;
  private url = environment.ApiUrl;
  userToken: string;
  id: string;

  @Output() change: EventEmitter<boolean> = new EventEmitter();

 toggle() {
    this.isOpen = !this.isOpen;
    this.change.emit(this.isOpen);
  }

  constructor(private http: HttpClient) {
    this.readToken();
  }

  // Funcion encargada de hacer el login
  login( user: UserModel ) {
        const authData = {
        email: user.email,
        password: user.password
        };
        return this.http.post(
          this.url + '/login', authData).pipe( map( (resp: any) => {
        
            if ( resp.OK != false) {
              this.addToken( resp['idToken']);
              
            } else {
              this.addToken("");           }
          localStorage.removeItem("doctorSelected");
          localStorage.removeItem("idDoctor");
          this.id = resp.id;
          return resp;
      }));
  }

 // Metodo para crear cuenta
 createAccount( user: UserModel ) {
     const authData = {
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          password: user.password,
          languages: user.languages,
          gender: user.gender,
          spanish: user.langEs,
          english: user.langEn,
          primary_language_id: user.primaryLang
     };
  
     return this.http.post(this.url + '/new-patient', authData)
                     .pipe( map ( resp => {
                     JSON.stringify(resp);
                     return resp;
                     }));
   }


   // Permite salvar el token
  private addToken( idToken: string) {
    this.userToken = idToken;
    // Guardamos el token en el local storage
    localStorage.setItem('token', idToken);
    localStorage.setItem('id', this.id);
  }

  // Nos permite verificar si hay un token guardado en nuestro localstorage
  readToken() {
    // Verificamos si hay un token en el localstorage
    if ( localStorage.getItem('token') && localStorage.getItem('id') ) {

      // Si existe le almacenamos en el userToken
      this.userToken = localStorage.getItem('token');
      this.id = localStorage.getItem('id');
    } else {
      // Devolvemos un token vacio si no encontramos nada
      this.userToken = '';
      this.id = '';
    }

    return { userToken: this.userToken, id: this.id } ;
  }

  // Devuelve un booleano si el usuario tiene un token funcional
  isAuthenticated( ): boolean {
  
    return this.userToken.length > 2;
  }


  // Destruccion del token
  logout(){
    // Destruimos el token al salir
    localStorage.removeItem('token');
    localStorage.removeItem('chatId');
    // Destruimos el id al salir
    localStorage.removeItem('id');
    localStorage.removeItem('idUser');
    localStorage.removeItem('name');
    localStorage.removeItem('gender');
    localStorage.removeItem('idDoctor');
    localStorage.removeItem("doctorSelected");
    localStorage.removeItem('isFirstTime');

  }

  IsUserFirtTime() {
    return this.http.get(`${this.url}/handbook-web`).pipe(
      map((Response) => {
        return Response;
      })
    );
  }
  detectInternerConection() {
    return new Observable ( observable => {
      window.addEventListener('load', function() {
        window.addEventListener('online', function() {
          observable.next(true)
        }, false);
        
        
      window.addEventListener('offline', function() {
        observable.next(false)
      }, false);
    })

      })
      
    }
}

<div id="mainContainer" class="container mx-auto">
  <h2>{{ "REQUESTSLIST.title" | translate }}</h2>
  <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <a
        class="nav-link active"
        id="wait-tab"
        data-toggle="tab"
        href="#wait"
        role="tab"
        aria-controls="wait"
        aria-selected="true"
        >{{ "REQUESTSLIST.waiting" | translate }} 
        </a
        >
    </li>
    <li [matBadge]="chatSocketService?.requestCountAccepted" [matBadgeHidden]="!chatSocketService?.requestAccepted" class="nav-item" role="presentation">
      <a
        class="nav-link"
        id="accepted-tab"
        data-toggle="tab"
        href="#accepted"
        role="tab"
        aria-controls="accepted"
        aria-selected="false"
        >{{ "REQUESTSLIST.accepted" | translate }}
        <label  *ngIf="acceptedNotification === true" class="badge badge-success">*</label>
        </a
      >
    </li>
    <li class="nav-item"  [matBadge]="chatSocketService?.requestCountRejected" [matBadgeHidden]="!chatSocketService?.requestRejected" role="presentation">
      <a
        class="nav-link"
        id="rejected-tab"
        data-toggle="tab"
        href="#rejected"
        role="tab"
        aria-controls="rejected"
        aria-selected="false"
        >{{ "REQUESTSLIST.reject" | translate }}
        <label *ngIf="rejectedNotification === true" class="badge badge-danger">*</label>
        </a
      >
    </li>
  </ul>
  <div id="requestsContainer">
    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="wait"
        role="tabpanel"
        aria-labelledby="wait-tab"
      >
        <ng-container class="row" *ngIf="requestsWait.length > 0; else noFound">
          <div
            class="list-group"
            *ngFor="let doctor of requestsWait; let i = index"
          >
            <mat-card class="mb-4 border-wait animated fadeIn fast">
              <div class="list-group-item list-group-item-action">
                <div id="listContainer" class="row">
                  <div id="imgContainer" class="col-6 col-md-4">
                    <div class="container-img align-middle">
                      <div id="circle-img">
                        <img
                          id="imgCard"
                          [src]="doctor.doctor.profile_picture | imgFalse"
                          class=""
                          alt="Doctor image"
                        />
                      </div>
                    </div>
                  </div>
                  <div id="ulContainer" class="col-6 col-md-4">
                    <ul id="cardInformtion">
                      <li>
                        <b>{{ "REQUESTSLIST.docName" | translate }}</b>
                        {{ doctor.doctor.first_name }}
                        {{ doctor.doctor.last_name }}
                      </li>
                      <li>
                        <b>{{ "REQUESTSLIST.docCountry" | translate }}</b>
                        {{ doctor.doctor.country.name }}
                      </li>
                      <li>
                        <b>{{ "REQUESTSLIST.docLanguage" | translate }}</b>
                        <span *ngIf="!showEs">{{ doctor.procedureType.name }} </span>
                        <span *ngIf="showEs">{{ doctor.procedureType.name_es }} </span>
                      </li>
                    </ul>
                  </div>
                  <div id="status" class="col-12 col-md-4 row">
                    <div id="left" class="col-6 col-md-6 text">
                      <p>
                        <b>{{ "PAYMENTDETAILS.waiting-time" | translate }}: </b>
                      </p>
                      <h6>{{ doctor.status_at | date }}</h6>
                    </div>
                    <div id="right" class="col-6 col-md-6">
                      <div id="text-card">
                        <p>
                          <b
                            >{{ "PAYMENTDETAILS.request-status" | translate }}:
                          </b>
                        </p>
                        <h6>{{ "REQUESTSLIST.created" | translate }}</h6>
                      </div>
                      <button
                        id="button-delete"
                        type="button"
                        class="btn btn-colornav mb-3"
                        (click)="deleteAppointment(doctor.id)"
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </ng-container>
        <ng-template #noFound class="row">
          <div class="col-md-12 mt-5 text-center">
            <i class="mt-5 fa fa-exclamation-triangle text-warning fa-3x"></i>
            <h4 class="text-muted mt-3">
              {{ "PAYMENT.no-result" | translate }}
            </h4>
          </div>
        </ng-template>
      </div>
      <div
        class="tab-pane fade"
        id="accepted"
        role="tabpanel"
        aria-labelledby="accepted-tab"
      >
        <ng-container
          class="row"
          *ngIf="requestAccepted.length > 0; else noFound"
        >
          <div
            class="list-group"
            *ngFor="let doctor of requestAccepted; let i = index"
          >
            <mat-card class="mb-4 border-accepted animated fadeIn fast">
              <div class="list-group-item list-group-item-action">
                <div id="listContainer" class="row">
                  <div id="imgContainer" class="col-6 col-md-4">
                    <div class="container-img">
                      <div id="circle-img">
                        <img
                          id="imgCard"
                          [src]="doctor.doctor.profile_picture | imgFalse"
                          class=""
                          alt="Doctor image"
                        />
                      </div>
                    </div>
                  </div>
                  <div id="ulContainer" class="col-6 col-md-4">
                    <ul id="cardInformtion">
                      <li>
                        <b>{{ "REQUESTSLIST.docName" | translate }}</b>
                        {{ doctor.doctor.first_name }}
                        {{ doctor.doctor.last_name }}
                      </li>
                      <li>
                        <b>{{ "REQUESTSLIST.docCountry" | translate }}</b>
                        {{ doctor.doctor.country.name }}
                      </li>
                      <li>
                        <b>{{ "REQUESTSLIST.docLanguage" | translate }}</b>
                        <span *ngIf="!showEs">{{ doctor.procedureType.name }} </span>
                        <span *ngIf="showEs">{{ doctor.procedureType.name_es }} </span>
                      </li>
                    </ul>
                  </div>
                  <div id="status" class="col-12 col-md-4 row">
                    <div id="left" class="col-6 col-md-6 text">
                      <p>
                        <b>{{ "PAYMENTDETAILS.waiting-time" | translate }}: </b>
                      </p>
                      <h6>{{ doctor.status_at | date }}</h6>
                    </div>
                    <div id="right" class="col-6 col-md-6">
                      <div id="text-card">
                        <p>
                          <b
                            >{{ "PAYMENTDETAILS.request-status" | translate }}:
                          </b>
                        </p>
                        <h6>{{ "REQUESTSLIST.accepted" | translate }}</h6>
                      </div>
                      <button
                        id="button-delete"
                        type="button"
                        class="btn btn-colornav mb-3"
                        (click)="deleteAppointment(doctor.id)"
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </ng-container>
        <ng-template #noFound class="row">
          <div class="col-md-12 mt-5 text-center">
            <i class="mt-5 fa fa-exclamation-triangle text-warning fa-3x"></i>
            <h4 class="text-muted mt-3">
              {{ "PAYMENT.no-result" | translate }}
            </h4>
          </div>
        </ng-template>
      </div>
      <div
        class="tab-pane fade"
        id="rejected"
        role="tabpanel"
        aria-labelledby="rejected-tab"
      >
        <ng-container
          class="row"
          *ngIf="requestsRejected.length > 0; else noFound"
        >
          <div
            class="list-group"
            *ngFor="let doctor of requestsRejected; let i = index"
          >
            <mat-card class="mb-4 border-cancelled animated fadeIn fast">
              <div class="list-group-item list-group-item-action">
                <div id="listContainer" class="row">
                  <div id="imgContainer" class="col-6 col-md-4">
                    <div class="container-img">
                      <div id="circle-img">
                        <img
                          id="imgCard"
                          [src]="doctor.doctor.profile_picture | imgFalse"
                          class=""
                          alt="Doctor image"
                        />
                      </div>
                    </div>
                  </div>
                  <div id="ulContainer" class="col-6 col-md-4">
                    <ul id="cardInformtion">
                      <li>
                        <b>{{ "REQUESTSLIST.docName" | translate }}</b>
                        {{ doctor.doctor.first_name }}
                        {{ doctor.doctor.last_name }}
                      </li>
                      <li>
                        <b>{{ "REQUESTSLIST.docCountry" | translate }}</b>
                        {{ doctor.doctor.country.name }}
                      </li>
                    
                      <li>
                        <b>{{ "REQUESTSLIST.docLanguage" | translate }}</b>
                        <span *ngIf="!showEs">{{ doctor.procedureType.name }} </span>
                        <span *ngIf="showEs">{{ doctor.procedureType.name_es }} </span>
                      </li>
                    </ul>
                  </div>
                  <div id="status" class="col-12 col-md-4 row">
                    <div id="left" class="col-6 col-md-6 text">
                      <p>
                        <b>{{ "PAYMENTDETAILS.waiting-time" | translate }}: </b>
                      </p>
                      <h6>{{ doctor.status_at | date }}</h6>
                    </div>
                    <div id="right" class="col-6 col-md-6">
                      <div id="text-card">
                        <p>
                          <b
                            >{{ "PAYMENTDETAILS.request-status" | translate }}:
                          </b>
                        </p>
                       
                        <h6>{{ "REQUESTSLIST.cancelled" | translate }}</h6>
                       
                      </div>
                      <div class="btn-group" role="group" aria-label="Basic example">
                        <button
                        id="button-delete"
                        type="button"
                        class="btn btn-sm btn-colornav mb-3"
                        (click)="deleteAppointment(doctor.id)"
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                      <button type="button" class="btn mb-3 btn-sm btn-warning"
                      (click)="seeReason(doctor.rejection_reason)"
                      >{{ "PAYMENTDETAILS.see" | translate }}
                    </button>
                      </div>
                
                    </div>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </ng-container>
        <ng-template #noFound class="row">
          <div class="col-md-12 mt-5 text-center">
            <i class="mt-5 fa fa-exclamation-triangle text-warning fa-3x"></i>
            <h4 class="text-muted mt-3">
              {{ "PAYMENT.no-result" | translate }}
            </h4>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

export class UserModel {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    gender: string;
    privacy: boolean;
    langEn: boolean;
    langEs: boolean;
    primaryLang: string;
    languages: any[];
}
import { Injectable } from "@angular/core";
import { FileItem } from "../models/file-item.model";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { analyzeAndValidateNgModules } from "@angular/compiler";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class UploadImageService {
  //Todo lo que sea comun de las ur lo guardamos en una variable
  private url = environment.ApiUrl;

  // Usuario id
  private id: string;
  private img: string;

  //Imagen para sidebar
  public profile_picture: string;

  constructor(private http: HttpClient) {
    // Recuperamos el id del localstorage
    this.id = localStorage.getItem("id");
    this.img = localStorage.getItem("nameImg");
  }

  // Recibe una imagen
  public uploadImagedb(origen: string, imageName: string, selectedFile: File) {
    // Le damos forma a los datos que enviaremos
    const fd = new FormData();
    // Adjuntamos los datso que queremos enviar
    fd.append(imageName, selectedFile, selectedFile.name);
    this.id = localStorage.getItem("id");
    // Subimos la imagen a nuestra url y recibimos la respuesta del API
    return this.http.post(this.url + "/form/image-patient/" + this.id, fd).pipe(
      map((resp) => {
        return resp;
      })
    );
  }


  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //Enviar Imagenes
  public sendImagest( imgObject: any, destination: string, patient_id:number ) {
    //this.idUser = localStorage.getItem("id");
    //console.log(destination);
    //console.log(patient_id);
    //console.log(imgObject);
    
    const fd = new FormData();

    fd.append(destination,imgObject.file,imgObject.name);

    //console.log(fd);

    return this.http.post( this.url + '/form/image-patient/' + patient_id, fd);
  }




  getImages() {
    this.id = localStorage.getItem("id");
    return this.http
      .get(this.url + "/search-image/" + this.id)
      .pipe(map((resp) => resp));
  }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
//import { CountrysService } from '../../../services/countrys.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
//import { DirectFormService } from '../../../services/direct-form.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
//import { DynamicFormModel } from '../../../classContainer/dynamic-form';
import { DynamicFormModel } from '../../classContainer/dynamic-form';
import { CountrysService } from '../../services/countrys.service';
import { DirectFormService } from '../../services/direct-form.service';
import { FormService } from '../../services/form.service';
import { strict } from 'assert';
import { MatDialog } from '@angular/material/dialog';
import { ModalAppointmentComponent } from '../client/modal-appointment/modal-appointment.component';

@Component({
  selector: 'app-form-surgery2',
  templateUrl: './form-surgery2.component.html',
  styleUrls: ['./form-surgery2.component.css']
})
export class FormSurgery2Component implements OnInit {

  public codeDoctorForm: FormGroup;
  public searchCodeProgress: boolean = false;
  public form: FormGroup;
  public lastform: FormGroup;
  public doctorName: string;
  public code_doctor: any;
  public countryTotal:any[] = [];
  public languages:any[] = [];
  public procedureList:any[] = [];
  public language: string;
  public loadingContainer: boolean = false;
  public disabledSEndButton: boolean = false;
  public feetAndMetersContainer: boolean = true;
  public imc:number = 0;
  public firstSave: boolean = false;

  public emailInfoContainer: boolean = true;
  public emailInfoTextr: string = "";
  public valid_email: boolean = false;
  public emailChange: number = 0;

  //Acivar el progress envio de imagen
  public sendFormProgressContainer: boolean = false;

  public genderGroup:any[] = [];
  //Permite almacenar altura peso y IMC para enviarlo
  public heightWeightIMC: any = {
    height: null,
    weight: null,
    imc: null,
  };

  //Habrir dialogo envio final al doctor
  public dialogConfirmContainer: boolean = false;
  public dialogConfirmProgressContainer: boolean = false;
  public firtSaveLoadingContainer: boolean = false;

  //DynamicFormModel
  public dynamicFormClass:any = new DynamicFormModel;
  public dynamicForm: FormGroup;
  public moduleList:any[] = [];
  public moduleListArray: any[] = [];
  public totalModule:any[] = [];

  public img_global:string = "assets/img/user2x.png";


  public first_name: string;
  public last_name: string;
  public identification: string;
  public email: string;
  public phone: string;
  public dateBirth: string;
  public gender: string;
  public languagesR: any[] = [];
  public procedure_type_id: any;
  public country: string;
  public city: string;
  public address: string;
  public status: string;
  public religion: string;
  public occupation: string;
  public kg: string;
  public meter: string;
  public pounds: string;
  public foot: string;
  public imcR: string;
  public emergencyPerson: string;
  public emergencyPersonPhone: string;
  public reason: string;
  public reasonAndProcedureContainer: boolean = false;

  public doctorListWithForm: any[] = [];

  public disabledButton: boolean = true;
  public modeProgress:any;
  public nameProgressID: string = "nameProgress";
  public numberProgressID: number = 0;
  public selectedFile:any;
  public nameFile:any;
  public fileType:any;

  public img1: string = "assets/img/front.png";
  public img2: string = "assets/img/back.png";
  public img3: string = "assets/img/left-profile.png";
  public img4: string = "assets/img/right-profile.png";
  public img5: string = "assets/img/document.png";
  public img6: string = "assets/img/profile.png";

  public img1_global: string = "assets/img/front.png";
  public img2_global: string = "assets/img/back.png";
  public img3_global: string = "assets/img/left-profile.png";
  public img4_global: string = "assets/img/right-profile.png";
  public img5_global: string = "assets/img/document.png";
  public img6_global: string = "assets/img/profile.png";

  public imgProgress1: boolean = false;
  public imgProgress2: boolean = false;
  public imgProgress3: boolean = false;
  public imgProgress4: boolean = false;
  public imgProgress5: boolean = false;
  public imgProgress6: boolean = false;
  

  public imagesGroup:any = {
    front: null,
    back: null,
    left: null,
    right: null,
    passport: null,
    profile: null,
  }

  public selectedDoctorProgress: boolean = false;
  public sendButtonDisabled: boolean = true;

  constructor(private fb: FormBuilder,
              private countryServices: CountrysService,
              private translate: TranslateService,
              private directFormService: DirectFormService,
              private router: Router,
              private formService: FormService,
              public dialog: MatDialog) 
  { 
    
    this.language = this.translate.getBrowserLang();
    //console.log( this.language );
  }




  ngOnInit(): void {

    //Tomamos los datos almecenado del doctor
    if( localStorage.getItem('doctorInfo')) {
      //Para pasar de string a array, proceso inverso
      let doctorInfo ​= JSON.parse(localStorage.getItem('doctorInfo'));

      //console.log(doctorInfo);
      this.doctorName = doctorInfo?.full_name;
      
      //this.code_doctor = doctorInfo?.code_doctor;

    }

    this.countryTotal = this.countryServices.sendCoubntries();
    ////console.log(this.countryTotal);
    this.createForm();
    this.createForm2();
    this.listTotal();

  }



  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  //Listas
  public listTotal() {

    this.directFormService.getLanguage().subscribe((resp:any) => {
      console.log(resp);
      this.languages = resp.Languages;
    });


    if (this.language === 'es' || this.language === 'es-ES' ) {

      this.selectedDoctorHTML = "Formulario Cirugía";
      this.genderGroup = [
        {
          id: "female",
          name: "Femenino"
        },
        {
          id: "male",
          name: "Masculino"
        }
      ];

    } 
    else if (this.language === 'en' || this.language === 'en-US') {

      this.selectedDoctorHTML = "Surgery Form";
      this.genderGroup = [
        {
          id: "female",
          name: "Female"
        },
        {
          id: "male",
          name: "Male"
        }
      ];

    }



    this.countryTotal = this.countryServices.sendCoubntries();


    this.formService.getData().subscribe((resp: any) => {
      console.log(resp);
      this.patientDataForm( resp );
    });


    //Lista de doctores a las que se le envio formulario
    this.formService.getDoctorListWithForm().subscribe((resp: any) => {
      console.log(resp);
      this.doctorListWithForm = resp.doctorList;
    });




  }



  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //Funcion encargada de rellenar los datos recibidos del formulario dinamico
  public fillDynamicForm( data ) {

    console.log("+++++++++++++++");
    console.log("+++++++++++++++");
    console.log(data);
    let array:any[] = [];
    array = data;
    //console.log(data.moduleList);
    for( let item1 of array ) {

      //console.log(item1);
      let arrayList:any[] = [];
      arrayList = item1.list_total;

      for( let item2 of arrayList ) {
        //console.log(item2);
        //console.log(item2.selector);
        //let selector = item2.selector;

        let value:any; 
        if( item2.type === "select" ) {
          value = parseInt(item2.value);
        }
        else {
          value = item2.value;
        }
        this.dynamicFormClass.formModule1[item2.selector] = value;
        //console.log(this.dynamicFormClass.formModule1['module3_t1']);
        //console.log(this.dynamicFormClass.formModule1.module3_t1 = "Holas");
        //let found = this.dynamicFormClass.formModule1.find(x => x.selector == item2.selector);
        //console.log(found);

      }

    }
    //this.dynamicFormClass.formModule1['module1_t1'] = 11;
    //console.log(this.dynamicFormClass.formModule1.module1_t1 );

    this.createForm();

  }


  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  //Recibi los datos ya rellenados del paciente
  public patientDataForm( form ) {

    console.log(form.patient);
    console.log(form.patientHistory);

    this.first_name = form.patient.first_name;
    this.last_name =form.patient.last_name;
    this.identification = form.patient.passport;
    this.email = form.patient.email;
    this.phone = form.patient.phone;
    this.dateBirth = form.patient.birthday;
    this.gender = form.patientHistory.gender;



    for( let item of form.patient.language ) {
      console.log(item);
      this.languagesR.push(item.id)
    }
    //this.languagesR = ['es','en'];
    //this.procedure_type_id = null;
    
    this.country = form.patient.country;
    this.city = form.patient.city;
    this.address = form.patient.address;
    this.status = form.patient.civil_status;
    this.religion = form.patient.religion;
    this.occupation = form.patient.occupation;
    

    //this.pounds = (this.archiveGeneralPatient?.kg * 2.2046).toFixed(2);
    //this.foot = (this.archiveGeneralPatient?.meters * 3.28).toFixed(1);
    this.kg = form.patientHistory.weight;
    this.meter = form.patientHistory.height;
    this.pounds = (form.patientHistory.weight * 2.2046).toFixed(2);
    this.foot = (form.patientHistory.height * 3.28).toFixed(1);
    this.imc = form.patientHistory.imc;

    this.heightWeightIMC.weight = form.patientHistory.weight;
    this.heightWeightIMC.height = form.patientHistory.height;
    this.heightWeightIMC.imc = form.patientHistory.imc;

    console.log(this.heightWeightIMC);
    
    this.emergencyPerson = form.patient.emergency_person;
    this.emergencyPersonPhone = form.patient.emergency_contact;


    this.formService.getImages().subscribe((resp:any) => {
      console.log(resp);

      let imageArray:any[] = [];
      imageArray = resp.patient_image;

      for( let item of imageArray ) {
        switch( item.name ) {
          case "front_picture": {
            this.img1 = item.url;
            break;
          }
          case "back_picture": {
            this.img2 = item.url;
            break;
          }
          case "left_picture": {
            this.img3 = item.url;
            break;
          }
          case "right_picture": {
            this.img4 = item.url;
            break;
          }
          case "passport_picture": {
            this.img5 = item.url;
            break;
          }
          case "profile_picture": {
            this.img6 = item.url;
            break;
          }
        }
      }

    });

    this.fillDynamicForm( this.moduleList );

    this.createForm();

  }

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  //Creacion del formulario
  public createForm() {

    this.codeDoctorForm = this.fb.group({
      codeOrName: [null]
    });

    this.codeDoctorForm.valueChanges.subscribe((resp:any) => {
      console.log(resp);
      this.searchDoctor( resp.codeOrName );
    });

    this.form = this.fb.group({
      first_name: [this.first_name, [Validators.required]],
      last_name: [this.last_name, [Validators.required]],
      identification: [this.identification, [Validators.required]],

      email: [this.email, [Validators.required]],
      phone: [this.phone, [Validators.required]],
      dateBirth: [this.dateBirth, [Validators.required]],

      gender: [this.gender, [Validators.required]],
      languages: [this.languagesR, [Validators.required]],
      procedure_type_id: [this.procedure_type_id],

      country: [this.country, [Validators.required]],
      city: [this.city, [Validators.required]],
      address: [this.address, [Validators.required]],

      

      status: [this.status],
      religion: [this.religion],
      occupation: [this.occupation],

      
      kg: [this.kg], 
      meter: [this.meter],
      pounds: [this.pounds],
      foot: [this.foot],
      imc: [this.imc],
      

      emergencyPerson: [this.emergencyPerson],
      emergencyPersonPhone: [this.emergencyPersonPhone],


      reason: [this.reason],

      terms: [false, [Validators.required]],

      changeFlight: [false, [Validators.required]],
      notFalseInformation: [false, [Validators.required]],
      allowSavingInformation: [false, [Validators.required]],
      
   
    });

    console.log(this.form.value);

    

    this.form.valueChanges.subscribe((resp:any) => {

      //console.log(resp);


      this.procedure_type_id = resp.procedure_type_id;
      let email: string = "";
      email = resp.email;
      let cont: number = 0;
      let emailInfo = $('#emailInfo');

      let email1: string;
      let email2: string;
      let email3: string;
      
      if (this.language === 'es' || this.language === 'es-ES' ) {
        email1 = "Este Email ya esta siendo utilizado !!!";
        email2 = "Email Valido";
        email3 = "Email Invalido !!!";

      } 
      else if (this.language === 'en' || this.language === 'en-US') {
        email1 = "This Email is already being used !!!";
        email2 = "Valid Email";
        email3 = "Invalid Email !!!";

      }

      //this.heightWeightIMC
      //Calcula imc en pies

      if( this.feetAndMetersContainer ) {
        ////console.log(resp.pounds);
        ////console.log(resp.foot);
        if( resp.pounds !== null && resp.foot !== null ) {
          let pounds:number = 0;
          let foot:number = 0;
          pounds = resp.pounds / 2.2046;
          foot = resp.foot * 0.3048;
          //console.log(pounds);
          //console.log(foot);
          
          this.imc = (pounds / ( foot * foot ));

          this.heightWeightIMC.weight = pounds;
          this.heightWeightIMC.height = foot;
          this.heightWeightIMC.imc = this.imc;
          //console.log(this.heightWeightIMC);
        }
      }

      //Calcula imc en metros
      if( !this.feetAndMetersContainer ) {
        ////console.log(resp.kg);
        ////console.log(resp.meter);
        if( resp.kg !== null && resp.meter !== null ) {

          let kg:number = 0;
          let meter:number = 0;
          kg = resp.kg;
          meter = resp.meter;

          this.imc = (kg / ( meter * meter ));

          this.heightWeightIMC.height = meter;
          this.heightWeightIMC.weight = kg;
          this.heightWeightIMC.imc = this.imc;
          //console.log(this.heightWeightIMC);
        }

      }
      
      
      //console.log(email);

      //console.log(email?.length);
      //console.log(this.emailChange);
      
      //Se encarga de determina si el formato del email es correcto y si no existe en NovusDoc
      if( email?.length > 4 && email?.length !== this.emailChange ) {

        for( let item of email ) {

          ////console.log(item);
          if( item === '.' || item === '@' ) {
            cont ++;
          }

        }

        if( cont == 2 ) {

          //Esperamos 3 segundos antes de realizar la busqueda para no saturar la bbdd
          setTimeout(() => {
            //console.log("Buscar el email");
            this.directFormService.emailVerifier( email ).subscribe((resp:any) => {

              //console.log(resp);
              if( resp.exist ) {

                this.valid_email = false;
                this.emailInfoTextr = email1;
                emailInfo.css('color', 'red');
              }
              else {

                this.valid_email = true;
                this.emailInfoTextr = email2;
                emailInfo.css('color', 'green');
              }
            });
          }, 4000);
          
        }
        else {
          this.emailInfoTextr = email3;
          emailInfo.css('color', 'red');
        }

        this.emailChange = email?.length;

      }

      ////console.log(this.valid_email);

      //Verifica si los datos basicos estas rellenos y guardar la informacion del paciente si es la primera vez que se completan los datos basicos
      if( !this.firstSave ) { 
        //console.log("##########################");
        //console.log("Entre primer Guardao 1");
        //console.log("##########################");
        
        if( this.form.value.first_name !== null && this.form.value.last_name !== null && this.form.value.identification !== null && this.form.value.email !== null &&
          this.form.value.dateBirth !== null && this.form.value.country !== null && this.form.value.city !== null && this.form.value.address !== null) {
  
            console.log( this.valid_email );
            if( this.valid_email  ) {
              //console.log("Aqui1");
              this.firstSave = true;
            }
            else{
              //console.log("Aqui2");
              return;
            }
            //console.log("##########################");
            //console.log("Entre primer Guardao 2");
            //console.log("##########################");
          //No pasaremos este valor a true hasta que no sea guardado el paciente
          /*
          setTimeout(() => {

            console.log("##########################");
            console.log("Entre primer Guardao 3");
            console.log("##########################");
            this.initialSaveForm();
          }, 10000);*/
    
        }

      }
      


      
      
    });



    this.dynamicForm = this.fb.group(
      this.dynamicFormClass.formModule1
    );


    this.dynamicForm.valueChanges.subscribe((resp:any) => {
      console.log(resp);
    });

  }


  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  //Creacion del formulario
  public createForm2() {


    this.lastform = this.fb.group({

      reason: [this.reason, [Validators.required]],
      origin: [null, [Validators.required]],
      changeFlight: [false, [Validators.required]],
      notFalseInformation: [false, [Validators.required]],
      allowSavingInformation: [false, [Validators.required]],


    });


    this.lastform.valueChanges.subscribe((resp:any) => {
      //console.log(resp);
      if( this.lastform.value.reason !== undefined && this.lastform.value.origin !== null && 
          this.lastform.value.notFalseInformation !== false && this.lastform.value.allowSavingInformation !== false && this.lastform.value.changeFlight !== false ) {

        this.sendButtonDisabled = false;
        //console.log("Quitar disabled");
      }
      else {
        this.sendButtonDisabled = true;
      }
    });

  }


  

  

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  public searchListFound: any[] = [];
  public searchListFoundSee: boolean = false;
  //Search doctor
  public searchDoctor( codeOrName: string ) {

    console.log(codeOrName);


    let search:string = codeOrName;
    let count: number = 0;
    let value1: string = "";
    let value2: string = "";
    let value3: string = "";

    for( let item of search ) {
      console.log(item);
      if( item === " " ) {
        console.log("Corte");
        count ++;
      }
      if( count === 0 ) {
        value1 += item;
      }
      if( count === 1 ) {
        value2 += item;
      }
      if( count === 2 ) {
        value3 += item;
      }
    }

    console.log(value1.trim());
    console.log(value2.trim());
    console.log(value3.trim());

    console.log(value1.length);

    this.searchCodeProgress = true;
    if( value1.length > 2 ) {
      console.log("Menor que 2");
    }

    const body = {
      name: value1.trim(),
      last_name: value2.trim(),
    };
    
    this.searchListFoundSee = true;
    this.formService.searchDoctor( body ).subscribe((resp: any) => {

      console.log(resp);
      this.searchCodeProgress = false;
      this.searchListFound = resp.Doctor;
      //this.code_doctor = resp.Doctor.code;
      //console.log(this.code_doctor);
      /*
      this.doctorSearch = resp.Doctor;
      if (resp.Doctor.length === 0) {
        this.noFound = true;
      } else {
        this.noFound = false;
      }*/
    });
  }


  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  public selectedDoctorHTML:string = "";
  //Doctor seleccionado
  public selectedDoctor( item ) {
    
    console.log(item);
    this.code_doctor = item.code;
    
    if (this.language === 'es' || this.language === 'es-ES' ) {

      this.selectedDoctorHTML = "Formulario del Dr. " + item.first_name + " " + item.last_name;


    } 
    else if (this.language === 'en' || this.language === 'en-US') {

      this.selectedDoctorHTML = "Form of Dr. " + item.first_name + " " + item.last_name;

    }
    
    this.searchListFoundSee = false;
    this.selectedDoctorProgress = true;

    //Lista de tratamientos que hace el doctor
    this.formService.getDynamicForm( item.code  ).subscribe( (resp:any) => {

      console.log(resp);
      this.moduleList = resp.moduleList;
      this.moduleListArray = resp.moduleListArray;
      this.reason = resp.reason;
      this.procedure_type_id = resp.procedure_type_id;
      this.reasonAndProcedureContainer = true;
      this.procedureList = resp.procedure_types;
      this.selectedDoctorProgress = false;

      this.fillDynamicForm( resp.moduleListValue.moduleList );
      this.createForm();

    });

  }

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  //Doctor seleccionado de la lista de formularios
  public selectedDoctorList( item ) {
    
    console.log(item);
    this.code_doctor = item.code;

    if (this.language === 'es' || this.language === 'es-ES' ) {

      this.selectedDoctorHTML = "Formulario del Dr. " + item.name;


    } 
    else if (this.language === 'en' || this.language === 'en-US') {

      this.selectedDoctorHTML = "Form of Dr. " + item.name;

    }

    this.searchListFoundSee = false;
    this.selectedDoctorProgress = true;

    //Lista de tratamientos que hace el doctor
    this.formService.getDynamicForm( item.code ).subscribe( (resp:any) => {

      console.log(resp);
      this.moduleList = resp.moduleList;
      this.moduleListArray = resp.moduleListArray;
      this.reason = resp.reason;
      this.procedure_type_id = resp.procedure_type_id;
      this.reasonAndProcedureContainer = true;
      this.procedureList = resp.procedure_types;
      this.selectedDoctorProgress = false;

      this.fillDynamicForm( resp.moduleListValue.moduleList );
      this.createForm();

    });
  }


  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  //Ir a terminos y condiciones
  public goTo() {

    //console.log("Ir a terminos y condiciones");

    this.router.navigate(['terms-conditions']);
  }


  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  //Todo lo necesario para la seleecion de imagenes
  //Seleccionar imagen y enviarla al servicio
  //Seleeciona las imagenes
  public onFileSelected( event:any, destination ) {

    let message: string;

    if (this.language === 'es' || this.language === 'es-ES' ) {
      message = "Verifique que a rellenado todos los campos con ( * ) para poder continuar !!!!";


    } 
    else if (this.language === 'en' || this.language === 'en-US') {
      message = "Verify that you have filled in all the fields with ( * ) to be able to continue !!!!";

    }

 
    /*
    if( !this.firstSave ) {

      Swal.fire({
        icon: 'info',
        title: 'Oops...',
        text: message,

      })
      return;
    }*/


    let patient_id: number;
    //Tomamos los datos almecenado del doctor
    if( localStorage.getItem('patientInfo')) {
      //console.log("###########");
      //console.log("Hay paciente guardado");
      //Para pasar de string a array, proceso inverso
      let patientInfo ​= JSON.parse(localStorage.getItem('patientInfo'));

      //console.log(patientInfo); 
      patient_id = patientInfo.patient_id;

    }
    /*
    else {
      //console.log("###########");
      //console.log("No hay paciente guardado !!!!!!");
      return;
    }*/




    this.nameProgressID = "nameProgress" + this.numberProgressID;

    ////console.log(this.nameProgressID);
    this.modeProgress = "indeterminate";

    ////console.log(event.target.value);
    ////console.log(this.listOfArchive);

    //Se encarga de detectar si se a pulsado el boton cancelar, para que no se rompa la app
    if ( event.target.value.length === 0) {
      //console.log("Suspect Cancel was hit, no files selected.");
      return;
    
    }
  
    //Desactivamos el boton
    this.disabledButton = true;

    //En la consola siguiente target file, encontraremos la url de la imagen nombre y demas
    if(event.target.files){


      let reader = new FileReader();
      //Archivo seleecionado para el envio por el servicio
      this.selectedFile = <File>event.target.files[0];

      //Almacenamos la url de la imagen
      reader.readAsDataURL( event.target.files[0] );
      ////console.log(event.target.files[0]);
      ////console.log(event.target.files);
      ////console.log(event.target.files[0].name);
      ////console.log(event.target.files[0].type);
      this.fileType = event.target.files[0].type;
      this.nameFile = event.target.files[0].name;


      reader.onload = ( eventDoc:any ) => {

        //Muestra el string de datos de la imagen
        ////console.log(eventDoc.target.result);
        let object = {
          name: this.nameFile,
          type: this.fileType,
          file: this.selectedFile
        }



        ////console.log(1);
        //let va: number;
        this.disabledButton = true;
        switch( destination ) {

          
          case 1: {
            //console.log("1");
            this.img1 = eventDoc.target.result;
            //this.imgProgress1 = true;
            this.imagesGroup.front = eventDoc.target.result;
            /*
            this.directFormService.sendImagest( object, "front", patient_id  ).subscribe((resp:any) => {
              //console.log(resp);
              this.imgProgress1 = false;
              this.disabledButton = false;
            });*/
            break;
          }
          case 2: {
            //console.log("2");
            this.img2 = eventDoc.target.result;
            //this.imgProgress2 = true;
            this.imagesGroup.back = eventDoc.target.result;
            /*
            this.directFormService.sendImagest( object, "back", patient_id  ).subscribe((resp:any) => {
              //console.log(resp);
              this.imgProgress2 = false;
              this.disabledButton = false;
            });*/
            break;
          }
          case 3: {
            //console.log("3");
            this.img3 = eventDoc.target.result;
            //this.imgProgress3 = true;
            this.imagesGroup.left = eventDoc.target.result;
            /*
            this.directFormService.sendImagest( object, "left", patient_id  ).subscribe((resp:any) => {
              //console.log(resp);
              this.imgProgress3 = false;
              this.disabledButton = false;
            });*/
            break;
          }
          case 4: {
            //console.log("4");
            this.img4 = eventDoc.target.result;
            //this.imgProgress4 = true;
            this.imagesGroup.right = eventDoc.target.result;
            /*
            this.directFormService.sendImagest( object, "right", patient_id ).subscribe((resp:any) => {
              //console.log(resp);
              this.imgProgress4 = false;
              this.disabledButton = false;
            });*/
            break;
          }
          case 5: {
            //console.log("5");
            this.img5 = eventDoc.target.result;
            //this.imgProgress5 = true;
            this.imagesGroup.passport = eventDoc.target.result;
            /*
            this.directFormService.sendImagest( object, "profile", patient_id  ).subscribe((resp:any) => {
              //console.log(resp);
              this.imgProgress5 = false;
              this.disabledButton = false;
            });*/
            break;
          }
          case 6: {
            //console.log("6");
            this.img6 = eventDoc.target.result;
            //this.imgProgress6 = true;
            
            this.imagesGroup.profile = eventDoc.target.result;
            /*
            this.directFormService.sendImagest( object, "passport", patient_id  ).subscribe((resp:any) => {
              //console.log(resp);
              this.imgProgress6 = false;
              this.disabledButton = false;
            });*/
            break;
          }
        }



      }

    }


    //Desactivamos el boton
    //this.disabledButton = false;
    
  }



  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  public dynamicForm2:any;
  //Envio del formulario o lo guarda 
  public sendFormOrSave() {


    //console.log(this.dynamicForm);
    //console.log(this.dynamicForm.value);

    this.dynamicForm2 = this.dynamicForm.value;
    console.log(this.code_doctor);
    if( this.code_doctor !== undefined ) {

      //console.log("Enviar Solicitud ");
      //console.log(this.form.value.reason);
      //console.log(this.reason);
      this.dialogConfirmContainer = true;

      this.reason = this.form.value.reason;
      this.createForm2();
      
    }
    else {
      //console.log("Actualizar");
      this.sendForm( false );
    }




  }


  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  //Envio del formulario
  public sendForm( notification?:boolean ) {

    let incompleteForm: string;
    let sendEmailError: string;
    let emailInfo: string;
    let sendEmailOk: string;

    //console.log(this.language );
    if (this.language === 'es' || this.language === 'es-ES' ) {
      incompleteForm = "Por favor rellene todos los campos con asterisco ( * ) !!!";
      sendEmailError = "Error al enviar el formulario, vuelva a probar en unos minutos !!!";
      emailInfo = "Introduzca un email valido !!!";
      sendEmailOk = "Formulario enviado correctamente !!!";
    } 
    else if (this.language === 'en' || this.language === 'en-US') {
      incompleteForm = "Please fill in all fields with an asterisk ( * ) !!!";
      sendEmailError = "Error submitting the form, please try again in a few minutes !!!";
      emailInfo = "Enter a valid email !!!";
      sendEmailOk = "Form sent successfully !!!";
    }

    console.log(this.form.value);

    if( this.form.value.first_name == null || this.form.value.last_name === null || this.form.value.passport === null || this.form.value.phone === null || 
        this.form.value.email === null || this.form.value.country === null || this.form.value.city === null || this.form.value.address === null || 
        this.form.value.gender === null || this.form.value.languages === null || this.form.value.procedure_doctor === null || this.form.value.dateBirth === null ) {
      
      
      Swal.fire({
        icon: 'info',
        title: 'Oops...',
        text: incompleteForm,
      })
      this.firstSave = true;
      return;
    }



    //console.log(answerForm);
    this.loadingContainer = true;
    this.disabledSEndButton = true;


    let patient_id: number;
    //Tomamos los datos almecenado del doctor
    if( localStorage.getItem('patientInfo')) {
      //Para pasar de string a array, proceso inverso
      let patientInfo ​= JSON.parse(localStorage.getItem('patientInfo'));

      //console.log(patientInfo); 
      patient_id = patientInfo.patient_id;

    }

    //console.log(patient_id);
    
    //Envia el formulario y abre el dialog
    //ModalAppointmentComponent

    /*
    const dialogRef = this.dialog.open(ModalAppointmentComponent, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      localStorage.removeItem("idDoctor");
      
    });*/


    if( !notification ) {
      this.sendFormProgressContainer = true;

      //console.log("Entre en notificar !!!!!");
    }
    else {
      this.dialogConfirmProgressContainer = true;
      //console.log("Entre en notificar !!!!!");
    }



    //console.log(this.form.value);
    //let procedure_type_id: any = this.form.value.procedure_type_id;

    
    //console.log(this.dynamicForm);
    //console.log(this.dynamicForm.value);
    

    this.formService.createBasicForm( this.form.value, this.heightWeightIMC, patient_id, this.dynamicForm2, this.moduleList, this.imagesGroup ).subscribe((resp:any) => {

      console.log(resp);
      this.sendFormProgressContainer = false;
      this.dialogConfirmProgressContainer = false;
      this.dialogConfirmContainer = false;
      //this.dialogConfirmContainer = true;

      if( !resp.Ok ) {

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: sendEmailError,
        })
      }
      else {

        //console.log(notification);

        if( notification ) {

          console.log(this.lastform.value);
          //console.log(procedure_type_id);
          this.formService.sendRequest( this.lastform.value, this.code_doctor, this.procedure_type_id ).subscribe((resp:any) => {
            console.log(resp);
            if( !resp.Ok ) {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: resp.Error,
              })
            }
          });
          
          this.formService.sendNotificationDoctor2( this.code_doctor, this.lastform.value.origin );
        }

        Swal.fire({
          icon: 'success',
          title: sendEmailOk,
          showConfirmButton: false,
          timer: 2500
        })
      }


    });


  }


}

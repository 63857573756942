import { Component, OnInit } from "@angular/core";
import { TranslateServices } from "../../services/translate.service";

@Component({
  selector: "app-about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.css"],
})
export class AboutComponent implements OnInit {
  constructor( public translate: TranslateServices ) {
    this.translate.translateText();
  }

  ngOnInit(): void {}
}

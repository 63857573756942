import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


@Injectable({
  providedIn: 'root'
})
export class CountrysService {
  lenguaje: string;

  constructor(private http: HttpClient,
    private translate: TranslateService) { 
    this.lenguaje = this.translate.getBrowserLang();
  }

  countriesEnglish: any[] = [
    {
    "id": "AF",
    "name": "Afghanistan"
    },
    {
    "id": "AX",
    "name": "Åland Islands"
    },
    {
    "id": "AL",
    "name": "Albania"
    },
    {
    "id": "DZ",
    "name": "Algeria"
    },
    {
    "id": "AS",
    "name": "American Samoa"
    },
    {
    "id": "AD",
    "name": "Andorra"
    },
    {
    "id": "AO",
    "name": "Angola"
    },
    {
    "id": "AI",
    "name": "Anguilla"
    },
    {
    "id": "AQ",
    "name": "Antarctica"
    },
    {
    "id": "AG",
    "name": "Antigua and Barbuda"
    },
    {
    "id": "AR",
    "name": "Argentina"
    },
    {
    "id": "AM",
    "name": "Armenia"
    },
    {
    "id": "AW",
    "name": "Aruba"
    },
    {
    "id": "AU",
    "name": "Australia"
    },
    {
    "id": "AT",
    "name": "Austria"
    },
    {
    "id": "AZ",
    "name": "Azerbaijan"
    },
    {
    "id": "BS",
    "name": "Bahamas"
    },
    {
    "id": "BH",
    "name": "Bahrain"
    },
    {
    "id": "BD",
    "name": "Bangladesh"
    },
    {
    "id": "BB",
    "name": "Barbados"
    },
    {
    "id": "BY",
    "name": "Belarus"
    },
    {
    "id": "BE",
    "name": "Belgium"
    },
    {
    "id": "BZ",
    "name": "Belize"
    },
    {
    "id": "BJ",
    "name": "Benin"
    },
    {
    "id": "BM",
    "name": "Bermuda"
    },
    {
    "id": "BT",
    "name": "Bhutan"
    },
    {
    "id": "BO",
    "name": "Bolivia (Plurinational State of)"
    },
    {
    "id": "BQ",
    "name": "Bonaire, Sint Eustatius and Saba"
    },
    {
    "id": "BA",
    "name": "Bosnia and Herzegovina"
    },
    {
    "id": "BW",
    "name": "Botswana"
    },
    {
    "id": "BV",
    "name": "Bouvet Island"
    },
    {
    "id": "BR",
    "name": "Brazil"
    },
    {
    "id": "IO",
    "name": "British Indian Ocean Territory"
    },
    {
    "id": "UM",
    "name": "United States Minor Outlying Islands"
    },
    {
    "id": "VG",
    "name": "Virgin Islands (British)"
    },
    {
    "id": "VI",
    "name": "Virgin Islands (U.S.)"
    },
    {
    "id": "BN",
    "name": "Brunei Darussalam"
    },
    {
    "id": "BG",
    "name": "Bulgaria"
    },
    {
    "id": "BF",
    "name": "Burkina Faso"
    },
    {
    "id": "BI",
    "name": "Burundi"
    },
    {
    "id": "KH",
    "name": "Cambodia"
    },
    {
    "id": "CM",
    "name": "Cameroon"
    },
    {
    "id": "CA",
    "name": "Canada"
    },
    {
    "id": "CV",
    "name": "Cabo Verde"
    },
    {
    "id": "KY",
    "name": "Cayman Islands"
    },
    {
    "id": "CF",
    "name": "Central African Republic"
    },
    {
    "id": "TD",
    "name": "Chad"
    },
    {
    "id": "CL",
    "name": "Chile"
    },
    {
    "id": "CN",
    "name": "China"
    },
    {
    "id": "CX",
    "name": "Christmas Island"
    },
    {
    "id": "CC",
    "name": "Cocos (Keeling) Islands"
    },
    {
    "id": "CO",
    "name": "Colombia"
    },
    {
    "id": "KM",
    "name": "Comoros"
    },
    {
    "id": "CG",
    "name": "Congo"
    },
    {
    "id": "CD",
    "name": "Congo (Democratic Republic of the)"
    },
    {
    "id": "CK",
    "name": "Cook Islands"
    },
    {
    "id": "CR",
    "name": "Costa Rica"
    },
    {
    "id": "HR",
    "name": "Croatia"
    },
    {
    "id": "CU",
    "name": "Cuba"
    },
    {
    "id": "CW",
    "name": "Curaçao"
    },
    {
    "id": "CY",
    "name": "Cyprus"
    },
    {
    "id": "CZ",
    "name": "Czech Republic"
    },
    {
    "id": "DK",
    "name": "Denmark"
    },
    {
    "id": "DJ",
    "name": "Djibouti"
    },
    {
    "id": "DM",
    "name": "Dominica"
    },
    {
    "id": "DO",
    "name": "Dominican Republic"
    },
    {
    "id": "EC",
    "name": "Ecuador"
    },
    {
    "id": "EG",
    "name": "Egypt"
    },
    {
    "id": "SV",
    "name": "El Salvador"
    },
    {
    "id": "GQ",
    "name": "Equatorial Guinea"
    },
    {
    "id": "ER",
    "name": "Eritrea"
    },
    {
    "id": "EE",
    "name": "Estonia"
    },
    {
    "id": "ET",
    "name": "Ethiopia"
    },
    {
    "id": "FK",
    "name": "Falkland Islands (Malvinas)"
    },
    {
    "id": "FO",
    "name": "Faroe Islands"
    },
    {
    "id": "FJ",
    "name": "Fiji"
    },
    {
    "id": "FI",
    "name": "Finland"
    },
    {
    "id": "FR",
    "name": "France"
    },
    {
    "id": "GF",
    "name": "French Guiana"
    },
    {
    "id": "PF",
    "name": "French Polynesia"
    },
    {
    "id": "TF",
    "name": "French Southern Territories"
    },
    {
    "id": "GA",
    "name": "Gabon"
    },
    {
    "id": "GM",
    "name": "Gambia"
    },
    {
    "id": "GE",
    "name": "Georgia"
    },
    {
    "id": "DE",
    "name": "Germany"
    },
    {
    "id": "GH",
    "name": "Ghana"
    },
    {
    "id": "GI",
    "name": "Gibraltar"
    },
    {
    "id": "GR",
    "name": "Greece"
    },
    {
    "id": "GL",
    "name": "Greenland"
    },
    {
    "id": "GD",
    "name": "Grenada"
    },
    {
    "id": "GP",
    "name": "Guadeloupe"
    },
    {
    "id": "GU",
    "name": "Guam"
    },
    {
    "id": "GT",
    "name": "Guatemala"
    },
    {
    "id": "GG",
    "name": "Guernsey"
    },
    {
    "id": "GN",
    "name": "Guinea"
    },
    {
    "id": "GW",
    "name": "Guinea-Bissau"
    },
    {
    "id": "GY",
    "name": "Guyana"
    },
    {
    "id": "HT",
    "name": "Haiti"
    },
    {
    "id": "HM",
    "name": "Heard Island and McDonald Islands"
    },
    {
    "id": "VA",
    "name": "Holy See"
    },
    {
    "id": "HN",
    "name": "Honduras"
    },
    {
    "id": "HK",
    "name": "Hong Kong"
    },
    {
    "id": "HU",
    "name": "Hungary"
    },
    {
    "id": "IS",
    "name": "Iceland"
    },
    {
    "id": "IN",
    "name": "India"
    },
    {
    "id": "ID",
    "name": "Indonesia"
    },
    {
    "id": "CI",
    "name": "Côte d'Ivoire"
    },
    {
    "id": "IR",
    "name": "Iran (Islamic Republic of)"
    },
    {
    "id": "IQ",
    "name": "Iraq"
    },
    {
    "id": "IE",
    "name": "Ireland"
    },
    {
    "id": "IM",
    "name": "Isle of Man"
    },
    {
    "id": "IL",
    "name": "Israel"
    },
    {
    "id": "IT",
    "name": "Italy"
    },
    {
    "id": "JM",
    "name": "Jamaica"
    },
    {
    "id": "JP",
    "name": "Japan"
    },
    {
    "id": "JE",
    "name": "Jersey"
    },
    {
    "id": "JO",
    "name": "Jordan"
    },
    {
    "id": "KZ",
    "name": "Kazakhstan"
    },
    {
    "id": "KE",
    "name": "Kenya"
    },
    {
    "id": "KI",
    "name": "Kiribati"
    },
    {
    "id": "KW",
    "name": "Kuwait"
    },
    {
    "id": "KG",
    "name": "Kyrgyzstan"
    },
    {
    "id": "LA",
    "name": "Lao People's Democratic Republic"
    },
    {
    "id": "LV",
    "name": "Latvia"
    },
    {
    "id": "LB",
    "name": "Lebanon"
    },
    {
    "id": "LS",
    "name": "Lesotho"
    },
    {
    "id": "LR",
    "name": "Liberia"
    },
    {
    "id": "LY",
    "name": "Libya"
    },
    {
    "id": "LI",
    "name": "Liechtenstein"
    },
    {
    "id": "LT",
    "name": "Lithuania"
    },
    {
    "id": "LU",
    "name": "Luxembourg"
    },
    {
    "id": "MO",
    "name": "Macao"
    },
    {
    "id": "MK",
    "name": "Macedonia (the former Yugoslav Republic of)"
    },
    {
    "id": "MG",
    "name": "Madagascar"
    },
    {
    "id": "MW",
    "name": "Malawi"
    },
    {
    "id": "MY",
    "name": "Malaysia"
    },
    {
    "id": "MV",
    "name": "Maldives"
    },
    {
    "id": "ML",
    "name": "Mali"
    },
    {
    "id": "MT",
    "name": "Malta"
    },
    {
    "id": "MH",
    "name": "Marshall Islands"
    },
    {
    "id": "MQ",
    "name": "Martinique"
    },
    {
    "id": "MR",
    "name": "Mauritania"
    },
    {
    "id": "MU",
    "name": "Mauritius"
    },
    {
    "id": "YT",
    "name": "Mayotte"
    },
    {
    "id": "MX",
    "name": "Mexico"
    },
    {
    "id": "FM",
    "name": "Micronesia (Federated States of)"
    },
    {
    "id": "MD",
    "name": "Moldova (Republic of)"
    },
    {
    "id": "MC",
    "name": "Monaco"
    },
    {
    "id": "MN",
    "name": "Mongolia"
    },
    {
    "id": "ME",
    "name": "Montenegro"
    },
    {
    "id": "MS",
    "name": "Montserrat"
    },
    {
    "id": "MA",
    "name": "Morocco"
    },
    {
    "id": "MZ",
    "name": "Mozambique"
    },
    {
    "id": "MM",
    "name": "Myanmar"
    },
    {
    "id": "NA",
    "name": "Namibia"
    },
    {
    "id": "NR",
    "name": "Nauru"
    },
    {
    "id": "NP",
    "name": "Nepal"
    },
    {
    "id": "NL",
    "name": "Netherlands"
    },
    {
    "id": "NC",
    "name": "New Caledonia"
    },
    {
    "id": "NZ",
    "name": "New Zealand"
    },
    {
    "id": "NI",
    "name": "Nicaragua"
    },
    {
    "id": "NE",
    "name": "Niger"
    },
    {
    "id": "NG",
    "name": "Nigeria"
    },
    {
    "id": "NU",
    "name": "Niue"
    },
    {
    "id": "NF",
    "name": "Norfolk Island"
    },
    {
    "id": "KP",
    "name": "Korea (Democratic People's Republic of)"
    },
    {
    "id": "MP",
    "name": "Northern Mariana Islands"
    },
    {
    "id": "NO",
    "name": "Norway"
    },
    {
    "id": "OM",
    "name": "Oman"
    },
    {
    "id": "PK",
    "name": "Pakistan"
    },
    {
    "id": "PW",
    "name": "Palau"
    },
    {
    "id": "PS",
    "name": "Palestine, State of"
    },
    {
    "id": "PA",
    "name": "Panama"
    },
    {
    "id": "PG",
    "name": "Papua New Guinea"
    },
    {
    "id": "PY",
    "name": "Paraguay"
    },
    {
    "id": "PE",
    "name": "Peru"
    },
    {
    "id": "PH",
    "name": "Philippines"
    },
    {
    "id": "PN",
    "name": "Pitcairn"
    },
    {
    "id": "PL",
    "name": "Poland"
    },
    {
    "id": "PT",
    "name": "Portugal"
    },
    {
    "id": "PR",
    "name": "Puerto Rico"
    },
    {
    "id": "QA",
    "name": "Qatar"
    },
    {
    "id": "XK",
    "name": "Republic of Kosovo"
    },
    {
    "id": "RE",
    "name": "Réunion"
    },
    {
    "id": "RO",
    "name": "Romania"
    },
    {
    "id": "RU",
    "name": "Russian Federation"
    },
    {
    "id": "RW",
    "name": "Rwanda"
    },
    {
    "id": "BL",
    "name": "Saint Barthélemy"
    },
    {
    "id": "SH",
    "name": "Saint Helena, Ascension and Tristan da Cunha"
    },
    {
    "id": "KN",
    "name": "Saint Kitts and Nevis"
    },
    {
    "id": "LC",
    "name": "Saint Lucia"
    },
    {
    "id": "MF",
    "name": "Saint Martin (French part)"
    },
    {
    "id": "PM",
    "name": "Saint Pierre and Miquelon"
    },
    {
    "id": "VC",
    "name": "Saint Vincent and the Grenadines"
    },
    {
    "id": "WS",
    "name": "Samoa"
    },
    {
    "id": "SM",
    "name": "San Marino"
    },
    {
    "id": "ST",
    "name": "Sao Tome and Principe"
    },
    {
    "id": "SA",
    "name": "Saudi Arabia"
    },
    {
    "id": "SN",
    "name": "Senegal"
    },
    {
    "id": "RS",
    "name": "Serbia"
    },
    {
    "id": "SC",
    "name": "Seychelles"
    },
    {
    "id": "SL",
    "name": "Sierra Leone"
    },
    {
    "id": "SG",
    "name": "Singapore"
    },
    {
    "id": "SX",
    "name": "Sint Maarten (Dutch part)"
    },
    {
    "id": "SK",
    "name": "Slovakia"
    },
    {
    "id": "SI",
    "name": "Slovenia"
    },
    {
    "id": "SB",
    "name": "Solomon Islands"
    },
    {
    "id": "SO",
    "name": "Somalia"
    },
    {
    "id": "ZA",
    "name": "South Africa"
    },
    {
    "id": "GS",
    "name": "South Georgia and the South Sandwich Islands"
    },
    {
    "id": "KR",
    "name": "Korea (Republic of)"
    },
    {
    "id": "SS",
    "name": "South Sudan"
    },
    {
    "id": "ES",
    "name": "Spain"
    },
    {
    "id": "LK",
    "name": "Sri Lanka"
    },
    {
    "id": "SD",
    "name": "Sudan"
    },
    {
    "id": "SR",
    "name": "Suriname"
    },
    {
    "id": "SJ",
    "name": "Svalbard and Jan Mayen"
    },
    {
    "id": "SZ",
    "name": "Swaziland"
    },
    {
    "id": "SE",
    "name": "Sweden"
    },
    {
    "id": "CH",
    "name": "Switzerland"
    },
    {
    "id": "SY",
    "name": "Syrian Arab Republic"
    },
    {
    "id": "TW",
    "name": "Taiwan"
    },
    {
    "id": "TJ",
    "name": "Tajikistan"
    },
    {
    "id": "TZ",
    "name": "Tanzania, United Republic of"
    },
    {
    "id": "TH",
    "name": "Thailand"
    },
    {
    "id": "TL",
    "name": "Timor-Leste"
    },
    {
    "id": "TG",
    "name": "Togo"
    },
    {
    "id": "TK",
    "name": "Tokelau"
    },
    {
    "id": "TO",
    "name": "Tonga"
    },
    {
    "id": "TT",
    "name": "Trinidad and Tobago"
    },
    {
    "id": "TN",
    "name": "Tunisia"
    },
    {
    "id": "TR",
    "name": "Turkey"
    },
    {
    "id": "TM",
    "name": "Turkmenistan"
    },
    {
    "id": "TC",
    "name": "Turks and Caicos Islands"
    },
    {
    "id": "TV",
    "name": "Tuvalu"
    },
    {
    "id": "UG",
    "name": "Uganda"
    },
    {
    "id": "UA",
    "name": "Ukraine"
    },
    {
    "id": "AE",
    "name": "United Arab Emirates"
    },
    {
    "id": "GB",
    "name": "United Kingdom of Great Britain and Northern Ireland"
    },
    {
    "id": "US",
    "name": "United States of America"
    },
    {
    "id": "UY",
    "name": "Uruguay"
    },
    {
    "id": "UZ",
    "name": "Uzbekistan"
    },
    {
    "id": "VU",
    "name": "Vanuatu"
    },
    {
    "id": "VE",
    "name": "Venezuela (Bolivarian Republic of)"
    },
    {
    "id": "VN",
    "name": "Viet Nam"
    },
    {
    "id": "WF",
    "name": "Wallis and Futuna"
    },
    {
    "id": "EH",
    "name": "Western Sahara"
    },
    {
    "id": "YE",
    "name": "Yemen"
    },
    {
    "id": "ZM",
    "name": "Zambia"
    },
    {
    "id": "ZW",
    "name": "Zimbabwe"
    }
    ]

    countriesSpanish: any[] = [
      {
      "id": "AF",
      "name": "Afganistán"
      },
      {
      "id": "AX",
      "name": "Alandia"
      },
      {
      "id": "AL",
      "name": "Albania"
      },
      {
      "id": "DE",
      "name": "Alemania"
      },
      {
      "id": "AD",
      "name": "Andorra"
      },
      {
      "id": "AO",
      "name": "Angola"
      },
      {
      "id": "AI",
      "name": "Anguilla"
      },
      {
      "id": "AG",
      "name": "Antigua y Barbuda"
      },
      {
      "id": "AQ",
      "name": "Antártida"
      },
      {
      "id": "SA",
      "name": "Arabia Saudí"
      },
      {
      "id": "DZ",
      "name": "Argelia"
      },
      {
      "id": "AR",
      "name": "Argentina"
      },
      {
      "id": "AM",
      "name": "Armenia"
      },
      {
      "id": "AW",
      "name": "Aruba"
      },
      {
      "id": "AU",
      "name": "Australia"
      },
      {
      "id": "AT",
      "name": "Austria"
      },
      {
      "id": "AZ",
      "name": "Azerbaiyán"
      },
      {
      "id": "BS",
      "name": "Bahamas"
      },
      {
      "id": "BH",
      "name": "Bahrein"
      },
      {
      "id": "BD",
      "name": "Bangladesh"
      },
      {
      "id": "BB",
      "name": "Barbados"
      },
      {
      "id": "BZ",
      "name": "Belice"
      },
      {
      "id": "BJ",
      "name": "Benín"
      },
      {
      "id": "BM",
      "name": "Bermudas"
      },
      {
      "id": "BY",
      "name": "Bielorrusia"
      },
      {
      "id": "BO",
      "name": "Bolivia"
      },
      {
      "id": "BN",
      "name": "Brunei"
      },
      {
      "id": "BG",
      "name": "Bulgaria"
      },
      {
      "id": "BF",
      "name": "Burkina Faso"
      },
      {
      "id": "BI",
      "name": "Burundi"
      },
      {
      "id": "BT",
      "name": "Bután"
      },
      {
      "id": "BE",
      "name": "Bélgica"
      },
      {
      "id": "CV",
      "name": "Cabo Verde"
      },
      {
      "id": "KH",
      "name": "Camboya"
      },
      {
      "id": "CM",
      "name": "Camerún"
      },
      {
      "id": "CA",
      "name": "Canadá"
      },
      {
      "id": "TD",
      "name": "Chad"
      },
      {
      "id": "CL",
      "name": "Chile"
      },
      {
      "id": "CN",
      "name": "China"
      },
      {
      "id": "CO",
      "name": "Colombia"
      },
      {
      "id": "KM",
      "name": "Comoras"
      },
      {
      "id": "CG",
      "name": "Congo"
      },
      {
      "id": "CD",
      "name": "Congo (Rep. Dem.)"
      },
      {
      "id": "KR",
      "name": "Corea del Sur"
      },
      {
      "id": "CR",
      "name": "Costa Rica"
      },
      {
      "id": "HR",
      "name": "Croacia"
      },
      {
      "id": "CU",
      "name": "Cuba"
      },
      {
      "id": "DK",
      "name": "Dinamarca"
      },
      {
      "id": "DM",
      "name": "Dominica"
      },
      {
      "id": "EC",
      "name": "Ecuador"
      },
      {
      "id": "CX",
      "name": "Isla de Navidad"
      },
      {
      "id": "CC",
      "name": "Islas Cocos o Islas Keeling"
      },
      {
      "id": "VI",
      "name": "Islas Vírgenes de los Estados Unidos"
      },
      {
      "id": "VG",
      "name": "Islas Vírgenes del Reino Unido"
      },
      {
      "id": "AS",
      "name": "Samoa Americana"
      },
      {
      "id": "BQ",
      "name": null
      },
      {
      "id": "BA",
      "name": "Bosnia y Herzegovina"
      },
      {
      "id": "BW",
      "name": "Botswana"
      },
      {
      "id": "BR",
      "name": "Brasil"
      },
      {
      "id": "AE",
      "name": "Emiratos Árabes Unidos"
      },
      {
      "id": "SI",
      "name": "Eslovenia"
      },
      {
      "id": "ES",
      "name": "España"
      },
      {
      "id": "US",
      "name": "Estados Unidos"
      },
      {
      "id": "BV",
      "name": "Isla Bouvet"
      },
      {
      "id": "KY",
      "name": "Islas Caimán"
      },
      {
      "id": "CK",
      "name": "Islas Cook"
      },
      {
      "id": "GS",
      "name": "Islas Georgias del Sur y Sandwich del Sur"
      },
      {
      "id": "SB",
      "name": "Islas Salomón"
      },
      {
      "id": "UM",
      "name": "Islas Ultramarinas Menores de Estados Unidos"
      },
      {
      "id": "CF",
      "name": "República Centroafricana"
      },
      {
      "id": "SN",
      "name": "Senegal"
      },
      {
      "id": "IO",
      "name": "Territorio Británico del Océano Índico"
      },
      {
      "id": "CW",
      "name": null
      },
      {
      "id": "QA",
      "name": "Catar"
      },
      {
      "id": "CY",
      "name": "Chipre"
      },
      {
      "id": "KP",
      "name": "Corea del Norte"
      },
      {
      "id": "CI",
      "name": "Costa de Marfil"
      },
      {
      "id": "EG",
      "name": "Egipto"
      },
      {
      "id": "SV",
      "name": "El Salvador"
      },
      {
      "id": "ER",
      "name": "Eritrea"
      },
      {
      "id": "EE",
      "name": "Estonia"
      },
      {
      "id": "ET",
      "name": "Etiopía"
      },
      {
      "id": "PH",
      "name": "Filipinas"
      },
      {
      "id": "FI",
      "name": "Finlandia"
      },
      {
      "id": "FJ",
      "name": "Fiyi"
      },
      {
      "id": "FR",
      "name": "Francia"
      },
      {
      "id": "GA",
      "name": "Gabón"
      },
      {
      "id": "GM",
      "name": "Gambia"
      },
      {
      "id": "GE",
      "name": "Georgia"
      },
      {
      "id": "GH",
      "name": "Ghana"
      },
      {
      "id": "GI",
      "name": "Gibraltar"
      },
      {
      "id": "GR",
      "name": "Grecia"
      },
      {
      "id": "GD",
      "name": "Grenada"
      },
      {
      "id": "GL",
      "name": "Groenlandia"
      },
      {
      "id": "GP",
      "name": "Guadalupe"
      },
      {
      "id": "GU",
      "name": "Guam"
      },
      {
      "id": "GT",
      "name": "Guatemala"
      },
      {
      "id": "GF",
      "name": "Guayana Francesa"
      },
      {
      "id": "GG",
      "name": "Guernsey"
      },
      {
      "id": "GN",
      "name": "Guinea"
      },
      {
      "id": "GQ",
      "name": "Guinea Ecuatorial"
      },
      {
      "id": "GW",
      "name": "Guinea-Bisáu"
      },
      {
      "id": "GY",
      "name": "Guyana"
      },
      {
      "id": "HT",
      "name": "Haiti"
      },
      {
      "id": "HN",
      "name": "Honduras"
      },
      {
      "id": "HK",
      "name": "Hong Kong"
      },
      {
      "id": "HU",
      "name": "Hungría"
      },
      {
      "id": "IN",
      "name": "India"
      },
      {
      "id": "ID",
      "name": "Indonesia"
      },
      {
      "id": "IQ",
      "name": "Irak"
      },
      {
      "id": "IR",
      "name": "Iran"
      },
      {
      "id": "IE",
      "name": "Irlanda"
      },
      {
      "id": "IM",
      "name": "Isla de Man"
      },
      {
      "id": "NF",
      "name": "Isla de Norfolk"
      },
      {
      "id": "IS",
      "name": "Islandia"
      },
      {
      "id": "FO",
      "name": "Islas Faroe"
      },
      {
      "id": "HM",
      "name": "Islas Heard y McDonald"
      },
      {
      "id": "FK",
      "name": "Islas Malvinas"
      },
      {
      "id": "MP",
      "name": "Islas Marianas del Norte"
      },
      {
      "id": "MH",
      "name": "Islas Marshall"
      },
      {
      "id": "PN",
      "name": "Islas Pitcairn"
      },
      {
      "id": "SJ",
      "name": "Islas Svalbard y Jan Mayen"
      },
      {
      "id": "TK",
      "name": "Islas Tokelau"
      },
      {
      "id": "TC",
      "name": "Islas Turks y Caicos"
      },
      {
      "id": "IL",
      "name": "Israel"
      },
      {
      "id": "IT",
      "name": "Italia"
      },
      {
      "id": "JM",
      "name": "Jamaica"
      },
      {
      "id": "JP",
      "name": "Japón"
      },
      {
      "id": "JE",
      "name": "Jersey"
      },
      {
      "id": "JO",
      "name": "Jordania"
      },
      {
      "id": "KZ",
      "name": "Kazajistán"
      },
      {
      "id": "KE",
      "name": "Kenia"
      },
      {
      "id": "KG",
      "name": "Kirguizistán"
      },
      {
      "id": "KI",
      "name": "Kiribati"
      },
      {
      "id": "XK",
      "name": "Kosovo"
      },
      {
      "id": "KW",
      "name": "Kuwait"
      },
      {
      "id": "LA",
      "name": "Laos"
      },
      {
      "id": "LS",
      "name": "Lesotho"
      },
      {
      "id": "LV",
      "name": "Letonia"
      },
      {
      "id": "LR",
      "name": "Liberia"
      },
      {
      "id": "LY",
      "name": "Libia"
      },
      {
      "id": "LI",
      "name": "Liechtenstein"
      },
      {
      "id": "LT",
      "name": "Lituania"
      },
      {
      "id": "LU",
      "name": "Luxemburgo"
      },
      {
      "id": "LB",
      "name": "Líbano"
      },
      {
      "id": "MO",
      "name": "Macao"
      },
      {
      "id": "MK",
      "name": "Macedonia"
      },
      {
      "id": "MG",
      "name": "Madagascar"
      },
      {
      "id": "MY",
      "name": "Malasia"
      },
      {
      "id": "MW",
      "name": "Malawi"
      },
      {
      "id": "MV",
      "name": "Maldivas"
      },
      {
      "id": "ML",
      "name": "Mali"
      },
      {
      "id": "MT",
      "name": "Malta"
      },
      {
      "id": "MA",
      "name": "Marruecos"
      },
      {
      "id": "MQ",
      "name": "Martinica"
      },
      {
      "id": "MU",
      "name": "Mauricio"
      },
      {
      "id": "MR",
      "name": "Mauritania"
      },
      {
      "id": "YT",
      "name": "Mayotte"
      },
      {
      "id": "FM",
      "name": "Micronesia"
      },
      {
      "id": "MD",
      "name": "Moldavia"
      },
      {
      "id": "MN",
      "name": "Mongolia"
      },
      {
      "id": "ME",
      "name": "Montenegro"
      },
      {
      "id": "MS",
      "name": "Montserrat"
      },
      {
      "id": "MZ",
      "name": "Mozambique"
      },
      {
      "id": "MM",
      "name": "Myanmar"
      },
      {
      "id": "MX",
      "name": "México"
      },
      {
      "id": "MC",
      "name": "Mónaco"
      },
      {
      "id": "NA",
      "name": "Namibia"
      },
      {
      "id": "NR",
      "name": "Nauru"
      },
      {
      "id": "NP",
      "name": "Nepal"
      },
      {
      "id": "NI",
      "name": "Nicaragua"
      },
      {
      "id": "NG",
      "name": "Nigeria"
      },
      {
      "id": "NU",
      "name": "Niue"
      },
      {
      "id": "NO",
      "name": "Noruega"
      },
      {
      "id": "NC",
      "name": "Nueva Caledonia"
      },
      {
      "id": "NZ",
      "name": "Nueva Zelanda"
      },
      {
      "id": "NE",
      "name": "Níger"
      },
      {
      "id": "OM",
      "name": "Omán"
      },
      {
      "id": "PK",
      "name": "Pakistán"
      },
      {
      "id": "PW",
      "name": "Palau"
      },
      {
      "id": "PS",
      "name": "Palestina"
      },
      {
      "id": "PA",
      "name": "Panamá"
      },
      {
      "id": "PG",
      "name": "Papúa Nueva Guinea"
      },
      {
      "id": "PY",
      "name": "Paraguay"
      },
      {
      "id": "NL",
      "name": "Países Bajos"
      },
      {
      "id": "PE",
      "name": "Perú"
      },
      {
      "id": "PF",
      "name": "Polinesia Francesa"
      },
      {
      "id": "PL",
      "name": "Polonia"
      },
      {
      "id": "PT",
      "name": "Portugal"
      },
      {
      "id": "PR",
      "name": "Puerto Rico"
      },
      {
      "id": "GB",
      "name": "Reino Unido"
      },
      {
      "id": "CZ",
      "name": "República Checa"
      },
      {
      "id": "DO",
      "name": "República Dominicana"
      },
      {
      "id": "ZA",
      "name": "República de Sudáfrica"
      },
      {
      "id": "RE",
      "name": "Reunión"
      },
      {
      "id": "RW",
      "name": "Ruanda"
      },
      {
      "id": "RO",
      "name": "Rumania"
      },
      {
      "id": "RU",
      "name": "Rusia"
      },
      {
      "id": "EH",
      "name": "Sahara Occidental"
      },
      {
      "id": "MF",
      "name": "Saint Martin"
      },
      {
      "id": "WS",
      "name": "Samoa"
      },
      {
      "id": "BL",
      "name": "San Bartolomé"
      },
      {
      "id": "KN",
      "name": "San Cristóbal y Nieves"
      },
      {
      "id": "SM",
      "name": "San Marino"
      },
      {
      "id": "PM",
      "name": "San Pedro y Miquelón"
      },
      {
      "id": "VC",
      "name": "San Vicente y Granadinas"
      },
      {
      "id": "SH",
      "name": "Santa Helena"
      },
      {
      "id": "LC",
      "name": "Santa Lucía"
      },
      {
      "id": "VA",
      "name": "Santa Sede"
      },
      {
      "id": "ST",
      "name": "Santo Tomé y Príncipe"
      },
      {
      "id": "RS",
      "name": "Serbia"
      },
      {
      "id": "SC",
      "name": "Seychelles"
      },
      {
      "id": "SL",
      "name": "Sierra Leone"
      },
      {
      "id": "SG",
      "name": "Singapur"
      },
      {
      "id": "SY",
      "name": "Siria"
      },
      {
      "id": "LK",
      "name": "Sri Lanka"
      },
      {
      "id": "SD",
      "name": "Sudán"
      },
      {
      "id": "TH",
      "name": "Tailandia"
      },
      {
      "id": "TZ",
      "name": "Tanzania"
      },
      {
      "id": "TF",
      "name": "Tierras Australes y Antárticas Francesas"
      },
      {
      "id": "DJ",
      "name": "Yibuti"
      },
      {
      "id": "SX",
      "name": null
      },
      {
      "id": "SK",
      "name": "República Eslovaca"
      },
      {
      "id": "SO",
      "name": "Somalia"
      },
      {
      "id": "SZ",
      "name": "Suazilandia"
      },
      {
      "id": "SS",
      "name": "Sudán del Sur"
      },
      {
      "id": "SE",
      "name": "Suecia"
      },
      {
      "id": "CH",
      "name": "Suiza"
      },
      {
      "id": "SR",
      "name": "Surinam"
      },
      {
      "id": "TW",
      "name": "Taiwán"
      },
      {
      "id": "TJ",
      "name": "Tayikistán"
      },
      {
      "id": "TL",
      "name": "Timor Oriental"
      },
      {
      "id": "TG",
      "name": "Togo"
      },
      {
      "id": "TO",
      "name": "Tonga"
      },
      {
      "id": "TT",
      "name": "Trinidad y Tobago"
      },
      {
      "id": "TM",
      "name": "Turkmenistán"
      },
      {
      "id": "TR",
      "name": "Turquía"
      },
      {
      "id": "TV",
      "name": "Tuvalu"
      },
      {
      "id": "TN",
      "name": "Túnez"
      },
      {
      "id": "UA",
      "name": "Ucrania"
      },
      {
      "id": "UG",
      "name": "Uganda"
      },
      {
      "id": "UY",
      "name": "Uruguay"
      },
      {
      "id": "UZ",
      "name": "Uzbekistán"
      },
      {
      "id": "VU",
      "name": "Vanuatu"
      },
      {
      "id": "VE",
      "name": "Venezuela"
      },
      {
      "id": "VN",
      "name": "Vietnam"
      },
      {
      "id": "WF",
      "name": "Wallis y Futuna"
      },
      {
      "id": "YE",
      "name": "Yemen"
      },
      {
      "id": "ZM",
      "name": "Zambia"
      },
      {
      "id": "ZW",
      "name": "Zimbabue"
      }
      ]


      sendCoubntries() {
        if (this.lenguaje === 'es' || this.lenguaje === 'es-ES' ) {
   
          return this.countriesSpanish
        } else if (this.lenguaje === 'en' || this.lenguaje === 'en-US') {
          return this.countriesEnglish
        }
      }

}

<div class="container" id="main-Container">
  <div class="row mat-card">
    <div class="col">
      <h2>{{ "CONTACT.title" | translate }}</h2>
      <div id="contact">
        <form [formGroup]="form" (ngSubmit)="sendForm()">
          <div class="form-group row">
            <div class="col">
              <div class="input-text">
                <label>{{ "CONTACT.name" | translate }}</label>
                <small *ngIf="nameNoValid" class="text-danger">{{
                  "CONTACT.required" | translate
                }}</small>
              </div>
              <input
                class="form-control"
                type="text"
                formControlName="name"
                [class.is-invalid]="nameNoValid"
              />
            </div>
          </div>
          <div class="form-group row">
            <div class="col">
              <div class="input-text">
                <label>{{ "CONTACT.lastname" | translate }}</label>
                <small *ngIf="lastnameNoValid" class="text-danger">{{
                  "CONTACT.required" | translate
                }}</small>
              </div>
              <input
                class="form-control"
                type="text"
                formControlName="lastname"
                [class.is-invalid]="lastnameNoValid"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="input-text">
              <label>{{ "CONTACT.email" | translate }}</label>
              <small *ngIf="emailNoValid" class="text-danger">{{
                "CONTACT.required" | translate
              }}</small>
            </div>
            <input
              type="email"
              class="form-control"
              formControlName="email"
              [class.is-invalid]="emailNoValid"
            />
          </div>
          <div class="form-group">
            <div class="input-text">
              <label>{{ "CONTACT.textareatitle" | translate }}</label>
              <small *ngIf="optionNoValid" class="text-danger">{{
                "CONTACT.required" | translate
              }}</small>
            </div>
            <select
              class="form-control"
              formControlName="option"
              [class.is-invalid]="optionNoValid"
            >
              <option>[ {{ "CONTACT.SELECT.choose" | translate }} ]</option>
              <option>{{ "CONTACT.SELECT.information" | translate }}</option>
              <option>{{ "CONTACT.SELECT.support" | translate }}</option>
              <option>{{ "CONTACT.SELECT.assistance" | translate }}</option>
              <option>{{ "CONTACT.SELECT.contact" | translate }}</option>
            </select>
          </div>
          <div class="form-group">
            <textarea
              class="form-control"
              formControlName="textarea"
              placeholder="{{ 'CONTACT.textarea' | translate }}"
              rows="3"
              [class.is-invalid]="textareaNoValid"
            ></textarea>
          </div>
          <button type="submit" class="btn btn-success btn-block">
            {{ "CONTACT.send" | translate }}
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

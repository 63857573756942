import { Component, OnInit } from "@angular/core";

import { TranslateServices } from "../../services/translate.service";

// Importaciones para manipulacion del formulario
import {
  FormGroup,
  FormBuilder,
  Validators,
  NgForm,
  FormControl,
} from "@angular/forms";

// Nos permite movernos entre vistas
import { Router } from "@angular/router";
import { UserModel } from "../../models/user.model";
import { AuthService } from "../../services/auth.service";

// Ventana de alerta
import Swal from "sweetalert2";
import { FormValidatorsService } from "../../services/form-validators.service";
import { AppRoutingModule } from "src/app/app-routing.module";
import { TermsConditionsComponent } from "../terms-conditions/terms-conditions.component";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { SettingsService } from "../../services/settings.service";
import { threadId } from "worker_threads";

@Component({
  selector: "app-create-account",
  templateUrl: "./create-account.component.html",
  styleUrls: ["./create-account.component.css"],
})
export class CreateAccountComponent implements OnInit {
  form: FormGroup;
  newUser: UserModel;
  nameUser: string;
  lang: string;
  // lang = new FormControl();
  langList: any[] = [];

  constructor(
    public translate: TranslateServices,
    private translat: TranslateService,
    private fb: FormBuilder,
    private route: Router,
    private _settingService: SettingsService,
    public dialog: MatDialog,
    private auth: AuthService,
    private formValidators: FormValidatorsService
  ) {
    this.translate.translateText();

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (p) {
          const latitude = p.coords.latitude;
          const longitude = p.coords.longitude;
          console.log(latitude);
          console.log(longitude);
        },
        function (e) {
          // ipLookup();
        }
      );
    }

    this.createForm();
    this.newUser = new UserModel();
  }

  ngOnInit(): void {
    this.lenguage();
    this.lang = navigator.language;
  }

  lenguage() {
    this._settingService.getLanguage().subscribe((Response: any) => {
      this.langList = Response.Languages;
    });
  }

  // this.forma.get('nombre').invalid : Comprueba si todas las validaciones son correctas, las validaciones de this.fb.group
  // this.forma.get('nombre').touched : Comprueba si el campo a sido tocado y perdido el focus
  get nameNoValid() {
    return (
      this.form.get("first_name").invalid && this.form.get("first_name").touched
    );
  }

  get lastnameNoValid() {
    return (
      this.form.get("last_name").invalid && this.form.get("last_name").touched
    );
  }

  get emailNoValid() {
    return this.form.get("email").invalid && this.form.get("email").touched;
  }
  get langNoValid() {
    return this.form.get("lang").invalid && this.form.get("lang").touched;
  }

  get genderNoValid() {
    return this.form.get("gender").invalid && this.form.get("gender").touched;
  }

  get password1NoValid() {
    return (
      this.form.get("password1").invalid && this.form.get("password1").touched
    );
  }

  get password2NoValid() {
    const pass1 = this.form.get("password1").value;
    const pass2 = this.form.get("password2").value;

    return pass1 === pass2 ? false : true;
    // return this.form.get('password2').invalid && this.form.get('password2').touched;
  }

  get privacityNoValid() {
    return (
      this.form.get("privacity").invalid && this.form.get("privacity").touched
    );
  }

  openDialog() {
    const dialogRef = this.dialog.open(TermsConditionsComponent);
    dialogRef.afterClosed().subscribe((result) => {

    });
  }
  // Creamos una funcion con todos los parametros y validaciones que deberan tener el formulario antes de que se muestre el html
  // por lo que inicializaremos esta funcion en el constructor
  createForm() {
    // Le agregamos a la referencia del formulario el FormBuild para crear las
    // validaciones y valores por defecto
    this.form = this.fb.group(
      {
        first_name: ["", [Validators.required]],
        last_name: ["", [Validators.required]],
        email: ["", [Validators.required, Validators.email]],
        password1: ["", [Validators.required, Validators.minLength(6)]],
        password2: ["", [Validators.required, Validators.minLength(6)]],
        gender: ["", [Validators.required]],
        privacity: [false, [Validators.required]],
        lang: ["", Validators.required],
      },
      {
        // Validamos despues de que el formulario este creado para evitar error los
        // pass1 y pass2
        validators: this.formValidators.equalPasswords(
          "password1",
          "password2"
        ),
      }
    );
  }

  sendForm() {
    // console.log(this.lang.value)
    // Verificamos si los campos son validos y cambiamos el valor a false en todos los campos que no lo sea
    if (this.form.invalid || this.form.value.privacity == false) {
      // Nos permite que si los valores no estan completos los marca como no validos
      Object.values(this.form.controls).forEach((resp) => {
        // Todos los valores han sido tocados
        resp.markAllAsTouched();
      });
      if (this.lang === "es-ES" || this.lang === "es") {
        Swal.fire({
          //icono que mostrara
          icon: "error",
          //Titulo
          title: "Política de privacidad",
          //Texto que se mostrara
          text: "Para la creación de la cuenta, debe aceptar las políticas de privacidad",
        });
      } else if (this.lang === "en-US" || this.lang === "en") {
        Swal.fire({
          icon: "error",
          title: "Privacy Policy",
          text: "For the creation of the account, you must accept the privacy policies",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Privacy Policy",
          text: "For the creation of the account, you must accept the privacy policies",
        });
      }
    
    return;
      // Salimos si el formulario no es valido
    
    } else if (this.form.valid) {
      let idLang = [];
      for (const iterator of this.form.value.lang) {
        idLang.push({ id: iterator });
      }
      this.newUser = new UserModel();
      this.newUser.first_name = this.form.value.first_name;
      this.newUser.last_name = this.form.value.last_name;
      this.newUser.email = this.form.value.email;
      this.newUser.password = this.form.value.password2;
      this.newUser.gender = this.form.value.gender;
      this.newUser.languages = idLang;
      this.newUser.primaryLang = this.translat.getBrowserLang();

      if (this.lang === "es-ES" || this.lang === "es") {
        Swal.fire({
          icon: "info",
          allowOutsideClick: false,
          text: "Espere por favor ...",
        });
      } else if (this.lang === "en-US" || this.lang === "en") {
        Swal.fire({
          icon: "info",
          allowOutsideClick: false,
          text: "Please wait ...",
        });
      } else {
        Swal.fire({
          icon: "info",
          allowOutsideClick: false,
          text: "Please wait ...",
        });
      }

      // Simulation de loading
      Swal.showLoading();

      // LLamamos la funcion encargada de la creacion de la nueva cuenta
      // enviamos los datos al servidor
      this.auth.createAccount(this.newUser).subscribe((resp: any) => {
        Swal.close();
        if (resp.OK === true) {
          if (this.lang === "es-ES" || this.lang === "es") {
            Swal.fire({
              icon: "success",
              title: "Cuenta creada correctamente",
              text:
                this.newUser.first_name +
                " " +
                this.newUser.last_name +
                ", le hemos enviado un mensaje de verificacion a su correo.",
            });
          } else if (this.lang === "en-US" || this.lang === "en") {
            Swal.fire({
              icon: "success",
              title: "Account created successfully",
              text:
                this.newUser.first_name +
                " " +
                this.newUser.last_name +
                ", We have sent you a verification message to your email.",
            });
          } else {
            Swal.fire({
              icon: "success",
              title: "Account created successfully",
              text:
                this.newUser.first_name +
                " " +
                this.newUser.last_name +
                ", We have sent you a verification message to your email.",
            });
          }

          this.route.navigateByUrl("/login");
        } else if (resp.OK === false) {
          // Mostrando mensaje de error

          if (this.lang === "es-ES" || this.lang === "es") {
            Swal.fire({
              icon: "error",
              title: "Error al Crear cuenta",
              text: resp.Error,
            });
          } else if (this.lang === "en-US" || this.lang === "en") {
            Swal.fire({
              icon: "error",
              title: "Error creating account",
              text: resp.Error,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Error creating account",
              text: resp.Error,
            });
          }
        }
      });

      // Borramos el formulario despues de ser enviado
      this.form.reset();
    }
  }
}

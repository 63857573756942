<div class="container" id="main-Container">
    <div class="row">
        <div class="col-12 col-md-12">
            <!--Titulo del login-->
            <div id="title">
                <h2> {{ "LOGIN.title" | translate }} </h2>
            </div>
            <!--Card-->
            <div class="card">
                <div class="card-body">
                    <form [formGroup]="form" (ngSubmit)="sendLogin()">
                        <div class="form-group">
                            <label for="exampleInputEmail1"> {{ "LOGIN.email" | translate }} </label>
                            <input type="email" class="form-control" formControlName="email" [class.is-invalid]="emailNoValid">
                        </div>
                        <div class="form-group">
                            <div id="password">
                                <label for="exampleInputPassword1"> {{ "LOGIN.password" | translate }} </label>
                                <a [routerLink]="['../forgot-password']"> {{ "LOGIN.forgotPassword" | translate }} </a>
                            </div>
                            <input type="password" class="form-control" formControlName="password" [class.is-invalid]="passwordNoValid">
                        </div>
                        <div class="form-group form-check">
                            <input type="checkbox" class="form-check-input" formControlName="remember">
                            <label class="form-check-label" for="exampleCheck1"> {{ "LOGIN.remember" | translate }} </label>
                        </div>
                        <button type="submit" class="btn btn-success btn-block"> {{ "LOGIN.buttonLogin" | translate }} </button>
                    </form>
                </div>
            </div>
            <button id="createAccount" type="button" (click)="create()" class="btn btn-outline-secondary btn-block"> {{ "LOGIN.buttonCreateAccount" | translate }} </button>
        </div>
    </div>
</div>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imgFalse'
})
export class ImgFalsePipe implements PipeTransform {

  transform(url1: any, url: boolean = false ): any {
 

    if ( url1 ) {
      return url1;

     }
    if ( url === false ) {
      return "assets/img/no-imgage.png"
     }
  }

}

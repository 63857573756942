<div class="container-fluid">
  <div class="row">
    <div class="mat-card" id="mat-card">
      <div class="h-vertical">
        <button
          type="button"
          routerLink="/payment"
          class="btn float-left clearfix"
        >
          <i class="fa fa-arrow-left fa-2x color-nav"></i>
        </button>
        <h2 class="text-center color-nav">
          {{ "PAYMENTDETAILS.title" | translate }}
        </h2>
      </div>
    </div>
  </div>
  <div
    id="flow-container"
    class="row mt-5 justify-content-center"
    *ngFor="let item of detailsList"
  >
    <div class="row" id="mat-details">
      <div class="col-2 text-right mt-3">
        <p><i class="fa fa-calendar-alt color-icon"></i></p>
        <hr />
        <p><i class="fa fa-user color-icon"></i></p>
        <hr />
        <p><i class="fa fa-barcode color-icon"></i></p>
        <hr />
        <p><i class="fa fa-procedures color-icon"></i></p>
        <hr />
        <p><i class="fa fa-money-bill-wave color-icon"></i></p>
        <hr />
        <p><i class="fa fa-info-circle color-icon"></i></p>
      </div>
      <div class="col-5 mt-3">
        <p>{{ "PAYMENTHISTORY.date" | translate }}</p>
        <hr />
        <p>{{ "PAYMENTHISTORY.name" | translate }}</p>
        <hr />
        <p>{{ "PAYMENTHISTORY.transactionN" | translate }}</p>
        <hr />
        <p>{{ "PAYMENTHISTORY.process" | translate }}</p>
        <hr />
        <p>{{ "PAYMENTHISTORY.amount" | translate }}</p>
        <hr />
        <p>{{ "PAYMENTHISTORY.status" | translate }}</p>
      </div>
      <div class="col-5 mt-3">
        <p>{{ item.created_at | date: "short" }}</p>
        <hr />
        <p>{{ item.doctor.first_name }} {{ item.doctor.last_name }}</p>
        <hr />
        <p id="code">{{ item.transaction_number }}</p>
        <hr />
        <p>{{ item.procedureType.name }}</p>
        <hr />
        <p>{{ item.amount }}</p>
        <hr />
        <p>
          <span>
            <i
              *ngIf="item.status === true"
              class="fa fa-check text-success fa-2x mr-2"
            >
            </i>
          </span>
        </p>
      </div>
    </div>
  </div>
  <div class="row mt-5 justify-content-center">
    <div class="row justify-content-end" id="btn-end">
      <button class="btn btn-light" (click)="downloadPDF()">
        <i class="fa fa-file-pdf"></i>
        {{ "PAYMENTHISTORY.export" | translate }}
      </button>
    </div>
  </div>
</div>

<div class="container-fluid" id="mainContainer">
    <div class="row justify-content-center">
        <p id="titel" class="text-muted">
            <span> {{"APPOINTMENT.titel" | translate}} </span>
        </p>
    </div>
    
    <ng-container *ngIf="listTotal.length > 0; else noFound">
        <div class="container-overflow animated fadeIn fast">
            <div class="row mb-4" *ngFor="let list of listTotal">
                <div id="div-card" class="col-sm-12 col-md-8 col-lg-10">
                    <mat-card id="matAppointment" class="border-card button-container mat-h overflow-auto">
                        <button id="button-info" type="button" class="btn" (click)="cardInfo( list.id )">
                            <div id="left">
                                <label class="titel-label"> {{"APPOINTMENT.label-titel-appointments" | translate}} </label>
                                <label>{{list.doctor.first_name}}  {{list.doctor.last_name}}</label>
                            </div>
                            <div id="left">
                                <label class="titel-label"> {{"APPOINTMENT.label-titel-date" | translate}} </label>
                                <label>{{list.schedule_date | date:'LL/yyyy/dd' }}</label>
                            </div>
                            <div id="right">
                                <label class="titel-label"> {{"APPOINTMENT.missingday" | translate}} </label>
                                <label> <span class="badge badge-warning">{{list.missing_day }}</span></label>
                            </div>         
                        </button>
                    </mat-card>
                </div>

                <div id="m" class="col-sm-6 col-md-2 col-lg-1">
                    <mat-card id="button-alert" class="mat-h" (click)="createAlert( list.id )">
                        <i id="icon" class="fas fa-bell"></i>
                    </mat-card>
                </div>

                <div id="l" class="col-sm-6 col-md-2  col-lg-1">
                    <mat-card id="button-delete" class="mat-h" (click)="cancelAppointment(list.doctor_id, list.id, list.patient_id, list.appointment.is_created_by_chat )">
                        <i id="icon" class="fas fa-trash"></i>
                    </mat-card>
                </div>
            </div>
        </div>
    </ng-container>
    
    <ng-template #noFound  class="row">
        <div class="col-md-12 mt-5 mb-5 text-center">
            <i class=" mt-5 fa fa-exclamation-triangle text-warning fa-3x "></i>
            <h4 class=" text-muted mt-3 mb-5 ">{{ "PAYMENT.no-result" | translate }}</h4>
        </div>
      </ng-template>
      <div class="row justify-content-sm-start ml-4">
          <hr>
          <h3 *ngIf="healingApoointment.length > 0" class="mt-5 mb-3 text-muted">{{"APPOINTMENT.title-healing" | translate}}</h3>
      </div>
      <div class=" container-overflow row animated fadeIn fast">
          <div class="col-md-6" *ngFor="let list of healingApoointment">
              <div class="button-container">
                  <mat-card id="mat-cancellled" class="border-healing mb-3 ">
                      <button id="button-info" type="button" class="btn">
                          <div id="left">
                              <label class="titel-label"> {{"APPOINTMENT.label-titel-appointments" | translate}} </label>
                              <label>{{list.doctor.first_name}}  {{list.doctor.last_name}}</label>
                            </div>
                            <div id="right">
                                <label class="titel-label"> {{"APPOINTMENT.label-titel-date" | translate}} </label>
                                <label>{{list.schedule_date | date:'LL/yyyy/dd' }}</label>
                            </div>
                        </button>
                    </mat-card>
                </div>
              </div>
        </div>
        <div class="row justify-content-sm-start ml-4">
            <hr>
            <h3 *ngIf="cancelTotal.length > 0" class="mt-5 mb-3 text-muted">{{"APPOINTMENT.title-canceled" | translate}}</h3>
        </div>
      <div class=" container-overflow  row animated fadeIn fast">
          <div class="col-md-6" *ngFor="let list of cancelTotal">
              <div class="button-container">
                  <mat-card id="mat-cancellled" class="border-cancelled mb-3 ">
                      <button id="button-info" type="button" class="btn">
                          <div id="left">
                              <label class="titel-label"> {{"APPOINTMENT.label-titel-appointments" | translate}} </label>
                              <label>{{list.doctor.first_name}}  {{list.doctor.last_name}}</label>
                            </div>
                            <div id="right">
                                <label class="titel-label"> {{"APPOINTMENT.label-titel-date" | translate}} </label>
                                <label>{{list.schedule_date | date:'LL/yyyy/dd' }}</label>
                            </div>
                        </button>
                    </mat-card>
                </div>
            </div>
      </div>
</div>
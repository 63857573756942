import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

    // Todo lo que sea comun de las ur lo guardamos en una variable
    private url = environment.ApiUrl;
    idUser: string;
    
    constructor(private http: HttpClient) { }


  // Recibimos el total de las citas ordenadas por fecha y hora
  getAppointmentList() {
    this.idUser = localStorage.getItem('id');
    return this.http.get(`${ this.url }/patient-procedure/` + this.idUser )
          .pipe( map( resp => resp )) ;
  }

  // Cancelar citas
  cancelAppointment(doctor_Id: string, info: any) {
    const body = {
      reason: info.reason,
      patient_id: info.idPatient,
      procedure_id: info.idProcedure
    };
    return this.http.post(`${ this.url }/cancel-procedure/` + doctor_Id, body)
                    .pipe( map( resp =>  resp ));
  }
  getCancelAppointment() {
       this.idUser = localStorage.getItem('id');
       return this.http.get(`${ this.url }/cancelled-procedure/` + this.idUser )
                    .pipe( map( resp =>  resp ));
  }
  // Tomamos el id de la cita y el id del usuario para crear una alerta que avisara al paciente una semana 
  // antes de la cita y otra vez un dia antes.
  alertAppointment( id: string ) {
    return this.http.post(`${ this.url }/appointment/${ id }`,
    this.idUser
    ).pipe(
      map( resp => {
     
      })
    )
  }
  
  showPolitics() {
    return this.http.get(`${ this.url }/refund-policies/` + this.idUser )
                     .pipe( map ( Response => {
                  
                       return Response;
                     }))
  }

  private handleError(error: any) { 
    let errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return Observable.throw(error);
  }
}

<div class="container">
    <div class="row mb-3 justify-content-center ">
        <div class="col-md-10">
            <div class="mat-card">
                  <div class="container-img-doctor pt-3">
                    <div id="circle-img-doctor">
                      <img
                        id="imgCard"
                        [src]="publication.doctor_image | imgFalse" 
                        class=""
                        alt="Doctor image"
                      />
                    </div>
                  </div>
                  <h2 class="text-center pb-3">
                    {{publication.doctor_name}}
                  </h2>
            </div>
        </div>
    </div>

   <div class="row mb-4 justify-content-center">
       <div class="col-md-10">
        <div class="mat-card card">
          <div class="container-img pt-3">
            <div id="circle-img">
              <img
                id="imgCard"
                [src]="publication.image | imgFalse"
                class=""
                alt="Doctor image"
              />
            </div>
          </div>
            <div class="card-body">
              <h5 class="card-title"> {{publication.title}} </h5>
              <p class="card-text">{{publication.text }}</p>
            </div>
            <div class="card-footer">
              <small class="text-muted"> 
              {{publication.date | date }}
             </small>
            </div>
          </div>
       </div>
   </div>
</div>
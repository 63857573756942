<div class="container-fluid">
  <div class="row justify-content-center">
    <h3 class="text-muted mt-4">{{ "SEARCH.titlePage" | translate }}</h3>
  </div>
  <div class="row justify-content-center">
    <mat-card id="searchMat" class="mb-4 border-search">
      <div class="col col-sm col-md-12 col-lg">
        <form class="form-inline my-2 my-lg-0">
          <input
            class="form-control col mt-5"
            type="search"
            placeholder="Search"
            aria-label="Search"
            (keyup)="search(searchText.value, country.value, treatment.value)"
            #searchText
          />
          <button
            class="btn btn-outline mt-5"
            type="button"
            (click)="search(searchText.value, country.value, treatment.value)"
          >
            <i class="fa fa-search"></i>
            <span id="none"> {{ "SEARCH.searchButton" | translate }} </span>
          </button>
        </form>
        <div class="row">
          <div class="col">
            <div id="optionGroup">
              <div id="country">
                <select class="custom-select custom-select-sm" #country>
                  <option value="" selected>
                    {{ "SEARCH.countrys" | translate }}
                  </option>
                  <option
                    *ngFor="let lista of listContries; let i = index"
                    [value]="lista.id"
                  >
                    {{ lista.name }}
                  </option>
                </select>
              </div>
              <div id="treatment">
                <select class="custom-select custom-select-sm" #treatment>
                  <option value="" selected>
                    {{ "SEARCH.treatments" | translate }}
                  </option>
                  <option
                    *ngFor="let item of listProcedureType; let i = index"
                    [value]="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
      <p class="text-center" *ngIf="turnOn">
        {{ "SEARCH.titleList1" | translate }}
      </p>
      <p class="text-center" *ngIf="!turnOn">
        {{ "SEARCH.titleList2" | translate }}
      </p>
    </mat-card>
  </div>
  <div id="searchList" class="row justify-content-center" *ngIf="loading">
    <app-loading class=""></app-loading>
  </div>
  <div id="searchList" class="row justify-content-center content">
    <mat-card
      id="matDoctor"
      (click)="client(doctor.id)"
      class="mt-3"
      *ngFor="let doctor of searchDoctorList; let i = index"
    >
      <div class="row mt-3">
        <div class="col col-sm col-md-4 col-lg-4" id="imgDiv">
          <div class="container-img">
            <div id="circle-img">
              <img
                id="imgCard"
                [src]="doctor.profile_picture | imgFalse"
                class=""
                alt="Doctor image"
              />
            </div>
          </div>
        </div>
        <div id="move" class="col col-sm col-md-4 col-lg">
          <ul id="cardInformtion" class="mt-2">
            <li>
              <b>{{ "SEARCH.docTitle" | translate }}</b> {{ doctor.title }}
            </li>
            <li>
              <b>{{ "SEARCH.docName" | translate }}</b> {{ doctor.first_name }}
              {{ doctor.last_name }}
            </li>
            <li  id="none">
              <b>{{ "SEARCH.docCountry" | translate }}</b>
             <span name="countryD" id="countryD"   > {{ doctor.country.name }} </span>
            </li>
            <li id="none" >
              <b>{{ "SEARCH.docLanguage" | translate }}</b>
              {{ doctor.primarylanguage?.name }}
            </li>
          </ul>
        </div>
        <div class="col col-sm col-md-4 col-lg" id="cardDesc">
          <div id="cardDescription" class="text-left info-card">
            <p>
              <b>{{ "SEARCH.description" | translate }}</b>
            </p>
            <p>{{ doctor.description }}</p>
          </div>
        </div>
      </div>
    </mat-card>
  </div>

  <div *ngIf="noFound === true" class="row justify-content-center">
    <div class="col col-sm col-md-12 col-lg mt-5 text-center">
      <i class="mt-5 fa fa-exclamation-triangle text-warning fa-3x"></i>
      <h4 class="text-muted mt-3">{{ "PAYMENT.no-found" | translate }}</h4>
    </div>
  </div>
</div>

<nav class="">
  <div class="nav nav-tabs" id="nav-tab" role="tablist">
    <a
      class="nav-link active"
      id="nav-pay-tab"
      data-toggle="tab"
      href="#nav-pay"
      role="tab"
      aria-controls="nav-pay"
      aria-selected="true"
    >
      {{ "PAYMENT.title" | translate }}
    </a>
    <a
      class="nav-link"
      id="nav-history-tab"
      data-toggle="tab"
      href="#nav-history"
      role="tab"
      aria-controls="nav-history"
      aria-selected="false"
    >
      {{ "PAYMENTHISTORY.title" | translate }}
    </a>
  </div>
</nav>

<div class="tab-content" id="nav-tabContent">
  <div
    class="tab-pane fade show active"
    id="nav-pay"
    role="tabpanel"
    aria-labelledby="nav-pay-tab"
  >
    <div class="mt-5">
      <div class="text-center">
        <img src="/assets/img/pay.png" />
        <h2 class="text-muted">{{ "PAYMENT.title" | translate }}</h2>
      </div>
    </div>
    <div id="container-overflow" *ngIf="requestList?.length > 0; else noFound">
      <div
        id="payment"
        class="row justify-content-center"
        *ngFor="let item of requestList"
      >
        <mat-card id="mat-payment" class="mat-width">
          <div class="row">
            <div class="col-md-3">
              <p class="text-muted">
                <i class="fa fa-calendar-alt color-icon"> </i>
                <span id="date"></span>
                {{ item.created_at | date: "short" }}
              </p>
            </div>
            <div class="col-md-3">
              <p class="text-muted text-center">
                <i class="fa fa-user color-icon"></i>
                <span id="name"> </span>
                {{ item.doctor.first_name }} {{ item.doctor.last_name }}
              </p>
            </div>
            <div class="col-md-3">
              <p class="text-muted text-right">
                <i class="fa fa-money-bill-wave color-icon"></i>
                <span id="amount"></span>
                {{ item.amount }}
              </p>
            </div>
            <div class="col-md-3">
              <button
                id="details"
                class="float-right btn btn-secondary"
                [routerLink]="['details', item.id]"
                routerLinkActive="router-link-active"
              >
                {{ "PAYMENT.btn-details" | translate }}
              </button>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>

  <div
    class="tab-pane fade"
    id="nav-history"
    role="tabpanel"
    aria-labelledby="nav-history-tab"
  >
    <div class="mt-4">
      <h2 class="text-center text-muted">
        {{ "PAYMENTHISTORY.title" | translate }}
      </h2>
      <div class="row justify-content-center">
        <div class="row" id="row-search">
          <div class="col-md-4">
            <div class="form-group">
              <mat-form-field color="color-icon" appearance="fill">
                <mat-label>{{ "PAYMENT.from" | translate }}:</mat-label>
                <input
                  matInput
                  #from
                  id="from"
                  [matDatepicker]="picker1"
                  disabled
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker1"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker1 disabled="false"></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <mat-form-field color="color-icon" appearance="fill">
                <mat-label>{{ "PAYMENT.to" | translate }}:</mat-label>
                <input
                  matInput
                  #to
                  id="to"
                  [matDatepicker]="picker2"
                  disabled
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker2"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker2 disabled="false"></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <button
                id="btn-search"
                class="btn btn-secondary float-left"
                (click)="filterHistory(from.value, to.value)"
              >
                <i class="fa fa-search"></i>
                {{ "PAYMENT.filter" | translate }}
              </button>
              <p
                *ngIf="getAll === true"
                id="get-all"
                class="text-get float-left btn btn-light"
                (click)="getHistoryPayment()"
              >
                {{ "PAYMENT.get-all" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="container-overflow">
      <ng-container *ngIf="historyList?.length > 0; else noFound">
        <div
          class="mt-3 row justify-content-center"
          *ngFor="let item of historyList"
        >
          <mat-card
            id="mat-history"
            class="mat-width border-"
            [routerLink]="['details-history', item.reservation_request_id]"
          >
            <div class="row">
              <div class="col-md-3">
                <div class="text-muted">
                  <p class="margin-l">
                    <i class="fa fa-calendar-alt color-icon"></i>
                    {{ "PAYMENTHISTORY.date" | translate }}
                  </p>
                </div>
                <p class="margin-l margin-t">
                  {{ item.created_at | date: "short" }}
                </p>
              </div>
              <div class="col-md-3">
                <p class="text-muted">
                  <i class="fa fa-user color-icon"></i>
                  {{ "PAYMENTHISTORY.name" | translate }}
                </p>
                <p class=" ">
                  {{ item.doctor.first_name }} {{ item.doctor.last_name }}
                </p>
              </div>

              <div class="col-md-3">
                <p class="text-muted">
                  <i class="fa fa-money-bill-wave color-icon"></i>
                  {{ "PAYMENTHISTORY.amount" | translate }}
                </p>
                <p class=" ">{{ item.amount }}</p>
              </div>
              <div class="col-md-3">
                <p class="text-muted">
                  <i class="fa fa-info-circle color-icon"></i>
                  {{ "PAYMENTHISTORY.status" | translate }}
                </p>
                <i
                  *ngIf="item.status === true"
                  class="fa fa-check-circle text-success mr-1"
                >
                </i
                ><span> {{ "PAYMENTHISTORY.status-sucess" | translate }}</span>
                <i
                  *ngIf="item.status === false"
                  class="fa fa-check-circle text-danger"
                >
                </i>
              </div>
            </div>
          </mat-card>
        </div>
      </ng-container>
      <ng-template #noFound class="row">
        <div class="col-md-12 mt-5 mb-5 text-center">
          <i class="mt-5 fa fa-exclamation-triangle text-warning fa-3x"></i>
          <h4 class="text-muted mt-3 mb-5">
            {{ "PAYMENT.no-result" | translate }}
          </h4>
        </div>
      </ng-template>
    </div>
  </div>
</div>

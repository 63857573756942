<div id="mainContainer1">

    <h5 id="title">Formulario Dr. {{doctorName}}</h5>

    <div id="formContainer">
        <form [formGroup]="form" autocomplete="off">


            <div class="inputGroupContainer">

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.name' | translate}} <span class="requiredInput">*</span></mat-label>
                    <input matInput formControlName="first_name">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.lastname' | translate}} <span class="requiredInput">*</span></mat-label>
                    <input matInput formControlName="last_name">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.identification' | translate}}<span class="requiredInput">*</span></mat-label>
                    <input matInput formControlName="identification">
                </mat-form-field>

            </div>

            <div class="inputGroupContainer">

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.email' | translate}} <span class="requiredInput">*</span></mat-label>
                    <input matInput type="email" formControlName="email">
                    <mat-hint id="emailInfo" *ngIf="emailInfoContainer">{{emailInfoTextr}}</mat-hint>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.phone' | translate}} <span class="requiredInput">*</span></mat-label>
                    <input matInput formControlName="phone">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.dateBirth' | translate}} <span class="requiredInput">*</span></mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="dateBirth">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>


            <div class="inputGroupContainer">

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.gender' | translate}} <span class="requiredInput">*</span></mat-label>
                    <mat-select formControlName="gender">
                      <mat-option *ngFor="let item of genderGroup" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.languages' | translate}} <span class="requiredInput">*</span></mat-label>
                    <mat-select multiple formControlName="languages">
                      <mat-option *ngFor="let item of languages" [value]="item">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.type_of_procedure' | translate}} <span class="requiredInput">*</span></mat-label>
                    <mat-select formControlName="procedure_doctor">
                      <mat-option *ngFor="let item of procedureList" [value]="item">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>


            <div class="inputGroupContainer">

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.country' | translate}} <span class="requiredInput">*</span></mat-label>
                    <mat-select formControlName="country">
                      <mat-option *ngFor="let item of countryTotal" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.city' | translate}} <span class="requiredInput">*</span></mat-label>
                    <input matInput formControlName="city">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.address' | translate}} <span class="requiredInput">*</span></mat-label>
                    <input matInput formControlName="address">
                </mat-form-field>

            </div>


            <div class="inputGroupContainer">

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.status' | translate}}</mat-label>
                    <input matInput formControlName="status">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.religion' | translate}}</mat-label>
                    <input matInput formControlName="religion">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.occupation' | translate}}</mat-label>
                    <input matInput formControlName="occupation">
                </mat-form-field>

            </div>


            


            <div id="buttonsFeetAndMetersContainer">
                <mat-button-toggle-group>
                    <mat-button-toggle checked value="feet" (click)="feetAndMetersContainer = true">{{'FORMS-SURGERY.feetButton' | translate}}</mat-button-toggle>
                    <mat-button-toggle value="meters" (click)="feetAndMetersContainer = false">{{'FORMS-SURGERY.meterButton' | translate}}</mat-button-toggle>
                </mat-button-toggle-group>
            </div>

            <div class="feetAndMetersContainer" *ngIf="feetAndMetersContainer">
                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.pounds' | translate}}</mat-label>
                    <input type="number" matInput placeholder="155" formControlName="pounds">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.foot' | translate}}</mat-label>
                    <input type="number"  matInput placeholder="5,2"  formControlName="foot">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>IMC : <span>{{imc | number}}</span></mat-label>
                    <input matInput disabled="true">
                </mat-form-field>
            </div>

            <div class="feetAndMetersContainer" *ngIf="!feetAndMetersContainer">
                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.kg' | translate}}</mat-label>
                    <input type="number"  matInput placeholder="89" formControlName="kg">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.meter' | translate}}</mat-label>
                    <input type="number"  matInput placeholder="1,8" formControlName="meter">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>IMC : <span>{{imc | number}}</span></mat-label>
                    <input matInput disabled="true">
                </mat-form-field>
            </div>

            

            <div class="inputGroupContainer">

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.emergencyPerson' | translate}}</mat-label>
                    <input matInput formControlName="emergencyPerson">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.emergencyPersonPhone' | translate}}</mat-label>
                    <input matInput formControlName="emergencyPersonPhone">
                </mat-form-field>

            </div>


            



            <!------------------------------------------------------------------------------------------------------------------------------------ 
                Formulario dinamico
            ------------------------------------------------------------------------------------------------------------------------------------->
            <form autocomplete="off" [formGroup]="dynamicForm">


                <div class="moduleContainer" *ngFor="let module of moduleList">
                    <div class="title">
                        {{module.name}}
                    </div>

                    <div class="attributesContainer" >


                        <div>

                            <div *ngFor="let value of module.list_total">

                                <div class="inputAttributesContainer" *ngIf="value.type === 'input'" >
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{value.name}}</mat-label>
                                        <input matInput [formControlName]="value.selector">
                                    </mat-form-field>
                                </div>
                               
                                <div class="selectAttributesContainer" *ngIf="value.type === 'select'">
                                    <mat-form-field appearance="outline" >
                                        <mat-label>{{value.name}} </mat-label>
                                        <mat-select [formControlName]="value.selector">
                                          <mat-option *ngFor="let total of value.listValue" [value]="total.id">
                                            {{total.name}}
                                          </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="checkboxAttributesContainer" *ngIf="value.type === 'checkbox'">
                                    <mat-checkbox [formControlName]="value.selector">{{value.name}}</mat-checkbox>
                                </div>
    
                                <div class="textareaAttributesContainer" *ngIf="value.type === 'textarea'">
                                    <mat-form-field  style="width: 100%;" appearance="outline">
                                        <mat-label>{{value.name}}</mat-label>
                                        <textarea #{{value.selector}} [formControlName]="value.selector" matInput></textarea>
                                    </mat-form-field>
                                </div>
                            </div>

                            
                        </div>
    
                    </div>

                </div>

            </form>








            <div class="imgGroupContainer">


                <div class="imgOneGroupContainer">
                    <p>{{'FORMS-SURGERY.front' | translate}}</p>
                    <img [src]="img1"  alt="">
                    <mat-progress-bar *ngIf="imgProgress1" mode="indeterminate"></mat-progress-bar>
                    <button type="button" mat-stroked-button (click)="fileInput1.click()">
                        {{'FORMS-SURGERY.picture_of' | translate}} {{'FORMS-SURGERY.front' | translate}}
                    </button>
                    <input hidden (change)="onFileSelected( $event, 1 )" #fileInput1 type="file" id="file1">
                </div>


                <div class="imgOneGroupContainer">
                    <p>{{'FORMS-SURGERY.back' | translate}}</p>
                    <img [src]="img2" alt="">
                    <mat-progress-bar *ngIf="imgProgress2" mode="indeterminate"></mat-progress-bar>
                    <button type="button" mat-stroked-button (click)="fileInput2.click()">
                        {{'FORMS-SURGERY.picture_of' | translate}} {{'FORMS-SURGERY.back' | translate}}
                    </button>
                    <input hidden (change)="onFileSelected( $event, 2 )" #fileInput2 type="file" id="file2">
                    
                </div>

            </div>


            <div class="imgGroupContainer">


                <div class="imgOneGroupContainer">
                    <p>{{'FORMS-SURGERY.left' | translate}}</p>
                    <img [src]="img3" alt="">
                    <mat-progress-bar *ngIf="imgProgress3" mode="indeterminate"></mat-progress-bar>
                    <button type="button" mat-stroked-button (click)="fileInput3.click()">
                        {{'FORMS-SURGERY.picture_of' | translate}} {{'FORMS-SURGERY.left' | translate}}
                    </button>
                    <input hidden (change)="onFileSelected( $event, 3 )" #fileInput3 type="file" id="file3">
                </div>


                <div class="imgOneGroupContainer">
                    <p>{{'FORMS-SURGERY.right' | translate}}</p>
                    <img [src]="img4"  alt="">
                    <mat-progress-bar *ngIf="imgProgress4" mode="indeterminate"></mat-progress-bar>
                    <button type="button" mat-stroked-button (click)="fileInput4.click()">
                        {{'FORMS-SURGERY.picture_of' | translate}} {{'FORMS-SURGERY.right' | translate}}
                    </button>
                    <input hidden (change)="onFileSelected( $event, 4 )" #fileInput4 type="file" id="file4">
                </div>

            </div>


            <div class="imgGroupContainer">


                <div class="imgOneGroupContainer">
                    <p>{{'FORMS-SURGERY.passport' | translate}}</p>
                    <img [src]="img5"  alt="">
                    <mat-progress-bar *ngIf="imgProgress5" mode="indeterminate"></mat-progress-bar>
                    <button type="button" mat-stroked-button (click)="fileInput5.click()">
                        {{'FORMS-SURGERY.passport' | translate}}
                    </button>
                    <input hidden (change)="onFileSelected( $event, 5 )" #fileInput5 type="file" id="file5">
                </div>


                <div class="imgOneGroupContainer">
                    <p>{{'FORMS-SURGERY.profile' | translate}}</p>
                    <img [src]="img6"  alt="">
                    <mat-progress-bar *ngIf="imgProgress6" mode="indeterminate"></mat-progress-bar>
                    <button type="button" mat-stroked-button (click)="fileInput6.click()">
                        {{'FORMS-SURGERY.picture_of' | translate}} {{'FORMS-SURGERY.profile' | translate}}
                    </button>
                    <input hidden (change)="onFileSelected( $event, 6 )" #fileInput6 type="file" id="file6">
                </div>

            </div>

            <mat-checkbox style="white-space: pre-wrap;" formControlName="terms">
                {{'FORMS-SURGERY.I_accept_novusdoc_conditions' | translate}} <span class="requiredInput">*</span> <span (click)="goTo()"> {{'FORMS-SURGERY.link_terms_Conditions' | translate}}</span>
            </mat-checkbox>


            <div id="sendFormProgressContainer" *ngIf="sendFormProgressContainer">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>


            <div id="sendButtonContainer">

                <button mat-flat-button color="warn" (click)="openDialogSendForm()">{{'FORMS-SURGERY.sendForm' | translate}}</button>

            </div>

            
            




        </form>
    </div>


    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>


</div>





<!---------------------------------------------------------------------------------------------------------------
    Formulario para dispositivos moviles
---------------------------------------------------------------------------------------------------------------->
<div id="mainContainer2">

    <h5 id="title">Formulario Dr. {{doctorName}}</h5>
    <div id="formContainer">
        <form [formGroup]="form" autocomplete="off">


            <div class="inputGroupContainer">

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.name' | translate}} <span class="requiredInput">*</span></mat-label>
                    <input matInput formControlName="first_name">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.lastname' | translate}} <span class="requiredInput">*</span></mat-label>
                    <input matInput formControlName="last_name">
                </mat-form-field>

                

            </div>

            <div class="inputGroupContainer">
                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.identification' | translate}}<span class="requiredInput">*</span></mat-label>
                    <input matInput formControlName="identification">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.email' | translate}} <span class="requiredInput">*</span></mat-label>
                    <input matInput type="email" formControlName="email">
                    <mat-hint id="emailInfo" *ngIf="emailInfoContainer">{{emailInfoTextr}}</mat-hint>
                </mat-form-field>


            </div>

            <div class="inputGroupContainer">


                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.phone' | translate}} <span class="requiredInput">*</span></mat-label>
                    <input matInput formControlName="phone">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.dateBirth' | translate}} <span class="requiredInput">*</span></mat-label>
                    <input matInput [matDatepicker]="picker3" formControlName="dateBirth">
                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                    <mat-datepicker #picker3></mat-datepicker>
                </mat-form-field>
            </div>


            <div class="inputGroupContainer">


                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.gender' | translate}} <span class="requiredInput">*</span></mat-label>
                    <mat-select formControlName="gender">
                      <mat-option *ngFor="let item of genderGroup" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
                

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.languages' | translate}} <span class="requiredInput">*</span></mat-label>
                    <mat-select multiple formControlName="languages">
                      <mat-option *ngFor="let item of languages" [value]="item">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                

            </div>

            <div class="inputGroupContainer">
                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.type_of_procedure' | translate}} <span class="requiredInput">*</span></mat-label>
                    <mat-select formControlName="procedure_doctor">
                      <mat-option *ngFor="let item of procedureList" [value]="item">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.country' | translate}} <span class="requiredInput">*</span></mat-label>
                    <mat-select formControlName="country">
                      <mat-option *ngFor="let item of countryTotal" [value]="item.id">{{item.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>


            <div class="inputGroupContainer">

                

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.city' | translate}} <span class="requiredInput">*</span></mat-label>
                    <input matInput formControlName="city">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.address' | translate}} <span class="requiredInput">*</span></mat-label>
                    <input matInput formControlName="address">
                </mat-form-field>

            </div>


            <div class="inputGroupContainer">

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.status' | translate}}</mat-label>
                    <input matInput formControlName="status">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.religion' | translate}}</mat-label>
                    <input matInput formControlName="religion">
                </mat-form-field>

            </div>

            <div class="inputGroupContainer">

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.occupation' | translate}}</mat-label>
                    <input matInput formControlName="occupation">
                </mat-form-field>

            </div>


            <div id="buttonsFeetAndMetersContainer">
                <mat-button-toggle-group>
                    <mat-button-toggle checked value="feet" (click)="feetAndMetersContainer = true">{{'FORMS-SURGERY.feetButton' | translate}}</mat-button-toggle>
                    <mat-button-toggle value="meters" (click)="feetAndMetersContainer = false">{{'FORMS-SURGERY.meterButton' | translate}}</mat-button-toggle>
                </mat-button-toggle-group>
            </div>

            <div class="feetAndMetersContainer" *ngIf="feetAndMetersContainer">
                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.pounds' | translate}}</mat-label>
                    <input type="number" matInput placeholder="155" formControlName="pounds">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.foot' | translate}}</mat-label>
                    <input type="number"  matInput placeholder="5,2"  formControlName="foot">
                </mat-form-field>
                <mat-form-field class="imc" appearance="outline">
                    <mat-label>IMC : <span>{{imc | number}}</span></mat-label>
                    <input matInput disabled="true">
                </mat-form-field>
            </div>

            <div class="feetAndMetersContainer" *ngIf="!feetAndMetersContainer">
                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.kg' | translate}}</mat-label>
                    <input type="number"  matInput placeholder="89" formControlName="kg">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.meter' | translate}}</mat-label>
                    <input type="number"  matInput placeholder="1,8" formControlName="meter">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>IMC : <span>{{imc | number}}</span></mat-label>
                    <input matInput disabled="true">
                </mat-form-field>
            </div>


            <div class="inputGroupContainer">

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.emergencyPerson' | translate}}</mat-label>
                    <input matInput formControlName="emergencyPerson">
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.emergencyPersonPhone' | translate}}</mat-label>
                    <input matInput formControlName="emergencyPersonPhone">
                </mat-form-field>

            </div>









            <!------------------------------------------------------------------------------------------------------------------------------------ 
                Formulario dinamico
            ------------------------------------------------------------------------------------------------------------------------------------->
            <form autocomplete="off" [formGroup]="dynamicForm">


                <div class="moduleContainer" *ngFor="let module of moduleList">
                    <div class="title">
                        {{module.name}}
                    </div>

                    <div class="attributesContainer" >


                        <div>

                            <div *ngFor="let value of module.list_total">

                                <div class="inputAttributesContainer" *ngIf="value.type === 'input'" >
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{value.name}}</mat-label>
                                        <input matInput [formControlName]="value.selector">
                                    </mat-form-field>
                                </div>
                               
                                <div class="selectAttributesContainer" *ngIf="value.type === 'select'">
                                    <mat-form-field appearance="outline" >
                                        <mat-label>{{value.name}} </mat-label>
                                        <mat-select [formControlName]="value.selector">
                                          <mat-option *ngFor="let total of value.listValue" [value]="total.id">
                                            {{total.name}}
                                          </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="checkboxAttributesContainer" *ngIf="value.type === 'checkbox'">
                                    <mat-checkbox [formControlName]="value.selector">{{value.name}}</mat-checkbox>
                                </div>
    
                                <div class="textareaAttributesContainer" *ngIf="value.type === 'textarea'">
                                    <mat-form-field  style="width: 100%;" appearance="outline">
                                        <mat-label>{{value.name}}</mat-label>
                                        <textarea #{{value.selector}} [formControlName]="value.selector" matInput></textarea>
                                    </mat-form-field>
                                </div>
                            </div>

                            
                        </div>
    
                    </div>

                </div>

            </form>


















            <div class="imgGroupContainer">


                <div class="imgOneGroupContainer">
                    <p>{{'FORMS-SURGERY.front' | translate}}</p>
                    <img [src]="img1"  alt="">
                    <mat-progress-bar *ngIf="imgProgress1" mode="indeterminate"></mat-progress-bar>
                    <button type="button" mat-stroked-button (click)="fileInput1.click()">
                        {{'FORMS-SURGERY.picture_of' | translate}} {{'FORMS-SURGERY.front' | translate}}
                    </button>
                    <input hidden (change)="onFileSelected( $event, 1)" #fileInput1 type="file" id="file1">
                </div>


                <div class="imgOneGroupContainer">
                    <p>{{'FORMS-SURGERY.back' | translate}}</p>
                    <img [src]="img2" alt="">
                    <mat-progress-bar *ngIf="imgProgress2" mode="indeterminate"></mat-progress-bar>
                    <button type="button" mat-stroked-button (click)="fileInput2.click()">
                        {{'FORMS-SURGERY.picture_of' | translate}} {{'FORMS-SURGERY.back' | translate}}
                    </button>
                    <input hidden (change)="onFileSelected( $event, 2 )" #fileInput2 type="file" id="file2">
                    
                </div>

            </div>


            <div class="imgGroupContainer">


                <div class="imgOneGroupContainer">
                    <p>{{'FORMS-SURGERY.left' | translate}}</p>
                    <img [src]="img3" alt="">
                    <mat-progress-bar *ngIf="imgProgress3" mode="indeterminate"></mat-progress-bar>
                    <button type="button" mat-stroked-button (click)="fileInput3.click()">
                        {{'FORMS-SURGERY.picture_of' | translate}} {{'FORMS-SURGERY.left' | translate}}
                    </button>
                    <input hidden (change)="onFileSelected( $event, 3 )" #fileInput3 type="file" id="file3">
                </div>


                <div class="imgOneGroupContainer">
                    <p>{{'FORMS-SURGERY.right' | translate}}</p>
                    <img [src]="img4"  alt="">
                    <mat-progress-bar *ngIf="imgProgress4" mode="indeterminate"></mat-progress-bar>
                    <button type="button" mat-stroked-button (click)="fileInput4.click()">
                        {{'FORMS-SURGERY.picture_of' | translate}} {{'FORMS-SURGERY.right' | translate}}
                    </button>
                    <input hidden (change)="onFileSelected( $event, 4 )" #fileInput4 type="file" id="file4">
                </div>

            </div>


            <div class="imgGroupContainer">


                <div class="imgOneGroupContainer">
                    <p>{{'FORMS-SURGERY.passport' | translate}}</p>
                    <img [src]="img5"  alt="">
                    <mat-progress-bar *ngIf="imgProgress5" mode="indeterminate"></mat-progress-bar>
                    <button type="button" mat-stroked-button (click)="fileInput5.click()">
                        {{'FORMS-SURGERY.passport' | translate}}
                    </button>
                    <input hidden (change)="onFileSelected( $event, 5 )" #fileInput5 type="file" id="file5">
                </div>


                <div class="imgOneGroupContainer">
                    <p>{{'FORMS-SURGERY.profile' | translate}}</p>
                    <img [src]="img6"  alt="">
                    <mat-progress-bar *ngIf="imgProgress6" mode="indeterminate"></mat-progress-bar>
                    <button type="button" mat-stroked-button (click)="fileInput6.click()">
                        {{'FORMS-SURGERY.picture_of' | translate}} {{'FORMS-SURGERY.profile' | translate}}
                    </button>
                    <input hidden (change)="onFileSelected( $event, 6 )" #fileInput6 type="file" id="file6">
                </div>

            </div>

            <mat-checkbox style="white-space: pre-wrap;" formControlName="terms">
                {{'FORMS-SURGERY.I_accept_novusdoc_conditions' | translate}} <span class="requiredInput">*</span> <span (click)="goTo()"> {{'FORMS-SURGERY.link_terms_Conditions' | translate}}</span>
            </mat-checkbox>


            <div id="sendFormProgressContainer" *ngIf="sendFormProgressContainer">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>

            <div id="sendButtonContainer">

                <button mat-flat-button color="warn" (click)="openDialogSendForm()">{{'FORMS-SURGERY.sendForm' | translate}}</button>

            </div>

            
            
            

        </form>
    </div>


    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>


</div>



<!-----------------------------------------------------------------------------------------
    Dialog envio final
------------------------------------------------------------------------------------------->
<div id="dialogConfirmContainer" *ngIf="dialogConfirmContainer">


    <mat-card>
        <form action="" [formGroup]="lastform">

            <h5 id="title">Formulario Dr. {{doctorName}}</h5>

            <div id="dialogCheckboxConfirmContainer">


                <mat-form-field appearance="outline">
                    <mat-label>{{'FORMS-SURGERY.where_are_you' | translate}}</mat-label>
                    <mat-select formControlName="origin">
                      <mat-option value="1">{{'FORMS-SURGERY.in_consultation' | translate}}</mat-option>
                      <mat-option value="2">{{'FORMS-SURGERY.elsewhere' | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="textareaContainer">

                    <mat-form-field appearance="outline">
                        <mat-label>{{'FORMS-SURGERY.reason' | translate}} <span class="requiredInput">*</span> </mat-label>
                        <textarea matInput formControlName="reason"></textarea>
                    </mat-form-field>
    
                </div>


                <mat-checkbox style="white-space: pre-wrap;" formControlName="notFalseInformation">
                    {{'FORMS-SURGERY.notFalseInformation' | translate}}
                </mat-checkbox>

                <mat-checkbox style="white-space: pre-wrap;" formControlName="allowSavingInformation" >
                    {{'FORMS-SURGERY.allowSavingInformation' | translate}}
                </mat-checkbox>

                <mat-checkbox style="white-space: pre-wrap;" formControlName="changeFlight">
                    {{'FORMS-SURGERY.changeFlight' | translate}}
                </mat-checkbox>

                
            </div>


        
            <div id="dialogButtonConfirmContainer">
            
                <button mat-flat-button color="warn" (click)="dialogConfirmContainer=false">{{'FORMS-SURGERY.cancel_button' | translate}}</button>
                <button mat-flat-button color="primary" (click)="sendFormDoctorFinal()">{{'FORMS-SURGERY.send_button' | translate}}</button>
            </div>

            <mat-progress-bar *ngIf="dialogConfirmProgressContainer" mode="indeterminate"></mat-progress-bar>


        </form>
        
    </mat-card>


</div>






<!----------------------------------------------------------------------------------------------------------------------
    Card confirmation send Form
----------------------------------------------------------------------------------------------------------------------->
<div id="ConfirmationSendFormContainer" *ngIf="ConfirmationSendFormContainer">


    <mat-card>
        <div id="ConfirmationSendFormIcon">
            <img src="assets/img/info.png" alt="">
        </div>
        <div id="ConfirmationSendFormDoctorInfo">
            {{ "MODALAPPOINTMENT.request" | translate }}:
            <strong> {{doctorName}} </strong>
        </div>
        <div id="ConfirmationSendFormBody">

            <form [formGroup]="confirmationSendForm">

                  <mat-form-field appearance="outline">
                    <mat-label>{{ "MODALAPPOINTMENT.reason" | translate }}</mat-label>
                    <textarea matInput formControlName="reason"></textarea>
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label>{{ "FORMS.origin1" | translate }}</mat-label>
                    <mat-select formControlName="origin">
                      <mat-option value="1">{{ "FORMS.origin2" | translate }}</mat-option>
                      <mat-option value="2">{{ "FORMS.origin3" | translate }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  

                  <mat-checkbox formControlName="changeFlight">{{ "FORMS-SURGERY.rule1" | translate }}</mat-checkbox>
                  <mat-checkbox formControlName="notFalseInformation">{{ "FORMS-SURGERY.rule2" | translate }}</mat-checkbox>
                  <mat-checkbox formControlName="allowSavingInformation">{{ "FORMS-SURGERY.rule3" | translate }}</mat-checkbox>
            </form>
        </div>

        <div id="ConfirmationSendFormButton">
            <button mat-stroked-button color="warn" (click)="ConfirmationSendFormContainer=false">{{ "MODALAPPOINTMENT.close" | translate }}</button>
            <button mat-stroked-button color="primary" [disabled]="sendFormButton" (click)="sendForm()">{{ "MODALAPPOINTMENT.request-btn" | translate }}</button>
        </div>

        <mat-progress-bar *ngIf="ConfirmationSendFormContainerProgress" mode="query"></mat-progress-bar>

    </mat-card>

</div>




<div class="container-fluid">
<div class="row justify-content-center">
  <h3 class="text-muted mt-3">{{ "news.titlePage" | translate }}</h3>
</div>
<ng-container *ngIf="publicationList?.length > 0; else noFound">
  <div id="overflow">
    <div class="row row-cols-1 row-cols-md-3 g-4">
      <div class="col mb-4" *ngFor="let list of publicationList">
        <div class=" mat-card card h-100"
        [routerLink]="['/publication/', list.id]">
          <img
            [src]="list.image | imgFalse"
            class="card-img-top"
            alt="..."
          />
          <div class="card-body">
            <h5 class="card-title">{{ list.title }}e</h5>
            <p class="card-text">{{ list.text}}</p>
          </div>
          <div class="card-footer">
            <small class="text-muted">{{ list.created_at | date}}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
</div>

<ng-template #noFound  class="row">
  <div class="col-md-12 mt-5 mb-5 text-center">
      <i class=" mt-5 fa fa-exclamation-triangle text-warning fa-3x "></i>
      <h4 class=" text-muted mt-3 mb-5 ">{{ "PAYMENT.no-result" | translate }}</h4>
  </div>
  </ng-template>
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { TranslateService } from '@ngx-translate/core';
import * as io from "socket.io-client";
import { DynamicFormModel } from '../classContainer/dynamic-form';

@Injectable({
  providedIn: 'root'
})
export class DirectFormService {

  // Todo lo que sea comun de las ur lo guardamos en una variable
  private url = environment.ApiUrl;
  private socket;
  public idUser: string;


  constructor(private http: HttpClient,
              private translate: TranslateService) 
  { 
    this.idUser = localStorage.getItem("idUser");
    this.socket = io(environment.SOCKET_ENDPOINT);
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //Conexion y desconexion del socket para notificar del envio del formulario
  public initConectionSocket() {
    //console.log("Conectar Socket");
    //console.log(this.idUser);

    const usuario = {
      user_id: this.idUser,
    };
    this.socket.emit("joinSystem", usuario);
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  // desconexion del socket para notificar del envio del formulario
  public disconnectSocket() {
    //console.log("Desconectar Socket");
    this.socket.emit("disconnect");
  }


  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Notifica al doctor de un nuevo formulario envia
  public sendNotificationDoctor( doctorId: any, origin ) { 
    
    //console.log(this.idUser);
    //console.log(doctorId);
    const data = {
      doctor_id: doctorId,
      user_id: this.idUser,
      origin: origin,
    }
    //console.log(data);
    this.socket.emit('NotificationNewRequestDirectForm', data); 
  }


  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Notifica al doctor de un nuevo formulario envia
  public sendNotificationDoctor2( doctorId: any, origin, idUser ) { 
    
    //console.log(idUser);
    //console.log(doctorId);
    const data = {
      doctor_id: doctorId,
      user_id: idUser,
      origin: origin,
    }
    //console.log(data);
    this.socket.emit('NotificationNewRequestDirectForm', data); 
  }



  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //Lista de idiomas
  public getLanguage() {
    return this.http.get( this.url + '/languages');
  }


  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //Devuelve la informacion del doctor
  public infoDoctor( codeDoctor: any) {

    ////console.log(this.url);
    ////console.log(codeDoctor);

    return this.http.get( this.url + '/doctor-form-direct/' + codeDoctor );

  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //Detecta si el email esta ya en uso en la plataforma
  public emailVerifier( email: string ) {

    ////console.log(email);
    let data = { 
      email: email 
    }

    ////console.log(data);

    return this.http.post( this.url + '/validate-email', data );

  }



  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Crea el formulario basico
  public postForm( form:any, heightWeightIMC?:any, patient_id?:number, dynamicForm?:any, moduleList?:any, imagesGroup?:any, dataConfirmation?:any ) {
  
    ////console.log(form);
    ////console.log(form.dateBirth);
    //console.log(heightWeightIMC);

    this.translate.getBrowserLang();
    ////console.log(this.translate.getBrowserLang());
    let primary_language_id: string = this.translate.getBrowserLang();

    let languagesToSend: any[] = [];
    let array: any[] = [];
    array = form.languages;
    for( let item of array ) {
      ////console.log(item);
      languagesToSend.push({id:item.id});
    }

    let date2
    let day;
    let month;
    let year;
    let newDateofbirth:any;
    let last_transfusion_date: any;
    let last_pregnancy_date: any;
    ////console.log(form);
    ////console.log(form.birthday);
    if( form.birthday != "Invalid date" && form.dateBirth.length != 0 ) {
      ////console.log("Entre en cumpleaños");

      date2 = new Date(form.dateBirth);
      //date2 = form.dateofbirth;
      ////console.log(date2);

      day = date2.getDate();
      month = date2.getMonth();
      year = date2.getFullYear();

      newDateofbirth = year + "/" + (month + 1) + "/" + day;
    }

    date2 = new Date(form.last_transfusion_date);
    ////console.log(date2);
    day = date2.getDate();
    month = date2.getMonth();
    year = date2.getFullYear();
    last_transfusion_date = year + "/" + (month + 1) + "/" + day;


    date2 = new Date(form.lastpregnancy);
    ////console.log(date2);
    day = date2.getDate();
    month = date2.getMonth();
    year = date2.getFullYear();
    last_pregnancy_date = year + "/" + (month + 1) + "/" + day;

    ////console.log(languagesToSend);


    let dateofbirth = new Date(newDateofbirth);
    let yearDateofbirth:any;
      //dateofbirth = resp.dateofbirth;

    let currentDate = new Date();

    let age = currentDate.getFullYear() - dateofbirth.getFullYear();

    dateofbirth.setFullYear( currentDate.getFullYear() );

    if( currentDate < dateofbirth ) {
      --age;
    }
      
    //yearDateofbirth = age;

    ////console.log(age);
    //////console.log(yearDateofbirth);

    //let dynamicFormClass:any = new DynamicFormModel;
    let dynamicFormClass:any = new DynamicFormModel();
    //console.log(dynamicFormClass);
    //console.log(dynamicFormClass.formModule1);
    //console.log(dynamicFormClass.subModulesCreateArray( dynamicForm, moduleList ));

    let sub_modules:any;
    sub_modules = dynamicFormClass.subModulesCreateArray( dynamicForm, moduleList );


    let data = {


      patient: {
        first_name: form.first_name,
        last_name: form.last_name,
        passport: form.identification,
        occupation: form.occupation,
        birthday: newDateofbirth,
        age: age,
        civil_status: form.status,
        religion: form.religion,
        phone: form.phone,
        primary_language_id: primary_language_id,
        email: form.email,
        address: form.address,
        emergency_person: form.emergencyPerson,
        emergency_contact: form.emergencyPersonPhone,
        city: form.city,
        country: form.country,
        language: languagesToSend,
        place_of_stay: form.residence, 
        place_of_stay_phone: form.residencePhone, 
        passport_picture: imagesGroup.passport,
        profile_picture: imagesGroup.profile,

      },
      patient_history: {

        referred: form.referred,
        last_pregnancy_date: last_pregnancy_date || null,
        previous_surgeries: form.previousSurgeries,
        gender: form.gender,
        weight: heightWeightIMC.weight,
        height: heightWeightIMC.height,
        imc: heightWeightIMC.imc,

        had_covid: form.had_covid,
        vaccinated_covid: form.vaccinated_covid,

        front_picture: imagesGroup.front,
        back_picture: imagesGroup.back,
        left_picture: imagesGroup.left,
        right_picture: imagesGroup.right,

      },

      sub_modules: sub_modules,

    };
    //this.idUser = localStorage.getItem("id") || undefined;

    //console.log(data);
    ////console.log(patient_id);

    //////console.log(this.idUser);


    // Determinar de donde viene el formulario, surgery o esthetic
    return this.http.post(this.url + "/new-form/" + patient_id, data);



  }



  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //Envia y actualiza el formulario del paciente
  public sendRequest(info: any, patient_id:number) {
    //this.idUser = localStorage.getItem("id");

    ////console.log(patient_id);
    ////console.log(info);
    const data = {
      reason: info.reason,
      doctor_id: info.doctor_id,
      origin: info.origin,
      procedure_type_identifier: info.procedure_type_identifier,
      assistants_agreement: info.assistants_agreement,
      flight_agreement : info.flight_agreement,
      information_agreement : info.information_agreement
    };

    //console.log(data);

    return this.http.post( this.url + '/new-request-direct/' + patient_id, data);
  }


  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //Enviar Imagenes
  public sendImagest( imgObject: any, destination: string, patient_id:number ) {
    //this.idUser = localStorage.getItem("id");
    ////console.log(destination);
    ////console.log(patient_id);
    ////console.log(imgObject);
    
    const fd = new FormData();

    fd.append(destination,imgObject.file,imgObject.name);

    return this.http.post( this.url + '/form/image-patient/' + patient_id, fd);
  }



  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Crea el formulario basico General
  public postFormGeneral( form:any, dynamicForm:any, moduleList:any, urlImage:any, heightWeightIMC?:any, patient_id?:number, code_doctor?:any  ) {


    //console.log(form);
    //console.log(dynamicForm);
    //console.log(urlImage);
    //console.log(heightWeightIMC);
    //console.log(patient_id);
    //console.log(code_doctor);
    ////console.log(form);
    ////console.log(form.dateBirth);
    ////console.log(heightWeightIMC);

    this.translate.getBrowserLang();
    ////console.log(this.translate.getBrowserLang());
    let primary_language_id: string = this.translate.getBrowserLang();

    let languagesToSend: any[] = [];
    let array: any[] = [];
    array = form.languages;
    for( let item of array ) {
      ////console.log(item);
      languagesToSend.push({id:item.id});
    }

    let date2
    let day;
    let month;
    let year;
    let newDateofbirth:any;

    ////console.log(form);
    ////console.log(form.birthday);
    if( form.birthday != "Invalid date" && form.dateBirth.length != 0 ) {
      ////console.log("Entre en cumpleaños");

      date2 = new Date(form.dateBirth);
      //date2 = form.dateofbirth;
      ////console.log(date2);

      day = date2.getDate();
      month = date2.getMonth();
      year = date2.getFullYear();

      newDateofbirth = year + "/" + (month + 1) + "/" + day;
    }



    ////console.log(languagesToSend);


    let dateofbirth = new Date(newDateofbirth);
    let yearDateofbirth:any;
      //dateofbirth = resp.dateofbirth;

    let currentDate = new Date();

    let age = currentDate.getFullYear() - dateofbirth.getFullYear();

    dateofbirth.setFullYear( currentDate.getFullYear() );

    if( currentDate < dateofbirth ) {
      --age;
    }
      
    //yearDateofbirth = age;

    ////console.log(age);
    //////console.log(yearDateofbirth);


    //let dynamicFormClass:any = new DynamicFormModel;
    let dynamicFormClass:any = new DynamicFormModel();
    //console.log(dynamicFormClass);
    //console.log(dynamicFormClass.formModule1);
    //console.log(dynamicFormClass.subModulesCreateArray( dynamicForm, moduleList ));

    let sub_modules:any;
    sub_modules = dynamicFormClass.subModulesCreateArray( dynamicForm, moduleList );



    let data = {
      patient: {
        first_name: form.first_name,
        last_name: form.last_name,
        passport: form.identification,
        occupation: form.occupation,
        birthday: newDateofbirth,
        age: age,
        civil_status: form.status,
        religion: form.religion,
        phone: form.phone,
        primary_language_id: primary_language_id,
        email: form.email,
        address: form.address,
        emergency_person: form.emergencyPerson,
        emergency_contact: form.emergencyPersonPhone,
        city: form.city,
        country: form.country,
        language: languagesToSend,
        place_of_stay: form.residence, 
        place_of_stay_phone: form.residencePhone, 
      },
      patient_history: {
        gender: form.gender,
        weight: heightWeightIMC.weight,
        height: heightWeightIMC.height,
        imc: heightWeightIMC.imc,

      },

      origin: form.location,
      profile_picture_buf: urlImage,
      sub_modules: sub_modules,
      reason: form.reason
    };


    //console.log(data);
    return this.http.post(this.url + "/new-request-direct-form-general/" + code_doctor, data);

  }


  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Crea el formulario basico General
  public postFormSurgery( form:any, heightWeightIMC:any, patient_id:any, dynamicForm:any, moduleList?:any, imagesGroup?:any, dataConfirmation?:any ) {


    //console.log(form);
    //console.log(dynamicForm);
    //console.log(heightWeightIMC);
    //console.log(patient_id);
    //console.log(dataConfirmation);
    //console.log(imagesGroup);

    ////console.log(form);
    ////console.log(form.dateBirth);
    ////console.log(heightWeightIMC);

    this.translate.getBrowserLang();
    ////console.log(this.translate.getBrowserLang());
    let primary_language_id: string = this.translate.getBrowserLang();

    let languagesToSend: any[] = [];
    let array: any[] = [];
    array = form.languages;
    for( let item of array ) {
      ////console.log(item);
      languagesToSend.push({id:item.id});
    }

    let date2
    let day;
    let month;
    let year;
    let newDateofbirth:any;

    ////console.log(form);
    ////console.log(form.birthday);
    if( form.birthday != "Invalid date" && form.dateBirth.length != 0 ) {
      ////console.log("Entre en cumpleaños");

      date2 = new Date(form.dateBirth);
      //date2 = form.dateofbirth;
      ////console.log(date2);

      day = date2.getDate();
      month = date2.getMonth();
      year = date2.getFullYear();

      newDateofbirth = year + "/" + (month + 1) + "/" + day;
    }



    ////console.log(languagesToSend);


    let dateofbirth = new Date(newDateofbirth);
    let yearDateofbirth:any;
      //dateofbirth = resp.dateofbirth;

    let currentDate = new Date();

    let age = currentDate.getFullYear() - dateofbirth.getFullYear();

    dateofbirth.setFullYear( currentDate.getFullYear() );

    if( currentDate < dateofbirth ) {
      --age;
    }
      
    //yearDateofbirth = age;

    ////console.log(age);
    //////console.log(yearDateofbirth);


    //let dynamicFormClass:any = new DynamicFormModel;
    let dynamicFormClass:any = new DynamicFormModel();
    //console.log(dynamicFormClass);
    //console.log(dynamicFormClass.formModule1);
    //console.log(dynamicFormClass.subModulesCreateArray( dynamicForm, moduleList ));

    let sub_modules:any;
    sub_modules = dynamicFormClass.subModulesCreateArray( dynamicForm, moduleList );



    let data = {
      patient: {
        first_name: form.first_name,
        last_name: form.last_name,
        passport: form.identification,
        occupation: form.occupation,
        birthday: newDateofbirth,
        age: age,
        civil_status: form.status,
        religion: form.religion,
        phone: form.phone,
        primary_language_id: primary_language_id,
        email: form.email,
        address: form.address,
        emergency_person: form.emergencyPerson,
        emergency_contact: form.emergencyPersonPhone,
        city: form.city,
        country: form.country,
        language: languagesToSend,
        place_of_stay: form.residence, 
        place_of_stay_phone: form.residencePhone, 
        passport_picture: imagesGroup.passport,
        profile_picture: imagesGroup.profile,
      },
      patient_history: {
        gender: form.gender,
        weight: heightWeightIMC.weight,
        height: heightWeightIMC.height,
        imc: heightWeightIMC.imc,
        front_picture: imagesGroup.front,
        back_picture: imagesGroup.back,
        left_picture: imagesGroup.left,
        right_picture: imagesGroup.right,
        esthetic_picture: imagesGroup.esthetic_picture,
        esthetic_picture_2: imagesGroup.esthetic_picture_2,
        
      },

      origin: dataConfirmation.origin,
      procedure_type_id:dataConfirmation.procedure_type,
      assistants_agreement:dataConfirmation.assistants_agreement,
      flight_agreement:dataConfirmation.flight_agreement,
      information_agreement:dataConfirmation.information_agreement,
      sub_modules: sub_modules,
      reason: dataConfirmation.reason,
      
    };


    //console.log(data);
    return this.http.post(this.url + "/new-request-direct-form-surgery/" + dataConfirmation.code_doctor, data);

  }



  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  //Funcion encargada de crear el array con los modulos y atributos para el envio
  public getDynamicForm( code_doctor ) {

    //console.log(code_doctor);

    return this.http.get( this.url + '/get-dinamic-form-module-code/' + code_doctor );
  }



  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  //Devuelve si el paciente tiene o no ya una cuenta con el doctor
  public getPatientInfo( infoID, code_doctor ) {

    //console.log(code_doctor);
    let data = {
      email: infoID,
    }

    //console.log(data);

    return this.http.post( this.url + '/validate-patient-archived/' + code_doctor, data );
  }


  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  //Devuelve si el paciente tiene o no ya una cuenta con el doctor
  public postReasonQuery( reason, location,  patient_id, doctor_code ) {

    //console.log(doctor_code);
    //console.log(patient_id);
    let data = {
      doctor_code: doctor_code,
      reason: reason,
      origin: location, //Para formulario directo 1 y para paciente logueado 2
    }

    //console.log(data);

    return this.http.post( this.url + '/new-request-direct-general/' + patient_id, data );
  }


  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  // validar si el formulario esta completo
  public validateFormComplete() {
    this.idUser = localStorage.getItem("id");

    return this.http.get( this.url + '/validate-form/' + this.idUser );
  }



  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  // validar si el formulario esta completo
  public sendRequestDirect(info: any) {
    this.idUser = localStorage.getItem("id");
    const data = {
      reason: info.reason,
      doctor_id: info.doctorId,
      origin: info.origin,
      procedure_type_identifier: info.selectedInformacion,
      assistants_agreement: info.assistants_agreement,
      flight_agreement : info.flight_agreement,
      information_agreement : info.information_agreement
    };

    return this.http.post( this.url + '/new-request/' + this.idUser, data );
    /*
    return this.http.post(`${this.url}/new-request/` + this.idUser, body).pipe(
      map((resp) => {
        this.sendNotificationDoctor(info.doctorId, info.origin);
        return resp;
      })
    );*/
  }



  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  // validar si el formulario esta completo
  // put doctor favorite
  public putNotificationDoctor(doctorId) {
    this.idUser = localStorage.getItem("id");
    const data = {
      doctor_id: doctorId,
    };
    return this.http.put( this.url + '/update-favorite/' + this.idUser, data );

  }

  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  public getNotification() {
    this.idUser = localStorage.getItem("id");

    return this.http.get( this.url + '/get-notification/' + this.idUser );

  }
  
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  public sendNotificationDoctorDirect( doctorId: string, origin: number) {
    this.idUser = localStorage.getItem('idUser');
    const data = {
     doctor_id: doctorId,
     origin: origin,
     user_id: this.idUser
    }
    this.socket.emit('NotificationNewRequest', data);
  }


}
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import * as io from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { FormModel } from "../models/form.model";
import { TranslateService } from "@ngx-translate/core";
import { DynamicFormModel } from 'src/app/classContainer/dynamic-form';

@Injectable({
  providedIn: "root",
})
export class FormService {
  // Todo lo que sea comun de las ur lo guardamos en una variable
  private url = environment.ApiUrl;
  languague: string;
  private socket;
  private idUser: string;
  // Usuario id
  private id: string;

  constructor(private http: HttpClient,
    private translate: TranslateService) {
    // Recuperamos el id del localstorage
    this.id = localStorage.getItem("id");
    this.languague = this.translate.getBrowserLang();
    this.socket = io(environment.SOCKET_ENDPOINT);
  }



  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Recuerar imagenes
  public getImages() {

    this.id = localStorage.getItem("id");

    return this.http.get(this.url + "/search-image/" + this.id );

  }


  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //Enviar Imagenes
  public sendImagest( imgObject: any, destination: string, patient_id:number ) {
    //this.idUser = localStorage.getItem("id");
    //console.log(destination);
    //console.log(patient_id);
    //console.log(imgObject);
    
    const fd = new FormData();

    fd.append(destination,imgObject.file,imgObject.name);

    //console.log(fd);

    return this.http.post( this.url + '/form/image-patient/' + patient_id, fd);
  }





  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  // Crea el formulario basico
  public createBasicForm( form: any, heightWeightIMC: any, patient_id:any, dynamicForm:any, moduleList:any, imagesGroup?:any) {
  

    
    console.log(form);
    console.log(heightWeightIMC);
    console.log(patient_id);
    console.log(dynamicForm);
    console.log(moduleList);
    console.log(imagesGroup);
    

    //let dynamicFormClass:any = new DynamicFormModel;
    let dynamicFormClass:any = new DynamicFormModel();
    console.log(dynamicFormClass);
    console.log(dynamicFormClass.formModule1);
    console.log(dynamicFormClass.subModulesCreateArray( dynamicForm, moduleList ));


    let sub_modules:any;
    sub_modules = dynamicFormClass.subModulesCreateArray( dynamicForm, moduleList );

    console.log(sub_modules);

    

    let language = document.documentElement.lang;
    console.log(language);

    if ( language === 'es' || language === 'es-ES' ) {
      language = 'es';
    } 
    else if ( language === 'en' || language === 'en-US') {
      language = 'en';
    }
    else {
      language = 'en';
    }

    let languagesArray:any[] = [];
    languagesArray = form.languages;
    let languages:any[] = [];
    for( let item of languagesArray ) {

      languages.push({id:item});
    }


    const data = {
      patient: {
        first_name: form.first_name,
        last_name: form.last_name,
        passport: form.identification,
        occupation: form.occupation,
        birthday: form.dateBirth,
        civil_status: form.status,
        religion: form.religion,
        phone: form.phone,
        primary_language_id: language,
        email: form.email,
        address: form.address,
        emergency_person: form.emergencyPerson,
        emergency_contact: form.emergencyPersonPhone,
        city: form.city,
        country: form.country,
        passport_picture: imagesGroup.passport,
        profile_picture: imagesGroup.profile,
        language: languages

      },
      patient_history: {

        weight: heightWeightIMC.weight,
        height: heightWeightIMC.height,
        imc: heightWeightIMC.imc,
        front_picture: imagesGroup.front,
        back_picture: imagesGroup.back,
        left_picture: imagesGroup.left,
        right_picture: imagesGroup.right,

        esthetic_picture: imagesGroup.esthetic_picture,
        esthetic_picture_2: imagesGroup.esthetic_picture_2,

        gender: form.gender,

      },

      sub_modules: sub_modules,

    };

    console.log(data);

    this.id = localStorage.getItem("id");

    return this.http.post(this.url + "/new-form/" + this.id, data);
    // Determinar de donde viene el formulario, surgery o esthetic
    /*
    if (origen === "surgery") {
   
      this.id = localStorage.getItem("id");
      return this.http.post(this.url + "/new-form/" + this.id, data).pipe(
        map((resp) => {
          form.id = parseInt(this.id);
          return resp;
        })
      );
    } else {
      return this.http.post(this.url + "/new-form/" + this.id, data).pipe(
        map((resp: any) => {
        
          form.id = parseInt(this.id);
          return resp;
        })
      );
    }*/
  }




  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  // metodo para traer los datos del formulario si el usuario anteriormente ha completado el formulario
  public getData() {
    this.id = localStorage.getItem("id");
    return this.http.get(this.url + "/get-form/" + this.id).pipe(
      map((formData) => {
        return formData;
      })
    );
  }



  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  public searchDoctor(nameDoctor: any) {
    return this.http.post(`${this.url}/search-doctors`, nameDoctor).pipe(
      map((resp) => {
        return resp;
      })
    );
  }




  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  public confirmDoctor(id: number) {
    return this.http.get(`${this.url}/search-doctor/` + id).pipe(
      map((resp) => {
        return resp;
      })
    );
  }



  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  public validateEmailExist( email: string) {
    const body = {
      email: email
    }
    return this.http.post(`${ this.url}/validate-email`, body)
    .pipe( map( (response) => {
      return response;
    })
    );
  }




  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  public getLenguaje() {
    return this.http.get(`${this.url}/languages`).pipe(
      map((Response) => {
        return Response;
      })
    );
  }




  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  public sendNotificationDoctor( doctorId: string) {
    this.idUser = localStorage.getItem('idUser');
    const data = {
     doctor_id: doctorId,
     user_id: this.idUser
    }
    this.socket.emit('NotificationNewRequest', data);
  }




  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  public initConectionSocket() {
    this.idUser = localStorage.getItem('idUser');
    const usuario = {
      user_id: this.idUser
    }
    this.socket.emit('joinSystem', usuario); 
  }




  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  public disconnectSocket() {
    this.socket.emit('disconnect');
  }




  
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  public getProcedureByDoctor(idDoctor: any) {
    return this.http.get(`${this.url}/doctor-procedureType-code/${idDoctor}`)
    .pipe( map( Response => {
      return Response;
    }));
  }





  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  //Lista de doctores que han sido rellenados con formulario directo
  public getDoctorsLisDynamicForm() {

    //console.log(this.doctorID);
    this.idUser = localStorage.getItem('idUser');
    console.log(this.idUser);

    return this.http.get( this.url + '/-------------------/' + this.idUser );
  }



  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  //Funcion encargada de crear el array con los modulos y atributos para el envio
  public getDynamicForm( code_doctor ) {

    console.log(code_doctor);
    this.idUser = localStorage.getItem("id");
    let data = {
      patient_id: this.idUser
    };
    console.log(data);

    return this.http.post( this.url + '/get-dinamic-form-module-values-code/' + code_doctor, data );
  }


  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  //Funcion encargada de crear el array con los modulos y atributos para el envio
  public getDoctorListWithForm( ) {

    
    this.idUser = localStorage.getItem("id");
    console.log(this.idUser);

    return this.http.get( this.url + '/get-list-doctor-request/' + this.idUser );
  }



  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  //Funcion encargada de crear el array con los modulos y atributos para el envio
  public sendRequest( form: any, code_doctor, procedure_type_id ) {
    this.idUser = localStorage.getItem("id");
    const data = {
      reason: form.reason,
      doctor_code: code_doctor, //Codigo del doctor
      origin: form.origin,
      procedure_type_id: procedure_type_id, //Id del tratamiento
      assistants_agreement: true,
      flight_agreement : true,
      information_agreement : true
    };

    console.log(data);

    return this.http.post(`${this.url}/new-request-form/` + this.idUser, data);
  }


  ///////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////
  public sendNotificationDoctor2( doctorId: string, origin: any) {
    this.idUser = localStorage.getItem('idUser');
    const data = {
     doctor_id: doctorId,
     origin: origin,
     user_id: this.idUser
    }

    console.log(data);
    
    this.socket.emit('NotificationNewRequestDirectForm', data);
  }


  
  
}

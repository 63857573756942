<div class="container-fluid">
  <div class="row justify-content-center">
    <h3 class="text-muted mt-3">{{ "GALLERY.titlePage" | translate }}</h3>
  </div>
  <!--Imagen espandida-->
  <div id="FullScreen" *ngIf="showFullImage" class="animated fadeIn fast">
    <div>
      <button mat-raised-button (click)="closeFullImage()">
        <i class="fas fa-times fa-2x"> </i>
      </button>
    </div>
    <img [src]="imgUrl" alt="" />
  </div>
  <div class="row justify-content-center">
    <mat-card id="mat-search">
      <div id="headerGallery">
        <form
          #form="ngForm"
          (ngSubmit)="search(form)"
          class="row"
          autocomplete="off"
        >
          <div id="firstDiv">
            <div class="form-inline my-2 my-lg-0">
              <input
                class="form-control col"
                name="search"
                (keyup)="search(form)"
                [ngModel]="galleryForm.search"
                type="search "
                placeholder=" {{ 'GALLERY.input' | translate }} "
                aria-label="Search"
                [disabled]="countryTotal.length === 0"
              />
              <button
                class="btn btn-outline-danger"
                type="button "
                (click)="search(form)"
              >
                <i class="fa fa-search"></i> {{ "GALLERY.search" | translate }}
              </button>
            </div>
            <div id="marg" class="mb-5">
              <select
                class="custom-select"
                id="inputGroupSelect01"
                name="country"
                ngModel
                #country="ngModel"
              >
                <option value="" selected>
                  {{ "GALLERY.country" | translate }}
                </option>
                <option
                  *ngFor="let country of countryTotal"
                  [value]="country.id"
                >
                  {{ country.name }}
                </option>
              </select>
            </div>
          </div>
        </form>
      </div>
      <div id="secondDiv">
        <div
          class="btn-toolbar mb-3 ml-4 mr-4"
          role="toolbar "
          aria-label="Toolbar with button groups"
          id="groupOption"
        >
          <div
            class="btn-group row"
            id="optionContainer"
            role="group "
            aria-label="First group"
          >
            <button
              type="button"
              class="btn btn-outline-secondary"
              #m
              value="all"
              (click)="getAllGallery()"
            >
              {{ "GALLERY.AllGallery" | translate }}
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary"
              #m
              value="medicine"
              (click)="optionGallery(m.value)"
            >
              {{ "GALLERY.medicine" | translate }}
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary"
              #s
              value="surgery"
              (click)="optionGallery(s.value)"
            >
              {{ "GALLERY.surgery" | translate }}
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary"
              #e
              value="aesthetic_treatment"
              (click)="optionGallery(e.value)"
            >
              {{ "GALLERY.esthetic" | translate }}
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary"
              #t
              value="treatments"
              (click)="optionGallery(t.value)"
            >
              {{ "GALLERY.treatments" | translate }}
            </button>
            <button
              type="button"
              class="btn btn-outline-secondary"
              #l
              value="lodgment"
              (click)="optionGallery(l.value)"
            >
              {{ "GALLERY.lodgment" | translate }}
            </button>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
  <hr class="ml-auto mr-auto w-90" />
  <div class="row justify-content-center">
    <div id="bodyGallery" *ngIf="startGallery">
      <div id="" class="row justify-content-center" *ngIf="loading">
        <app-loading class=""></app-loading>
      </div>
      <ng-container *ngIf="gallery.length > 0; else noResult">
        <div class="card-columns animated fadeIn fast">
          <div class="card mb-5" *ngFor="let item of gallery; let i = index">
            <mat-card>
              <img
                [src]="item.url | imgFalse"
                class="card-img-top pointer-img"
                (click)="fullImage(item.url)"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title">{{ item.doctor_name }}</h5>
                <p class="card-text">{{ item.description }}</p>
                <p class="card-text">
                  <small class="text-muted">{{ item.date | date }}</small>
                </p>
                <button
                  class="btn btn-outline-danger btn-block"
                  (click)="nextView(item.doctor_id)"
                >
                  Mas ...
                </button>
              </div>
            </mat-card>
          </div>
        </div>
      </ng-container>
    </div>
    <div id="bodyGallery" *ngIf="!startGallery">
      <hr />
      <ng-container>
        <div class="card-columns">
          <div class="card mb-5" *ngFor="let item of gallery; let i = index">
            <mat-card>
              <img
                [src]="item.url | imgFalse"
                class="card-img-top pointer-img"
                (click)="fullImage(item.url)"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title">{{ item.doctor_name }}</h5>
                <p class="card-text">{{ item.description }}</p>
                <p class="card-text">
                  <small class="text-muted">{{ item.date | date }}</small>
                </p>
                <button
                  class="btn btn-outline-danger btn-block"
                  (click)="nextView(item.doctor_id)"
                >
                  Mas ...
                </button>
              </div>
            </mat-card>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-template #noResult>
    <div class="col-md-12 mt-5 text-center" *ngIf="!loading">
      <i class="mt-5 fa fa-exclamation-triangle text-warning fa-3x"></i>
      <h4 class="text-muted mt-3">{{ "PAYMENT.no-result" | translate }}</h4>
    </div>
  </ng-template>
</div>
<div *ngIf="noFound === true" class="row">
  <div class="col-md-12 mt-5 mb-5 text-center">
    <i class="mt-5 fa fa-exclamation-triangle text-warning fa-3x"></i>
    <h4 class="text-muted mt-3">{{ "PAYMENT.no-found" | translate }}</h4>
  </div>
</div>

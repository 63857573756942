import { Component, OnInit } from "@angular/core";
import { FormService } from "../../../services/form.service";
import { SearchService } from "../../../services/search.service";
import { TranslateServices } from "../../../services/translate.service";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ChatSocketService } from '../../../services/chat-socket.service';
import { error } from "console";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-modal-appointment",
  templateUrl: "./modal-appointment.component.html",
  styleUrls: ["./modal-appointment.component.css"],
})
export class ModalAppointmentComponent implements OnInit {
  procedureByDoctor: Array<any> = [];
  idDoctor: string;
  form: FormGroup;
  currentUrl: string;
  lang: string;
  showOrigin: boolean;
  showEs: boolean;
  doctorInfo: string;

  constructor(
    private formService: FormService,
    private search: SearchService,
    private router: Router,
    private fb: FormBuilder,
    public translate: TranslateServices,
    private chatSocketService: ChatSocketService,
    public dialogRef: MatDialogRef<ModalAppointmentComponent>
  ) {
    this.idDoctor = localStorage.getItem("idDoctor");
    this.doctorInfo = localStorage.getItem("doctorSelectedInfo");
  
  }

  ngOnInit(): void {


    console.log("Entre en modal !!!!");
    this.getProcedureByDoctor();
    this.createForm();
    this.lang = navigator.language;

    let url = window.location.href;
    let split = url.split("/", 4);
    this.currentUrl = split[3];

    if (this.currentUrl === "form-direct") {
      this.showOrigin = true;
    } else {
      this.showOrigin = false;
      const origin = this.form.get('origin');
      origin.setValidators(null);
      origin.updateValueAndValidity();
    }
  }
  get procedureDoctor() {
    return (
      this.form.get("procedure").invalid && this.form.get("procedure").invalid
    );
  }
  get originNoValid() {
    return this.form.get("origin").invalid && this.form.get("origin").touched;
  }

  get reasonNoValid() {
    return this.form.get("reason").invalid && this.form.get("reason").touched;
  }

  get allowSavingInformationNoValid() {
    return (
      this.form.get("allowSavingInformation").invalid &&
      this.form.get("allowSavingInformation").touched
    );
  }

  get notFalseInformationNoValid() {
    return (
      this.form.get("notFalseInformation").invalid &&
      this.form.get("notFalseInformation").touched
    );
  }

  get changeFlightNoValid() {
    return (
      this.form.get("changeFlight").invalid &&
      this.form.get("changeFlight").touched
    );
  }

  getProcedureByDoctor() {
    const idDoctor = localStorage.getItem("idDoctor");

    this.formService
      .getProcedureByDoctor(idDoctor)
      .subscribe((Response: any) => {
        if (this.lang === "es-ES" || this.lang === "es") {
          var procedure = [];
          for (const iterator of Response.procedureTypes) {
            procedure.push({ name: iterator.name_es, identifier: iterator.identifier });
          }
          this.procedureByDoctor = procedure;
        } else {
          var procedure = [];
          for (const iterator of Response.procedureTypes) {
            procedure.push({ name: iterator.name, identifier: iterator.identifier});
          }
          this.procedureByDoctor = procedure;
        }
       
      });
  }

  createForm() {
    this.form = this.fb.group({
      reason: ["", [Validators.required]],
      procedure: ["", [Validators.required]],
      changeFlight: [false, [Validators.requiredTrue]],
      notFalseInformation: [false, [Validators.requiredTrue]],
      allowSavingInformation: [false, [Validators.requiredTrue]],
      origin: ["",[Validators.required]]
    });
  }

  requestAppointment( procedure:string, reason: string) {
    this.search.validateFormComplete().subscribe((resp: any) => {
      if ( this.form.invalid || this.form.value.assistants_agreement === false || this.form.value.flight_agreement === false || this.form.value.information_agreement === false) {
      Object.values(this.form.controls).forEach((control) => {
        // Todos los valores han sido tocados
          control.markAllAsTouched();
          return;
       });
    }
    
      if (this.form.valid ) {
        if (resp.ok === true) {
          if (this.lang === "es-ES" || this.lang === "es") {
            Swal.fire({
              icon: "info",
              allowOutsideClick: false,
              text: "Espere por favor ...",
            });
          } else if (this.lang === "en-US" || this.lang === "en") {
            Swal.fire({
              icon: "info",
              allowOutsideClick: false,
              text: "Please wait ...",
            });
          } else {
            Swal.fire({
              icon: "info",
              allowOutsideClick: false,
              text: "Please wait ...",
            });
          }
      
          // Simulación de loading
          Swal.showLoading();
      
          if (reason && procedure ) {

            const body = {
              selectedInformacion: procedure,
              reason: reason,
              doctorId: this.idDoctor,
              origin: this.form.value.origin,
              assistants_agreement: true,
              flight_agreement: true,
              information_agreement: true
            };

            this.search.sendRequest(body).subscribe((resp: any) => {
              localStorage.removeItem("idDoctor");
              let url = window.location.href;
              let split = url.split("/", 4);
              this.currentUrl = split[3];
              Swal.close();
              if (resp.Ok === true) {
                this.dialogRef.close();
                this.chatSocketService.notificationManager();
                if (this.currentUrl === "form-direct") {

                  if (this.lang === "es-ES" || this.lang === "es") {
                    Swal.fire(
                      "Solicitud de cita enviado correctamente",
                      `Se le ha creado una cuenta con éxito,
                       su nombre de usuario y contraseña  han sido enviados a su correo electrónico para iniciar sesión.`,
                      "success"
                    );
                    
                  } else if (this.lang === "en-US" || this.lang === "en") {
                    Swal.fire(
                      "Appointment request has been sent successfully",
                      "An account has been created successfully, your username and password have been sent to your email to log in.",
                      "success"
                    );
                  } else {
                    Swal.fire(
                      "Appointment request has been sent successfully",
                      "An account has been created successfully, your username and password have been sent to your email to log in.",
                      "success"
                    );
                  }

                  localStorage.removeItem("id");
                  localStorage.removeItem("idUser");
                  
                  this.formService.disconnectSocket();
                  this.router.navigateByUrl("/login");

                } else {
                  if (this.lang === "es-ES" || this.lang === "es") {
                    Swal.fire(
                      "Enviado",
                      "Se ha enviado la solicitud de cita correctamente",
                      "success"
                    );
                  } else if (this.lang === "en-US" || this.lang === "en") {
                    Swal.fire(
                      "Sent",
                      "Appointment request has been sent successfully",
                      "success"
                    );
                  } else {
                    Swal.fire(
                      "Sent",
                      "Appointment request has been sent successfully",
                      "success"
                    );
                  }
                }
              } else if (resp.exist === true) {
                this.dialogRef.close();
                
                if (this.lang === "es-ES" || this.lang === "es") {
                  Swal.fire(
                    " ",
                    "Usted ya ha solicitado a este doctor y está pendiente de respuesta.",
                    "warning"
                  );
                } else if (this.lang === "en-US" || this.lang === "en") {
                  Swal.fire(
                    " ",
                    "You have already requested this doctor and a response is pending.",
                    "warning"
                  );
                } else {
                  Swal.fire(
                    " ",
                    "You have already requested this doctor and a response is pending.",
                    "warning"
                  );
                }
              }
              if (resp.OK === false && resp.exist === false) {
                Swal.fire({
                  icon: "error",
                  title: "Error",
                  text: resp.Error,
                });
              }
            }, (error: HttpErrorResponse) => {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: error.message,
              });
            });
          }
        } else if (resp.ok === false) {

          if (this.lang === "es-ES" || this.lang === "es") {
            Swal.fire({
              title: "Error!",
              text: "Favor completar el formulario, antes de solicitar una cita",
              icon: "error",
              confirmButtonText: "Dirigirse al formulario",
              showCancelButton: true,
            }).then((ok) => {
              if (ok) {
                this.router.navigateByUrl("/form-surgery");
              }
            });
          } else if (this.lang === "en-US" || this.lang === "en") {
            Swal.fire({
              title: "Error!",
              text: "Please complete the form, before requesting an appointment",
              icon: "error",
              confirmButtonText: "Go to the form",
              showCancelButton: true,
            }).then((ok) => {
              if (ok) {
                this.router.navigateByUrl("/form-surgery");
              }
            });
          } else {
            Swal.fire({
              title: "Error!",
              text: "Please complete the form, before requesting an appointment",
              icon: "error",
              confirmButtonText: "Go to the form",
              showCancelButton: true,
            }).then((ok) => {
              if (ok) {
                this.router.navigateByUrl("/form-surgery");
              }
            });
          }
   
        }
      }
    });
  }
}

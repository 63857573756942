<mat-nav-list>
  <div class="col text-center mt-4">
    <div class="row justify-content-center">
      <div class="container-img">
        <div id="circle-img" class="img-thumbnail">
          <img
            id="imgCard"
            [src]="imageService.profile_picture | imgFalse"
            class=""
            alt="Doctor image"
          />
        </div>
      </div>
    </div>
    <div class="name">
      <span class="name-span">{{ nameUser }}</span>
    </div>
  </div>
  <h3 matSubheader="matSubheader"></h3>
  <div class="ml-3" id="focusText">
    <a
      mat-list-item
      routerLinkActive="list-item-active"
      (click)="toggleSideBar()"
      [routerLink]="['/start']"
    >
      <i class="fas fa-home"></i>
      <span class="ml-3">{{ "START.home" | translate }} </span>
    </a>
    <a mat-list-item routerLinkActive="list-item-active" class="dropdown-btn">
      <i class="fas fa-file-medical"></i>
      <span class="ml-3"> {{ "START.form" | translate }} </span>
      <i class="fa fa-caret-down ml-margin"></i>
    </a>
    <div class="dropdown-container ml-4">
      <a
        mat-list-item
        routerLinkActive="list-item-active"
        (click)="toggleSideBar()"
        [routerLink]="['/form-surgery2']"
      >
        <i class="fas fa-file-medical"></i>
        <span class="ml-2"> {{ "FORMS.surgery" | translate }} </span>
      </a>
      <a
        mat-list-item
        routerLinkActive="list-item-active"
        (click)="toggleSideBar()"
        [routerLink]="['/form-esthetic2']"
      >
        <i class="fas fa-file"></i>
        <span class="ml-2"> {{ "FORMS.esthetic" | translate }} </span>
      </a>
      <a
        mat-list-item
        routerLinkActive="list-item-active"
        (click)="toggleSideBar()"
        [routerLink]="['/form-general2']"
      >
        <i class="fas fa-file"></i>
        <span class="ml-2"> {{ "FORMS.general_form" | translate }} </span>
      </a>
      <!-- 
      <a
        mat-list-item
        routerLinkActive="list-item-active"
        (click)="toggleSideBar()"
        [routerLink]="['/form-general']"
      >
        <i class="fas fa-file"></i>
        <span class="ml-2"> {{ "FORMS.general_form" | translate }} </span>
      </a>-->
    </div>

    <a
      mat-list-item
      routerLinkActive="list-item-active"
      (click)="toggleSideBar()"
      [routerLink]="['/search']"
    >
      <i class="fas fa-user-md"></i>
      <span class="ml-3">{{ "START.search" | translate }} </span>
    </a>
    <a
      mat-list-item
      routerLinkActive="list-item-active"
      (click)="toggleSideBar()"
      [routerLink]="['/chat']"
    >
      <i class="fas fa-comments"></i>
      <span class="ml-3">{{ "START.chat" | translate }} </span>
    </a>
    <a
  
      mat-list-item
      routerLinkActive="list-item-active"
      (click)="toggleSideBar(); showBadget();"
      [routerLink]="['/payment']"
    >
      <i class="fas fa-credit-card"   [matBadge]="_socketService?.paymenttNotificationCount" 
      [matBadgeHidden]="!_socketService.paymentmentBadget"></i>
      <span class="ml-3">{{ "START.payment" | translate }} </span>
    </a>
    <a
      mat-list-item
      routerLinkActive="list-item-active"
      (click)="toggleSideBar()"
      [routerLink]="['/request-list']"
    >
      <i class="fas fa-list-alt"></i>
      <span class="ml-3">{{ "START.request" | translate }} </span>
    </a>
    <a
      mat-list-item
      routerLinkActive="list-item-active"
      (click)="toggleSideBar()"
      [routerLink]="['/appointment']"
    >
      <i class="fas fa-calendar-alt"></i>
      <span class="ml-3">{{ "START.appointment" | translate }} </span>
    </a>
    <a
      mat-list-item
      routerLinkActive="list-item-active"
      (click)="toggleSideBar()"
      [routerLink]="['/gallery']"
    >
      <i class="fas fa-images"></i>
      <span class="ml-3">{{ "START.gallery" | translate }} </span>
    </a>
    <a mat-list-item routerLinkActive="list-item-active" *ngIf="moveText" (click)="toggleSideBar()" [routerLink]="['/news']">
          <i class="fas fa-newspaper"></i>
           <span class="ml-3">{{ "NAVBAR.news" | translate }} </span>
        </a>
    <a
      mat-list-item
      routerLinkActive="list-item-active"
      *ngIf="moveText"
      (click)="toggleSideBar()"
      [routerLink]="['/favorite']"
    >
      <i class="fas fa-heart"></i>
      <span class="ml-3">{{ "NAVBAR.favorite" | translate }} </span>
    </a>
    <a
      mat-list-item
      routerLinkActive="list-item-active"
      (click)="toggleSideBar()"
      [routerLink]="['/settings']"
    >
      <i class="fas fa-user-cog"></i>
      <span class="ml-3"> {{ "NAVBAR.settings" | translate }} </span>
    </a>
  </div>
</mat-nav-list>

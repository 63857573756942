import { Component, OnInit, OnDestroy, Input } from '@angular/core';

// Permite recibir el parametro enviado por la url
import { ActivatedRoute, Router } from '@angular/router';
import { SearchService } from '../../services/search.service';
import { RequestsListService } from '../../services/requests-list.service';
import { FlagService } from '../../services/flag.service';
import { DoctorModel } from '../../models/doctor.model';
import Swal from 'sweetalert2';
import { GalleryService } from '../../services/gallery.service';
import { FormService } from '../../services/form.service';
import { ModalAppointmentComponent } from './modal-appointment/modal-appointment.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateServices } from 'src/app/services/translate.service';
import { HttpErrorResponse } from '@angular/common/http';




@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {

  doctor: any = {};
  access: boolean;
  lang: string;
  idD: number;
  city: string;
  showEs: boolean;
  doctorName: string;
  dondeEstoy: string;
  idDoctor: string;
  gallery: any[];
  textFavorite: string = 'Agregar a favorito';
 


  constructor( private activatedRoute: ActivatedRoute,
               private search: SearchService,
               private accesoClient: RequestsListService,
               public flag: FlagService,
               public translate: TranslateServices,
               private router: Router,
               private _galleryService: GalleryService,
               public dialog: MatDialog ) {
                    this.translate.translateText();


    this.getDoctorGallery();
    this.activatedRoute.params.subscribe( resp => {
         this.search.getDoctorSelected(resp.id).subscribe( response  => {
         this.doctor = response;
         this.city = response.country.name;
         this.doctorName = response.first_name + ' ' + response.last_name;
         localStorage.setItem("doctorSelectedInfo",  this.doctorName)
        
         }
        );
    }, (error: HttpErrorResponse) => {
     Swal.fire({
       icon: "error",
       title: "Error",
       text: error.message,
     });
   });
  }

  ngOnInit(): void {
    this.lang = navigator.language;
    this.access = this.accesoClient.accesoClient;
    if (this.access) {
      this.dondeEstoy = "Lista Solicitudes";
    } else {
       this.dondeEstoy = "Search";
    }

    if (this.lang === "es-ES" || this.lang === "es") {
          this.showEs = true;
   } else {
       this.showEs = false;
   }
    this.putText();
  }
  ngOnDestroy(): void {
       //Called once, before the instance is destroyed.
       //Add 'implements OnDestroy' to the class.
       localStorage.removeItem("idDoctor");
       
  }
    // Obtener la galeria del doctor
   getDoctorGallery() {
     this.gallery = [];
     this.idDoctor = localStorage.getItem('idDoctor');
     this._galleryService.getGallery(this.idDoctor).subscribe( (resp: any) => {
                          this.gallery = resp;
    }, (error: HttpErrorResponse) => {
     Swal.fire({
       icon: "error",
       title: "Error",
       text: error.message,
     });
   });
   }

   putFavorite(press: boolean) {
     this.activatedRoute.params.subscribe((resp) => {
          this.idDoctor = resp.id;   
        });
     //   this.idDoctor = localStorage.getItem('idDoctor');
       if ( this.textFavorite === 'Quitar de favorito' && press === true ) {
            this.deleteFavorite();
       } else  {
            this.textFavorite = 'Quitar de favorito';
            this.search.putFavorite(this.idDoctor).subscribe( (resp: any) =>  {
                  if ( resp.OK === false ) {
                       Swal.fire({
                            icon: 'error',
                            text: resp.Message
                       })
                   
                  }
            }, (error: HttpErrorResponse) => {
               Swal.fire({
                 icon: "error",
                 title: "Error",
                 text: error.message,
               });
             });
      }
   }

   putText( ) {
       this.search.getFavorite().subscribe( (resp: any) => {
          this.activatedRoute.params.subscribe((resp) => {
               this.idDoctor = resp.id;   
             }, (error: HttpErrorResponse) => {
               Swal.fire({
                 icon: "error",
                 title: "Error",
                 text: error.message,
               });
             });
          //  this.idDoctor = localStorage.getItem('idDoctor');
           this.idD = parseInt(this.idDoctor);
           resp.forEach( items => {
               let itemsId = items.doctor_id;
               if ( itemsId === this.idD ) {
               this.textFavorite = 'Quitar de favorito';
               }
           });
       });
  }
  backClient() {
     this.router.navigate(['search']);
  }

   deleteFavorite() {
       this.search.deleteFavorite( this.idDoctor ).subscribe( (resp: any ) => {
           if ( resp.OK === true ) {
                this.textFavorite = 'Agregar a favoritos';
           } else if ( resp.OK === false ) {
               if ( resp.OK === false ) {
                    Swal.fire({
                         icon: 'error',
                         text: resp.Message
                    })
               }
           }
      }, (error: HttpErrorResponse) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error.message,
          });
        });
   }


  openDialog() {
     const dialogRef = this.dialog.open(ModalAppointmentComponent,
           { disableClose: true}
            );
     dialogRef.afterClosed().subscribe(result => {
      
       localStorage.removeItem("idDoctor");
     });
   }

}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CountrysService } from '../../../services/countrys.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { DirectFormService } from '../../../services/direct-form.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { DynamicFormModel } from '../../../classContainer/dynamic-form';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-direct-form-aesthetic',
  templateUrl: './direct-form-aesthetic.component.html',
  styleUrls: ['./direct-form-aesthetic.component.css']
})
export class DirectFormAestheticComponent implements OnInit {

  
  
  public form: FormGroup;
  public confirmationSendForm: FormGroup;
  public lastform: FormGroup;
  public doctorName: string;
  public code_doctor: any;
  public countryTotal:any[] = [];
  public languages:any[] = [];
  public procedureList:any[] = [];
  public language: string;
  public loadingContainer: boolean = false;
  public disabledSEndButton: boolean = false;
  public feetAndMetersContainer: boolean = true;
  public imc:number = 0;
  public firstSave: boolean = false;

  public emailInfoContainer: boolean = true;
  public emailInfoTextr: string = "";
  public valid_email: boolean = false;
  public emailChange: number = 0;

  //Acivar el progress envio de imagen
  public sendFormProgressContainer: boolean = false;

  public genderGroup:any[] = [];
  //Permite almacenar altura peso y IMC para enviarlo
  public heightWeightIMC: any = {
    height: null,
    weight: null,
    imc: null,
  };

  //Habrir dialogo envio final al doctor
  public dialogConfirmContainer: boolean = false;
  public dialogConfirmProgressContainer: boolean = false;
  public firtSaveLoadingContainer: boolean = false;

  //DynamicFormModel
  public dynamicFormClass:any = new DynamicFormModel;
  public dynamicForm: FormGroup;
  public moduleList:any[] = [];
  public moduleListArray: any[] = [];
  public totalModule:any[] = [];


  public disabledButton: boolean = true;
  public modeProgress:any;
  public nameProgressID: string = "nameProgress";
  public numberProgressID: number = 0;
  public selectedFile:any;
  public nameFile:any;
  public fileType:any;

  public img1: string = "assets/img/imagen_esthetic.png";
  public img2: string = "assets/img/imagen_esthetic.png";
  public img3: string = "assets/img/left-profile.png";
  public img4: string = "assets/img/right-profile.png";
  public img5: string = "assets/img/document.png";
  public img6: string = "assets/img/profile.png";

  public imgProgress1: boolean = false;
  public imgProgress2: boolean = false;
  public imgProgress3: boolean = false;
  public imgProgress4: boolean = false;
  public imgProgress5: boolean = false;
  public imgProgress6: boolean = false;
  

  public imagesGroup:any = {
    front: null,
    back: null,
    left: null,
    right: null,
    passport: null,
    profile: null,
    esthetic_picture: null,
    esthetic_picture_2: null,
  }

  constructor(private fb: FormBuilder,
              private countryServices: CountrysService,
              private translate: TranslateService,
              private directFormService: DirectFormService,
              private router: Router,
              public dialog: MatDialog,) 
  { 
    
    this.language = this.translate.getBrowserLang();
    ////console.log( this.language );
  }




  ngOnInit(): void {

    //Tomamos los datos almecenado del doctor
    if( localStorage.getItem('doctorInfo')) {
      //Para pasar de string a array, proceso inverso
      let doctorInfo ​= JSON.parse(localStorage.getItem('doctorInfo'));

      //console.log(doctorInfo);
      this.doctorName = doctorInfo?.full_name;
      this.procedureList = doctorInfo?.procedureTypes;
      this.code_doctor = doctorInfo?.code_doctor;

    }

    this.countryTotal = this.countryServices.sendCoubntries();
    //////console.log(this.countryTotal);
    this.createForm();
    this.listTotal();

  }



  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  //Listas
  public listTotal() {

    this.directFormService.getLanguage().subscribe((resp:any) => {
      ////console.log(resp);
      this.languages = resp.Languages;
    });

    if (this.language === 'es' || this.language === 'es-ES' ) {

      this.genderGroup = [
        {
          id: "female",
          name: "Femenino"
        },
        {
          id: "male",
          name: "Masculino"
        }
      ];

    } 
    else if (this.language === 'en' || this.language === 'en-US') {

      this.genderGroup = [
        {
          id: "female",
          name: "Female"
        },
        {
          id: "male",
          name: "Male"
        }
      ];

    }


    //Lista de tratamientos que hace el doctor
    this.directFormService.getDynamicForm( this.code_doctor  ).subscribe( (resp:any) => {

      //console.log(resp);
      this.moduleList = resp.moduleList;
      this.moduleListArray = resp.moduleListArray;

    });

    this.countryTotal = this.countryServices.sendCoubntries();

  }

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  //Creacion del formulario
  public createForm() {


    this.form = this.fb.group({
      first_name: [null, [Validators.required]],
      last_name: [null, [Validators.required]],
      identification: [null, [Validators.required]],

      email: [null, [Validators.required]],
      phone: [null, [Validators.required]],
      dateBirth: [null, [Validators.required]],

      gender: [null, [Validators.required]],
      languages: [null, [Validators.required]],
      procedure_doctor: [null, [Validators.required]],

      country: [null, [Validators.required]],
      city: [null, [Validators.required]],
      address: [null, [Validators.required]],


      status: [null],
      religion: [null],
      occupation: [null],

      
      kg: [null], 
      meter: [null],
      pounds: [null],
      foot: [null],
      imc: [null],
      
      residence: [null],
      residencePhone: [null],
      emergencyPerson: [null],
      emergencyPersonPhone: [null],


      accept_transfusion: [false],
      had_covid: [false],
      vaccinated_covid: [false],

      pregnancy: [null],
      abortions: [null],
      childbirth: [null],
      caesarean: [null],


      lastpregnancy: [null],
      blood_type: [null],
      last_transfusion_date: [null],

      
      tachycardia: [null],
      neurological: [null],
      circulatory: [null],
      

      skin: [null],
      gastrointestinal: [null],
      breast_pathologies: [null],
      hypothyroidism: [null],


      previousSurgeries: [null],
      diseases: [null],
      medicines: [null],
      allergies: [null],
      hereditary_history: [null],
      extraInformation: [null],



      asthma: [false],
      natural_medicines: [false],
      lactating: [false],
      diabetes: [false],



      calcium: [false],
      hepatitis: [false],
      vitamins_e_d_c: [false],
      sedatives: [false],
      
      
      leukemia: [false],
      smoker: [false],
      omega_3: [false],
      hemophilia: [false],


      alcohol: [false],
      leucine: [false],
      penicillinAllergy: [false],
      contraceptive: [false],


      sinusitis: [false],
      currently_pregnant: [false],
      hiv: [false],
      hypercholesterolemia: [false],


      transfusion: [false],
      previous_transfusion: [false],
      cancer: [false],
      hypertension: [false],
      aspirin: [false],


      thalassemia: [false],
      falcemia: [false],
      pFalcemia: [false],
      willebrand: [false],
      

      depression: [false],
      anti_hypertensive: [false],
      biopolymers: [false],
      hookah: [false],
      
      
      anti_coagulants: [false],
      oxycodone: [false],
      drugs: [false],
      anti_depressants: [false],


      medicines_to_lose_weight: [false],

      terms: [false, [Validators.required]],

      changeFlight: [false, [Validators.required]],
      notFalseInformation: [false, [Validators.required]],
      allowSavingInformation: [false, [Validators.required]],
      
   
    });

    

    this.form.valueChanges.subscribe((resp:any) => {

      //console.log(resp);
      ////console.log(resp.procedure_doctor);
      ////console.log(resp.procedure_doctor.id);

      let email: string = "";
      email = resp.email;
      let cont: number = 0;
      let emailInfo = $('#emailInfo');

      let email1: string;
      let email2: string;
      let email3: string;
      
      if (this.language === 'es' || this.language === 'es-ES' ) {
        email1 = "Este Email ya esta siendo utilizado !!!";
        email2 = "Email Valido";
        email3 = "Email Invalido !!!";

      } 
      else if (this.language === 'en' || this.language === 'en-US') {
        email1 = "This Email is already being used !!!";
        email2 = "Valid Email";
        email3 = "Invalid Email !!!";

      }
      
      
      ////console.log(email);

      ////console.log(email?.length);
      ////console.log(this.emailChange);
      
      //Se encarga de determina si el formato del email es correcto y si no existe en NovusDoc
      if( email?.length > 4 && email?.length !== this.emailChange ) {

        for( let item of email ) {

          //////console.log(item);
          if( item === '.' || item === '@' ) {
            cont ++;
          }

        }

        if( cont == 2 ) {

          //Esperamos 3 segundos antes de realizar la busqueda para no saturar la bbdd
          setTimeout(() => {
            ////console.log("Buscar el email");
            this.directFormService.emailVerifier( email ).subscribe((resp:any) => {

              ////console.log(resp);
              if( resp.exist ) {

                this.valid_email = false;
                this.emailInfoTextr = email1;
                emailInfo.css('color', 'red');
              }
              else {

                this.valid_email = true;
                this.emailInfoTextr = email2;
                emailInfo.css('color', 'green');
              }
            });
          }, 4000);
          
        }
        else {
          this.emailInfoTextr = email3;
          emailInfo.css('color', 'red');
        }

        this.emailChange = email?.length;

      }

      //////console.log(this.valid_email);

      //Verifica si los datos basicos estas rellenos y guardar la informacion del paciente si es la primera vez que se completan los datos basicos
      if( !this.firstSave ) { 
        ////console.log("##########################");
        ////console.log("Entre primer Guardao 1");
        ////console.log("##########################");
        
        if( this.form.value.first_name !== null && this.form.value.last_name !== null && this.form.value.identification !== null && this.form.value.email !== null &&
          this.form.value.dateBirth !== null && this.form.value.country !== null && this.form.value.city !== null && this.form.value.address !== null) {
  
            //console.log( this.valid_email );
            if( this.valid_email  ) {
              ////console.log("Aqui1");
              this.firstSave = true;
            }
            else{
              ////console.log("Aqui2");
              return;
            }
            ////console.log("##########################");
            ////console.log("Entre primer Guardao 2");
            ////console.log("##########################");
          //No pasaremos este valor a true hasta que no sea guardado el paciente
          /*
          setTimeout(() => {

            //console.log("##########################");
            //console.log("Entre primer Guardao 3");
            //console.log("##########################");
            this.initialSaveForm();
          }, 10000);*/
    
        }

      }
      


      //this.heightWeightIMC
      //Calcula imc en pies
      if( this.feetAndMetersContainer ) {

        //////console.log(resp.pounds);
        //////console.log(resp.foot);
        if( resp.pounds !== null && resp.foot !== null ) {
          let pounds:number = 0;
          let foot:number = 0;
          pounds = resp.pounds / 2.2046;
          foot = resp.foot * 0.3048;
          ////console.log(pounds);
          ////console.log(foot);
          
          this.imc = (pounds / ( foot * foot ));

          this.heightWeightIMC.weight = pounds;
          this.heightWeightIMC.height = foot;
          this.heightWeightIMC.imc = this.imc;
          ////console.log(this.heightWeightIMC);
        }
      }

      //Calcula imc en metros
      if( !this.feetAndMetersContainer ) {

        //////console.log(resp.kg);
        //////console.log(resp.meter);
        if( resp.kg !== null && resp.meter !== null ) {

          let kg:number = 0;
          let meter:number = 0;
          kg = resp.kg;
          meter = resp.meter;

          this.imc = (kg / ( meter * meter ));

          this.heightWeightIMC.height = meter;
          this.heightWeightIMC.weight = kg;
          this.heightWeightIMC.imc = this.imc;
          ////console.log(this.heightWeightIMC);
        }

      }
      
    });


    this.lastform = this.fb.group({

      reason: [null, [Validators.required]],
      origin: [null, [Validators.required]],
      changeFlight: [false, [Validators.required]],
      notFalseInformation: [false, [Validators.required]],
      allowSavingInformation: [false, [Validators.required]],


    });


    this.lastform.valueChanges.subscribe((resp:any) => {
      ////console.log(resp);
    });


    this.dynamicForm = this.fb.group(
      this.dynamicFormClass.formModule1
    );


    this.dynamicForm.valueChanges.subscribe((resp:any) => {
      ////console.log(resp);
    });


    this.confirmationSendForm = this.fb.group({

      reason: [null, [Validators.required]],
      origin: [null, [Validators.required]],
      changeFlight: [false, [Validators.required]],
      notFalseInformation: [false, [Validators.required]],
      allowSavingInformation: [false, [Validators.required]],

    });

    this.confirmationSendForm.valueChanges.subscribe((resp:any) => {
      //console.log(resp);

      if( resp.reason !== null && resp.origin !== null && resp.changeFlight !== false && resp.notFalseInformation !== false  && resp.allowSavingInformation !== false ) {
        this.sendFormButton = false;
      }
      else {
        this.sendFormButton = true;
      }

      //sendFormButton
    });


  }

  


  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  //Ir a terminos y condiciones
  public goTo() {

    ////console.log("Ir a terminos y condiciones");

    this.router.navigate(['terms-conditions']);
  }


  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  //Todo lo necesario para la seleecion de imagenes
  
  
  //Seleccionar imagen y enviarla al servicio
  //Seleeciona las imagenes
  public onFileSelected( event:any, destination ) {

    let message: string;

    if (this.language === 'es' || this.language === 'es-ES' ) {
      message = "Verifique que a rellenado todos los campos con ( * ) para poder continuar !!!!";


    } 
    else if (this.language === 'en' || this.language === 'en-US') {
      message = "Verify that you have filled in all the fields with ( * ) to be able to continue !!!!";

    }

 
    /*
    if( !this.firstSave ) {

      Swal.fire({
        icon: 'info',
        title: 'Oops...',
        text: message,

      })
      return;
    }*/


    let patient_id: number;
    //Tomamos los datos almecenado del doctor
    if( localStorage.getItem('patientInfo')) {
      ////console.log("###########");
      ////console.log("Hay paciente guardado");
      //Para pasar de string a array, proceso inverso
      let patientInfo ​= JSON.parse(localStorage.getItem('patientInfo'));

      ////console.log(patientInfo); 
      patient_id = patientInfo.patient_id;

    }
    /*
    else {
      ////console.log("###########");
      ////console.log("No hay paciente guardado !!!!!!");
      return;
    }*/




    this.nameProgressID = "nameProgress" + this.numberProgressID;

    //////console.log(this.nameProgressID);
    this.modeProgress = "indeterminate";

    //////console.log(event.target.value);
    //////console.log(this.listOfArchive);

    //Se encarga de detectar si se a pulsado el boton cancelar, para que no se rompa la app
    if ( event.target.value.length === 0) {
      ////console.log("Suspect Cancel was hit, no files selected.");
      return;
    
    }
  
    //Desactivamos el boton
    this.disabledButton = true;

    //En la consola siguiente target file, encontraremos la url de la imagen nombre y demas
    if(event.target.files){


      let reader = new FileReader();
      //Archivo seleecionado para el envio por el servicio
      this.selectedFile = <File>event.target.files[0];

      //Almacenamos la url de la imagen
      reader.readAsDataURL( event.target.files[0] );
      //////console.log(event.target.files[0]);
      //////console.log(event.target.files);
      //////console.log(event.target.files[0].name);
      //////console.log(event.target.files[0].type);
      this.fileType = event.target.files[0].type;
      this.nameFile = event.target.files[0].name;


      reader.onload = ( eventDoc:any ) => {

        //Muestra el string de datos de la imagen
        //////console.log(eventDoc.target.result);
        let object = {
          name: this.nameFile,
          type: this.fileType,
          file: this.selectedFile
        }



        //////console.log(1);
        //let va: number;
        this.disabledButton = true;
        switch( destination ) {

          
          case 1: {
            ////console.log("1");
            this.img1 = eventDoc.target.result;
            //this.imgProgress1 = true;
            this.imagesGroup.esthetic_picture = eventDoc.target.result;
            /*
            this.directFormService.sendImagest( object, "front", patient_id  ).subscribe((resp:any) => {
              ////console.log(resp);
              this.imgProgress1 = false;
              this.disabledButton = false;
            });*/
            break;
          }
          case 2: {
            ////console.log("2");
            this.img2 = eventDoc.target.result;
            //this.imgProgress2 = true;
            this.imagesGroup.esthetic_picture_2 = eventDoc.target.result;
            /*
            this.directFormService.sendImagest( object, "back", patient_id  ).subscribe((resp:any) => {
              ////console.log(resp);
              this.imgProgress2 = false;
              this.disabledButton = false;
            });*/
            break;
          }

          case 6: {
            ////console.log("6");
            this.img6 = eventDoc.target.result;
            //this.imgProgress6 = true;
            this.imagesGroup.passport = eventDoc.target.result;
            /*
            this.directFormService.sendImagest( object, "passport", patient_id  ).subscribe((resp:any) => {
              ////console.log(resp);
              this.imgProgress6 = false;
              this.disabledButton = false;
            });*/
            break;
          }
        }



      }

    }


    //Desactivamos el boton
    //this.disabledButton = false;
    
  }



  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  //Funcion encargada de hacer el guardado inicial del formulario para optener el paciente_id
  public initialSaveForm() {

    ////console.log(this.form.value);
    //this.wait(0);


    ////console.log("##########################");
    ////console.log("Entre primer Guardao 4");
    ////console.log("##########################");
    let patient_id: number;
    //Tomamos los datos almecenado del doctor
    if( localStorage.getItem('patientInfo')) {
      //Para pasar de string a array, proceso inverso
      let patientInfo ​= JSON.parse(localStorage.getItem('patientInfo'));


      //console.log(patientInfo); 
      patient_id = patientInfo.patient_id;

    }

    //console.log(patient_id);

    this.directFormService.postForm( this.form.value, this.heightWeightIMC, patient_id ).subscribe((resp:any) => {
      //console.log(resp);
      if( !resp.Ok ) {
        this.firstSave = false;
      }
      else {
        let patientInfo = {
          patient_id: resp?.patient_id,
          user_patient_id: resp?.user_patient_id,
        };
  
        ////console.log(patientInfo);
        localStorage.setItem('patientInfo',JSON.stringify(patientInfo));
        this.firstSave = true;
        this.disabledButton = false;
          
        //return patientInfo;
      }
        
    });

  }

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  //Comprobar que los valores estan llenos y abrir card
  public openDialogSendForm() {

    //console.log("Abrir dialog");
    let incompleteForm: string;
    let emailError: string;

    ////console.log(this.language );
    if (this.language === 'es' || this.language === 'es-ES' ) {
      incompleteForm = "Por favor rellene todos los campos con asterisco ( * ) !!!";
      emailError = "Introduzca un email valido !!!";
    } 
    else if (this.language === 'en' || this.language === 'en-US') {
      incompleteForm = "Please fill in all fields with an asterisk ( * ) !!!";
      emailError = "Enter a valid email !!!";
    }

    if( this.form.value.first_name == null || this.form.value.last_name === null || this.form.value.passport === null || this.form.value.phone === null || 
        this.form.value.email === null || this.form.value.country === null || this.form.value.city === null || this.form.value.address === null || 
        this.form.value.gender === null || this.form.value.languages === null || this.form.value.procedure_doctor === null || this.form.value.terms === false ) {
      
      
      Swal.fire({
        icon: 'info',
        title: 'Oops...',
        text: incompleteForm,
      })
      this.firstSave = true;
      return;
    }



    ////console.log("Detectar Email Existente");
    ////console.log(this.valid_email);
    if( !this.valid_email ) {

      Swal.fire({
        icon: 'info',
        title: 'Oops...',
        text: emailError,
      })

      return;
    }

    this.ConfirmationSendFormContainer = true;


  }


  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  public ConfirmationSendFormContainer: boolean = false;
  public ConfirmationSendFormContainerProgress: boolean = false;
  public sendFormButton: boolean = true;
  //Envio del formulario
  public sendForm() {

    let answerFormError: string;
    let success: string;
    //let dialogError: string;

    ////console.log(this.language );
    if (this.language === 'es' || this.language === 'es-ES' ) {
      answerFormError = "Error al enviar el formulario, vuelva a probar en unos minutos !!!";
      success = "El formulario a sido enviado correctamente !!";

    } 
    else if (this.language === 'en' || this.language === 'en-US') {
      answerFormError = "Error submitting the form, please try again in a few minutes !!!";
      success = "The form has been sent correctly !!";

    }

    /*
    //console.log(this.confirmationSendForm.value);
    if( this.confirmationSendForm.value.reason == null || this.confirmationSendForm.value.origin == null || this.confirmationSendForm.value.changeFlight == null || 
        this.confirmationSendForm.value.notFalseInformation == null || this.confirmationSendForm.value.allowSavingInformation == null ) {
    
    
      Swal.fire({
        icon: 'info',
        title: 'Oops...',
        text: dialogError,
      })
      this.firstSave = true;
      return;
    }*/





    




    ////console.log(answerForm);
    this.loadingContainer = true;
    this.disabledSEndButton = true;


    ////console.log(this.form.value);
    ////console.log(this.heightWeightIMC);

    let patient_id: number;
    //Tomamos los datos almecenado del doctor
    if( localStorage.getItem('patientInfo')) {
      //Para pasar de string a array, proceso inverso
      let patientInfo ​= JSON.parse(localStorage.getItem('patientInfo'));

      ////console.log(patientInfo); 
      patient_id = patientInfo.patient_id;

    }



    this.ConfirmationSendFormContainerProgress = true;
    const idDoctor = localStorage.getItem("idDoctor");

    const dataConfirmation = {
      procedure_type: this.form.value.procedure_doctor?.id,
      reason: this.confirmationSendForm.value.reason,
      code_doctor: this.code_doctor,
      origin: this.confirmationSendForm.value.origin,
      assistants_agreement: true,
      flight_agreement: true,
      information_agreement: true
    };

    //console.log(dataConfirmation);

    this.directFormService.postFormSurgery( this.form.value, this.heightWeightIMC, patient_id, this.dynamicForm.value, this.moduleList, this.imagesGroup, dataConfirmation ).subscribe((resp:any) => {

      //console.log(resp);
      this.sendFormProgressContainer = false;
      this.dialogConfirmContainer = true;
      this.ConfirmationSendFormContainerProgress = false;
      this.ConfirmationSendFormContainer = false;

      if( !resp.Ok ) {

        Swal.fire({
          icon: 'info',
          title: 'Oops...',
          text: answerFormError,
        })
      }
      else {

        Swal.fire({
          icon: 'success',
          title: success,
          showConfirmButton: false,
          timer: 2500
        })

        this.directFormService.sendNotificationDoctor( this.code_doctor, this.confirmationSendForm.value.origin );
      }


      this.router.navigate(['home']);

    });


  }




  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////
  //Enviar formulario al doctor
  public sendFormDoctorFinal() {

    ////console.log("Enviar al doctor Final ");

    //Actualizamos los datos del formulario
    let patient_id: number;
    //Tomamos los datos almecenado del doctor
    if( localStorage.getItem('patientInfo')) {
      //Para pasar de string a array, proceso inverso
      let patientInfo ​= JSON.parse(localStorage.getItem('patientInfo'));

      //console.log(patientInfo); 
      patient_id = patientInfo.patient_id;
      this.directFormService.idUser = patientInfo.user_patient_id;
      this.directFormService.initConectionSocket();

    }

    let incomplete_form:string = "";
    let form_ok:string = "";
    let form_error:string = "";

    if (this.language === 'es' || this.language === 'es-ES' ) {
      incomplete_form = "Para poder continuar rellene los campos y acepte las condiciones de NovusDoc !!!";
      form_ok = "Su formulario fue creado correctamente y se le creo una cuenta en NovusDoc.com  \n " + "Usuario : " + this.form.value.email + "\n" + "Contraseña : " +  this.form.value.identification + "\n Vaya a su email y verifique su correo electronico y Cambie su contraseña despues de iniciar sesión";
      form_error = "Error al enviar el formulario, compruebe su conexión a internet !!!";
    } 
    else if (this.language === 'en' || this.language === 'en-US') {
      incomplete_form = "In order to continue, fill in the fields and accept the conditions of NovusDoc !!!";
      form_ok = "Your form was created successfully and a NovusDoc.com account was created for you \n " + "User : " + this.form.value.email  + "\n" + "Password : " + this.form.value.identification + "\n Go to your email and verify your email and Change your password after login";
      form_error = "Error submitting the form, please check your internet connection !!!";
    }

    //this.finishWait = true;
    ////console.log(this.lastform.value);

    //Verificamos que todos los checkbox esten seleccionados
    if( this.lastform.value.origin === null || this.lastform.value.allowSavingInformation === false || 
        this.lastform.value.notFalseInformation === false || this.lastform.value.changeFlight === false || 
        this.lastform.value.reason === false || this.lastform.value.reason === null ) {


        Swal.fire(incomplete_form)
        //////console.log(object);
        return;
    }


    ////console.log(this.form.value);
    ////console.log(this.form.value.procedure_doctor);
    ////console.log(this.form.value.procedure_doctor?.identifier);

    const data = {
      reason: this.lastform.value.reason,
      doctor_id: this.code_doctor,
      origin: this.lastform.value.origin,
      procedure_type_identifier: this.form.value.procedure_doctor?.identifier,
      assistants_agreement: this.lastform.value.allowSavingInformation,
      flight_agreement : this.lastform.value.changeFlight,
      information_agreement : this.lastform.value.notFalseInformation,

    };

    ////console.log(data);
    this.dialogConfirmProgressContainer = true;
    //this.dialogConfirmContainer = true;
    this.directFormService.sendRequest( data, patient_id  ).subscribe((resp:any) => {

      ////console.log(resp);
      if( !resp.Ok ) {

        Swal.fire(form_error)
      }
      else {

        Swal.fire({
          icon: 'success',
          title: form_ok,
        })
        
        
        this.directFormService.sendNotificationDoctor( this.code_doctor, this.lastform.value.origin );

        this.dialogConfirmContainer = false;
        this.router.navigate(['login']);

        setTimeout( () => {
          //console.log("Desconectar");
          this.directFormService.disconnectSocket();
          //Borramos el pacientInfo
          localStorage.removeItem("patientInfo");
        },1000);

        
      }
      this.dialogConfirmProgressContainer = false;

    });
    
    

  }

}

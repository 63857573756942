<div class="container">
  <div class="row justify-content-center">
    <h2 class="text-muted text-center mt-3">
      {{ "FAVORITE.title" | translate }}
    </h2>
  </div>
  <div id="marg" class="row mb-5 mt-4">
    <ng-container
      class="row mb-5 mt-3"
      *ngIf="favorite.length > 0; else noFound"
    >
      <div class="col-sm-4 col-md-3 mt-4" *ngFor="let item of favorite">
        <mat-card class="mat-height shadow animated fadeIn fast">
          <div (click)="redirectToDoctor(item.doctor_id)" class="pointer">
            <!-- <mat-card-header> -->
            <!-- <div mat-card-avatar class="example-header-image"></div> -->
            <div class="row justify-content-center">
              <h2 id="title" class="mt-3 mb-5">
                {{ item.doctor.first_name }} {{ item.doctor.last_name }}
              </h2>
            </div>
            <!-- </mat-card-header> -->
            <mat-card-content>
              <div class="container-img">
                <div id="circle-img">
                  <img
                    mat-card-image
                    id="imgCard"
                    [src]="item.doctor.profile_picture | imgFalse"
                    class=""
                    alt="Doctor image"
                  />
                  <!-- <img mat-card-image [src]="item.doctor.profile_picture" alt="Photo of a Shiba Inu"> -->
                </div>
              </div>
              <div class="text-muted"> 
                {{ "FORMS-SURGERY.phone" | translate }}:
                 {{ item.doctor.phone }}
              </div>
              <p>
                <span class="text-black-50">
                    {{ "REQUESTSLIST.description" | translate }}:
                 </span>
                {{ item.doctor.description }}
              </p>
            </mat-card-content>
          </div>
          <mat-card-actions>
            <div class="row justify-content-center">
              <!-- <div class="col-md-12"> -->
              <div class="text-center">
                <button
                  mat-button
                  (click)="putNotificationFavorite(item.doctor_id)"
                >
                  <mat-icon class="color-icon">notifications</mat-icon>
                </button>
                <button mat-button (click)="deleteFavorite(item.doctor_id)">
                  <mat-icon class="text-danger">delete</mat-icon>
                </button>
              </div>
              <!-- </div> -->
            </div>
          </mat-card-actions>
        </mat-card>
      </div>
    </ng-container>
  </div>
</div>
<ng-template #noFound class="row">
  <div class="col-md-12 mt-5 mb-5 text-center">
    <i class="mt-5 fa fa-exclamation-triangle text-warning fa-3x"></i>
    <h4 class="text-muted mt-3 mb-5">{{ "PAYMENT.no-result" | translate }}</h4>
  </div>
</ng-template>

import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class TranslateServices {
  lenguaje: string;

  constructor(public translate: TranslateService) {}

  translateText() {
    // variable para saber el idioma del navegador
    this.lenguaje = this.translate.getBrowserLang();
    // lenguajes que se van a utilizar
    this.translate.addLangs(["es", "en"]);

    if (this.lenguaje === "es" || this.lenguaje === "en") {
      this.translate.use(this.lenguaje);
    } else {
      this.translate.setDefaultLang("en");
    }
  }
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { loadStripe } from "@stripe/stripe-js";
import { map, take } from "rxjs/operators";
import { rejects } from "assert";
import * as io from "socket.io-client";
import { environment } from "src/environments/environment";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { BehaviorSubject, Observable, observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PaymentsService {
  userId: number;
  public showNav = new BehaviorSubject<boolean>(true);
  constructor(private http: HttpClient) {
    this.userId = Number(localStorage.getItem("id"));
    this.showNav.next(true);

  }

  private url = environment.ApiUrl;

  createSesion(id: number) {
    // const stripe = await loadStripe(this.stripe);
    const body = {
      reservation_request_id: id,
    };
    return this.http
      .post(`${this.url}/create-session/` + this.userId, body)
      .pipe(
        map((Response) => {
          return Response;
        })
      );
    // let promise = new Promise((resolve, reject) => {
    //   return  this.http.post(`${ this.url }/create-session/` + this.userId, body )
    //   .toPromise()
    //   .then( function(res: any) { return res; })

    //   .then( function(session) {
    //     return stripe.redirectToCheckout({ sessionId: session.id  })
    //   }).then( function ( result ) {




    //     // If `redirectToCheckout` fails due to a browser or network
    //     // error, you should display the localized error message to your
    //     // customer using `error.message`.
    //     if (result.error) {
    //       alert(result.error.message);


    //     }
    //     // resolve(  () => {
    //       //   this.paymentResolution()
    //  
    //       // })
    //       return result
    //     }).then(this.handleResult).catch(function(error) {
    // 
    //     });

    //   }).then( function(resol) {
    //  
    //     this.paymentResolution(body.reservation_request_id,
    //       'fesrweererseraetrtgfssfsrfsd').subscribe()
    //   });

    // return promise;
  }
  handleResult(result) {
  }

  getRequestPayment() {
    this.showNav.next(true);
   
    this.userId = Number(localStorage.getItem("id"))
    return this.http
      .get(`${this.url}/reservation-patient-request/` + this.userId)
      .pipe(
        map((Response) => {
          return Response;
        })
      );
  }

  getHistory() {
    this.showNav.next(true);
    this.userId = Number(localStorage.getItem("id"))
    return this.http.get(`${this.url}/reservation-patient/` + this.userId).pipe(
      map((Response) => {
        return Response;
      })
    );
  }

  filterHistory(from: Date, to: Date) {
    this.showNav.next(true);
    const body = {
      afterDate: to,
      beforeDate: from,
    };
    this.userId = Number(localStorage.getItem("id"))
    return this.http
      .post(`${this.url}/reservation-patient/` + this.userId, body)
      .pipe(
        map((Response) => {
          return Response;
        })
      );
  }

  getDetailsHistory(id: number) {
    this.showNav.next(true);
    return this.http.get(`${this.url}/reservation-payment/` + id).pipe(
      map((Response) => {
        return Response;
      })
    );
  }

  getDetails(id: number) {
    this.showNav.next(true);
    return this.http.get(`${this.url}/reservation-request/` + id).pipe(
      map((Response) => {
        return Response;
      })
    );
  }




  paymentResolution(id: number, session: any) {
    this.showNav.next(false);
    const body = {
      reservation_request_id: id,
      session_id: session,
    };
    this.userId = Number(localStorage.getItem("id"))
    return this.http.post(`${this.url}/make-payment/` + this.userId, body).pipe(
      map((Response) => {
        return Response;
      })
    );
  }
  
 
  get Nav() {
    return this.showNav.asObservable();
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.Nav.pipe(
      take(1),
      map((nav: boolean) => {
        if (!nav) {
          return false;
        }
        return true;
      })
    );
  }

  getNotificationsPayment() {
    this.userId = Number(localStorage.getItem("id"))
    return this.http
      .get(`${this.url}/notification-reservation-patient/` + this.userId)
      .pipe(
        map((Response) => {
          return Response;
        })
      );
  }
}

import { Component, OnInit } from "@angular/core";
import { NewsService } from "../../services/news.service";
import { TranslateServices } from "../../services/translate.service";

@Component({
  selector: "app-news",
  templateUrl: "./news.component.html",
  styleUrls: ["./news.component.css"],
})
export class NewsComponent implements OnInit {
  
  publicationList: any[];

  constructor(
    private newsService: NewsService,
    public translate: TranslateServices
  ) {
    this.translate.translateText();
  }

  ngOnInit(): void {
    this.getPulication();
  }
  
 getPulication() {
  this.newsService.getPublication().subscribe( Response => {
    this.publicationList = Response;
  })
 }
 
}

import { Component, OnInit } from '@angular/core';
import { SearchService } from '../../services/search.service';
import { TranslateServices } from "../../services/translate.service";
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-favorite',
  templateUrl: './favorite.component.html',
  styleUrls: ['./favorite.component.css']
})
export class FavoriteComponent implements OnInit {

  favorite: any[] = [];
  count: number = 0;
  fav: boolean;

  constructor( private _searchDoctor: SearchService,
               public translate: TranslateServices,
               private router: Router ) {
                this.translate.translateText();
                }

  ngOnInit(): void {
    this.getFavorite();
  }

  getFavorite() {
    this._searchDoctor.getFavorite().subscribe( resp => {
         this.favorite = resp;
    });
  }
  deleteFavorite( doctor_id: string ) {
    this._searchDoctor.deleteFavorite(doctor_id).subscribe( ( resp: any ) => {
      if ( resp.OK === true ) {
           this.getFavorite();
      }
    });
  }
  redirectToDoctor( id: string ) {
   this.router.navigateByUrl('/client/' + id );
  }

  putNotificationFavorite(doctorId: number) {
    this._searchDoctor.putNotificationDoctor(doctorId)
                      .subscribe(Response => {
                        if ( Response.is_Enabled === false ) {

                          const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 4000,
                            timerProgressBar: true
                          })
                          
                          Toast.fire({
                            icon: 'info',
                            title: Response.Message
                          })
                        }
                        if ( Response.is_Enabled === true ) {

                          const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 4000,
                            timerProgressBar: true
                          })
                          
                          Toast.fire({
                            icon: 'success',
                            title: Response.Message
                          })
                        }
          });
  }

}

import { Component, OnInit } from "@angular/core";
import Swal from "sweetalert2";
import { AppointmentService } from "../../services/appointment.service";
import { TranslateService } from "@ngx-translate/core";
import { async } from "@angular/core/testing";
import { NgIf } from "@angular/common";
import { TranslateServices } from "../../services/translate.service";
import { SearchService } from '../../services/search.service';
import { CountrysService } from "src/app/services/countrys.service";
import { MatDialog } from "@angular/material/dialog";
import { ModalCancelComponent } from './modal-cancel/modal-cancel.component';
import { ModalResponseCanceledComponent } from "./modal-response-canceled/modal-response-canceled.component";
declare var $: any;

@Component({
  selector: "app-appointment",
  templateUrl: "./appointment.component.html",
  styleUrls: ["./appointment.component.css"],
})
export class AppointmentComponent implements OnInit {
  listTotal: any[] = [];
  lang: string;
  cancelTotal: any[] = [];
  healingApoointment: any[] = [];
  totalAppointment: any[] = [];
  public Police: any[] = [];
  countryTotal: any[] = [];
  public showPolitic = true;

  constructor(
    private appointmentService: AppointmentService,
    private translate: TranslateServices,
    public dialog: MatDialog,
    private _countryServices: CountrysService
  ) {
    this.translate.translateText();
  }

  ngOnInit(): void {
    this.getAppointment();
    this.showPolitics();
    this.getCancelAppointment();
    this.lang = navigator.language;
    
  }

  // openDialog(   id: string, procedureId: string, patiendId: string, isCreatedChat: boolean) {

  // }
  getAppointment() {
    this.appointmentService.getAppointmentList().subscribe((resp: any) => {
      this.totalAppointment = resp.procedure;
      this.listTotal = resp.procedure.filter(
        (item) => item.is_healing === false
      );
      this.HealingAppointment();
    });
  }
  getCancelAppointment() {
    this.appointmentService.getCancelAppointment().subscribe((resp: any) => {
      this.cancelTotal = resp.procedure;
    });
  }

  cancelAppointment(
    id: string,
    procedureId: string,
    patiendId: string,
    isCreatedChat: boolean
  ) {
    //  this.swal(id, procedureId, patiendId  );

    const dialogRef = this.dialog.open(ModalCancelComponent, {
      width: "50%",
      data: {
        id: id,
        procedureId: procedureId,
        patiendId: patiendId,
        isCreatedChat: isCreatedChat,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getAppointment();
      this.getCancelAppointment();
    });
  }
  // Ventana emergente con toda la informacion pulsada
  cardInfo(id: string) {
    this.countryTotal = this._countryServices.sendCoubntries();
    // Targeta emergente con la informacion del
    const list: any = this.listTotal.filter((li) => li.id === id);

    var city = this.countryTotal.find((x) => x.id === list[0].doctor.country);

    Swal.fire({
      text: "Modal with a custom image.",
      icon: "info",
      html:
        '<div class="card">' +
        '<div class="card-header text-muted">Detalles </div>' +
        '<ul class="list-group list-group-flush text-justify">' +
        '<li class="list-group-item"> Doctor: ' +
        list[0].doctor.title +
        ". " +
        list[0].doctor.first_name +
        " " +
        list[0].doctor.last_name +
        "</li>" +
        '<li class="list-group-item">Telefono: ' +
        list[0].doctor.phone +
        "</li> " +
        '<li class="list-group-item"> Dirección: <span class="badge badge-success"> ' +
        list[0].doctor.address +
        "</span></li> " +
        '<li class="list-group-item"> País: <span class="badge badge-success"> ' +
        city.name +
        "</span></li> " +
        '<li class="list-group-item"> Estado: <span class="badge badge-success"> ' +
        list[0].doctor.state +
        "</span></li> " +
        '<li class="list-group-item">Fecha: <span class="badge badge-primary">' +
        list[0].appointment.schedule_date +
        "</span></li> " +
        '<li class="list-group-item">Razones: ' +
        list[0].appointment.reason +
        "</li> " +
        '<li class="list-group-item ">Días restantes <span class="badge badge-warning">' +
        list[0].missing_day +
        "</span></li> " +
        " </ul> " +
        "</div>",
    });
  }

  // Envia una alerta que avisara al
  createAlert(id: string) {
    if (this.lang === "es-ES" || this.lang === "es") {
      Swal.fire({
        icon: "success",
        title: "Notificaciones activada",
        showConfirmButton: false,
        timer: 1500,
      });
    } else if (this.lang === "en-US" || this.lang === "en") {
      Swal.fire({
        icon: "success",
        title: "Notifications activated",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        icon: "success",
        title: "Notifications activated",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  HealingAppointment() {
    this.listTotal;
    this.healingApoointment = this.totalAppointment.filter((item) => {
      return item.is_healing === true;
    });
  }

  showPolitics() {
    this.appointmentService.showPolitics().subscribe((Response: any) => {
      this.Police = Response.refund_policies;
    });
  }
}

<mat-dialog-content class="mat-typography">
  <h3 class="mt-1 mb-4"> {{ "settings.title-modal" | translate }}</h3>
  <mat-form-field appearance="fill">
    <mat-label>{{ "settings.label" | translate }}</mat-label>
    <mat-select [formControl]="lang" multiple>
      <!-- <mat-select-trigger>
        {{ lang.value ? lang.value[0] : "" }}
        <span
          *ngIf="lang.value?.length > 1"
          class="example-additional-selection"
        >
          (+{{ lang.value.length - 1 }}
          {{ lang.value?.length === 2 ? "other" : "others" }})
        </span>
      </mat-select-trigger> -->
      <mat-option *ngFor="let langs of langList"  [value]="langs.id"> 
        {{ langs.name  }}</mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="btn btn-secondary" mat-button mat-dialog-close>{{ "settings.btn-close" | translate }}</button>
  <button class="btn btn-color" mat-button (click)="setNewLanguage()">{{ "settings.btn-save" | translate }}</button>
</mat-dialog-actions>

import { Component, Output, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';
import { ChatSocketService } from './services/chat-socket.service';
import { FlagService } from './services/flag.service';
import { PaymentsService } from './services/payments.service';
import { TranslateServices } from './services/translate.service';
import Swal from "sweetalert2";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'novusdoc2';
  public showNav: Observable<boolean>;
  sideBarOpened: boolean;
  constructor(
    public auth: AuthService, 
    public flag: FlagService,
     private _paymentService: PaymentsService,
      private translate: TranslateServices,
     public _socketService: ChatSocketService) { 

      this.showNav = this._paymentService.Nav;
     }


  toggleSideBar()  {

    this.auth.change.subscribe(
      resp => {
        this.sideBarOpened = resp;
      }
      );

  }

}

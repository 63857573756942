<div class="container mb-4" id="">
  <div class="row">
    <div class="col">
      <!--Titulo del login-->
      <div id="title">
        <h2>{{ "ACCOUNT.title" | translate }}</h2>
      </div>
      <!--Contenedor del card para el formulario-->
      <div id="form">
        <div class="card">
          <div class="card-body">
            <form [formGroup]="form" (ngSubmit)="sendForm()">
              <div class="form-group row">
                <div class="col">
                  <div class="input-text">
                    <label>{{ "ACCOUNT.name" | translate }}</label>
                    <small *ngIf="nameNoValid" class="text-danger">{{
                      "ACCOUNT.required" | translate
                    }}</small>
                  </div>

                  <input
                    class="form-control"
                    type="text"
                    formControlName="first_name"
                    [class.is-invalid]="nameNoValid"
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col">
                  <div class="input-text">
                    <label>{{ "ACCOUNT.lastname" | translate }}</label>
                    <small *ngIf="lastnameNoValid" class="text-danger">{{
                      "ACCOUNT.required" | translate
                    }}</small>
                  </div>
                  <input
                    class="form-control"
                    type="text"
                    formControlName="last_name"
                    [class.is-invalid]="lastnameNoValid"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="input-text">
                  <label>{{ "ACCOUNT.email" | translate }}</label>
                  <small *ngIf="emailNoValid" class="text-danger">{{
                    "ACCOUNT.required" | translate
                  }}</small>
                </div>

                <input
                  type="email"
                  class="form-control"
                  formControlName="email"
                  id="exampleInputPassword1"
                  [class.is-invalid]="emailNoValid"
                />
              </div>
              <div class="form-group">
                <div class="input-text">
                  <small *ngIf="langNoValid" class="text-danger ml-1 mr-3">{{
                    "ACCOUNT.required" | translate
                  }}</small>
                </div>

                <mat-form-field appearance="fill">
                  <mat-label>{{ "settings.label" | translate }}</mat-label>
                  <mat-select
                    formControlName="lang"
                    [class.is-invalid]="langNoValid"
                    multiple
                  >
                    <mat-option
                      *ngFor="let langs of langList"
                      [value]="langs.id"
                    >
                      {{ langs.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="form-group">
                <div class="input-text">
                  <label>{{ "ACCOUNT.gender" | translate }}</label>
                  <small *ngIf="genderNoValid" class="text-danger">{{
                    "ACCOUNT.required" | translate
                  }}</small>
                </div>
                <select
                  [class.is-invalid]="genderNoValid"
                  formControlName="gender"
                  class="form-control"
                >
                  <option [class.is-invalid]="genderNoValid">male</option>
                  <option [class.is-invalid]="genderNoValid">female</option>
                </select>
              </div>

              <div class="form-group">
                <div class="input-text">
                  <label>{{ "ACCOUNT.password1" | translate }}</label>
                  <small *ngIf="password1NoValid" class="text-danger">{{
                    "ACCOUNT.required" | translate
                  }}</small>
                </div>

                <input
                  type="password"
                  class="form-control"
                  formControlName="password1"
                  id="exampleInputPassword1"
                  [class.is-invalid]="password1NoValid"
                />
              </div>
              <div class="form-group">
                <div class="input-text">
                  <label>{{ "ACCOUNT.password2" | translate }}</label>
                  <small *ngIf="password2NoValid" class="text-danger">{{
                    "ACCOUNT.passwordError" | translate
                  }}</small>
                </div>

                <input
                  type="password"
                  class="form-control"
                  formControlName="password2"
                  id="exampleInputPassword1"
                  [class.is-invalid]="password2NoValid"
                />
              </div>

              <div class="form-group form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  formControlName="privacity"
                  id="exampleCheck1"
                  [class.is-invalid]="privacityNoValid"
                />
                <label class="form-check-label small"
                  >{{ "ACCOUNT.checkbox-privacity-policy" | translate }}
                  <a class="navbar-brand color-text" (click)="openDialog()">
                    {{
                    "ACCOUNT.privacy-policy-link" | translate
                  }}</a>
                </label>
                <span class="text-danger small" *ngIf="privacityNoValid"
                  >{{ "ACCOUNT.required" | translate }} 
                   <a class="navbar-brand color-text" (click)="openDialog()">
                    {{
                    "ACCOUNT.privacy-policy-link" | translate
                  }}
                </a>
                </span>
              </div>
              <button type="submit" class="btn btn-success btn-block">
                {{ "ACCOUNT.send" | translate }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="mainContainer" class="container">
    <mat-card class="mb-4 mt-5 mat-heigh">
                <div id="vertical">
                    <i id="back" (click)="backClient()" class="fa fa-arrow-left fa-2x text-danger float-left"></i> 
                    <h2 id="text" class=" text-danger float-left"> {{ "SEARCH.back" | translate }}</h2>
                    <h3 class="text-muted text-center">{{ "SEARCH.titlePage" | translate }}</h3>
                </div>
    </mat-card>
    <!--HeadClient-->
    <mat-card>
    <div class="row" id="headerInformation">
            <div class="col-12 col-md-4">
                <div class="container-image">
                    <div id="circle-image">
                        <img id="img-" class="img" [src]="doctor.profile_picture  | imgFalse" alt="doctor picture">
                    </div>
                </div>
            </div>
            <div id="info" class="col-12 col-md-4">
                <p><b>Titulo : </b> {{ doctor.title }} </p>
                <p><b>Nombre : </b> {{ doctor.first_name }} {{ doctor.last_name }} </p>
                <p><b>Ciudad : </b> {{ city }} </p>
                <p><b>Idiomas : </b> 
                    <span *ngFor="let item of doctor.language">
                    <span> {{ item.name }}, </span>
                    </span> </p>
            </div>
            <div id="info-2" class="col-12 col-md-4">
                <p><b>Descripción :</b> </p>
                <p id="description">{{ doctor.description }}</p>
                <p><b>Tratamientos : </b> 
                    <span *ngFor="let item of doctor.procedureTypes">
                       <p *ngIf="showEs === false ">{{ item.name }}</p> 
                       <p *ngIf="showEs === true ">{{ item.name_es }}</p> 
                    </span>
                </p>
            </div>
        </div>
    </mat-card>
    <div class="actionButtons">
        <button class="btn btn-outline-danger" (click)="openDialog()">Solicitar cita</button>
        <button class="btn btn-outline-danger" (click)="putFavorite(true)">{{ textFavorite }}</button>
    </div>
    <div id="bodyGallery" style="width: 100%;">
        <div class="card-columns">
            <div class="card" *ngFor="let picture of gallery">
                <mat-card>
                    <div class="container-img">
                        <div id="circle-img">
                            <img id="imgCard" [src]="picture.url" class="" alt="Doctor image">
                        </div>
                    </div>
                    <div  class="card-body">
                        <!-- <h5 class="card-title">{{ picture.date | date }}</h5> -->
                        <p class="card-text">{{ picture.description }}</p>
                        <p  class="card-text"><small  style="color: #009688;">{{ picture.date | date }}</small></p>
                    </div>
                </mat-card>
                </div>
            </div>
            
    </div>
</div>
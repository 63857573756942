import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { DirectFormService } from '../../services/direct-form.service';

@Component({
  selector: 'app-direct-form',
  templateUrl: './direct-form.component.html',
  styleUrls: ['./direct-form.component.css']
})
export class DirectFormComponent implements OnInit {

  constructor(private directFormService: DirectFormService,
              private router: Router,
              private activateRoute: ActivatedRoute) 
  { 


  }

  ngOnInit(): void {


    //Me permite recuperar el parametro de la url
    this.activateRoute.params.subscribe( params => {
      //El id viene del app.routes.ts que es como le llamamos a la variable agregada a la url
      ////console.log( params['id'] );
      let codeDoctor: string = params['id'];

      this.directFormService.infoDoctor( codeDoctor ).subscribe((resp:any) => {
        ////console.log(resp);
        //this.directFormService.doctorInfo = resp.doctor;

        let specialty: number;
        //specialty = resp?.doctor?.specialty;
        specialty = resp?.doctor?.specialty[0].id;


        ////console.log(specialty);

        let doctorInfo = {
          full_name: resp?.doctor?.full_name,
          specialty: resp?.doctor?.specialty[0],
          procedureTypes: resp?.doctor?.procedureTypes,
          code_doctor: codeDoctor
        };


        ////console.log(doctorInfo);
        localStorage.setItem('doctorInfo',JSON.stringify(doctorInfo));
        //////console.log(resp?.doctor?.specialty[0]);
        

        //aesthetic = 2, cardiologist = 4, endocrinologist = 5, surgery = 3, pulmonilogy = 6, place of reco = 7, Laborytory = 8, Fisio = 9, Naturalist = 10
        this.redirect( specialty );
      });
      //searchDoctor

    });
    
  }


  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  //Redirige en funcion de la especialidad del doctor, despues de recibir los datos del doctor
  public redirect( redirectTo:number ) {

    ////console.log(redirectTo);

    //redirectTo = 0;

    
    switch( redirectTo ) {

      case 3 : {
        //console.log("Ir a Cirugia");
        this.router.navigate(['direct-forms-surgery']);
        break;
      }
      case 2 : {
        //console.log("Ir a Estetica");
        this.router.navigate(['direct-forms-aesthetic']);
        break;
      }
      default: {
        //console.log("Formulario general con el resto de especialidades");
        this.router.navigate(['direct-forms-general']);
        break;
      }

    }
  }

}

<div class="container">
  <div class="row justify-content-center">
    <div class="col-12">
      <h2 mat-dialog-title class="text-center">Maarprog Corp</h2>
    </div>
    <p class="black" mat-dialog-title>Terms and Conditions</p>
  </div>

  <div class="row justify-content-center margin">
    <p>
      {{ "terms.text-1" | translate }}
    </p>
    <p>
      {{ "terms.text-2" | translate }}
      <a class="navbar-brand" [routerLink]="['/privacy-policy']">
        {{ "ACCOUNT.privacy-policy-link" | translate }}
      </a>
    </p>
  </div>
  <mat-dialog-content class="mat-typography">
    <div>
      <p class="black-text">
        {{ "terms.title-text-3" | translate }}
      </p>
      <p>{{ "terms.text-3" | translate }}</p>

      <h3 class="black-text">
        {{ "terms.title-text-4" | translate }}
      </h3>
      <p>{{ "terms.text-4" | translate }}</p>
      <ul class="">
        <li>{{ "terms.text-5" | translate }}</li>
        <li>{{ "terms.text-6" | translate }}</li>
        <li>{{ "terms.text-7" | translate }}</li>
        <li>{{ "terms.text-8" | translate }}</li>
        <li>{{ "terms.text-9" | translate }}</li>
        <li>{{ "terms.text-10" | translate }}</li>
        <li>{{ "terms.text-11" | translate }}</li>
        <li>{{ "terms.text-12" | translate }}</li>
        <li>{{ "terms.text-13" | translate }}</li>
        <li>{{ "terms.text-14" | translate }}</li>
      </ul>
    </div>
    <div>
      <p class="black-text">
        {{ "terms.title-text-15" | translate }}
      </p>
      <p>{{ "terms.text-15" | translate }}</p>
      <p>{{ "terms.text-16" | translate }}</p>
      <p class="black-text">
        {{ "terms.title-text-17" | translate }}
      </p>
      <p class="black-text">
        {{ "terms.subtitle-text-17" | translate }}
      </p>
      <p>{{ "terms.text-17" | translate }}</p>
      <p>{{ "terms.text-18" | translate }}</p>
      <p class="black-text">
        {{ "terms.subtitle-text-19" | translate }}
      </p>
      <p class="">{{ "terms.text-20" | translate }}</p>
      <p class="ml-4">{{ "terms.text-21" | translate }}</p>
      <p class="ml-4">{{ "terms.text-22" | translate }}</p>

      <ul class="">
        <li>
          <strong> Application </strong> (or app, App, APP) means the software
          program provided by the Company downloaded by you on any electronic
          device, named NovusDoc.
        </li>
        <li>
          <strong> Affiliate </strong> means an entity that controls, is
          controlled by or is under common control with a party, where "control"
          means ownership of 50% or more of the shares, equity interest or other
          securities entitled to vote for election of directors or other
          managing authority.
        </li>
        <li>
          <strong> Account </strong> means a unique account created for you to
          access our service or parts of our service.
        </li>
        <li>
          <strong> Associate</strong> means any healthcare provider that employs
          NovusDoc for handling patients’ requests for any of their medical
          specialties
        </li>
        <li>
          <strong>service </strong> refers to the Application and its function
          of collecting your information and sending it to the healthcare
          provider of your choice.
        </li>
        <li><strong> Country</strong> refers to the United States</li>
        <li>
          <strong> Service</strong> Provider means any natural or legal person
          who processes the data on behalf of the Company. It refers to
          third-party companies or individuals employed by the Company to
          facilitate the service, to provide the service on behalf of the
          Company, to perform services related to the service or to assist the
          Company in analyzing how the service is used.
        </li>
        <li>
          <strong> Third-party </strong> Social Media service refers to any
          website or any social network website through which a user can log in
          or create an account to use the service.
        </li>
        <li>
          <strong> Personal</strong> data is any information that relates to an
          identified or identifiable individual. For the purposes of the CCPA,
          personal data means any information that identifies, relates to,
          describes or is capable of being associated with, or could reasonably
          be linked, directly or indirectly, with you.
        </li>
        <li>
          <strong> Device</strong> means any device that can access the service
          such as a computer, a cellphone or a digital tablet.
        </li>
        <li>
          <strong> Usage</strong> data refers to data collected automatically,
          either generated by the use of the service or from the service
          infrastructure itself (for example, the duration of a page visit).
        </li>
        <li>
          <strong> Business</strong>, for the purpose of the CCPA (California
          Consumer Privacy Act), refers to the Company as the legal entity that
          collects Consumers' personal information and determines the purposes
          and means of the processing of Consumers' personal information, or on
          behalf of which such information is collected and that alone, or
          jointly with others, determines the purposes and means of the
          processing of consumers' personal information, that does business in
          the State of California.
        </li>
        <li>
          <strong>Consumer</strong>, for the purpose of the CCPA (California
          Consumer Privacy Act), means a natural person who is a California
          resident. A resident, as defined in the law, includes (1) every
          individual who is in the USA for other than a temporary or transitory
          purpose, and (2) every individual who is domiciled in the USA who is
          outside the USA for a temporary or transitory purpose.
        </li>
        <li>
          <strong>Sale</strong>, for the purpose of the CCPA (California
          Consumer Privacy Act), means selling, renting, releasing, disclosing,
          disseminating, making available, transferring, or otherwise
          communicating orally, in writing, or by electronic or other means, a
          Consumer’s personal information to another business or a third party
          for monetary or other valuable consideration.
        </li>
      </ul>
    </div>
    <div>
      <p class="black-text">Creating an Account on NovusDoc</p>
      <p>
        You, the user, can download and create an account with either your iOS
        or Android digital device. The information you provide should be
        accurate. In our Privacy Policy we discuss in detail how we handle the
        security of our users. As a general rule, you should create secure
        passwords that are not easily stolen or guessed. Be aware of any
        unauthorized access to your account and change your password as soon as
        possible if you suspect your account has been accessed by someone who is
        not authorized. Use passwords and real names carefully.
      </p>
      <p>
        The sign up and profile creation process on NovusDoc will ask you to
        provide pictures of your body which are very intimate. They are required
        by the healthcare providers for some treatments. That information is
        encrypted for your protection. Be careful about who you share your
        intimate pictures with, and make sure the doctor you selected is someone
        you can trust.
      </p>
      <p class="black-text">Minimum age</p>

      <p>You must be at least 18 years old to use NovusDoc app.</p>

      <p class="black-text">One account per user</p>
      <p>
        You are not allowed to let other people apply for healthcare services on
        NovusDoc using your account. Only one account per person is permitted.
      </p>
      <p>
        You will need an email address in order to sign up. If you do not have
        one, get one or ask someone you trust to help you opening an email
        account with any free providers on the internet.
      </p>
      <p class="black-text">Sensitive Medical Information</p>
      <p>
        Although privacy is the one of core foundations of our company, before
        you apply you need to consider whether you can go through sharing
        sensitive information about you (e.g., a medical condition such as HIV,
        etc.) that you will be asked to provide for the doctor to consider your
        case. NovusDoc is not responsible if because of any particular
        information you provide, the healthcare provider of your choice refuses
        to accept your request.
      </p>
      <p class="black-text">Payments</p>
      <p>
        Our app offers a paid service. We collect payments from users which can
        be paid on the same app through an electronic payment processor. We
        currently use Stripe, (www.stripe.com), which offers highly encrypted
        protection for your personal information such as credit or debit cards.
        You can pay using your debit or credit cards when you sign up for our
        service.
      </p>
      <p class="black-text">Means of Communication</p>
      <p>
        The preferred means of communication is email; we encourage user of our
        service to contact us via our email address. There might be other means
        that we will inform user as they become available, such us Chat, SMS or
        Instant Messaging such as WhatsApp. NovusDoc is not responsible for
        communication problems between you and the healthcare provider.
      </p>
      <p class="black-text">Language and Translations</p>
      <p>
        NovusDoc uses primarily English and Spanish as its operating languages.
        If you do not speak any of those, and use external means of translation,
        we are not responsible for any misunderstanding or inaccuracy of
        translation that may lead you in the wrong direction. In such case,
        contact us so we can find a way to assist you under no obligation
        whatsoever for either party.
      </p>
      <p class="black-text">Inappropriate Use of NovusDoc</p>
      <p>
        We adhere to ethical business practices. You may not use any of our
        content and service for illegal purposes. As a registered user, you are
        entitled solely for the purpose of providing the doctor with the
        information he/she requires of patients. You are not authorized to
        resell information or services to any third party, such as access,
        advice or connection to any of the healthcare providers on this
        platform. If you are found in any violation of these terms or any of the
        activity that is against the law, NovusDoc can terminate your account
        and if necessary, file a report to the police department.
      </p>
      <p class="black-text">Advertising</p>
      <p>There is no advertising on our app.</p>
      <p class="black-text">Property Rights</p>
      <p>
        NovusDoc and other names affiliated to Maarprog Corp are registered
        trademarks of aforementioned company. Other brands and trademarks
        mentioned in these Terms and Conditions are exclusive properties of
        their owners. By using our service, you agree not to violate any of the
        terms that apply to intellectual property, both in the United States and
        in other parts of the world where NovusDoc might conduct business, now
        or in the future. We reserve the right to purse legal actions against
        the misuse of any of our intellectual property, including logos, names,
        trademarks, software codes, etc.
      </p>

      <p class="black-text">General Guidelines for Healthcare Providers</p>
      <p>
        As a healthcare provider that opted to use NovusDoc for patient
        information management, there are certain guidelines hat we need to make
        sure are emphasized for the success of the system.
      </p>
      <ul class="">
        <li>
          Code of conduct: As healthcare provider, you are bound by an ethical
          code of conduct. We expect you maintain that level of professionalism
          in handling the information provided by your patients on this platform
        </li>
        <li>
          Appropriate use: patients expect quality service that is not
          threatening, intimidatory, harassing, abusive, defamatory, slanderous,
          libelous, harmful to minors, vulgar, gratuitously violent, obscene,
          pornographic, indecent, lewd, invasive of another's privacy, or
          racially, ethnically or otherwise offensive, hateful, or abusive. We
          might terminate our business relation if we find violation claims are
          founded.
        </li>
        <li>
          NovusDoc is a system to connect patients with healthcare providers; we
          do not endorse any particular doctor or service.
        </li>
        <li>
          Be as clear and upfront as possible with patients about what to
          expect. Although we are responsible for the outcome of a medical
          intervention, we aspire to be a service where patients also find
          reliable advice
        </li>
        <li>
          Try to verify as soon as possible the submitted information. If the
          profile is incomplete, ask patient through the same platform to
          provide more information.
        </li>
        <li>
          We strive for creating a system that is valued by you as a doctor as
          well as the patient. We encourage you to be friendly and responsive,
          even when a patient is being difficult.
        </li>
      </ul>

      <p class="black-text">Acknowledgement</p>
      <p>
        These are the Terms and Conditions governing the use of this service and
        the agreement that operates between you and the Company. These Terms and
        Conditions set out the rights and obligations of all users regarding the
        use of the service.
      </p>
      <p>
        your access to and use of the service is conditioned on your acceptance
        of and compliance with these Terms and Conditions. These Terms and
        Conditions apply to all visitors, users and others who access or use the
        service.
      </p>
      <p>
        By accessing or using the service you agree to be bound by these Terms
        and Conditions. If you disagree with any part of these Terms and
        Conditions then you may not access the service.
      </p>
      <p>
        your access to and use of the service is also conditioned on your
        acceptance of and compliance with the Privacy Policy of the Company. Our
        Privacy Policy describes Our policies and procedures on the collection,
        use and disclosure of your personal information when you use the
        Application or the Website and tells you about your privacy rights and
        how the law protects you. Please read Our Privacy Policy carefully
        before using Our service.
      </p>
      <p class="black-text">Links to Other Websites</p>
      <p>
        Our service may contain links to third-party web sites or services that
        are not owned or controlled by the Company.
      </p>
      <p>
        The Company has no control over, and assumes no responsibility for, the
        content, privacy policies, or practices of any third-party web sites or
        services. You further acknowledge and agree that the Company shall not
        be responsible or liable, directly or indirectly, for any damage or loss
        caused or alleged to be caused by or in connection with the use of or
        reliance on any such content, goods or services available on or through
        any such web sites or services.
      </p>
      <p>
        We strongly advise you to read the terms and conditions and privacy
        policies of any third-party web sites or services that you visit.
      </p>
      <p class="black-text">Notice about Apple (iOS)</p>
      <p>
        You acknowledge that this Terms apply to your use of NovusDoc service
        and not Apple’s. Apple Inc. is not involved or responsible in anyway
        with regards to the content of the app or the service. As an iOS user,
        we inform you that Apple Inc and its products (iPhone, iPads, iMacs,
        etc.) are not part of this agreement and they bear no consequence in
        whatever fault you might encounter with our service. Therefore, there is
        no obligation on Apple Inc.’s part to respond to issues pertaining to
        NovusDoc app on devices manufactured by them. All brands, logos and
        trademarks of their products are the exclusive right of Apple Inc., and
        any violation of property rights can have legal consequences.
      </p>
      <p class="black-text">Termination</p>
      <p>
        We may terminate or suspend your access immediately, without prior
        notice or liability, for any reason whatsoever, including without
        limitation if you breach these Terms and Conditions. Upon termination,
        your right to use the service will cease immediately.
      </p>
      <p class="black-text">Limitation of Liability</p>
      <p>
        Notwithstanding any damages that you might incur, the entire liability
        of the Company and any of its suppliers under any provision of this
        Terms and your exclusive remedy for all of the foregoing shall be
        limited to the amount actually paid by you through the service or 100
        USD if you haven't purchased anything through the service.
      </p>
      <p>
        To the maximum extent permitted by applicable law, in no event shall the
        Company or its suppliers be liable for any special, incidental,
        indirect, or consequential damages whatsoever (including, but not
        limited to, damages for loss of profits, loss of data or other
        information, for business interruption, for personal injury, loss of
        privacy arising out of or in any way related to the use of or inability
        to use the service, third-party software and/or third-party hardware
        used with the service, or otherwise in connection with any provision of
        this Terms), even if the Company or any supplier has been advised of the
        possibility of such damages and even if the remedy fails of its
        essential purpose.
      </p>
      <p>
        Some states do not allow the exclusion of implied warranties or
        limitation of liability for incidental or consequential damages, which
        means that some of the above limitations may not apply. In these states,
        each party's liability will be limited to the greatest extent permitted
        by law.
      </p>
      <p class="black-text">"AS IS" and "AS AVAILABLE" Disclaimer</p>
      <p>
        The service is provided to you "AS IS" and "AS AVAILABLE" and with all
        faults and defects without warranty of any kind. To the maximum extent
        permitted under applicable law, the Company, on its own behalf and on
        behalf of its Affiliates and its and their respective licensors and
        service providers, expressly disclaims all warranties, whether express,
        implied, statutory or otherwise, with respect to the service, including
        all implied warranties of merchantability, fitness for a particular
        purpose, title and non-infringement, and warranties that may arise out
        of course of dealing, course of performance, usage or trade practice.
        Without limitation to the foregoing, the Company provides no warranty or
        undertaking, and makes no representation of any kind that the service
        will meet your requirements, achieve any intended results, be compatible
        or work with any other software, applications, systems or services,
        operate without interruption, meet any performance or reliability
        standards or be error free or that any errors or defects can or will be
        corrected.
      </p>
      <p>
        Without limiting the foregoing, neither the Company nor any of the
        company's provider makes any representation or warranty of any kind,
        express or implied: (i) as to the operation or availability of the
        service, or the information, content, and materials or products included
        thereon; (ii) that the service will be uninterrupted or error-free;
        (iii) as to the accuracy, reliability, or currency of any information or
        content provided through the service; or (iv) that the service, its
        servers, the content, or e-mails sent from or on behalf of the Company
        are free of viruses, scripts, trojan horses, worms, malware, timebombs
        or other harmful components.
      </p>
      <p>
        Some jurisdictions do not allow the exclusion of certain types of
        warranties or limitations on applicable statutory rights of a consumer,
        so some or all of the above exclusions and limitations may not apply to
        you. But in such a case the exclusions and limitations set forth in this
        section shall be applied to the greatest extent enforceable under
        applicable law.
      </p>
      <p class="black-text">Governing Law</p>
      <p>
        The laws of the Country, excluding its conflicts of law rules, shall
        govern this Terms and your use of the service. your use of the
        Application may also be subject to other local, state, national, or
        international laws.
      </p>
      <p class="black-text">California Online Privacy Statute</p>

      <p><strong>Your Rights under the CCPA </strong></p>
      <p>
        Under this Privacy Policy, and by law if you are a resident of
        California, you have the following rights:
      </p>
      <ul class="">
        <li>
          The right to notice. You must be properly notified which categories of
          personal data are being collected and the purposes for which the
          personal data is being used.
        </li>
        <li>
          The right to access / the right to request. The CCPA permits you to
          request and obtain from the Company information regarding the
          disclosure of your personal data that has been collected in the past
          12 months by the Company or its subsidiaries to a third-party for the
          third party’s direct marketing purposes.
        </li>
        <li>
          The right to say no to the sale of personal data. You also have the
          right to ask the Company not to sell your personal data to third
          parties. You can submit such a request by visiting our "Do Not Sell My
          Personal Information" section or web page.
        </li>
        <li>
          The right to know about your personal data. You have the right to
          request and obtain from the Company information regarding the
          disclosure of the following:
          <ul>
            <li>The categories of personal data collected</li>
            <li>The sources from which the personal data was collected</li>
            <li>
              The business or commercial purpose for collecting or selling the
              personal data
            </li>
            <li>
              Categories of third parties with whom We share personal data
            </li>
            <li>The specific pieces of personal data we collected about you</li>
          </ul>
        </li>
        <li>
          The right to delete personal data. You also have the right to request
          the deletion of your personal data that have been collected in the
          past 12 months.
        </li>
        <li>
          The right not to be discriminated against. You have the right not to
          be discriminated against for exercising any of your Consumer's rights,
          including by:
          <ul>
            <li>Denying goods or services to you</li>
            <li>
              Charging different prices or rates for goods or services,
              including the use of discounts or other benefits or imposing
              penalties
            </li>
            <li>
              Providing a different level or quality of goods or services to you
            </li>
            <li>
              Suggesting that you will receive a different price or rate for
              goods or services or a different level or quality of goods or
              services.
            </li>
          </ul>
        </li>
      </ul>
      <p class="black-text">Exercising your CCPA data Protection Rights</p>
      <p>
        In order to exercise any of your rights under the CCPA, and if you are a
        California resident, you can email or call us or visit our "Do Not Sell
        My Personal Information" section or web page.
      </p>
      <p>
        The Company will disclose and deliver the required information free of
        charge within 45 days of receiving your verifiable request. The time
        period to provide the required information may be extended once by an
        additional 45 days when reasonably necessary and with prior notice.
      </p>

      <p class="black-text">Do Not Sell My Personal Information</p>
      <p>
        We do not sell personal information. However, the service providers we
        partner with (for example, our advertising partners) may use technology
        on the service that "sells" personal information as defined by the CCPA
        law.
      </p>
      <p>
        If you wish to opt out of the use of your personal information for
        interest-based advertising purposes and these potential sales as defined
        under CCPA law, you may do so by following the instructions below.
      </p>
      <p>
        Please note that any opt out is specific to the browser you use. You may
        need to opt out on every browser that you use.
      </p>
      <p class="black-text">Website</p>
      <p>
        You can opt out of receiving ads that are personalized as served by our
        service providers by following our instructions presented on the
        service:
      </p>
      <ul class="">
        <li>From Our "Cookie Consent" notice banner</li>
        <li>Or from Our "CCPA Opt-out" notice banner</li>
        <li>Or from Our "Do Not Sell My Personal Information" notice banner</li>
        <li>Or from Our "Do Not Sell My Personal Information" link</li>
      </ul>
      <p>
        The opt out will place a cookie on your computer that is unique to the
        browser you use to opt out. If you change browsers or delete the cookies
        saved by your browser, you will need to opt out again.
      </p>
      <p class="black-text">Disputes Resolution</p>
      <p>
        If you have any concern or dispute about the service, you agree to first
        try to resolve the dispute informally by contacting the Company.
      </p>
      <p class="black-text">For European Union (EU) Users</p>
      <p>
        If you are a European Union consumer, you will benefit from any
        mandatory provisions of the law of the country in which you are resident
        in.
      </p>
      <p class="black-text">United States Legal Compliance</p>
      <p>
        you represent and warrant that (i) you are not located in a country that
        is subject to the United States government embargo, or that has been
        designated by the United States government as a “terrorist supporting”
        country, and (ii) you are not listed on any United States government
        list of prohibited or restricted parties.
      </p>
      <p class="black-text">Severability and Waiver</p>
      <p class="black-text">Severability</p>
      <p>
        If any provision of these Terms is held to be unenforceable or invalid,
        such provision will be changed and interpreted to accomplish the
        objectives of such provision to the greatest extent possible under
        applicable law and the remaining provisions will continue in full force
        and effect.
      </p>
      <p class="black-text">Waiver</p>
      <p>
        Except as provided herein, the failure to exercise a right or to require
        performance of an obligation under this Terms shall not effect a party's
        ability to exercise such right or require such performance at any time
        thereafter nor shall be the waiver of a breach constitute a waiver of
        any subsequent breach.
      </p>
      <p class="black-text">Translation Interpretation</p>
      <p>
        These Terms and Conditions may have been translated if We have made them
        available to you on our service.
      </p>
      <p>
        you agree that the original English text shall prevail in the case of a
        dispute.
      </p>
      <p class="black-text">Changes to These Terms and Conditions</p>
      <p>
        We reserve the right, at Our sole discretion, to modify or replace these
        Terms at any time. If a revision is material, we will make reasonable
        efforts to provide at least 30 days' notice prior to any new terms
        taking effect. What constitutes a material change will be determined at
        our sole discretion.
      </p>
      <p>
        By continuing to access or use our service after those revisions become
        effective, you agree to be bound by the revised terms. If you do not
        agree to the new terms, in whole or in part, please stop using the
        website and the service.
      </p>
      <p class="black-text">Contact Us</p>
      <p>
        If you have any questions about these Terms and Conditions, you can
        contact us:
      </p>
      <p>By email: privacy@novusdoc.com</p>
      <p>Last updated: February 03, 2020</p>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
  </mat-dialog-actions>
</div>

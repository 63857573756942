import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DoctorModel } from '../models/doctor.model';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as io from 'socket.io-client';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RequestsListService {
  idUser: string;
  private url = environment.ApiUrl;
  
  constructor( private http: HttpClient ) { 
  }


  getRequest() {
    this.idUser = localStorage.getItem('id');
    return this.http.get(`${ this.url }/patient-request/` + this.idUser )
                    .pipe( map ( (resp: any ) => 
                      {
                        return resp;
                      }));
  }


  getDoctorRequest( doctor_id: string ) {
    return this.http.get(`${ this.url }/search-doctor/` + doctor_id )
                    .pipe( map ( response => {
                    }))
  }
  deleteRequest( id: string ) {
    return this.http.delete(`${ this.url }/delete-request/` + id )
                    .pipe( map ( resp => {
                      return resp;
                    }));
  }


  // Permite determinar si accedemos a client desde search o desde request-list
  accesoClient: boolean = false;

   //Top de doctores
   getRequestsList() {
    // return this.doctorList;
  }
}

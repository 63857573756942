import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PaymentsService } from "src/app/services/payments.service";
import { TranslateServices } from "../../../services/translate.service";
import { DatePipe, Location } from "@angular/common";
import { PaymentModel } from "src/app/models/payment.model";
import * as html2pdf from "html2pdf.js";

@Component({
  selector: "app-payment-details-history",
  templateUrl: "./payment-details-history.component.html",
  styleUrls: ["./payment-details-history.component.css"],
})
export class PaymentDetailsHistoryComponent implements OnInit {
  public detailsList: PaymentModel[];
  idDetails: number;
  date: string;
  dateNow: string;

  constructor(
    private _paymentService: PaymentsService,
    private activatedRoute: ActivatedRoute,
    public location: Location,
    public translate: TranslateServices
  ) {
    this.translate.translateText();
  }

  ngOnInit(): void {
    this.getDetails();
  }
  getDetails() {
    this.activatedRoute.params.subscribe((resp: any) => {
      this.idDetails = resp.id;
      this._paymentService
        .getDetailsHistory(Number(resp.id))
        .subscribe((Response: any) => {
          this.detailsList = [Response.reservationPayment];
          this.date = new Date(this.detailsList[0].created_at).toLocaleString();
          this.dateNow = new Date().toLocaleString();
        });
    });
  }

  public downloadPDF() {
    const options = {
      filename: "Invoice.pdf",
      image: { type: "jpeg", quality: 0.99 },
      html2canvas: { dpi: 192, letterRendering: true },
      jsPDF: { orientation: "landscape" },
    };

    html2pdf()
      .from(
        `
    <div class="container">  
         <div class="row mt-3">
           <div class="col-md-6">
               <h3 class="text-left float-left">MaarProg.</h3>
               <h3 class="text-left float-left"> 
                <i class="fa fa-file-invoice-dollar" style="color:#009688; margin-left: 10px"> 
                </i> Recibo</h3>
           </div>
           <div class="col-md-6">
                <h5 class=" text-right text-muted"> ${this.dateNow} </h5>
           </div>
         </div>
         <hr>
         <div class="row mt-5">
            <div class="col-md-4">
                <p class="ml-4"> Nombre: </p>
                <p  class="ml-4"> Apellido: </p>
                <p class="ml-4"> Date: </p>
                <p class="ml-4"> Número de transacción: </p>
                <p class="ml-4"> Procedimiento: </p>
                <p class="ml-4"> Costo: </p>
                <p class="ml-4"> Estado: </p>
            </div>
            <div class="col-md-8">
                <p class="text-left"> ${this.detailsList[0].doctor.first_name} </p>
                <p class="text-left"> ${this.detailsList[0].doctor.last_name} </p>
                <p class=" text-left"> ${this.date} </p>
                <p class="text-left"> ${this.detailsList[0].transaction_number} </p>
                <p class="text-left"> ${this.detailsList[0].procedureType.name} </p>
                <p class="text-left"> ${this.detailsList[0].amount} </p>
                <p class="text-left"> <i class=" fa fa-check text-success  mr-2"> </i> </p>
              </div>
         </div>
         <hr>
         <div class="row" style="margin-top: 35px">  
         <div class="col-md-12">
         <table class="table">
             <thead class="thead-dark">
               <tr>
                 <th>Descripción</th>
                 <th>Total</th>
               </tr>
             </thead>
             <tbody>
               <tr>
                 <td rowspan="4">Este recibo, es utilizado como comprobante de pago realizado.</td>
                 <td>${this.detailsList[0].amount}</td>
               </tr>
               <tr>
               <td class="font-weight-bold">Monto Pagado</td>
               </tr>
               <tr>
               <td>${this.detailsList[0].amount}</td>
               </tr>
             </tbody>
           </table>
        </div>  
        </div>
    </div>

    `
      )
      .set(options)
      .save();
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { TranslateService } from '@ngx-translate/core';
import * as io from "socket.io-client";

@Injectable({
  providedIn: 'root'
})
export class FormGeneralService {

  // Todo lo que sea comun de las ur lo guardamos en una variable
  private url = environment.ApiUrl;
  private socket;
  public idUser: string;


  constructor(private http: HttpClient,
              private translate: TranslateService) 
  { 

    this.idUser = localStorage.getItem("idUser");
    this.socket = io(environment.SOCKET_ENDPOINT);
  }



  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //Conexion y desconexion del socket para notificar del envio del formulario
  public initConectionSocket() {
    console.log("Conectar Socket");
    console.log(this.idUser);

    const usuario = {
      user_id: this.idUser,
    };
    this.socket.emit("joinSystem", usuario);
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  // desconexion del socket para notificar del envio del formulario
  public disconnectSocket() {
    console.log("Desconectar Socket");
    this.socket.emit("disconnect");
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  // metodo para traer los datos del formulario si el usuario anteriormente ha completado el formulario
  public getData( patient_id ) {

    console.log(patient_id);

    return this.http.get(this.url + "/get-form/" + patient_id );
  }



  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //Lista de idiomas
  public getLanguage() {
    return this.http.get( this.url + '/languages');
  }


  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //Devuelve la informacion del doctor
  public infoDoctor( codeDoctor: any) {

    //console.log(this.url);
    //console.log(codeDoctor);

    return this.http.get( this.url + '/doctor-form-direct/' + codeDoctor );

  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //Detecta si el email esta ya en uso en la plataforma
  public emailVerifier( email: string ) {

    //console.log(email);
    let data = { 
      email: email 
    }

    //console.log(data);

    return this.http.post( this.url + '/validate-email', data );

  }



  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Crea el formulario basico
  public postForm( form:any, heightWeightIMC?:any, patient_id?:number  ) {
  
    //console.log(form);
    //console.log(form.dateBirth);
    //console.log(heightWeightIMC);

    this.translate.getBrowserLang();
    //console.log(this.translate.getBrowserLang());
    let primary_language_id: string = this.translate.getBrowserLang();

    let languagesToSend: any[] = [];
    let array: any[] = [];
    array = form.languages;
    for( let item of array ) {
      //console.log(item);
      languagesToSend.push({id:item.id});
    }

    let date2
    let day;
    let month;
    let year;
    let newDateofbirth:any;
    let last_transfusion_date: any;
    let last_pregnancy_date: any;
    //console.log(form);
    //console.log(form.birthday);
    if( form.birthday != "Invalid date" && form.dateBirth.length != 0 ) {
      //console.log("Entre en cumpleaños");

      date2 = new Date(form.dateBirth);
      //date2 = form.dateofbirth;
      //console.log(date2);

      day = date2.getDate();
      month = date2.getMonth();
      year = date2.getFullYear();

      newDateofbirth = year + "/" + (month + 1) + "/" + day;
    }

    date2 = new Date(form.last_transfusion_date);
    //console.log(date2);
    day = date2.getDate();
    month = date2.getMonth();
    year = date2.getFullYear();
    last_transfusion_date = year + "/" + (month + 1) + "/" + day;


    date2 = new Date(form.lastpregnancy);
    //console.log(date2);
    day = date2.getDate();
    month = date2.getMonth();
    year = date2.getFullYear();
    last_pregnancy_date = year + "/" + (month + 1) + "/" + day;

    //console.log(languagesToSend);


    let dateofbirth = new Date(newDateofbirth);
    let yearDateofbirth:any;
      //dateofbirth = resp.dateofbirth;

    let currentDate = new Date();

    let age = currentDate.getFullYear() - dateofbirth.getFullYear();

    dateofbirth.setFullYear( currentDate.getFullYear() );

    if( currentDate < dateofbirth ) {
      --age;
    }
      
    //yearDateofbirth = age;

    //console.log(age);
    ////console.log(yearDateofbirth);



    let data = {
      patient: {
        first_name: form.first_name,
        last_name: form.last_name,
        passport: form.identification,
        occupation: form.occupation,
        birthday: newDateofbirth,
        age: age,
        civil_status: form.status,
        religion: form.religion,
        phone: form.phone,
        primary_language_id: primary_language_id,
        email: form.email,
        address: form.address,
        emergency_person: form.emergencyPerson,
        emergency_contact: form.emergencyPersonPhone,
        city: form.city,
        country: form.country,
        language: languagesToSend,
        place_of_stay: form.residence, 
        place_of_stay_phone: form.residencePhone, 
      },
      patient_history: {
        last_pregnancy_date: last_pregnancy_date || null,
        previous_surgeries: form.previousSurgeries,
        gender: form.gender,
        weight: heightWeightIMC.weight,
        height: heightWeightIMC.height,
        imc: heightWeightIMC.imc,

        had_covid: form.had_covid,
        vaccinated_covid: form.vaccinated_covid,
        
        allergies: form.allergies,
        number_of_abortions: form.abortions,
        accepts_blood_transfusion: form.accept_transfusion,
        number_of_child_births: form.childbirth || 0,
        other_diseases: form.diseases,
        medicines_in_use: form.medicines,
        extra_information: form.extraInformation,
        asthma: form.asthma,
        falcemia: form.falcemia,
        diabetes: form.diabetes,
        smoker: form.smoker,
        alcohol: form.alcohol,
        oral_contraceptive: form.contraceptive,
        hiv: form.hiv,
        cancer: form.cancer,
        thalassemia: form.thalassemia,
        willebrand: form.willebrand,
        biopolymers: form.biopolymers,
        hypertension: form.hypertension,
        falcemia_carrier: form.pFalcemia,
        depression: form.depression,
        hookah: form.hookah,
        drugs: form.drugs,
        lactating: form.lactating,
        hepatitis_b_or_c: form.hepatitis,
        leukemia: form.leukemia,
        hemophilia: form.hemophilia,
        penicillin_allergy: form.penicillinAllergy,
        number_of_pregnancies: form.pregnancy || 0,
        number_of_cesareans: form.caesarean || 0,
        
        currently_pregnant: form.currently_pregnant,
        previous_transfusion : form.previous_transfusion,
        last_transfusion_date : last_transfusion_date || null,
        blood_type : form.blood_type,
        aspirin : form.aspirin,
        sedatives : form.sedatives,
        anti_hypertensive : form.anti_hypertensive,
        anti_depressants : form.anti_depressants,
        medicines_to_lose_weight : form.medicines_to_lose_weight,
        natural_medicines : form.natural_medicines,
        calcium : form.calcium,
        vitamins_e_d_c : form.vitamins_e_d_c,
        omega_3 : form.omega_3,
        leucine : form.leucine,
        sinusitis : form.sinusitis,
        hypercholesterolemia : form.hypercholesterolemia,
        tachycardia : form.tachycardia,
        neurological : form.neurological,
        circulatory : form.circulatory,
        skin : form.skin,
        gastrointestinal : form.gastrointestinal,
        breast_pathologies : form.breast_pathologies,
        hypothyroidism : form.hypothyroidism,
        hereditary_history : form.hereditary_history,
        anti_coagulants : form.anti_coagulants,
        oxycodone : form.oxycodone

      },
    };
    //this.idUser = localStorage.getItem("id") || undefined;

    //console.log(data);
    //console.log(patient_id);

    ////console.log(this.idUser);


    // Determinar de donde viene el formulario, surgery o esthetic
    return this.http.post(this.url + "/new-form/" + patient_id, data);



  }



  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //Envia y actualiza el formulario del paciente
  public sendRequest(info: any, patient_id:number) {
    //this.idUser = localStorage.getItem("id");

    //console.log(patient_id);
    //console.log(info);
    const data = {
      reason: info.reason,
      doctor_id: info.doctor_id,
      origin: info.origin,
      procedure_type_identifier: info.procedure_type_identifier,
      assistants_agreement: info.assistants_agreement,
      flight_agreement : info.flight_agreement,
      information_agreement : info.information_agreement
    };

    //console.log(data);

    return this.http.post( this.url + '/new-request/' + patient_id, data);
  }


  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Crea el formulario basico General
  public postFormGeneral( form:any, heightWeightIMC?:any, patient_id?:number  ) {


    console.log(form);
    console.log(heightWeightIMC);
    //console.log(form.dateBirth);
    //console.log(heightWeightIMC);

    this.translate.getBrowserLang();
    //console.log(this.translate.getBrowserLang());
    let primary_language_id: string = this.translate.getBrowserLang();

    let languagesToSend: any[] = [];
    let array: any[] = [];
    array = form.languages;
    for( let item of array ) {
      //console.log(item);
      languagesToSend.push({id:item});
    }

    //console.log(languagesToSend);
    let date2
    let day;
    let month;
    let year;
    let newDateofbirth:any;
    let last_transfusion_date: any;
    let last_pregnancy_date: any;
    //console.log(form);
    //console.log(form.birthday);
    if( form.birthday != "Invalid date" && form.dateBirth.length != 0 ) {
      //console.log("Entre en cumpleaños");

      date2 = new Date(form.dateBirth);
      //date2 = form.dateofbirth;
      //console.log(date2);

      day = date2.getDate();
      month = date2.getMonth();
      year = date2.getFullYear();

      newDateofbirth = year + "/" + (month + 1) + "/" + day;
    }

    date2 = new Date(form.last_transfusion_date);
    //console.log(date2);
    day = date2.getDate();
    month = date2.getMonth();
    year = date2.getFullYear();
    last_transfusion_date = year + "/" + (month + 1) + "/" + day;


    date2 = new Date(form.lastpregnancy);
    //console.log(date2);
    day = date2.getDate();
    month = date2.getMonth();
    year = date2.getFullYear();
    last_pregnancy_date = year + "/" + (month + 1) + "/" + day;

    //console.log(languagesToSend);


    let dateofbirth = new Date(newDateofbirth);
    let yearDateofbirth:any;
      //dateofbirth = resp.dateofbirth;

    let currentDate = new Date();

    let age = currentDate.getFullYear() - dateofbirth.getFullYear();

    dateofbirth.setFullYear( currentDate.getFullYear() );

    if( currentDate < dateofbirth ) {
      --age;
    }
      

    let data = {
      patient: {
        first_name: form.first_name,
        last_name: form.last_name,
        passport: form.identification,
        occupation: form.occupation,
        birthday: newDateofbirth,
        age: age,
        civil_status: form.status,
        religion: form.religion,
        phone: form.phone,
        primary_language_id: primary_language_id,
        email: form.email,
        address: form.address,
        emergency_person: form.emergencyPerson,
        emergency_contact: form.emergencyPersonPhone,
        city: form.city,
        country: form.country,
        language: languagesToSend,
        place_of_stay: form.residence, 
        place_of_stay_phone: form.residencePhone, 
      },
      patient_history: {
        last_pregnancy_date: last_pregnancy_date || null,
        gender: form.gender,
        weight: heightWeightIMC.weight,
        height: heightWeightIMC.height,
        imc: heightWeightIMC.imc,

        had_covid: form.had_covid,
        vaccinated_covid: form.vaccinated_covid,
        

        obesity: form.obesity,
        smoker: form.smoker,
        alcohol: form.alcohol,
        valvulopathy: form.valvulopathy,
        


        asthma: form.bronchialAsthma,
        syncope: form.syncope,
        oral_contraceptive: form.oral_contraceptive,
        hepatitis_b_or_c: form.hepatitis_b_or_c,


        diabetes: form.diabetes,
        myocardial_infarction: form.myocardial_infarction,
        cardiovascular_accident: form.cardiovascular_accident,
        thyroid_disease: form.thyroid_disease,
        
        heart_failure: form.heart_failure,
        high_blood_pressure_1: form.high_blood_pressure_1,
        high_blood_pressure_2: form.high_blood_pressure_2,



        allergies: form.allergies,
        previous_surgeries: form.previous_surgeries,
        other_diseases: form.diseases,
        medicines_in_use: form.medicines,
        hereditary_history : form.hereditary_history,
        extra_information: form.extraInformation,
        



        revascularization: form.revascularization,
        revascularization_date: form.revascularization_date,

        /*
        valvulopathy?: boolean,
        infarct?: boolean,
        valvular_disease?: boolean,
        cancer_history?: text,
        
        collocation?: boolean, */
        


      },
    };


    console.log(data);
    return this.http.post(this.url + "/new-form/" + patient_id, data);

  }




  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  // Recuerar imagenes
  public getImages( patient_id ) {

    console.log(patient_id);

    return this.http.get(this.url + "/search-image/" + patient_id );

  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////
  //Enviar Imagenes
  public sendImagest( imgObject: any, destination: string, patient_id:number ) {
    //this.idUser = localStorage.getItem("id");
    //console.log(destination);
    //console.log(patient_id);
    //console.log(imgObject);
    
    const fd = new FormData();

    fd.append(destination,imgObject.file,imgObject.name);

    //console.log(fd);

    return this.http.post( this.url + '/form/image-patient/' + patient_id, fd);
  }
}

import { Injectable,Output,EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  

  constructor() { }

  //Nos permite enviar la informacion de contacto.
  getContact( form: FormGroup ){
  }
}

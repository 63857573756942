import { Component, OnInit, HostListener } from "@angular/core";
import { ChatModel } from "src/app/models/chat.model";
import { ChatSocketService } from "src/app/services/chat-socket.service";
import { filter } from "rxjs/operators";
import { TranslateServices } from "src/app/services/translate.service";
import { ThrowStmt } from "@angular/compiler";

@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.css"],
})
export class ChatComponent implements OnInit {
  showMesssage = false;
  public Contact: any[];
  public messag: string = "";
  public chatId: number;
  public comingId: any;
  public sendUserId: number;
  public chatIdNotify: any;
  public waitDoctor = false;
  public waitMesage: string;
  public hideEdit: any;
  schedule_date: Date;
  public messageEdit: any;
  userIdNotification: number;
  payAppointment: any = {};
  doctorId: number;
  public imgUser: any;
  disable = false;
  //Creamos una referencia al div contenedor de los mensajes
  public textContainer: any;

  //Lista de nombres para cargar en las llamadas
  public chatListUser: any[] = [];

  //chat comunication
  public chats: any[];
  public chatsWithoutMessage: any[];
  public messageSender: any = [];
  public messageReciever: any[] = [];
  public sm: string;

  //Almacenamos la informacion de chat de un usuario
  public userChat;

  //User name
  public user: string = "Pedro";
  public chatTitle;
  public titleInChat: string;

  //Apagar encencer chat
  public apagar1: boolean = true;
  public apagar2: boolean = false;

  public isReadIdUser: number;
  public isRead = false;
  //Mensaje enviado desde el chat
  public message: string = "";

  //Tomamos el ID del usuario seleccionado
  public idSelectUser: string;

  //Permite recibir el nombre de la persona que se busca
  public person: string;
  public showListChat = true;
  public showListContainer = true;
  reservation_request_id = null;

  ///////////////////////
  mymessage;

  evenName = "message";

  //   onResize(event){
  //       console.log("Width: " + event.target.innerWidth);
  //       if ( event.target.innerWidth < 890 ) {
  //         this.modeSidebar = 'over';
  //         this.sideBarOpened = false;
  //       } else {
  //         this.modeSidebar = 'side';
  //         this.sideBarOpened = true;
  //       }

  // }
  constructor(
    private _socketService: ChatSocketService,
    public translate: TranslateServices
  ) {
    let fh = screen.height;
    this.sendUserId = Number(localStorage.getItem("idUser"));
    this.translate.translateText();
  }

  ngOnInit(): void {
    this.showContainer();
    this._socketService.initConectionSocket();
    this.recibeMessage();
    this._socketService.ReadMessage().subscribe((Response: any) => {
      localStorage.setItem("userComing", Response.userComing);
      localStorage.setItem("newMessages", Response.newMessages);
      localStorage.setItem("chatid", Response.chatid);

      this.isReadIdUser = Number(localStorage.getItem("userComing"));
      this.isRead = Boolean(localStorage.getItem("newMessages"));
      this.chatIdNotify = Number(localStorage.getItem("chatid"));
    });
    this.isReadIdUser = Number(localStorage.getItem("userComing"));
    this.isRead = Boolean(localStorage.getItem("newMessages"));
    this.chatIdNotify = Number(localStorage.getItem("chatid"));
    //Tomamos la lista de usuarios desde el servicio
    this.getAllChats();
    //Vinculamos el element con un elemento del html
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }
  showContainer() {
    if (window.innerWidth < 900) {
      this.showListChat = true;
      this.showMesssage = false;
      this.showListContainer = false;
    } else {
      this.showListChat = true;
      this.showMesssage = false;
      this.showListContainer = true;
    }
  }
  getAllChats() {
    this._socketService.getAllChat().subscribe((Response: any) => {
      this.chats = Response.ChatWithMessage;
      this.chatsWithoutMessage = Response.ChatWithoutMessage;
    });
  }

  search() {
    if (this.person !== "") {
      const firt_name = this.person.split(" ", 1);
      const lastName = this.person.split(" ", 3);
      let firstLastName = String(lastName[1]);
      let lastname = String(lastName[2]);
      let firstName = String(firt_name[0]);

      if (firstLastName === "undefined") {
        firstLastName = "";
      }
      if (lastname === "undefined") {
        lastname = "";
      }
      let fullName = firstLastName + " " + lastname;

      this.chats = this.chats.filter((item) => {
        return item.user.first_name
          .toLocaleLowerCase()
          .match(firstName.toLocaleLowerCase());
      });
      this.chatsWithoutMessage = this.chatsWithoutMessage.filter((item) => {
        return item.user.first_name
          .toLocaleLowerCase()
          .match(this.person.toLocaleLowerCase());
      });
    } else if (this.person == "") {
      this.ngOnInit();
    }
  }

  sendMessage() {
    if (this.messag.trim() === "") {
      return;
    } else {
      this.sendUserId = Number(localStorage.getItem("idUser"));
      this._socketService
        .sendMessage(this.chatId, this.sendUserId, this.messag)
        .subscribe((Response: any) => {
          if (Response.OK === false) {
            this.waitDoctor = true;
            this.waitMesage = Response.Error;
            setTimeout(() => {
              this.textContainer = document.getElementById("chat-container");
              //Despues de recibir los mensajes muevo el scroll hasta el final
              this.textContainer.scrollTop = this.textContainer.scrollHeight;
            }, 20);
          } else {
            this.waitDoctor = false;
          }
        });
      this.messag = "";
    }
    this.isRead = false;
  }

  getMessage(
    id: number,
    firstName: string,
    lastName: string,
    touch: boolean,
    img: any,
    doctorId: number
  ) {
    this.showMesssage = true;
    this.doctorId = doctorId;
    this.chatId = id;
    var chat = Number(localStorage.getItem("chatid"));
    if (id == chat) {
      localStorage.removeItem("userComing");
      localStorage.removeItem("newMessages");
      localStorage.removeItem("chatid");
    }
    this._socketService.getMessage(id).subscribe((response: any) => {
      this.getDateAndRequest();
      localStorage.setItem("chatId", String(id));
      this.showMesssage = true;
      this.chatTitle = firstName + " " + lastName;
      this.imgUser = img;
      this.messageSender = response.data;
      this.textContainer = document.getElementById("chat-container");
      setTimeout(() => {
        //Despues de recibir los mensajes muevo el scroll hasta el final
        this.textContainer.scrollTop = this.textContainer.scrollHeight;
      }, 20);
    });
    this.isRead = false;

    if (window.innerWidth < 900 && touch === true) {
      this.showListChat = false;
      this.showMesssage = true;
      this.showListContainer = true;
    }
    // else {
    //   this.showListChat = true;
    //   this.showMesssage = false;
    // }
  }

  recibeMessage() {
    this._socketService.receiveMessage().subscribe((response: any) => {
      this.messageSender = response.data;
      setTimeout(() => {
        this.textContainer = document.getElementById("chat-container");
        //Despues de recibir los mensajes muevo el scroll hasta el final
        this.textContainer.scrollTop = this.textContainer.scrollHeight;
      }, 20);
    });
  }

  showEdit() {
    this.showMesssage = true;
    this.hideEdit = document.getElementById("show-edit");
    this.messageEdit = document.getElementById("message");

    if (this.hideEdit.style.display === "none") {
      this.hideEdit.style.display = "block";
      this.messageEdit.style.display = "none";
      setTimeout(() => {
        this.textContainer = document.getElementById("chat-container");
        //Despues de recibir los mensajes muevo el scroll hasta el final
        this.textContainer.scrollTop = this.textContainer.scrollHeight;
      }, 20);
    } else {
      this.hideEdit.style.display = "none";
      this.messageEdit.style.display = "block";
    }
  }

  sendEditMessage(messageEdited: string, id: number, chatID: number) {
    if (messageEdited.trim() === "") {
      return;
    }
    this.showMesssage = true;
    this.hideEdit = document.getElementById("show-edit");
    this.messageEdit = document.getElementById("message");

    this._socketService
      .editMessage(this.sendUserId, id, chatID, messageEdited)
      .subscribe((response: any) => {
        if (this.hideEdit.style.display === "block") {
          this.hideEdit.style.display = "none";
          this.messageEdit.style.display = "block";
        } else {
          this.hideEdit.style.display = "none";
          this.messageEdit.style.display = "block";
        }
        this.messageSender = response.data;
        setTimeout(() => {
          this.textContainer = document.getElementById("chat-container");
          //Despues de recibir los mensajes muevo el scroll hasta el final
          this.textContainer.scrollTop = this.textContainer.scrollHeight;
        }, 20);
      });
  }

  getDateAndRequest() {
    this._socketService
      .getDateAndRequest(this.doctorId)
      .subscribe((Response: any) => {
        this.schedule_date = Response.appointment.schedule_date;
        this.payAppointment = Response.appointment;
        this.disable = this.payAppointment.is_disabled;
        this.reservation_request_id =
          this.payAppointment.reservation_request_id;
      });
  }
}

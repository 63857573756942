import { Component, OnInit } from "@angular/core";
import { TranslateServices } from "../../services/translate.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-forms",
  templateUrl: "./forms.component.html",
  styleUrls: ["./forms.component.css"],
})
export class FormsComponent implements OnInit {
  constructor(public translate: TranslateServices, private router: Router) {
    this.translate.translateText();
  }

  ngOnInit(): void {}


  
  //Permite movernos a diferentes rutas
  public goTo( route ) {

    console.log(route);
    switch( route ) {
      case 1: {
        this.router.navigateByUrl("form-surgery2");
        break;
      }
      case 2: {
        this.router.navigateByUrl("form-esthetic2");
        break;
      }
      case 3: {
        this.router.navigate(['form-general2']);
        break;
      }
    }
  }
}

<div class="container-fluid">
  <div class="row">
    <div class="" id="back-container"></div>
  </div>
  <!-- <div id="back-container"   class="row position-absolute"> </div> -->
  <mat-card class="mt-5" id="mat-success">
    <div class="row">
      <div class="mt-5 col-md-12">
        <p class="text-center">
          <i class="far fa-check-circle text-success fa-6x"> </i>
        </p>
        <h2 class="text-center">{{ "SUCCESS-PAYMENT.title" | translate }}</h2>
      </div>
    </div>
    <div class="row">
      <div id="text-success" class="col-md-12 text-center">
        <p id="text">
          {{ "SUCCESS-PAYMENT.text-1" | translate }}
        </p>
        <div *ngIf="loading === true" id="loading" class="text-center">
          <div class="spinner-border text-success" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <!-- <p> <small>Se le estará enviando un correo con el pago realizado</small></p>  -->
        <p id="muted" class="text-muted">
          {{ "SUCCESS-PAYMENT.text-2" | translate }}.
        </p>
        <button
          [routerLink]="['/payment/details-history', idDetails]"
          class="btn btn-secondary mb-4"
        >
          {{ "SUCCESS-PAYMENT.btn-text" | translate }}
        </button>
      </div>
    </div>
  </mat-card>
</div>

<div class="container ">
    <div class="row">
        <div class="col">
            <!--Titulo del login-->
            <div id="title" class="text-center mt-5 mb-5">
                <h2> {{ "FORGOTPASSWORD.title2" | translate }} </h2>
            </div>
            <!--Card-->
            <div class="card">
                <div class="card-body">
                    <form autocomplete="on"   [formGroup]="form" (submit)="changePassword()" >
                        <div class="form-group">
                            <label> {{ "FORGOTPASSWORD.password1" | translate }} </label>
                            <input 
                                type="password"
                                class="form-control" 
                                name="password1"
                                placeholder="*********" 
                                formControlName="password1"
                                [class.is-invalid]="password1NoValid">
                        </div>
                        <div class="form-group">
                            <label> {{ "FORGOTPASSWORD.password2" | translate }} </label>
                            <input 
                               type="password"
                               class="form-control" 
                               formControlName="password2"
                               name="password2"
                               placeholder="*********" 
                              [class.is-invalid]="password2NoValid">
                        </div>
                        <button type="submit" class="btn btn-success btn-block"> {{ "FORGOTPASSWORD.send" | translate }} </button>
                    </form>
                </div>
            </div>

        </div>
    </div>


</div>
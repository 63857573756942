<mat-toolbar *ngIf="flag.navbarButtonsActive">
  <mat-toolbar-row>
    <span>
      <a class="text-white text-center" *ngIf="flag.navbarButtonsActive"
        >NovusDoc</a
      >
    </span>
    <button
      id="menu-btn"
      mat-icon-button
      (click)="toggleSideBar()"
      *ngIf="flag.navbarButtonsActive"
    >
      <mat-icon class="text-white">menu</mat-icon>
    </button>
    <span>
      <a
        class="text-white"
        [routerLink]="['home']"
        *ngIf="!flag.navbarButtonsActive"
        >NovusDoc </a
      >
    </span>
    <span class="example-spacer"></span>
    <div>
  
      <ul class="row text-white mt-2">
        <li *ngIf="!moveText">
          <a class="nav-link" title="{{ 'NAVBAR.home' | translate }}"   matTooltip="{{ 'NAVBAR.home' | translate }}"
          [routerLink]="['start']">
          <i class="fa fa-home icon-nav fa-2x"></i>
          </a>
        </li>
        
        <li class="nav-item" routerLinkActive="active">
          <a
            class="nav-link"
            [routerLink]="['news']"
            matTooltip="{{ 'NAVBAR.news' | translate }}"
            *ngIf="flag.navbarButtonsActive && !moveText"
            title="{{ 'NAVBAR.news' | translate }}"
          >
            <i class="fa fa-newspaper icon-nav fa-2x"></i>
          </a>
        </li>
        <li
          class="nav-item mt-1"
          routerLinkActive="active"
          *ngIf="flag.navbarButtonsActive"
        >
          <button
            id="btn"
            class="btn"
            matTooltip="{{ 'NAVBAR.titlenotify' | translate }}"
            (click)="getNotification()"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-bell text-white icon-nav fa-2x"></i>
          </button>
          <div
            class="dropdown-menu notification shadow animated fadeIn fast"
            aria-labelledby="dropdownMenuButton"
          >
            <span id="text-notify" class="text-muted">
              {{ "NAVBAR.titlenotify" | translate }}
            </span>
            <a *ngFor="let item of notification">
              <hr />
              <div
                id="notification-list"
                mat-menu-item
                [routerLink]="[item.route, item.router_id]"
                *ngIf="flag.navbarButtonsActive"
              >
                <div class="container-img">
                  <div id="circle-img">
                    <img
                      id="imgCard"
                      [src]="item.doctor.profile_picture | imgFalse"
                      alt="Doctor image"
                    />
                  </div>
                </div>
                <div id="heigth-message">
                  <label class="text-muted" id="message-text">
                    {{ item.message }}
                    <span *ngIf="item.type === 'GALLERY'">
                      {{ "NAVBAR.notify" | translate }}
                    </span>
                    <span *ngIf="item.type === 'PUBLICATION'">
                      {{ "NAVBAR.notificationnews" | translate }}
                    </span>
                  </label>
                </div>
              </div>
            </a>
            <div *ngIf="noResult === true" class="justify-content-center">
              <hr />
              <div class="mt-4 text-center">
                <i class="fa fa-exclamation-triangle text-warning fa-3x"></i>
                <p class="text-muted">
                  {{ "NAVBAR.notification" | translate }}
                </p>
              </div>
            </div>
          </div>
        </li>

        <!-- Notification payment -->
        <li
          class="nav-item mt-1"
          routerLinkActive="active"
          *ngIf="flag.navbarButtonsActive"
        >
          <button
            id="btn"
            class="btn"
            (click)="notificationPayment(); showBadget();"
            type="button"
            matTooltip="{{ 'NAVBAR.titlenotify' | translate }}"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
          <i class="fa fa-credit-card text-white icon-nav fa-2x" [matBadge]="_socketService?.paymenttNotificationCount" 
          [matBadgeHidden]="!_socketService.paymentmentBadget">
          </i>
          
          <span *ngIf="haveNotification" class="badge badge-pill badge-warning">.
          </span>
          </button>
          <div
            class="dropdown-menu payment shadow animated fadeIn fast"
            aria-labelledby="dropdownMenuButton"
          >
            <span id="text-notify" class="text-muted">
              {{ "NAVBAR.titlenotify" | translate }}
            </span>
            <div class="overflow-notification">
              <a *ngFor="let item of paymentNotification">
                <hr />
                <div
                  id="notification-list"
                  mat-menu-item
                  [routerLink]="[
                    'payment/details/',
                    item.reservation_request_id
                  ]"
                  *ngIf="flag.navbarButtonsActive"
                >
                  <div class="container-img">
                    <div id="circle-img">
                      <img
                        id="imgCard"
                        [src]="item.doctor.profile_picture"
                        alt="Doctor image"
                      />
                    </div>
                  </div>
                  <div id="heigth-message">
                    <label class="text-muted" id="message-text">
                      {{ item.doctor.first_name }}
                      {{ item.doctor.last_name }}
                      {{ "PAYMENT.text-notification" | translate }}
                    </label>
                  </div>
                </div>
              </a>
              <div
                *ngIf="paymentNotification?.length === 0"
                class="justify-content-center"
              >
                <hr />
                <div class="mt-4 text-center">
                  <i class="fa fa-exclamation-triangle text-warning fa-3x"></i>
                  <p class="text-muted">
                    {{ "NAVBAR.notification" | translate }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </li>
        <!-- finish -->
     
        <li class="nav-item" id="favorite" routerLinkActive="active">
          <a
            class="nav-link"
            [routerLink]="['favorite']"
            matTooltip="{{ 'NAVBAR.favorite' | translate }}"
            *ngIf="flag.navbarButtonsActive && !moveText"
            title="{{ 'NAVBAR.favorite' | translate }}"
          >
            <i class="fa fa-heart icon-nav fa-2x"></i>
          </a>
        </li>
        
        <li
        class="nav-item mt-1"
        routerLinkActive="active"
        matTooltip="Video Tour"
        *ngIf="flag.navbarButtonsActive " 
      >
        <button
          id="btn"
          class="btn"
          (click)="openDialog()"
          type="button"
        >
        <i class="material-icons text-white">
          smart_display
        </i>
        </button>
        </li>
        <li
          class="nav-item mt-1"
          routerLinkActive="active"
          *ngIf="flag.navbarButtonsActive"
        >
          <button
            id="btn"
            class="icon-nav"
            mat-button
            matTooltip="{{ 'NAVBAR.settings' | translate }}"
            [matMenuTriggerFor]="menu"
          >
          
            <i class="fas fa-user icon-nav fa-2x"></i>
          </button>
          <mat-menu #menu="matMenu" id="menu-panel">
            <button
              mat-menu-item
              [routerLink]="['contact/log']"
              *ngIf="flag.navbarButtonsActive"
            >
              <mat-icon> contacts</mat-icon>
              {{ "NAVBAR.contact" | translate }}
            </button>
            <button mat-menu-item [routerLink]="['settings']">
              <mat-icon> manage_accounts</mat-icon>
              {{ "NAVBAR.settings" | translate }}
            </button>
            <button mat-menu-item (click)="exit()">
              <mat-icon>exit_to_app</mat-icon>
              {{ "NAVBAR.exit" | translate }}
            </button>
          </mat-menu>
        </li>

        <!-- <li>
                    <button mat-icon-button>
                        <mat-icon [routerLink]="['start']" *ngIf="flag.navbarButtonsActive">settings</mat-icon>
                         {{ "NAVBAR.home" | translate }}
                    </button>
                </li> -->

        <!-- <li class="nav-item" routerLinkActive="active" *ngIf="!flag.navbarButtonsActive">
                    <a class="nav-link" [routerLink]="['home']">{{ "NAVBAR.home" | translate }}</a>
                </li>
                 -->
      </ul>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<nav class="navbar navbar-expand-lg" *ngIf="!flag.navbarButtonsActive">
  <a
    class="text-white navbar-brand"
    [routerLink]="['home']"
    *ngIf="!flag.navbarButtonsActive"
    >NovusDoc</a
  >
  <span class=""></span>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#content"
    aria-controls="content"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <mat-icon id="menu" class="text-white icon-nav">menu</mat-icon>
  </button>
  <div class="collapse navbar-collapse mr-4" id="content">
    <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
      <li
        class="nav-item"
        routerLinkActive="active"
        *ngIf="!flag.navbarButtonsActive"
      >
       
        <!--
        <a class="nav-link" [routerLink]="['direct-forms/MTRD485']">
          Directos
        </a> 
        -->
      </li>
      <li
        class="nav-item"
        routerLinkActive="active"
        *ngIf="!flag.navbarButtonsActive"
      >
        <a class="nav-link" [routerLink]="['home']">{{
          "NAVBAR.home" | translate
        }}</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a
          class="nav-link"
          [routerLink]="['about']"
          *ngIf="!flag.navbarButtonsActive"
          >{{ "NAVBAR.about" | translate }}</a
        >
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a
          class="nav-link"
          [routerLink]="['contact']"
          *ngIf="!flag.navbarButtonsActive"
          >{{ "NAVBAR.contact" | translate }}</a
        >
      </li>
      <li
        class="nav-item"
        routerLinkActive="active"
        *ngIf="!flag.navbarButtonsActive"
      >
        <a class="nav-link" [routerLink]="['login']"
          >{{ "NAVBAR.login" | translate }}
        </a>
      </li>
    </ul>
  </div>
</nav>

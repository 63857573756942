
<mat-card class="mt-5 ">
  <div class="container" id="mainContainer">
    <div class="row">
      <div id="divCol" class="col-6 col-md-4">
        <button type="button" class="btn btn-color" (click)="forms()">
          <div>
            <i class="fas fa-file-medical"></i>
            <p> {{ "START.form" | translate }} </p>
          </div>
        </button>
      </div>
      
      <div id="divCol" class="col-6 col-md-4">
        <button type="button" class="btn btn-color" (click)="search()">
          <div>
            <i class="fas fa-user-md"></i>
            <p>{{ "START.search" | translate }}</p>
          </div>
        </button>
      </div>
      <div id="divCol" class="col-6 col-md-4">
        <button  [matBadge]="_socketService.chatNotificationCount" [matBadgeHidden]="!_socketService.chatBadget" type="button" class="btn btn-color" (click)="chat()">
          <div class="text-right" *ngIf="haveNotification === true">
             <a class=" fa fa-circle text-success"></a> 
          </div>
          <div>
            <i class="fas fa-comments"></i>
            <p>{{ "START.chat" | translate }}</p>
          </div>
        </button>
      </div>
      <div id="divCol" class="col-6 col-md-4">
        <button type="button"  [matBadge]="_socketService?.requestNotificationCount" [matBadgeHidden]="!_socketService?.requestBadget" class="btn btn-color" (click)="requestList()">
          <div>
            <i class="fas fa-list-alt"></i>
            <p>{{ "START.request" | translate }}</p>
          </div>
        </button>
      </div>
      <div id="divCol" class="col-6 col-md-4">
       
        <button  [matBadge]="_socketService?.appointmentNotificationCount" [matBadgeHidden]="!_socketService?.appointmentBadget" type="button" class="btn btn-color" (click)="appointment()">
          <div>
            <i class="fas fa-calendar-alt"></i>
            <p>{{ "START.appointment" | translate }}</p>
          </div>
        </button>
      </div>
      <div id="divCol" class="col-6 col-md-4">
        <button type="button" class="btn btn-color" (click)="gallery()">
          <div>
            <i class="fas fa-images"></i>
            <p>{{ "START.gallery" | translate }}</p>
          </div>
        </button>
      </div>
    </div>
  </div>  
</mat-card>
import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../../../services/auth.service";

@Component({
  selector: "app-modal-user-first-time",
  templateUrl: "./modal-user-first-time.component.html",
  styleUrls: ["./modal-user-first-time.component.css"],
})
export class ModalUserFirstTimeComponent implements OnInit {
  
  urlVideo: any;
  loading: boolean;
  isFirstTime: string;

  constructor(private _authService: AuthService) {}

  ngOnInit(): void {
    this.loading = true;
    this.isFirstTime = localStorage.getItem("isFirstTime");
    this.getUrlVideo();
  }

  getUrlVideo() {
    this._authService.IsUserFirtTime().subscribe((response: any) => {
      this.urlVideo = response.handbook;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
  
    });
  }
}

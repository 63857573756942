<div class="container">
    <div class="row">
        <div class="col">

            <h3>{{ "PRIVACITY.title" | translate }}</h3>
            <p>

                We at Maarprog Corp understand the value of your privacy and your data. As a company in the business of information exchange, this Privacy Policy describes our policies and procedures on the collection, use and disclosure of your information when you
                use our service. As a user, this policy also details your privacy rights and how the law protects you, should you ever need to inquire or request information about how your private data is handled. We use your personal data to provide
                and improve the Service. You might be one of our associates (doctors, practitioners) or users who share information with doctors using our platform. By using the service, you agree to the collection and use of information in accordance
                with this Privacy Policy. Interpretation and Definitions Interpretation The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless
                of whether they appear in singular or in plural. Definitions For the purposes of this Privacy Policy: You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is
                accessing or using the Service, as applicable. Company (referred to as either "the Company", "We", "us" or "Our" in this Agreement) refers to Maarprog Corp, JP-5770, 8374 NW 64th St, Miami, FL 33195. Application (or app, App, APP) means
                the software program provided by the Company downloaded by you on any electronic device, named NovusDoc. Affiliate means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership
                of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority. Account means a unique account created for you to access our Service or parts of our Service. Associate
                means any healthcare provider that employs NovusDoc for handling patients’ requests for any of their medical specialties Service refers to the Application and its function of collecting your information and sending it to the healthcare
                provider of your choice. Country refers to the United States Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company
                to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used. Third-party Social Media Service refers to any website
                or any social network website through which a user can log in or create an account to use the Service. personal data is any information that relates to an identified or identifiable individual. For the purposes of the CCPA, personal data
                means any information that identifies, relates to, describes or is capable of being associated with, or could reasonably be linked, directly or indirectly, with you. Device means any device that can access the service such as a computer,
                a cellphone or a digital tablet. usage data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit). Business, for the purpose
                of the CCPA (California Consumer Privacy Act), refers to the Company as the legal entity that collects Consumers' personal information and determines the purposes and means of the processing of Consumers' personal information, or on behalf
                of which such information is collected and that alone, or jointly with others, determines the purposes and means of the processing of consumers' personal information, that does business in the State of California. Consumer, for the purpose
                of the CCPA (California Consumer Privacy Act), means a natural person who is a California resident. A resident, as defined in the law, includes (1) every individual who is in the USA for other than a temporary or transitory purpose, and
                (2) every individual who is domiciled in the USA who is outside the USA for a temporary or transitory purpose. Sale, for the purpose of the CCPA (California Consumer Privacy Act), means selling, renting, releasing, disclosing, disseminating,
                making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means, a Consumer’s personal information to another business or a third party for monetary or other valuable consideration. Collecting
                and Using Your Personal Data Types of Data Collected Personal data While using Our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable
                information may include, but is not limited to: Email address First name and last name Phone number Address, State, Province, ZIP/Postal code, City usage data Usage data Usage data is collected automatically when using the service. Usage
                data may include information such as your device's internet protocol address (e.g. IP address), browser type, browser version, the pages of our service that you visit, the time and date of your visit, the time spent on those pages, unique
                device identifiers and other diagnostic data. When you access the service by or through a mobile device, we may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile device
                unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data. We may also collect information that your browser sends whenever
                you visit our Service or when you access the Service by or through a mobile device. Information Collected While Using the Application While using our Application, in order to provide features of our Application, we may collect, with your
                prior permission: Pictures and other information from your device's camera and photo library We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's
                servers and/or a Service Provider's server or it be simply stored on your device. You can enable or disable access to this information at any time, through your device settings. You can also enable or disable location services when you
                use Our Service at any time, through your device settings. Use of Your Personal Data The Company may use personal data for the following purposes: To provide and maintain our service, including to monitor the usage of our service. To manage
                your account: to manage your registration as a user of the service. The personal data you provide can give you access to different functionalities of the service that are available to you as a registered user. For the performance of a
                contract: the development, compliance and undertaking of the purchase contract for the products, items or services you have purchased or of any other contract with us through the service. To contact you: To contact you by email, telephone
                calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including
                the security updates, when necessary or reasonable for their implementation. To provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have
                already purchased or enquired about unless you have opted not to receive such information. To manage your requests: To attend and manage your requests to us. We may share your personal information in the following situations: With Service
                Providers: We may share your personal information with Service Providers to monitor and analyze the use of our service, to show advertisements to you to help support and maintain our service, to contact you, to advertise on third party
                websites to you after you visited our service or for payment processing. For Business transfers: We may share or transfer your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing,
                or acquisition of all or a portion of our business to another company. Associates: We may share or transfer your personal information with our associate healthcare providers whom you have been in contact prior to using our service. With
                Affiliates: We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include our parent company and any other subsidiaries, joint venture partners or other
                companies that we control or that are under common control with us. With Business partners: We may share your information with our business partners to offer you certain products, services or promotions. With other users: when you share
                personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If you interact with other users or register through a Third-Party Social
                Media Service, your contacts on the Third-Party Social Media Service may see your name, profile, pictures and description of your activity. Similarly, other users will be able to view descriptions of your activity, communicate with you
                and view your profile. Retention of your personal data The Company will retain your personal data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your personal data to the extent
                necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies. The Company will also retain usage data
                for internal analysis purposes. usage data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or we are legally obligated to retain
                this data for longer time periods. Transfer of your personal data Your information, including personal data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located.
                It means that this information may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.
                Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer. The Company will take all steps reasonably necessary to ensure that your data is treated securely and in accordance
                with this Privacy Policy and no transfer of your personal data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information. Disclosure of
                your personal data Business Transactions If the Company is involved in a merger, acquisition or asset sale, your personal data may be transferred. We will provide notice before your personal data is transferred and becomes subject to a
                different Privacy Policy. Law enforcement Under certain circumstances, the Company may be required to disclose your personal data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government
                agency). Other legal requirements The Company may disclose your personal data in the good faith belief that such action is necessary to: Comply with a legal obligation Protect and defend the rights or property of the Company Prevent or
                investigate possible wrongdoing in connection with the service Protect the personal safety of users of the service or the public Protect against legal liability Security of your personal data The security of your personal data is important
                to us but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal data, we cannot guarantee its absolute security.
                Detailed Information on the Processing of Your Personal Data Service providers have access to your personal data only to perform their tasks on our behalf and are obligated not to disclose or use it for any other purpose. Payments We may
                provide paid products and/or services within the service. In that case, we may use third-party services for payment processing (e.g. payment processors). We will not store or collect your payment card details. That information is provided
                directly to Our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council,
                which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information. Stripe Stripe is currently the electronic payment processing provider
                we use to collect payments from our customers. Their Privacy Policy can be viewed at https://stripe.com/us/privacy CCPA Privacy Your Rights under the CCPA Under this Privacy Policy, and by law if you are a resident of California, you have
                the following rights: The right to notice. You must be properly notified which categories of personal data are being collected and the purposes for which the personal data is being used. The right to access / the right to request. The
                CCPA permits you to request and obtain from the Company information regarding the disclosure of your personal data that has been collected in the past 12 months by the Company or its subsidiaries to a third-party for the third party’s
                direct marketing purposes. The right to say no to the sale of personal data. You also have the right to ask the Company not to sell your personal data to third parties. You can submit such a request by visiting our "Do Not Sell My Personal
                Information" section or web page. The right to know about your personal data. You have the right to request and obtain from the Company information regarding the disclosure of the following: The categories of personal data collected The
                sources from which the personal data was collected The business or commercial purpose for collecting or selling the personal data Categories of third parties with whom We share personal data The specific pieces of personal data we collected
                about you The right to delete personal data. You also have the right to request the deletion of your personal data that have been collected in the past 12 months. The right not to be discriminated against. You have the right not to be
                discriminated against for exercising any of your Consumer's rights, including by: Denying goods or services to you Charging different prices or rates for goods or services, including the use of discounts or other benefits or imposing penalties
                Providing a different level or quality of goods or services to you Suggesting that you will receive a different price or rate for goods or services or a different level or quality of goods or services. Exercising your CCPA data Protection
                Rights In order to exercise any of your rights under the CCPA, and if you are a California resident, you can email or call us or visit our "Do Not Sell My Personal Information" section or web page. The Company will disclose and deliver
                the required information free of charge within 45 days of receiving your verifiable request. The time period to provide the required information may be extended once by an additional 45 days when reasonably necessary and with prior notice.
                Do Not Sell My Personal Information We do not sell personal information. However, the service providers we partner with (for example, our advertising partners) may use technology on the service that "sells" personal information as defined
                by the CCPA law. If you wish to opt out of the use of your personal information for interest-based advertising purposes and these potential sales as defined under CCPA law, you may do so by following the instructions below. Please note
                that any opt out is specific to the browser you use. You may need to opt out on every browser that you use. Website You can opt out of receiving ads that are personalized as served by our service providers by following our instructions
                presented on the service: From Our "Cookie Consent" notice banner Or from Our "CCPA Opt-out" notice banner Or from Our "Do Not Sell My Personal Information" notice banner Or from Our "Do Not Sell My Personal Information" link The opt out
                will place a cookie on your computer that is unique to the browser you use to opt out. If you change browsers or delete the cookies saved by your browser, you will need to opt out again. Mobile Devices Your mobile device may give you the
                ability to opt out of the use of information about the apps you use in order to serve you ads that are targeted to your interests: "Opt out of Interest-Based Ads" or "Opt out of Ads personalization" on Android devices "Limit Ad Tracking"
                on iOS devices You can also stop the collection of location information from your mobile device by changing the preferences on your mobile device. Children's Privacy Our service does not address anyone under the age of 13. We do not knowingly
                collect personally identifiable information from anyone under the age of 13. If you are a parent or guardian and you are aware that your child has provided us with personal data, please contact us. If we become aware that we have collected
                personal data from anyone under the age of 13 without verification of parental consent, we take steps to remove that information from our servers. We also may limit how we collect, use, and store some of the information of users between
                13 and 18 years old. In some cases, this means we will be unable to provide certain functionality of the service to these users. If we need to rely on consent as a legal basis for processing your information and your country requires consent
                from a parent, we may require your parent's consent before we collect and use that information. Links to Other Websites Our service may contain links to other websites that are not operated by us. If you click on a third-party link, you
                will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit. We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party
                sites or services. Changes to this Privacy Policy We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We will let you know via email and/or a prominent notice
                on our service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are
                effective when they are posted on this page. Contact us If you have any questions about this Privacy Policy, you can contact us: By email: privacy@novusdoc.com By Mail to the following address: Maarprog Corp 8374 NW 64th Street JP-5770
                Miami, FL 33195 United States of America Last updated: February 03, 2020

            </p>
        </div>
    </div>
</div>
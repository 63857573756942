import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormValidatorsService {

  constructor() { }


  //Validamos que las contraseña sean iguales al crear una cuenta
  equalPasswords(pass1: string, pass2: string) {
    //Atrapamos el formulario recibido
    return (form: FormGroup) => {
      const pass1Control = form.controls[pass1];
      const pass2Control = form.controls[pass2];

      if ( pass1Control.value === pass2Control.value ) {
        pass2Control.setErrors(null);
      }
      else {
        pass2Control.setErrors({ different: true });
      }

    }
  }
}

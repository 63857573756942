import { Injectable } from "@angular/core";
import * as io from "socket.io-client";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import * as Rx from "rxjs";
import { subscribeOn, map } from "rxjs/operators";
import { eventNames } from "process";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ChatSocketService {
  private socket;
  private idUser: string;

  chatNotificationCount: number;
  chatBadget: boolean = false;

  requestNotificationCount: number;
  requestBadget: boolean = false;

  appointmentNotificationCount: number;
  appointmentBadget: boolean = false;

  paymenttNotificationCount: number;
  paymentmentBadget: boolean = false;

  requestCountAccepted: number;
  requestAccepted: boolean = false;

  requestCountRejected: number;
  requestRejected: boolean = false;

 
  constructor(private http: HttpClient) {
    this.idUser = localStorage.getItem("idUser");
    this.socket = io(environment.SOCKET_ENDPOINT);
  }
  private url = environment.ApiUrl;

  initConectionSocket() {
    this.idUser = localStorage.getItem("idUser");
    const usuario = {
      user_id: this.idUser,
    };
    this.socket.emit("joinSystem", usuario);
  }

  haveMessage() {
    return new Observable((observer) => {
      this.socket.on("haveMessage", (response: any) => {
        observer.next(response);
      });
    });
  }

  public getAllChat = () => {
    const usuario = {
      user_id: Number(this.idUser),
    };
    this.socket.emit("enterChat", usuario);
    return Observable.create((observer) => {
      this.socket.on("getChats", (response: any) => {
        observer.next(response);
      });
    });
  };

  sendMessage(chatId: number, senderId: number, message: string) {
    const usuario = {
      user_id: senderId,
      chat_id: chatId,
      message: message,
    };
    this.socket.emit("message", usuario);
    return new Observable((observer) => {
      this.socket.on("WaitForDoctor", (response: any) => {
        observer.next(response);
      });
    });
  }

  getMessage(chatID: number) {
    const usuario = {
      user_id: Number(this.idUser),
      chat_id: chatID,
    };

    return new Observable((observer) => {
      this.socket.emit("getChat", usuario);
      this.socket.on("getChat", (response: any) => {
        observer.next(response);
      });
    });
  }

  public notificationManager() {
    const identifier = {
      is_manager: false
    };
    this.socket.emit("NotificationManager", identifier);
  }

  receiveMessage() {
    return new Observable((observer) => {
      this.socket.on("message", (response: any) => {
        observer.next(response);
      });
    });
  }
  ReadMessage() {
    return new Observable((observer) => {
      this.socket.on("getChatsByMessage", (response: any) => {
        observer.next(response);
      });
    });
  }

  editMessage(
    senderId: number,
    id: number,
    chatID: number,
    messageEdited: string
  ) {
    const usuario = {
      message_id: id,
      user_id: senderId,
      chat_id: chatID,
      message: messageEdited,
    };
    this.socket.emit("editMessage", usuario);
    return new Observable((observer) => {
      this.socket.on("editMessage", (response: any) => {
        observer.next(response);
      });
    });
  }

  Notifications() {
    return new Observable((observer) => {
      this.socket.on("chatNotification", (response: any) => {
        this.chatNotificationCount = response.countNotification;
        this.chatBadget = true;
        observer.next(response);
      });
    });
  }

  getDateAndRequest(doctorId: number) {
    const data = {
      patient_id: this.idUser,
    };
    return this.http
      .post(`${this.url}/last-reservation/${doctorId}`, data)
      .pipe(
        map((Response) => {
          return Response;
        })
      );
  }

  emitNotificationPayment() {
    return new Observable((observer) => {
      this.socket.on("NotificationSetPaymentRequest", (response: any) => {
        observer.next(response);
      });
    });
  }

  disconnectSocket() {
    this.socket.emit("disconnect");
  }

  emitNotification() {
    return new Observable((observer) => {
      this.socket.on("NotificationStatusRequest", (response: any) => {
        this.requestNotificationCount = response.countNotificationTotalRequest;
        this.requestBadget = true;

        this.requestCountAccepted = response.countNotificationAccepted;
        this.requestAccepted = true;

        this.requestCountRejected = response.countNotificationRejected;
        this.requestRejected = true;

        observer.next(response);
      });
    });
  }

  NotifyAppointment() {
    return new Observable((observer) => {
      this.socket.on("NotificationCreatedAppointment", (response: any) => {

        this.appointmentNotificationCount = response.countNotificationAppointment;
        this.appointmentBadget = true;
        observer.next(response);
      });
    });
  }

  paymentNotification(reservation_id: number) {
    this.socket.emit("MakePaymentNotification", reservation_id);
  }

  NotifyPay() {
    return new Observable((observer) => {
      this.socket.on("NotificationRequestPayment", (response: any) => {
        observer.next(response);

        this.paymenttNotificationCount = response.countNotification;
        this.paymentmentBadget = true;
      });
    });
  }

  checkNotification() {
    this.idUser = localStorage.getItem('id');
    return this.http.get(`${ this.url }/check-notification-patient/` + this.idUser )
          .pipe( map( resp => resp ));
  }
}

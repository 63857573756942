import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  HostListener,
} from "@angular/core";
import { TranslateServices } from "../../../services/translate.service";
import { AuthService } from "../../../services/auth.service";
import { Router } from "@angular/router";
import { FlagService } from "../../../services/flag.service";
import { ChatSocketService } from "src/app/services/chat-socket.service";
import { SearchService } from "src/app/services/search.service";
import { PaymentsService } from "../../../services/payments.service";
import Swal from "sweetalert2";
import { MatDialog } from "@angular/material/dialog";
import { ModalUserFirstTimeComponent } from "./modal-user-first-time/modal-user-first-time.component";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnInit {
  moveText = false;
  notification: any[];
  noResult = false;
  route = "";
  lang: string;
  showBagdet: any;
  haveNotification = false;
  paymentNotification: any[];
  checkNotification: any;
  
  constructor(
    private translate: TranslateServices,
    public auth: AuthService,
    private router: Router,
    public flag: FlagService,
    public _socketService: ChatSocketService,
    private _doctorService: SearchService,
    private _paymentService: PaymentsService,
    public dialog: MatDialog
  ) {
    this.translate.translateText();
  }

  ngOnInit(): void {
    this.moveInfo();
    this.lang = navigator.language;
    this.emitNotificationPayment();
    this.payNotification();

   if (this.flag.navbarButtonsActive) {
    this._socketService.checkNotification().subscribe( (Response: any) => {
      this.checkNotification = Response;
     
      this._socketService.paymenttNotificationCount =  Response.paymentCount;
      this._socketService.paymentmentBadget = Response.payment;
    });
   }  
  }

  toggleSideBar() {
    this.auth.toggle();
  }

  payNotification() {
    this._socketService.NotifyPay().subscribe((Response: any) => {
      if (Response.OK === true) {
        this.haveNotification = true;

        if (this.lang === "es-ES" || this.lang === "es") {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showCloseButton: true,
            timer: 8000,
            timerProgressBar: true,
          });
          Toast.fire({
            icon: "info",
            title: "Nueva solicitud de pago",
            showConfirmButton: false,
          });
        } else if (this.lang === "en-US" || this.lang === "en") {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showCloseButton: true,
            timer: 8000,
            timerProgressBar: true,
          });
          Toast.fire({
            icon: "info",
            title: "New payment request",
            showConfirmButton: false,
          });
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showCloseButton: true,
            timer: 8000,
            timerProgressBar: true,
          });
          Toast.fire({
            icon: "info",
            title: "New payment request",
            showConfirmButton: false,
          });
        }
      } else {
        this.haveNotification = false;
      }
    });
  }
  getNotification() {
    this._doctorService.getNotification().subscribe((item) => {
      this.notification = item;

      if (item.length === 0) {
        this.noResult = true;
      } else {
        this.noResult = false;
      }

      // this.moveRoute();
    });
  }


  showBadget() {
   this._socketService.paymentmentBadget = false;
  } 

  // Permite que salgamos
  exit() {
    // llamamos la función que destruye el token
    this._socketService.disconnectSocket();
    this.auth.logout();

    // Movemos al usuario al login
    this.router.navigateByUrl("home");
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (event.target.innerWidth < 890) {
      this.moveText = true;
    } else {
      this.moveText = false;
    }
  }
  moveInfo() {
    if (window.innerWidth < 890) {
      this.moveText = true;
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(ModalUserFirstTimeComponent, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  notificationPayment() {
    this._paymentService.getNotificationsPayment().subscribe((data: any) => {
      this.paymentNotification = data.NotificationReservationRequest;
    });
  }

  emitNotificationPayment() {
    this._socketService.emitNotificationPayment().subscribe((Response: any) => {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showCloseButton: true,
        timer: 8000,
        timerProgressBar: true,
      });
      Toast.fire({
        icon: "info",
        title: Response.message,
        showConfirmButton: false,
      });
    });
  }
}
